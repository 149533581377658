<template>
    <div class="product-slider-main-wrapper" :id="id">
        <div class="active-slide-preview">
            <div class="editable-content" :class="{ outlined: edit }">
                <drawable-images-toolbar
                    v-if="!hideControls"
                    :edit="edit"
                    :mode="mode"
                    :extended-mode="extendedMode && (getCurrentSlide && fileExtension(getCurrentSlide.original_name) !== 'pdf')"
                    :disable-files-update="disableFilesUpdate"
                    :disable-delete="!canDelete || disabledDeleteToolbar"
                    :disable-replace="!canReplace"
                    :is-renders-view="isRendersView"
                    @exit="drawingExit"
                    @undo="drawingUndo"
                    @redo="drawingRedo"
                    @delete="drawingDelete"
                    @add-comment="drawingAddComment"
                    @scale-up="drawingScaleUp"
                    @start-draw="drawingStart"
                    @commentsVisibilityChange="commentsVisibilityChange"
                    @replace="replaceFile"
                    @deleteFile="deleteFile"/>
                <v-responsive :aspect-ratio="4/3" class="overflow-visible">
                    <div v-if="!items.length" class="page-preloader"/>

                    <div class="preview">
                        <div class="image-wrapper swiper" :ref="id + '-image-wrapper'">
                            <div class="swiper-wrapper">
                                <div
                                    class="item swiper-slide"
                                    v-for="(item, index) of items"
                                    :key="item.id">
                                    <drawable-image
                                        v-if="item.type !== $config.fileTypes.models && item.type !== $config.fileTypes.others"
                                        :class="{ editable: edit }"
                                        :ref="'drawable-image-' + index"
                                        :key="'drawable-image-' + index"
                                        :name="id + '-' + item.id"
                                        :mode="mode"
                                        :file="item"
                                        :allow-replay-comment="allowReplayComment"
                                        :allow-edit-comment="allowEditComment"
                                        :allow-change-stone="allowChangeStone"
                                        :allow-change-metal="allowChangeMetal"
                                        :initially-opened-comment="initiallyOpenedComment"
                                        :comments="item.comments"
                                        :initial-data="
                                            currentFileHasDrawings
                                                ? items[activeSlideIndex].canvas_data
                                                : null
                                        "
                                        :atachments="atachments"
                                        :background-image="getBackgroundImage()"
                                        @addReply="
                                            $emit('addReply', {
                                                content: $event,
                                                fileId: items[activeSlideIndex].id,
                                            })
                                        "
                                        @addComment="addComment"
                                        @addStone="addAttachment($event, 'stone_type')"
                                        @addMetall="addAttachment($event, 'metal_type')"
                                        @redoComment="redoComment"
                                        @deleteComment="
                                            $emit('deleteComment', { file: item.id, comment: $event })
                                        "
                                        @updateComment="
                                            $emit('updateComment', { file: item.id, comment: $event })
                                        "/>

                                    <model-viewer
                                        v-if="item.type === $config.fileTypes.models"
                                        :src="item.url"
                                        :width="previewSliderWidth"
                                        :height="previewSliderHeight"
                                        :file-name="item.original_name"
                                        :file-id="item.id"
                                        @setControlls="setModelControls"
                                        @onLoad="getModelPreview"/>

                                    <v-img :src="require('../../assets/_redesigne/icons/ic-lazy-img.svg')"
                                           v-if="item.type === $config.fileTypes.others && fileExtension(item.original_name) !== 'pdf'"
                                           width="100%" height="100%" contain
                                           :style="{
                                               cursor: edit ? 'not-allowed' :''
                                           }">
                                        <span class="d-flex justify-center align-center w-100 fill-height file-ext text-h4 grey--text text--darken-2">
                                            {{ `.${fileExtension(item.original_name)}` }}
                                        </span>
                                    </v-img>

                                    <iframe
                                        v-if="item.type === $config.fileTypes.others && fileExtension(item.original_name) === 'pdf'"
                                        :src="item.url"
                                        width="100%"
                                        height="100%"
                                        frameborder="0"/>

                                    <div v-show="showPreloader"
                                         class="page-preloader"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-responsive>
            </div>
            <div class="info-panel">
                <template v-if="isClientView">
                    <div class="file-name text-truncate" v-if="items[activeSlideIndex]">
                        {{ items[activeSlideIndex].original_name }}
                    </div>
                    <div class="file-type" v-if="items[activeSlideIndex]">
                        Type: {{ $config.fileTypesLabels[items[activeSlideIndex].type] }}
                    </div>
                </template>

                <button
                    class="add-reference-btn text-no-wrap"
                    :class="{'ml-0': !isClientView}"
                    v-if="(isClientView || filesHasVersion) && showDownloadAllBtn && !isRendersView"
                    @click="downloadAllAssets(isClientView ? 'client' : 'designer')">
                    Download all assets
                </button>

                <button
                    v-if="(isClientView && isRendersView) && (isRendersCompleted || isCompleted)"
                    class="add-reference-btn text-no-wrap ml-0 mr-auto"
                    @click="downloadAllAssets('manager')">
                    Download all renders
                </button>

                <div class="file-name text-truncate mr-0" v-if="!isClientView && !isDesigner" :class="{'ml-auto': !isRendersView}">
                    <button
                        class="add-reference-btn text-no-wrap"
                        @click="uploadReferenceForComment"
                        v-if="!isRendersView && isReadyForReview">
                        + Add more assets
                    </button>

                    <template v-else>
                        {{ isRendersView ? "Renders" : "Model Files" }}
                    </template>
                </div>

                <button
                    class="add-reference-btn text-no-wrap"
                    @click="addReferences"
                    v-if="!disableFilesUpdate">
                    <template v-if="isDesigner">+ Add more 3D models</template>
                    <template v-else-if="isManagerOrAdmin && isRendersView">+ Add Renders</template>
                    <template v-else>+ Add more references</template>
                </button>
            </div>
        </div>

        <div class="slider">
            <button
                class="slider-controll slider-controll-prev"
                :ref="id + '-slider-controll-prev'">
                <img src="@/assets/_redesigne/icons/ic-arrow-previous.svg"/>
            </button>
            <div class="carousel" :ref="id + '-carousel'">
                <div class="swiper-wrapper">
                    <div v-if="enableDelete && modelThumbnailWidth" class="slide-delete-btns d-flex">
                        <div v-for="item of items"
                             :key="item.id"
                             :style="{width: `${modelThumbnailWidth}px`}"
                             class="flex-shrink-0">
                            <v-btn
                                v-if="(item.created_by === profile.id || isAdminProject) && ((isRendersView || isDesigner) ? item.version !== null : true)"
                                fab
                                icon
                                width="20"
                                height="20"
                                @click="$emit('deleteAllFileVersions', item)"
                                class="slide-delete-btn">
                                <img src="@/assets/_redesigne/icons/ic-close.svg"/>
                            </v-btn>
                        </div>
                    </div>
                    <div
                        class="swiper-slide"
                        v-for="(item, index) of items"
                        :key="item.id">
                        <div class="type text-uppercase">
                            {{ thumbFileTypeLabel(item) }}
                        </div>
                        <div class="slide">
                            <v-img
                                v-if="item.type !== $config.fileTypes.models && item.type !== $config.fileTypes.others"
                                lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                max-height="85%"
                                max-width="90%"
                                :src="item.url"/>
                            <v-img
                                v-if="item.type === $config.fileTypes.others"
                                lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                contain
                                width="38"
                                height="38"
                                max-height="85%"
                                max-width="90%"
                                :src="require('../../assets/_redesigne/icons/ic-lazy-img.svg')">
                                <span class="d-flex justify-center align-center w-100 fill-height file-ext grey--text text--darken-2">
                                    {{ `.${fileExtension(item.original_name)}` }}
                                </span>
                            </v-img>

                            <div class="model-wrapper" v-if="item.type === $config.fileTypes.models">
                                <v-img
                                    v-if="item.modelPreview"
                                    lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                    :max-height="slideHeight"
                                    :max-width="slideWidth"
                                    :src="item.modelPreview"/>
                                <v-img
                                    v-else
                                    lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                                    :max-height="slideHeight"
                                    :max-width="slideWidth"
                                    src="@/assets/_redesigne/icons/ic-lazy-img.svg"/>
                            </div>
                        </div>
                        <div class="comments-count" v-if="items[index].type !== $config.fileTypes.models && items[index].type !== $config.fileTypes.others">
                            <img src="@/assets/_redesigne/icons/ic-comment.svg"/>
                            {{ items[index].comments.length }}
                        </div>
                        <button class="download-item" @click="downloadFile(item)">
                            <img src="@/assets/_redesigne/icons/ic-download-icon.svg"/>
                        </button>
                    </div>
                </div>
            </div>
            <button
                class="slider-controll slider-controll-next"
                :ref="id + '-slider-controll-next'">
                <img src="@/assets/_redesigne/icons/ic-arrow-next.svg"/>
            </button>
        </div>

        <v-dialog v-model="modal" content-class="modal x2-modal" max-width="1270" scrollable>
            <v-card v-if="items[activeSlideIndex] && modal" class="modal-content-wrapper" flat>
                <v-card-text class="px-0 py-0">
                    <div class="border-plate">
                        <drawable-images-toolbar
                            :mode="mode"
                            :edit="edit"
                            :disable-files-update="disableFilesUpdate"
                            :disable-delete="!isDesigner || isDesigner && getCurrentSlide && getCurrentSlide.created_by !== profile.id"
                            :disable-replace="!isDesigner || isDesigner && getCurrentSlide && getCurrentSlide.type !== $config.fileTypes.models"
                            :extended-mode="extendedMode && (getCurrentSlide && fileExtension(getCurrentSlide.original_name) !== 'pdf')"
                            x2-modal
                            @exit="drawingExit"
                            @undo="drawingUndo"
                            @redo="drawingRedo"
                            @delete="drawingDelete"
                            @add-comment="drawingAddComment"
                            @commentsVisibilityChange="commentsVisibilityChangeInDialog"
                            @scale-up="drawingScaleUp"
                            @start-draw="drawingStart"/>
                        <v-responsive :aspect-ratio="4/3">
                            <div class="wrapper-dialog">
                                <drawable-image
                                    v-if="getCurrentSlide && getCurrentSlide.type !== $config.fileTypes.models && getCurrentSlide.type !== $config.fileTypes.others"
                                    ref="dialog-view"
                                    :name="id + '-' + items[activeSlideIndex].original_name + '-modal'"
                                    :mode="mode"
                                    :file="getCurrentSlide"
                                    :comments="items[activeSlideIndex].comments"
                                    :initial-data="
                                        currentFileHasDrawings
                                            ? items[activeSlideIndex].canvas_data
                                            : null
                                    "
                                    :allow-replay-comment="allowReplayComment"
                                    :allow-edit-comment="allowEditComment"
                                    :allow-change-stone="allowChangeStone"
                                    :allow-change-metal="allowChangeMetal"
                                    :atachments="atachments"
                                    :background-image="getBackgroundImage()"
                                    @addReply="
                                        $emit('addReply', {
                                            content: $event,
                                            fileIndex: activeSlideIndex,
                                        })
                                    "
                                    @addComment="addComment"
                                    @addStone="addAttachment($event, 'stone_type')"
                                    @addMetall="addAttachment($event, 'metal_type')"
                                    @redoComment="redoComment"
                                    @deleteComment="
                                        $emit('deleteComment', { file: items[activeSlideIndex].id, comment: $event })
                                    "
                                    @updateComment="
                                        $emit('updateComment', { file: items[activeSlideIndex].id, comment: $event })
                                    "/>
                                <model-viewer
                                    v-if="getCurrentSlide && getCurrentSlide.type === $config.fileTypes.models"
                                    :src="getCurrentSlide.url"
                                    :file-name="getCurrentSlide.original_name"
                                    :file-id="getCurrentSlide.id"/>
                                <v-img :src="require('../../assets/_redesigne/icons/ic-lazy-img.svg')"
                                       v-if="getCurrentSlide && getCurrentSlide.type === $config.fileTypes.others && fileExtension(getCurrentSlide.original_name) !== 'pdf'"
                                       width="100%" height="100%" contain
                                       :style="{cursor: edit ? 'not-allowed' :''}">
                                    <span class="d-flex justify-center align-center w-100 fill-height file-ext text-h4 grey--text text--darken-2">
                                        {{ `.${fileExtension(getCurrentSlide.original_name)}` }}
                                    </span>
                                </v-img>
                                <iframe
                                    v-if="getCurrentSlide && getCurrentSlide.type === $config.fileTypes.others && fileExtension(getCurrentSlide.original_name) === 'pdf'"
                                    :src="getCurrentSlide.url"
                                    width="100%"
                                    height="100%"
                                    frameborder="0"/>
                            </div>
                        </v-responsive>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <file-upload-modal
            :upload-modal-opened="uploadModalOpened"
            :mode="fileUploadMode"
            :accept="isRendersView ? rendersFilesFormats : fileUploadTypes"
            :type-image="isRendersView ? $config.fileTypes.reference : 'any'"
            :max-file-size="fileSize"
            :multiple-files-upload="isMultipleUpload && fileUploadMode !== 'add_comment'"
            :round-base-file-id="getRoundBaseFileId"
            :without-upload="fileUploadMode === 'add_comment'"
            @select="chooseFileComment"
            @open="uploadModalOpened = true"
            @close="uploadModalOpened = false; isMultipleUpload = true"
            @upload="bindFileToProject"/>
    </div>
</template>

<script>
    import Swiper, { EffectFade } from "swiper";
    import DrawableImagesToolBar from "@/components/DrawableImagesToolBar";
    import FileUploadModal from "@/components/FileUploadModal";
    import { mapActions, mapGetters } from "vuex";
    import { getExtension } from "@/utils/index.utils";
    import config from "@/utils/config";
    import { commentApi } from "@/api";
    Swiper.use([EffectFade]);

    export default {
        props: {
            allowReplayComment: {
                type: Boolean,
                default: true
            },
            allowEditComment: {
                type: Boolean,
                default: true
            },
            isClientView: {
                type: Boolean,
                default: true
            },
            isRendersView: {
                type: Boolean,
                default: false
            },
            id: {
                type: String
            },
            atachments: {
                default: null
            },
            items: {
                type: Array,
                default: () => []
            },
            extendedMode: {
                type: Boolean,
                default: false
            },
            disableFilesUpdate: {
                type: Boolean,
                default: false
            },
            initiallyOpenedComment: {
                type: [String, Boolean],
                default: null
            },
            hideControls: {
                type: Boolean,
                default: false
            },
            allowChangeStone: {
                type: Boolean,
                default: true
            },
            allowChangeMetal: {
                type: Boolean,
                default: true
            },
            disabledDeleteToolbar: {
                type: Boolean,
                default: false
            },
            enableDelete: {
                type: Boolean,
                default: true
            },
            showDownloadAllBtn: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.createCarousel();
            });
        },
        components: {
            DrawableImage: () => import("@/components/DrawableImage"),
            ModelViewer: () => import("@/components/3d-model/model"),
            "drawable-images-toolbar": DrawableImagesToolBar,
            "file-upload-modal": FileUploadModal
        },
        data: () => ({
            modal: false,
            carousel: null,
            edit: false,
            modelControls: {},
            modelThumbnailWidth: 0,
            previewSlider: null,
            mode: null,
            uploadModalOpened: false,
            fileToSwap: null,
            fileUploadMode: "add", //"add" or "swap" or "add_new_version"
            fileUploadTypes: ".jpg, .jpeg, .png",
            fileSize: {
                size: config.fileSizeLimit,
                label: "500MB"
            },
            showPreloader: false,
            imageZoom: {
                x: 2,
                y: 2
            },
            previousActiveIndex: 0,
            isMultipleUpload: true
        }),
        methods: {
            ...mapActions(["downloadAll"]),
            async downloadAllAssets(type) {
                const { data } = await this.downloadAll({ projectId: this.$route.params.id, type })
                await this.forceFileDownload(data)
            },
            forceFileDownload (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                const filename= `project_${this.$route.params.id}_${this.isClientView ? "client" : "designer"}_assets.zip`
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            },
            async downloadFile(file) {
                if(!file) return
                const image = await fetch(file.url);
                const imageBlog = await image.blob();
                const imageURL = URL.createObjectURL(imageBlog);

                const anchor = document.createElement("a");
                anchor.href = imageURL;
                anchor.download = file.original_name;

                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

                URL.revokeObjectURL(imageURL);
            },
            createCarousel() {
                const swiper = new Swiper(this.$refs[this.id + "-carousel"], {
                    speed: 400,
                    spaceBetween: 28,
                    slidesPerView: 3,
                    resizeObserver: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    followFinger: false,
                    longSwipes: false,
                    shortSwipes: false,
                    allowTouchMove: false,
                    observer: true,
                    breakpoints: {
                        280: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 3
                        }
                    },
                    on: {
                        slidesGridLengthChange: slider => {
                            this.modelThumbnailWidth = slider.slidesSizesGrid[0];
                        }
                    }
                });

                const preview = new Swiper(this.$refs[this.id + "-image-wrapper"], {
                    observer: true,
                    followFinger: false,
                    allowTouchMove: false,
                    effect: "fade",
                    fadeEffect: { crossFade: true },
                    navigation: {
                        nextEl: this.$refs[this.id + "-slider-controll-next"],
                        prevEl: this.$refs[this.id + "-slider-controll-prev"]
                    },
                    thumbs: {
                        swiper: swiper
                    },
                    on: {
                        slideChange: () => {
                            this.$emit("changeActiveFile", this.getCurrentSlide);
                            if(this.getCurrentSlide.type === this.$config.fileTypes.models){
                                this.drawingExit()
                            }
                        }
                    }
                });

                this.carousel = swiper;
                this.previewSlider = preview;
            },
            setModelControls(e) {
                this.modelControls[e.file_id] = e;
            },
            getModelPreview(model) {
                const file = this.items.find(item => item.id == model.id && item.type === this.$config.fileTypes.models);
                if (file) {
                    setTimeout(() => {
                        file.modelPreview = this.modelControls[file.id].snapshot()[1];
                        this.$emit("setModelPreview", {
                            slider: this.$props.id,
                            file
                        })
                    }, 200);
                }
            },
            getBackgroundImage() {
                let currentFile = this.items[this.activeSlideIndex];
                if(!currentFile) return
                if (this.items.length === this.activeSlideIndex) {
                    currentFile = this.items[this.items.length - 1];
                }
                if (currentFile.type === this.$config.fileTypes.models) {
                    return currentFile.modelPreview;
                }
                return currentFile.url;
            },
            redoComment(comment) {
                this.$emit("redoComment", comment);
            },
            addComment(commentData) {
                const comment = commentData;
                comment.fileId = this.items[this.activeSlideIndex].id;
                this.$emit("addComment", comment);
            },
            drawingExit() {
                this.$emit("updateFile", { fileIndex: this.activeSlideIndex });

                this.edit = false;
                this.mode = null;
            },
            drawingUndo() {
                const drawableImageModal = this.$refs["dialog-view"]
                const drawableImage =   this.$refs["drawable-image-" + this.activeSlideIndex][0];
                drawableImage.reduceHistoryStep();
                drawableImageModal?.reduceHistoryStep();
            },
            drawingRedo() {
                const drawableImageModal = this.$refs["dialog-view"]
                const drawableImage =   this.$refs["drawable-image-" + this.activeSlideIndex][0];
                drawableImage.increaseHistoryStep();
                drawableImageModal?.increaseHistoryStep();
            },
            async drawingDelete() {
                const drawableImageId = "drawable-image-" + this.activeSlideIndex;
                const drawableImage = this.$refs[drawableImageId][0];

                const confirmation = confirm(
                    "Are you sure, want to delete all comments and attachments?"
                );

                if (confirmation) {
                    const commentsToDelete = this.items[this.activeSlideIndex].comments.map(
                        (comment) => comment.id
                    );
                    this.$emit("deleteFileComments", this.items[this.activeSlideIndex]);
                    try {
                        await commentApi.removeBulk(this.$route.params.id, commentsToDelete)


                        this.items[this.activeSlideIndex].comments = []
                        drawableImage.removeAll();
                        this.drawingExit();
                    } catch (e) {
                        console.error(e)
                    }

                }
            },
            drawingAddComment() {
                if (this.mode === "comment") {
                    this.mode = "draw";
                } else {
                    this.mode = "comment";
                    this.createSnapshotFormCurrentFile()
                    this.edit = true;
                }
            },
            drawingScaleUp() {
                this.$emit("attachmentPlaced");
                this.modal = !this.modal;
            },
            createSnapshotFormCurrentFile() {
                const currentFile = this.items[this.activeSlideIndex];
                if (currentFile.type === this.$config.fileTypes.models) {
                    //is 3d model
                    const modelSnapshot = this.modelControls[currentFile.id].snapshot();
                    this.$emit("addSnapshot", modelSnapshot);
                }
            },
            drawingStart() {
                this.edit = true;
                this.createSnapshotFormCurrentFile()
                this.mode = "draw";
            },

            addAttachment(event,type) {
                const commentTypes = event.attachments.map(attach => attach.itemValue)
                const commentTitles = event.attachments.map(attach => attach.itemTitle)
                const payload = {
                    comment: commentTitles.join(", "),
                    file_id: this.items[this.activeSlideIndex].id,
                    [type]: commentTypes,
                    points: event.points,
                    x: event.position.x,
                    y: event.position.y,
                    brush_width: event.brush_width,
                    canvas_width: event.canvas_width,
                    canvas_height: event.canvas_height
                };
                if(type === "metal_type") {
                    this.$emit("addMetall", payload);
                } else {
                    this.$emit("addStone", payload);
                }
            },
            slideTo(index, speed = 200) {
                if (index < this.items.length && index >= 0) {
                    this.previewSlider.slideTo(index, speed);
                }
            },
            commentsVisibilityChange(isVisible) {
                const drawableImage =
                    this.$refs["drawable-image-" + this.activeSlideIndex][0];
                drawableImage.setCommentsVisibility(isVisible);
            },
            commentsVisibilityChangeInDialog(isVisible) {
                const drawableImageModal = this.$refs["dialog-view"]
                drawableImageModal?.setCommentsVisibility(isVisible);
            },
            replaceFile() {
                this.isMultipleUpload = false
                if (this.isDesigner || this.isRendersView) {
                    this.fileUploadMode = "add_new_version";
                    this.fileUploadTypes = [
                        ...this.$config.allowedFileExtension.models,
                        ...this.$config.allowedFileExtension.others_for_designer
                    ].join(", ");
                    this.uploadModalOpened = true;
                    return;
                }

                const file = this.items[this.activeSlideIndex];
                if(file.type === this.$config.fileTypes.models) {
                    this.fileUploadTypes = this.$config.allowedFileExtension.models.join(", ")
                } else {
                    this.fileUploadTypes = this.$config.allowedFileExtension.images.join(", ")
                }
                this.fileToSwap = file.id;
                this.uploadModalOpened = true;
                this.fileUploadMode = "swap";
            },
            deleteFile() {
                const file = this.items[this.activeSlideIndex];
                const eventName = file.id === file.round_base_file_id ? "deleteAllFileVersions" : "deleteFile";
                this.$emit(eventName, file);
            },
            chooseFileComment(files){
                this.$emit("addSnapshot", files)
                this.uploadModalOpened = false;
                this.fileUploadTypes = null;
                this.fileToSwap = null;
            },
            bindFileToProject(file) {
                if (this.fileUploadMode === "add" || this.fileUploadMode === "add_new_version") {
                    this.$emit("bindFileToProject", file);
                } else if (this.fileUploadMode === "swap") {
                    const playlod = {
                        newFile: file,
                        target: this.fileToSwap
                    };
                    this.$emit("swapFile", playlod);
                }
                this.uploadModalOpened = false;
                this.fileUploadTypes = null;
                this.fileToSwap = null;
            },
            addReferences(){
                if (this.isDesigner) {
                    this.fileUploadTypes = [
                        ...this.$config.allowedFileExtension.models,
                        this.$config.allowedFileExtension.others_for_designer
                    ].join(", ")
                } else if (this.isRendersView) {
                    this.fileUploadTypes= [
                        ...this.$config.allowedFileExtension.renders
                    ].join(", ")
                } else {
                    this.fileUploadTypes= [
                        ...this.$config.allowedFileExtension.images,
                        ...this.$config.allowedFileExtension.models,
                        ...this.$config.allowedFileExtension.others
                    ].join(", ")
                }
                this.fileUploadMode = "add";
                this.uploadModalOpened = true;
            },

            uploadReferenceForComment(){
                this.fileUploadTypes = [
                    ...this.$config.allowedFileExtension.images
                ].join(", ")
                this.fileUploadMode = "add_comment";
                this.uploadModalOpened = true;
            },
            setPreloader(value){
                this.showPreloader = value
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "isManager",
                "isManagerOrAdmin",
                "profile",
                "isAdminProject",
                "getProjectData"
            ]),

            thumbFileTypeLabel() {
                return (file) => {
                    const isSnapshot = file.props && file.props[0].isSnapshot
                    if(isSnapshot) {
                        return "Comment"
                    } else if(this.isRendersView) {
                        return file.comments.length ? "Comment": "Render"
                    } else {
                        return this.$config.fileTypesLabels[file.type]
                    }
                }
            },

            isRendersCompleted() {
                return this.getProjectData.status_id === this.$config.project.status.final_assets_prep;
            },

            isReadyForReview() {
                return this.getProjectData.status_id === this.$config.project.status.ready_for_review;
            },
            isCompleted() {
                return this.getProjectData.status_id === this.$config.project.status.completed;
            },
            fileExtension() {
                return (filename) => getExtension(filename)
            },
            rendersFilesFormats() {
                const formats = [...this.$config.allowedFileExtension.renders];
                return !this.getRoundBaseFileId ? formats.join(", ") : formats.filter(ext => ext !== ".zip" && ext !== ".7z" && ext !== ".rar").join(", ");
            },
            filesHasVersion() {
                return this.items.filter(item => item.type === this.$config.fileTypes.models).every((item) => item.version);
            },
            slideHeight() {
                const typeLabelHeight = 20;
                if (this.carousel && this.carousel.height) {
                    return this.carousel.height - typeLabelHeight;
                } else {
                    return 94; //fallback height (from Figma)
                }
            },
            slideWidth() {
                const paddings = 0;
                if (this.carousel && this.carousel.width) {
                    return this.carousel.width - paddings;
                } else {
                    return 143; //fallback height (from Figma)
                }
            },
            previewSliderWidth() {
                const paddings = 0;
                if (this.previewSlider && this.previewSlider.width) {
                    return this.previewSlider.width - paddings;
                } else {
                    return 479; //fallback height (from Figma)
                }
            },
            previewSliderHeight() {
                const paddings = 0;
                if (this.previewSlider && this.previewSlider.height) {
                    return this.previewSlider.height - paddings;
                } else {
                    return 412; //fallback height (from Figma)
                }
            },
            activeSlideIndex() {
                return this.previewSlider ? this.previewSlider.activeIndex : 0;
            },
            currentFileHasDrawings() {
                const item = this.items[this.activeSlideIndex];
                return item && item.comments;
            },
            getCurrentSlide() {
                return this.items[this.activeSlideIndex];
            },
            getRoundBaseFileId() {
                if (!this.getCurrentSlide) return null;
                return this.getCurrentSlide.id === this.getCurrentSlide.round_base_file_id ? this.getCurrentSlide.id : this.getCurrentSlide.round_base_file_id;
            },
            canDelete() {
                return !this.isDesigner && this.getCurrentSlide && this.getCurrentSlide.created_by === this.profile.id && this.getCurrentSlide.round === null && !this.isClientView ||
                    this.isDesigner && this.getCurrentSlide && this.getCurrentSlide.created_by === this.profile.id ||
                    !this.isClient && this.isRendersView && !this.isRendersCompleted && !this.isCompleted && this.getCurrentSlide && this.getCurrentSlide.version !== null
            },
            canReplace() {
                return this.isDesigner && this.getCurrentSlide && this.getCurrentSlide.type === this.$config.fileTypes.models && this.getCurrentSlide.created_by === this.profile.id ||
                    !this.isClient && this.isRendersView && !this.isRendersCompleted && !this.isCompleted && this.getCurrentSlide && this.getCurrentSlide.version !== null
            }
        },
        watch: {
            extendedMode(val) {
                if(!val) {
                    this.drawingExit()
                }
            },
            atachments(val) {
                if (val) {
                    this.drawingStart()
                } else  {
                    this.drawingExit()
                }
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>

<style>
.x2-modal.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
    padding: 0 !important;
}
.slide-delete-btn .v-btn__content {
    pointer-events: none;
}
</style>
