<template>
    <v-container
        class="pa-0"
        fluid
        style="display: flex">
        <v-stepper
            v-model="step"
            class="full-height steps"
            non-linear>
            <v-stepper-items
                class="flex-grow-1  d-flex overflow-y-auto overflow-x-hidden"
                style="z-index: 2; flex: 1 1 0;">
                <v-stepper-content
                    class="full-height mt-auto mb-auto"
                    step="1">
                    <start-project-tab>Start Project</start-project-tab>
                </v-stepper-content>
                <v-stepper-content
                    class="full-height mt-auto mb-auto upload-files-tab"
                    step="2">
                    <upload-files-tab :invalid-unique-stone-count="invalidUniqueStoneCount" :invalid-overall-stone-count="invalidOverallStoneCount"/>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <review-project-tab
                        :key="forceUpdate"
                        @saveEditable="(saveFnc)=> this.saveEditable = saveFnc"/>
                </v-stepper-content>
                <v-stepper-content
                    class="full-height model-specs"
                    step="4">
                    <div class="grid mx-auto" v-if="getProjectData.status === getValueProjectStatus($config.project.status.pricing_needed)">
                        <div

                            class="project-pricing-awaiting">
                            <h1 class="mb-5">Thank you for sending a custom project. Our internal team will take a look at it.</h1>
                            <p class="mb-0">You will get a notification when we finish reviewing your project.</p>
                            <p class="mb-8 mb-sm-12">In the mean time, please contact us if you have any more questions.</p>
                            <v-btn
                                width="160"
                                height="40"
                                depressed
                                rounded
                                outlined
                                link
                                :to="{name: 'support'}">
                                Contact Us
                            </v-btn>
                        </div>
                    </div>
                    <model-specs-tab v-else/>
                </v-stepper-content>
            </v-stepper-items>

            <div :class="['unselect', {'is-editing' : $store.getters.getCommentEditStatus}]">
                <v-stepper-header class="align-center  justify-center justify-sm-space-between">
                    <div
                        v-if="getProjectData.status !== this.getValueProjectStatus($config.project.status.pricing_needed)"
                        class="d-flex flex-wrap flex-grow-1 flex-md-grow-0 justify-md-space-between">
                        <v-stepper-step
                            :class="{'v-stepper__step--complete': step > 1 }"
                            :editable="projectId === null"
                            :step="1"
                            color="#9FF4A9">
                            Select Product
                        </v-stepper-step>

                        <stepper-arrow/>

                        <v-stepper-step
                            :step="2"
                            color="#9FF4A9"
                            editable>
                            Upload Files
                        </v-stepper-step>

                        <stepper-arrow/>

                        <v-stepper-step
                            :step="3"
                            :editable="projectId !== null"
                            color="#9FF4A9">
                            Model Specs
                        </v-stepper-step>

                        <stepper-arrow/>
                        <v-stepper-step
                            :step="4"
                            :editable="projectId !== null"
                            color="#9FF4A9">
                            Render Specs & Complete
                        </v-stepper-step>
                    </div>
                    <dialog-payment
                        v-model="paymentDialog"
                        :autocloseable="false"
                        :amount="$store.getters.getProjectAmount"
                        @loading="dialogLoading"/>
                    <div class="d-flex apd-btn-wrap ml-auto mr-auto mr-md-12 flex-wrap justify-center align-center justify-self-end text-right">
                        <v-btn
                            v-if="getProjectData.status !== this.getValueProjectStatus($config.project.status.pricing_needed)"
                            :disabled="disableStart"
                            :loading="loading"
                            class="accept-btn pl-14 pr-14 mb-2 mb-sm-0 justify-self-end text-right mr-sm-2"
                            color="#9FF4A9"
                            width="160"
                            height="40"
                            depressed
                            rounded
                            @click="step === 4 ? complete() : nextStep()">
                            {{ step === 4 ? "Complete" : "Continue" }}
                            <div v-if="disableStart" :class="['tooltip-wrapper', {'is-large' : $store.getters.getProjectData.unique_stone_count > $store.getters.getProjectData.overall_stone_count}]">
                                <div class="tooltip" v-if="!hasUploadedFiles()">Please upload files</div>
                                <div class="tooltip" v-if="step === 4 && !hasViews">Choose at least 1 angle</div>
                            <!--    <div class="tooltip" v-else-if="!$store.getters.getProjectData.text">Please enter dimensions</div>-->
                            <!--    <div class="tooltip" v-else-if="$store.getters.getProjectData.unique_stone_count === ''">Please enter unique stone count</div>-->
                            <!--    <div class="tooltip" v-else-if="$store.getters.getProjectData.overall_stone_count === ''">Please enter overall stone count</div>-->
                            <!--    <div class="tooltip" v-else-if="$store.getters.getProjectData.unique_stone_count > $store.getters.getProjectData.overall_stone_count">-->
                            <!--        Quantity of unique stones cannot be higher than overall stone quantity-->
                            <!--    </div>-->
                            <!--    <div class="tooltip" v-else-if="invalidUniqueOrOverallStoneCount">-->
                            <!--        Please enter valid stone count numbers-->
                            <!--    </div>-->
                            <!--    <div class="tooltip" v-else>Please upload files and enter dimensions</div>-->
                            </div>
                        </v-btn>
                        <v-btn
                            v-else
                            class="accept-btn pl-14 pr-14 mb-2 mb-sm-0 justify-self-end text-right mr-sm-2"
                            color="#9FF4A9"
                            width="160"
                            height="40"
                            depressed
                            rounded
                            link
                            :to="{name: 'dashboard'}">
                            Go to dashboard
                        </v-btn>
                    </div>
                </v-stepper-header>
            </div>
        </v-stepper>

        <dialog-confirm-complete-project-by-admin v-model="dialogCreateByAdmin" @create="completeByAdmin"/>
        <dialog-default
            emit-action="delete"
            type="dialogLeaveProject"
            @delete="deleteExit()"
            @other="$router.push('/dashboard')"
            @yes="saveExit()"/>
        <dialog-default type="alertInvalidFileType"/>
        <dialog-default type="alertCrashProject"/>
        <dialog-default type="alertCrashAddFileToProject"/>
    </v-container>
</template>

<script>
    import http from "@/api/httpClient.api";
    import StepperArrow from "@/components/ui/stepper/StepperArrow";
    import StartProjectTab from "@/views/project/start/steps/StartProjectTab";
    import UploadFilesTab from "@/views/project/start/steps/UploadFilesTab";
    import { paymentApi, projectApi } from "@/api";
    import DialogPayment from "@/components/ui/DialogPayment";
    import DialogDefault from "@/components/ui/dialog-default"
    import { mapGetters } from "vuex";
    import ModelSpecsTab from "@/views/project/start/steps/ModelSpecsTab";
    import DialogConfirmCompleteProjectByAdmin from "@/components/ui/DialogConfirmCompleteProjectByAdmin";

    const ReviewProjectTab = () => import("@/views/project/start/steps/ReviewProjectTab");

    const map = {
        1: "project-start",
        2: "upload-files",
        3: "project-start-edit",
        4: "render-configurator"
    };

    export default {
        name: "start-project",
        components: {
            DialogConfirmCompleteProjectByAdmin,
            ModelSpecsTab,
            DialogPayment,
            StartProjectTab,
            StepperArrow,
            ReviewProjectTab,
            UploadFilesTab,
            DialogDefault
        },
        data: () => ({
            loading: false,
            dialogCreateByAdmin: false,
            forceUpdate: 0,
            saveEditable: () => {
            },
            loadingSave: false,
            limit: 4,
            paymentDialog: false
        }),
        created () {
            this.getStep();
        },
        computed: {
            ...mapGetters([
                "getProjectData",
                "isAdminProject",
                "getValueProjectStatus",
                "getCurrentModelAssetsSpecifications"
            ]),
            step: {
                get () {
                    return this.$store.getters.startProject;
                },
                set (val) {
                    if (val === 4) {
                        this.forceUpdate++;
                    }
                    this.$store.dispatch("updateStep", val);
                    if (this.projectId > 0 && this.$route.name !== map[val]) {
                        this.$router.push({ name: map[val], params: { id: this.projectId + "" } });
                    }
                }
            },
            limitsStones() {
                return this.$store.getters.getStonesLimits
            },
            projectId () {
                return this.$store.getters.getProjectId;
            },
            invalidUniqueStoneCount() {
                return +this.$store.getters.getProjectData.unique_stone_count  > +this.limitsStones.unique_stone_count
            },
            invalidOverallStoneCount() {
                return +this.$store.getters.getProjectData.overall_stone_count > +this.limitsStones.overall_stone_count
            },
            invalidUniqueOrOverallStoneCount() {
                return this.invalidOverallStoneCount || this.invalidUniqueStoneCount
            },
            areLinksValid () {
                return this.$store.getters.areLinksValid
            },
            hasViews() {
                const currentAssetChildren = this.getCurrentModelAssetsSpecifications(true)
                return currentAssetChildren ? currentAssetChildren.some(file => {
                    return file.type === this.$config.fileTypes.type_of_views
                }) : false
            },
            disableStart() {
                // const { text, unique_stone_count, overall_stone_count } = this.$store.getters.getProjectData;

                // return this.step === 2 && (!this.hasUploadedFiles() || !text || unique_stone_count > overall_stone_count ||  unique_stone_count === "" || overall_stone_count === "" || this.invalidUniqueOrOverallStoneCount);
                return (this.step === 2 && !this.hasUploadedFiles()) || (this.step === 4 &&  !this.hasViews);
            }
        },
        methods: {
            deleteExit () {
                console.log("deleteExit");
                console.log("projectId", this.projectId);
                if (this.projectId) {
                    this.removeProject();
                }
                this.$router.push("/dashboard");
            },
            removeProject () {
                http.delete(`/projects/${this.projectId}`);
            },
            dialogLoading (val) {
                this.loading = val;
                console.log("dialogLoading!!!", val);
            },
            alert (type, text) {
                this.$store.state.dialogs.content[type].text = text;
                this.$store.commit(this.$store.state.dialogs.content[type].set, true);
            },
            hasUploadedFiles () {
                return this.$store.getters.getProjectFileInfo.filter((item) => !(item instanceof File)).length > 0;
            },
            async complete () {
                if (!this.areLinksValid) {
                    return
                }

                const { type_id, status } = this.getProjectData;
                const { project } = this.$config;
                if (type_id === project.type.custom && status === this.getValueProjectStatus(project.status.draft) && !this.isAdminProject) {
                    this.loadingSave = true;
                    await projectApi.requestCustomTypeProjectPrice(this.projectId)
                    this.loadingSave = false;
                    await this.$store.dispatch("updateProjectData", {
                        status: this.getValueProjectStatus(project.status.pricing_needed)
                    });
                    return;
                }

                this.saveEditable();
                if(this.isAdminProject){
                    this.dialogCreateByAdmin = true
                    return
                }
                if (!this.$store.getters.isProjectPaid) {
                    this.paymentDialog = true;
                } else {
                    await this.$router.push("/dashboard");
                }
            },

            async completeByAdmin() {
                this.loading = true;
                this.dialogCreateByAdmin = false
                try{
                    await paymentApi.paidAdminProject(this.projectId)

                    await this.$router.push("/dashboard");
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }

            },
            saveExit () {

                this.loadingSave = true;
                const nextStep = () => {
                    this.$store.commit("setProjectId", null);
                    this.loadingSave = false;
                    this.$router.push("/dashboard");
                };
                if (this.projectId !== null) {
                    this.addFilesToProject((loading) => this.loadingSave = loading).then(() => nextStep());
                } else {
                    const files = this.$store.getters.getProjectFileInfo.filter((item) => !(item instanceof File)).map((item) => item.id);
                    const STATUS_DRAFT = 4;
                    // const { text, unique_stone_count, overall_stone_count } = this.$store.getters.getProjectData;
                    projectApi.create({
                        type_id: this.$store.getters.getProjectType,
                        status: STATUS_DRAFT,
                        files
                        // text, unique_stone_count, overall_stone_count
                    }).then(() => nextStep()).catch(() => {
                        this.alert("alertCrashProject", `Project isn't created, try again`);
                    });
                }
            },
            async addFilesToProject (loading) {
                if (this.projectId) {
                    loading ? loading(true) : this.loading = true;
                    const files = this.$store.getters.getProjectFileInfo.filter((item) => !(item instanceof File)).map((item) => item.id);
                    // const { text, unique_stone_count, overall_stone_count } = this.$store.getters.getProjectData;
                    return projectApi.edit(this.projectId, {
                        files
                        // text, unique_stone_count, overall_stone_count
                    }).then((data) => {
                        loading ? loading(false) : this.loading = false;
                        return data;
                    }).catch(() => {
                        loading ? loading(false) : this.loading = false;
                        this.alert("alertCrashAddFileToProject", `File isn't added to project, try again`);
                        return Promise.reject();
                    });
                }
                return Promise.reject(`File isn't added to project, try again`);
            },
            nextStep () {
                const path = map[this.step + 1];

                //
                if (this.loading || this.step >= this.limit || !path) {
                    return;
                }

                const nextStep = () => {
                    this.loading = false;
                    this.step++;
                };

                if (this.step === 2 && this.projectId !== null) {
                    this.addFilesToProject().then(() => nextStep());
                } else if (this.step === 1) {
                    nextStep();
                } else if (this.step === 2) {
                    this.loading = true;
                    const files = this.$store.getters.getProjectFileInfo.filter((item) => !(item instanceof File)).map((item) => item.id);
                    // const { text, unique_stone_count, overall_stone_count } = this.$store.getters.getProjectData;
                    projectApi.create({
                        type_id: this.$store.getters.getProjectType,
                        status: 0,
                        files
                        // text, unique_stone_count, overall_stone_count
                    }).then((data) => {
                        this.$store.commit("setProjectId", data.id);
                    }).then(() => {
                        // this.$store.dispatch('loadProject');
                    }).then(() => {
                        this.loading = false;
                        nextStep();
                    }).catch(() => {
                        this.loading = false;
                        this.alert("alertCrashProject", `Project isn't created, try again`);
                    });
                } else if(this.step === 3) {
                    nextStep()
                }
            },
            getStep () {
                const map = {
                    "project-start": 1,
                    "upload-files": 2,
                    "project-start-edit": 3,
                    "render-configurator": 4
                };
                if (this.projectId) {
                    this.step = map[this.$route.name];
                }
            }
        }
    };
</script>

<style lang="scss">
    .unselect {
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        outline: none;
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0px -1px 80px rgba(188, 188, 188, 0.25);
        z-index: 2;
    }
    .v-application .unselect.is-editing {
        pointer-events: none;
        opacity: .5;
        .orange {
            background-color: #CBD2D0 !important;
        }
    }
    .full-height {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .v-stepper__step--complete {
        .v-stepper__step__step {
            background: #E8E8E8 !important;
            color: #464646 !important;
        }
    }

    .v-stepper__step--active {

        .v-stepper__label {
            color: #464646 !important;
        }
        .v-stepper__step__step {
            color: #464646 !important;
            background-color: #9FF4A9 !important;
            border: 1px #464646 solid !important;
        }
    }

    .v-stepper__step__step {
        background: rgba(159, 172, 169, 0.2) !important;
        color: #9FACA9 !important;


        .v-stepper__label {
            color: #464646 !important;
            font-weight: 400 !important;
        }
    }

    .accept-btn {
        border: 1px solid #464646 !important;
        height: 38px;
        color: #464646!important;
        .tooltip-wrapper {
            display: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 22px;
            width: 270px;
            height: 72px;
            border-top: 1px solid #c4c4c4;
            background: transparent url('./../../../assets/img/configurator-tooltip.png') no-repeat -2px -130px;
            background-size: calc(100% + 4px) 200px;
            pointer-events: none;
            text-align: center;
            white-space: normal;

            &.is-large {
                height: 126px;
                background-size: calc(100% + 4px) 256px;
            }

            .tooltip {
                position: static !important;
                transform: none;
                padding: 12px;
                color: #000!important;
            }
        }
    }
    .apd-btn-wrap:hover{
        .accept-btn.v-btn.v-btn--disabled .tooltip-wrapper {
            display: block;
        }
    }
    .steps {
        &::before {
            content: " ";
            position: absolute;
            z-index: 1;
            background: linear-gradient(0deg, #ECFDEE 9.71%, rgba(255, 255, 255, 0) 100%) !important;
            height: 20%;
            bottom: 0;
            width: 100%;
        }

        .v-stepper__wrapper {
            overflow: inherit;
        }

        .upload-files-tab {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    .grid {
        max-width: 1074px;
        width: 100%;
    }

    .project-pricing-awaiting {
        max-width: 640px;
        padding-top: 136px;
        h1 {
            max-width: 610px;
            font-size: 36px;
            line-height: 40px;
        }
        p {
            font-size: 24px;
            line-height: 27px;
            color: #464646;
        }
        .v-btn {
            color: #464646;
            background-color: #9ff4a9;
        }

        @media screen and (max-width: 600px) {
            padding-top: 48px;
            h1 {
                font-size: 32px;
                line-height: 36px;
            }
            p {
                font-size: 20px;
                line-height: 23px;
            }
        }
    }

</style>
