<template>
    <div class="project-info">
        <v-row>
            <v-col class="description-column mr-8 ">
                <div class="description-content pa-5 mb-5" v-if="getProjectData.description">
                    <div class="title">Description</div>
                    <div class="project-description" v-html="getProjectData.description"/>
                </div>

                <div v-if="showRenderDescription && getProjectData.render_description" class="description-content mb-5 pa-5">
                    <div class="title">Render Description</div>
                    <div class="project-description" v-html="getProjectData.render_description"/>
                </div>
                <project-specification v-if="isClient || isManagerOrAdmin"/>
            </v-col>

            <v-col class="description-column ml-8">
                <div class="deatils-container">
                    <div class="conteiner-title">DETAILS</div>

                    <div class="content">
                        <div class="left-column">
                            <div class="info-row">
                                Request from: <span>{{ getProjectOwner }}</span>
                            </div>
                            <div v-if="isManager || isAdmin" class="info-row">
                                Designer: <span>{{ getProjectData.designer_name }}</span>
                            </div>
                            <div class="info-row d-flex align-center">
                                Due Date:
                                <span class="ml-1">
                                    {{ $moment(getProjectData.estimated_date).format("MMM. DD, YYYY") }}
                                </span>

                                <v-tooltip
                                    v-if="isClient"
                                    top
                                    nudge-right="70"
                                    max-width="155"
                                    min-width="155">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="tooltip-question-mark d-inline-block ml-2 text-center">?</span>
                                    </template>
                                    <span>Deadline is for the first submission only.</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <div class="right-column text-right">
                            <div class="info-row d-flex justify-end">
                                <template v-if="isClient || isManagerOrAdmin">
                                    Project Price:
                                    <span class="ml-1">${{ getProjectData.client_amount }}</span>
                                    <extra-charge-status-tooltip
                                        activator-class="ml-1"
                                        v-if="isManagerOrAdmin && getProjectData.extra_charge"
                                        :status-id="getProjectData.extra_charge.status"/>
                                </template>
                                <template v-if="isDesigner">
                                    Model Price: <span class="ml-1">${{ getProjectData.overall_designer_amount }}</span>
                                </template>
                            </div>
                            <div class="info-row">
                                <template v-if="isManager || isAdmin">Designer compensation: <span>${{ getProjectData.overall_designer_amount }}</span></template>
                                <template v-else>Project ID: <span>{{ getProjectData.id }}</span></template>
                            </div>
                            <div v-if="isManager || isAdmin" class="info-row">
                                Project ID: <span>{{ getProjectData.id }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <modeling-tech-specs v-if="isDesigner"/>

                <div v-if="getProjectLinks && getProjectLinks.length" class="links-container">
                    <div class="container-title">LINKS</div>
                    <v-chip
                        v-for="(link, idx) in getProjectLinks"
                        :key="idx"
                        class="mb-2 mr-2 project-descr__option-chip"
                        color="#D9FBDD"
                        :href="checkLink(link) ? createLink(link) : null"
                        target="_blank"
                        text-color="#464646">
                        {{ link }}
                    </v-chip>
                </div>
                <div
                    class="deatils-container" v-if="getProjectData.text">
                    <div class="conteiner-title dimensions-title">
                        Dimensions
                    </div>
                    <div class="content pt-3 text-break">
                        {{ getProjectData.text }}
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ModelingTechSpecs from "@/components/ModelingTechSpecs";
    import { mapGetters } from "vuex";
    import ProjectSpecification from "@/components/project/ProjectSpecification";
    import ExtraChargeStatusTooltip from "@/components/ExtraCharge/ExtraChargeStatusTooltip";
    export default {
        name: "project-in-progress-info",
        components: {
            ProjectSpecification,
            ModelingTechSpecs,
            ExtraChargeStatusTooltip
        },
        props: {
            showRenderDescription: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "isManager",
                "isManagerOrAdmin",
                "isAdmin",
                "getProjectData",
                "getProjectLinks"
            ]),
            getProjectOwner() {
                return !this.isDesigner ? this.getProjectData.user : this.getProjectData.user.split(" ")[0];
            }
        },
        methods: {
            checkLink(link) {
                let pattern = new RegExp(
                    "^(https?:\\/\\/)?" + // protocol
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                        "(\\?[\\:;&a-z\\d%_.~+=-]*)?" + // query string
                        "(\\#[-a-z\\d_]*)?$",
                    "i"
                );
                return !!pattern.test(link);
            },
            createLink(link) {
                link = link.toLowerCase();
                if (!link.includes("http")) {
                    link = "http://" + link;
                }
                return link;
            }
        }
    }
</script>

<style scoped lang="scss">
.project-info {
    color: #464646;

    .description-content {
        background: #ffffff;
        border: 1px solid #bcbcbc;
        box-sizing: border-box;
        border-radius: 5px;
        color: #464646;
        word-break: break-word;

        .title {
            font-size: 14px !important;
            line-height: 18px;
            font-weight: 600;
            font-family: "Futura PT", sans-serif !important;
            text-transform: uppercase;
            color: #464646;
            margin-bottom: 20px;
        }

        ::v-deep .project-description {
            font-size: 14px;
            line-height: 18px;
            color: #464646;
            word-break: break-word;

            h1 {
                margin-bottom: 8px;
                font-size: 24px;
                line-height: 26px;
            }
            h2 {
                margin-bottom: 8px;
                font-size: 22px;
                line-height: 24px;
            }
            h3 {
                margin-bottom: 8px;
                font-size: 20px;
                line-height: 22px;
            }
        }
    }

    .description-column {
        width: calc(50% - 32px);

        .links-container {
            background: #ffffff;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;
            box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
            border-radius: 5px;
            padding: 4px 18px 18px 18px;

            .container-title {
                font-size: 14px;
                line-height: 18px;
                color: #464646;
                padding: 12px 0;
                border-bottom: 1px solid #bcbcbc;
                margin-bottom: 14px;
            }

            .pl-iw {
                width: fit-content;
                display: inline-block;
                margin: 0 12px 12px 0;
            }

            .pl-item {
                background: #d9fbdd;
                border: 1px solid #464646;
                box-sizing: border-box;
                border-radius: 5px;
                font-size: 13px;
                line-height: 18px;
                color: #464646;
                padding: 2px 7px;
            }
        }

        ::v-deep .deatils-container {
            background: #ffffff;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;
            box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
            border-radius: 5px;
            margin-bottom: 18px;
            padding: 14px 25px 16px 20px;

            .conteiner-title {
                border-bottom: 1px solid #bcbcbc;
                padding-bottom: 11px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                &.dimensions-title{
                    font-weight: 400;
                    font-size: 18px;
                    text-transform: capitalize;
                }
            }

            .content {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                ul {
                    font-size: 14px;

                    li + li {
                        margin-top: 12px;
                    }
                }

                .info-row {
                    font-weight: normal;
                    margin-top: 10px;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
