<template>
    <div
        :class="{ disabled: disableControls, 'view-spec': isViewSpec }"
        class="spec__wrap">
        <div class="spec">
            <div
                :class="[
                    {
                        'spec__image--active':
                            currentSelected && currentSelected.id === data.id
                    },
                    { 'with-title': title }
                ]"
                class="spec__image"
                @click="$emit('selected', data)">
                <div
                    v-if="title"
                    @click.stop="imageLoop ? $emit('open', data) : $event.preventDefault()"
                    class="spec__title"
                    :class="{
                        'title-green': data.type===8,
                        'title-yellow': data.type===5,
                        'title-grey': data.type===7
                    }">
                    {{ title }}
                </div>
                <v-img
                    @click.stop="imageLoop ? $emit('open', data) : $event.preventDefault()"
                    :src="data.url"
                    contain
                    min-width="72"
                    max-height="45"
                    max-width="72"/>
            </div>
            <div
                class="spec__info"
                @click="$emit('selected', data)">
                <div class="spec__owner-name">
                    {{ ownerName }}
                </div>
                <div class="spec__create-date">
                    {{ createdDate }}
                </div>
                <div v-if="data.shadow">
                    <div v-if="'backgroundColor' in data.shadow">Background: {{ bgcolor }}</div>
                    <div v-if="'blur' in data.shadow">Blur: {{ data.shadow.blur + '%' }}</div>
                    <div v-if="'opacity' in data.shadow">Opacity: {{ data.shadow.opacity + '%' }}</div>
                </div>
            </div>
            <div class="spec__actions flex-shrink-0 align-center">
                <v-btn
                    :disabled="!loopBtn"
                    class="spec__btn spec__loupe px-0"
                    height="24"
                    width="24"
                    @click.exact="$emit('open', data)">
                    <v-img
                        :src="require('@/assets/icons/ic-draw-zoom.svg')"
                        contain
                        max-height="13"
                        max-width="13"/>
                </v-btn>
                <v-menu
                    v-if="!disableEdit"
                    v-model="menu"
                    :disabled="disableControls"
                    bottom
                    class="red"
                    left
                    max-width="182"
                    offset-x
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disableControls"
                            height="24"
                            width="24"
                            class="ml-1"
                            icon>
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>

                    <div
                        class="drop-item-menu__links"
                        style="background-color:#fff;">
                        <button
                            v-if="isNotBackground && !isReference && !isDesigner && (isClient || isAdminProject) && !disableEdit"
                            class="drop-item-menu__btn pa-3 d-block"
                            role="button"
                            disabled
                            @click="$emit('edit', data)">
                            Edit
                        </button>
                        <button
                            v-if="!isDesigner && (isClient || isAdminProject) && !disableEdit"
                            class="drop-item-menu__btn pa-3 d-block red--text"
                            role="button"
                            @click="$emit('delete', data)">
                            Delete
                        </button>
                        <a
                            v-if="!isClient"
                            class="drop-item-menu__btn pa-3 d-block"
                            :href="data.type === 5 && data.children ? data.children[0].url : data.url"
                            target="_blank"
                            download>
                            Download
                        </a>
                    </div>
                </v-menu>
            </div>
        </div>

        <div v-if="comment" class="spec__comment">
            {{ comment.comment }}
        </div>

        <div v-if="!disableEdit" class="line">
            <div class="edit-link">
                <v-btn
                    role="button"
                    text
                    color="primary"
                    disabled
                    class="px-1 btn--link text-decoration-underline"
                    min-width="50"
                    @click="$emit('replay', data)">
                    Edit
                </v-btn>
            </div>
            <button
                v-if="isNotBackground && !isReference"
                class="spec__replay" role="button"
                @click="$emit('replay', data)">
                Reply
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { FILETYPES } from "@/utils/constans";

    export default {
        name: "spec",
        mounted() {
            if (
                this.data.type == 7 &&
                this.data.props &&
                this.data.props.length > 0
            ) {
                const parsedProps = JSON.parse(this.data.props);
                if (parsedProps["background-color"]) {
                    this.backgroundColor = parsedProps["background-color"];
                }
            }
        },
        props: {
            disableEdit: {
                type: Boolean,
                default: false
            },
            disableControls: {
                type: Boolean,
                default: false
            },
            customTitle: {
                type: String,
                default: null
            },
            index: {
                type: Number,
                default: null
            },
            data: {
                type: Object,
                default: () => ({})
            },
            currentSelected: {
                type: Object,
                default: () => ({})
            },
            isViewSpec: {
                type: Boolean,
                default: false
            },
            imageLoop: {
                type: Boolean,
                default: false
            },
            loopBtn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                FILETYPES,
                backgroundColor: null
            };
        },
        methods: {
        },
        computed: {
            ...mapGetters(["isClient", "isDesigner", "isAdminProject"]),
            isNotBackground() {
                return this.data.type !== FILETYPES.BACKGROUND;
            },
            isReference() {
                return this.data.type === FILETYPES.REFERENCE;
            },
            title() {
                const index = this.index ?? "";
                let title;
                if (this.data.title) {
                    title =
                        (this.data.title ?? this.customTitle) +
                        (index.length ? index : index);
                }
                return title;
            },
            ownerName() {
                return this.data.user ?? "Janelle Ratliff";
            },
            createdDate() {
                return this.$moment(
                    this.data.created_at ?? "2021-04-27T07:26:09.000000Z"
                ).format("MMM DD, YYYY");
            },
            menu: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit("input", val);
                }
            },
            comment() {
                return this.$store.getters["getCommentByFileId"](this.data.id);
            },
            hasBackgroundColor(){
                const isBackgroundType = this.data.type == 7;
                const hasBackgroundColorField = !!this.data.backgroundColor
                return isBackgroundType && hasBackgroundColorField
            },
            bgcolor() {
                return this.data.backgroundColor
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "spec";

.view-spec {
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;
}

.spec__wrap {
    padding: 14px 16px;
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;
    margin: 12px 0 0;
}

.spec__wrap.disabled {
    .spec__image--active {
        border: 1px solid #c0c0c0;
    }
    .spec__title {
        background: #c0c0c0;
    }
    .btn--link {
        color: #c0c0c0;
    }
}

.title-green {
    background-color: #9FF4A9 !important;
}
.title-yellow {
    background-color: #FCF037 !important;
}
.title-grey {
    background-color: #E8E8E8 !important;
}
</style>
