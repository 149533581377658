<template>
    <div
        :class="{ 'drawer--active': isDraw, 'mb-2': isInProjectView }"
        class="drawer">
        <v-btn
            v-show="isDraw"
            class="ml-2 mr-4"
            color="white btn__max-height"
            elevation="1"
            small
            @click="store.dispatch('drawer/exitDrawing')">
            Exit Drawing
        </v-btn>

        <a
            v-show="isDraw"
            class="drawer__link no-select "
            role="button"
            @click="store.dispatch('drawer/undoDrawing')"
            v-if="!disableDrawingTools">
            Undo
        </a>
        <a
            v-show="isDraw"
            class="drawer__link no-select "
            role="button"
            @click="store.dispatch('drawer/redoDrawing')"
            v-if="!disableDrawingTools">
            Redo
        </a>
        <!--    <a v-show="isDraw" role="button" @click="store.commit('drawer/ADD_LINE', {val: 'new'})" class="drawer__link">AddLine</a>-->
        <!--    <a v-show="isDraw" role="button" @click="store.commit('drawer/SET_LINES', [{val: 'new'}, {val: 'new'}])" class="drawer__link">SetLine</a>-->
        <!--    <a v-show="isDraw" role="button" @click="store.commit('drawer/REMOVE_LINE_BY_INDEX', 0)" class="drawer__link">RemoveLine</a>-->
        <!--    <a v-show="isDraw" role="button" @click="store.commit('drawer/REMOVE_LINE_BY_OBJECT', {points: 2})" class="drawer__link">Remove by Object</a>-->
        <!--    <a v-show="isDraw" role="button" @click="store.commit('drawer/REMOVE_LINE_BY_OBJECT', {points: 3})" class="drawer__link">Remove by Object</a>-->

        <div
            v-if="isInProjectView && !isDraw && !isLarge && !renderReviewSliderToolbar"
            class="project-compare-lbl">
            {{ projectSectionTitle }}
        </div>

        <v-spacer/>

        <!--   v-if="!isCompleted" because of page configuration doesn't work draw-->
        <template>
            <div
                v-if="
                    showEdit &&
                        isInProjectView &&
                        !isDraw &&
                        !isLarge &&
                        !disableDrawingTools
                "
                class="compare-edit-menu-wrap pos-rel mr-4">
                <v-menu
                    :close-on-content-click="true"
                    attach=".compare-edit-menu-wrap"
                    bottom
                    content-class="compare-edit-list-wrap"
                    left
                    max-width="none"
                    nudge-bottom="0"
                    offset-y
                    z-index="2">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                            class="def-blue-link">
                            Edit
                        </div>
                    </template>
                    <div class="compare-edit-list pl-4 pr-4 pt-2 pb-2">
                        <div class="compare-edit-lw">
                            <div
                                class="def-blue-link"
                                @click="deleteFile">
                                Delete
                            </div>
                        </div>
                        <div class="compare-edit-lw">
                            <div
                                class="def-blue-link"
                                @click="replaceFile">
                                Replace
                            </div>
                        </div>
                    </div>
                </v-menu>
            </div>

            <v-btn
                v-if="!isDraw && !disableDrawingTools"
                :style="{ background: isCommentAble ? '#CCCCCC' : '' }"
                class="mr-4 drawer__btn"
                @click="startAddingComment">
                <v-img
                    :src="require('@/assets/icons/ic-comment-blue.svg')"
                    contain
                    max-height="16"
                    max-width="16"/>
            </v-btn>

            <div
                v-if="isDraw && !disableDrawingTools"
                class="d-flex align-center drawer__block">
                <div class="d-flex align-center drawer__edit btn__max-height">
                    <v-img
                        :src="require('@/assets/icons/ic-draw-edit.svg')"
                        contain
                        max-height="16"
                        max-width="16"
                        style="cursor: pointer"
                        @click="store.commit('drawer/SET_STATUS_GRATER', false)"
                        v-if="!disablePaintingTool"/>

                    <v-menu
                        :disabled="isColorFreeze"
                        content-class="drawer__menu-color"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="btn__max-height-20  pr-0"
                                color="white"
                                dark
                                elevation="0"
                                min-width="34">
                                <div
                                    :style="{ background: getColor }"
                                    style="width: 18px; height: 18px"/>
                                <v-img
                                    :src="
                                        require('@/assets/icons/ic-draw-down.svg')
                                    "
                                    class="ml-3"
                                    contain
                                    max-height="16"
                                    max-width="16"/>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="color in getColorList"
                                :key="color"
                                :class="{
                                    'drawer__menu-item--active':
                                        getColor === color
                                }"
                                class="drawer__menu-item"
                                @click="store.commit('drawer/SET_COLOR', color)">
                                <div
                                    :style="{ background: color }"
                                    style="width: 18px; height: 18px"/>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu
                        content-class="drawer__menu-brush"
                        offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="btn__max-height-20 pr-0"
                                color="white"
                                dark
                                elevation="0"
                                min-width="34">
                                <div
                                    :style="{
                                        background: getColor,
                                        width: '6px',
                                        height: '6px'
                                    }"
                                    style="border-radius: 50%"/>
                                <v-img
                                    :src="
                                        require('@/assets/icons/ic-draw-down.svg')
                                    "
                                    class="ml-3"
                                    contain
                                    max-height="16"
                                    max-width="16"/>
                            </v-btn>
                        </template>
                        <div class="d-flex align-center">
                            <v-slider
                                :value="getBrush"
                                class="mt-24"
                                color="#ccc"
                                dense
                                max="32"
                                min="6"
                                track-color="#ccc"
                                track-fill-color="#ccc"
                                @input="
                                    nv => store.commit('drawer/SET_BRUSH', nv)
                                "/>
                            <div
                                class="d-flex align-center justify-center"
                                style="width: 40px">
                                <div
                                    :style="{
                                        background: getColor,
                                        width: getBrush + 'px',
                                        height: getBrush + 'px'
                                    }"
                                    style="border-radius: 50%"/>
                            </div>
                        </div>
                    </v-menu>
                </div>
                <v-btn
                    class="ml-2 mr-4"
                    color="orange white--text btn__max-height"
                    elevation="1"
                    small
                    @click="store.dispatch('drawer/saveDrawing')">
                    Save
                </v-btn>
                <v-btn
                    :class="{ 'drawer__btn--active': isGrater }"
                    class="mr-4 drawer__btn"
                    @click="store.commit('drawer/SET_STATUS_GRATER', !isGrater)">
                    <v-img
                        :src="require('@/assets/icons/ic-draw-grater.svg')"
                        contain
                        max-height="16"
                        max-width="16"/>
                </v-btn>
            </div>

            <v-btn
                v-if="
                    !disablePaintingTool &&
                        !isBrushHidden &&
                        !disableDrawingTools
                "
                :style="{ background: isDraw ? '#CCCCCC' : '' }"
                ref="startDraw"
                class="mr-4 drawer__btn start-draw"
                @click="startDrawing">
                <v-img
                    :src="require('@/assets/icons/ic-draw-edit.svg')"
                    contain
                    max-height="16"
                    max-width="16"/>
            </v-btn>
        </template>

        <v-btn
            v-if="!isZoomHidden && !renderReviewSliderToolbar"
            class="drawer__btn"
            @click="store.dispatch('drawer/largeView', !isLarge)">
            <v-img
                :src="require('@/assets/icons/ic-draw-zoom.svg')"
                contain
                max-height="16"
                max-width="16"/>
        </v-btn>


        <button
            class="action-btn d-flex align-center justify-center"
            v-if="!isZoomHidden && renderReviewSliderToolbar"
            @click="store.dispatch('drawer/largeView', !isLarge)">
            <v-img
                :src="require('@/assets/_redesigne/icons/ic-scale-up.svg')"
                contain
                max-height="16"
                max-width="16"/>
        </button>
    </div>
</template>

<script>
//import { mapGetters  } from 'vuex'

    export default {
        name: "drawer",
        computed: {
            isCompleted() {
                return this.$store.getters.getProjectData.status === "Completed";
            },
            projectSectionTitle() {
                return this.id === "right"
                    ? "Pending Model Preview"
                    : "Reference Image";
            },
            showEdit() {
                return (
                    (this.isClient && this.id === "left") ||
                    (this.isDesigner && this.id === "right")
                );
            },
            isClient() {
                return this.$store.getters.isClient;
            },
            isDesigner() {
                return this.$store.getters.isDesigner;
            },
            isInProjectView() {
                return this.$store.getters.getIsInProjectView;
            },
            isBrushHidden() {
                return this.store.getters["drawer/isBrushHidden"];
            },
            isZoomHidden() {
                return this.store.getters["drawer/isZoomHidden"];
            },
            isCommentAble() {
                return this.store.getters["drawer/isCommentAble"];
            },
            isDraw() {
                return this.store.getters["drawer/isDraw"];
            },
            isColorFreeze() {
                return this.store.getters["drawer/isColorFreeze"];
            },
            isGrater() {
                return this.store.getters["drawer/isGrater"];
            },
            getColor() {
                return this.store.getters["drawer/getColor"];
            },
            getColorList() {
                return this.store.getters["drawer/getColorList"];
            },
            getBrush() {
                return this.store.getters["drawer/getBrush"];
            },
            isLarge() {
                return this.store.getters["view/isLarge"];
            }
            // ...mapGetters('drawer', ['isDraw', 'isGrater', 'getColorList', 'getColor', 'getBrush']),
            // ...mapGetters('view', ['isLarge']),
        },
        props: {
            store: {
                type: Object,
                required: true
            },
            id: {
                type: String,
                default: ""
            },
            currentFileInfo: {
                type: Object,
                default() {
                    return {};
                }
            },
            disableDrawingTools: {
                type: Boolean,
                default: false
            },
            disablePaintingTool: {
                type: Boolean,
                default: false
            },
            blockDrawerOptions: {
                type: Boolean,
                default: false
            },
            renderReviewSliderToolbar: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.$emit("startDrawBtn", this.$refs.startDraw);
        },
        methods: {
            deleteFile() {
                if (this.currentFileInfo?.id) {
                    this.$root.$emit("removeProjectFile", this.currentFileInfo);
                }
            },
            replaceFile() {
                if (this.currentFileInfo?.id) {
                    this.$emit("repplaceFile");
                }
            },
            startDrawing(){
                if(!this.blockDrawerOptions){
                    this.store.commit("drawer/SET_STATUS_DRAW", true)
                }
            },
            startAddingComment(){
                if(!this.blockDrawerOptions){
                    this.store.dispatch("drawer/toggleCommentAble")
                }
            }
        }
    };
</script>

<style>
/*.drawer__menu-brush .v-messages {*/
/*  display: none!important;*/
/*}*/
.drawer__menu-brush .v-input__slot {
    top: 10px !important;
}

.drawer__menu-brush .v-slider__thumb {
    background-color: rgb(121 119 119) !important;
    border-color: rgb(204, 204, 204) !important;
}
</style>
<style lang="scss" scoped>
.btn__max-height {
    min-height: 24px !important;
    max-height: 24px !important;
}
.btn__max-height-20 {
    min-height: 20px !important;
    max-height: 20px !important;
}
.drawer .v-ripple__container {
    border-radius: 5px !important;
}
.drawer {
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
    &__block {
        @media screen and (max-width: 600px) {
            order: 1;
            margin-top: 14px;
            margin-left: 8px;
            width: 100%;
            //width: calc(100% - 50px);
        }
    }
    &__link {
        //font-family: "Futura PT", sans-serif;
        //font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #0046af;
        margin-right: 12px;
    }
    &__menu-item {
        &:hover {
            background: #e5e5e5;
        }
    }
    &__menu-item--active {
        background: rgba(251, 56, 5, 0.2);
        &:hover {
            background: #e5e5e5;
        }
    }
    &__menu-color {
        min-width: 24px !important;
        transform: translate(0, 5px);
    }
    &__menu-brush {
        background: #ffffff;
        min-width: 139px !important;
        min-height: 54px !important;
        transform: translate(0, 5px);
        //.v-messages {
        //  display: none;
        //}
        //.v-input__slot {
        //  top: 20px;
        //}
    }
    &__exit {
        outline: 2px solid #e7e7e7;
    }
    &__save {
    }
    &__btn {
        padding: 0;
        min-width: 24px !important;
        min-height: 24px;
        max-width: 24px;
        max-height: 24px;
        &--active {
            background: orange !important;
        }
    }
    &--active {
        border: 1px solid #e8e8e8;
        .drawer__edit {
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
            border: 1px solid #0046af;
        }
    }
}
</style>

<style scoped lang="scss">
.action-btn {
    width: 24px;
    height: 24px;
    background: #d9fbdd;
    border: 1px solid #464646;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #9ff4a9;
    }
}
</style>
