<template>
    <div v-click-outside="close">
        <div
            :class="{'disabled': disableControls}"
            class="configurator mt-4 help-hide">
            <v-btn
                :disabled="disableControls || step === 0"
                v-if="hideBtnControl"
                class="configurator-btn configurator__previous mr-auto"
                outlined
                rounded
                @click="decStep">
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.50781 1.42773L0.749614 6.18593L5.50781 10.9441" stroke="#464646" stroke-linecap="round"/>
                </svg>

                <span
                    v-if="!mdAndDown"
                    class="configurator__btn-text ml-2 hidden-sm-and-down">
                    Previous
                </span>
            </v-btn>
            <div class="d-flex align-end justify-center mx-5 items-container">
                <conf-engraving
                    v-show="!isMobile && isStep(0) && !showHelp"
                    :disabled="disableControls"
                    class="mt-4 mr-2"
                    @close="close()"
                    :idx="0"
                    @openInstruction="openInstructions"
                    @place-engraving="$emit('place-engraving')"/>

                <div class="tooltip-wrapper">
                    <div class="tooltip" v-if="showHelp && tooltipAt===0">
                        <div class="tooltip-content">
                            <div class="video-wrapper">
                                <video src=""></video>
                                <button>
                                    <img src="@/assets/icons/ic-player-play.svg">
                                </button>
                            </div>
                            <div class="info-block mb-4">
                                <div class="title">ENGRAVING </div>
                                <div class="text">
                                    <ol class="px-3">
                                        <li>Rotate 3D model</li>
                                        <li>Click “Place Engraving”</li>
                                        <li>Draw where you want the engraving to be</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="controls">
                                <button class="style-link" @click="dismissInstructions">Dismiss instructions</button>
                                <button class="next-step" @click="setStep(1)">Next Step</button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-show="isMobile || !isStep(0) || (tooltipAt!==null && tooltipAt>=0)"
                        class="configurator__icon"
                        :class="{'highlighted': showHelp && tooltipAt===0 }"
                        @click="setStep(0)">
                        <v-img
                            :src="require('@/assets/icons/configurator/engraving.svg')"
                            contain/>
                    </div>
                </div>

                <conf-logo
                    v-show="!isMobile && isStep(1) && !showHelp"
                    :disabled="disableControls"
                    class="mt-4 mr-2"
                    @close="close()"
                    :idx="1"
                    @openInstruction="openInstructions"
                    @upload-logo="(file)=>$emit('upload-logo', file)"/>

                <div class="tooltip-wrapper">
                    <div class="tooltip" v-if="showHelp && tooltipAt===1">
                        <div class="tooltip-content">
                            <!--                            <div class="video-wrapper">-->
                            <!--                                <video src=""></video>-->
                            <!--                                <button>-->
                            <!--                                    <img src="@/assets/icons/ic-player-play.svg">-->
                            <!--                                </button>-->
                            <!--                            </div>-->
                            <div class="info-block mb-4">
                                <div class="title">WATERMARK </div>
                                <div class="text">
                                    <ol class="px-3">
                                        <li>Rotate model</li>
                                        <li>Upload file (.PNG, .SVG, .JPEG)</li>
                                        <li>Place watermark anywhere on the image</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="controls">
                                <button class="style-link" @click="dismissInstructions">Dismiss instructions</button>
                                <button class="next-step" @click="setStep(2)">Next Step</button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="isMobile || !isStep(1) || tooltipAt===1"
                        :class="{'configurator__icon--active': isSelected(1), 'highlighted':showHelp && tooltipAt===1 }"
                        class="configurator__icon"
                        @click="setStep(1)">
                        <v-img
                            :src="require('@/assets/icons/configurator/watermark.svg')"
                            contain/>
                    </div>
                </div>

                <conf-rotation
                    v-show="!isMobile && isStep(2) && !showHelp"
                    :key="currentFile3d.id"
                    :control-files="controlFiles"
                    :current-file3d="currentFile3d"
                    :disabled="disableControls"
                    :type-views="typeViews"
                    class="mt-4 mr-2"
                    @close="close()"
                    @remove-view="(view)=>$emit('remove-view', view)"
                    @add-view="(view)=>$emit('add-view', view)"
                    @snapshot-views="(angles)=>$emit('snapshot-views', angles)"/>

                <div class="tooltip-wrapper">
                    <div class="tooltip" v-if="showHelp && tooltipAt===2">
                        <div class="tooltip-content">
                            <div class="video-wrapper">
                                <video src=""></video>
                                <button>
                                    <img src="@/assets/icons/ic-player-play.svg">
                                </button>
                            </div>
                            <div class="info-block">
                                <div class="title">Model Views </div>
                                <div class="text">
                                    Select the views you want included on your final render. Remember the standarn price includes 3 different views. Adittional renders will incur an extra fee.
                                </div>
                            </div>
                            <div class="controls">
                                <button class="style-link" @click="dismissInstructions">Dismiss instructions</button>
                                <button class="next-step" @click="setStep(3)">Next Step</button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-show="isMobile || !isStep(2) || tooltipAt===2"
                        :class="{'configurator__icon--active': isSelected(2), 'highlighted': showHelp && tooltipAt===2 }"
                        class="configurator__icon"
                        @click="setStep(2)">
                        <v-img
                            :src="require('@/assets/icons/configurator/view.svg')"
                            contain/>
                    </div>
                </div>

                <conf-background
                    v-show="!isMobile && isStep(3) && !showHelp"
                    :key="currentFile3d.id + 9999"
                    :bg="bc"
                    :current-file3d="currentFile3d"
                    :disabled="disableControls"
                    :file="backgroundFile"
                    class="mt-4 mr-2"
                    @close="close()"
                    @remove-background="(file)=>$emit('remove-background', file)"
                    @upload-background="(file)=>$emit('upload-background', file)"
                    @color-changed="(color)=>$emit('color-changed', color)"
                    @attachBackgroundColor="$emit('attachBackgroundColor', $event)"/>

                <div class="tooltip-wrapper">
                    <div class="tooltip" v-if="showHelp && tooltipAt===3">
                        <div class="tooltip-content">
                            <div class="video-wrapper">
                                <video src=""></video>
                                <button>
                                    <img src="@/assets/icons/ic-player-play.svg">
                                </button>
                            </div>
                            <div class="info-block">
                                <div class="title">Background Color </div>
                                <div class="text">Select the background color for the render. You can pick from a Solid Color, Gradient or upload your own Background Image.</div>
                            </div>
                            <div class="controls">
                                <button class="style-link" @click="dismissInstructions">Dismiss instructions</button>
                                <button class="next-step" @click="dismissInstructions">Complete</button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-show="isMobile || !isStep(3) || tooltipAt===3"
                        :class="{'configurator__icon--active': isSelected(3), 'highlighted': showHelp && tooltipAt===3}"
                        class="configurator__icon mr-0"
                        @click="setStep(3)">
                        <v-img
                            :src="require('@/assets/icons/configurator/background.svg')"
                            contain/>
                    </div>
                </div>
            </div>
            <v-btn
                :disabled="disableControls || step === max -1"
                v-if="hideBtnControl"
                class="configurator-btn configurator__next ml-auto"
                outlined
                rounded
                height="22"
                @click="incStep">
                <span
                    v-if="!mdAndDown"
                    class="configurator__btn-text mr-2 hidden-sm-and-down">
                    Next
                </span>
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11523 10.1406L5.87343 5.38243L1.11523 0.624231" stroke="#464646" stroke-linecap="round"/>
                </svg>
            </v-btn>
        </div>

        <conf-engraving
            v-if="isMobile && isStep(0)"
            :disabled="disableControls"
            class="mt-4"
            @close="close()"
            :idx="0"
            @openInstruction="openInstructions"
            @place-engraving="$emit('place-engraving')"/>
        <conf-logo
            v-if="isMobile && isStep(1)"
            :disabled="disableControls"
            class="mt-4"
            @close="close()"
            :idx="1"
            @openInstruction="openInstructions"
            @upload-logo="(file)=>$emit('upload-logo', file)"/>
        <conf-rotation
            v-if="isMobile && isStep(2)"
            :key="currentFile3d.id"
            :control-files="controlFiles"
            :current-file3d="currentFile3d"
            :disabled="disableControls"
            :type-views="typeViews"
            class="mt-4 mr-md-0"
            @close="close()"
            @remove-view="(view)=>$emit('remove-view', view)"
            @add-view="(view)=>$emit('add-view', view)"
            @snapshot-views="(angles)=>$emit('snapshot-views', angles)"/>
        <conf-background
            v-if="isMobile && isStep(3)"
            :key="currentFile3d.id + 99999"
            :bg="bc"
            :current-file3d="currentFile3d"
            :disabled="disableControls"
            :file="backgroundFile"
            class="mt-4"
            @close="close()"
            @remove-background="(file)=>$emit('remove-background', file)"
            @upload-background="(file)=>$emit('upload-background', file)"
            @color-changed="(color)=>$emit('color-changed', color)"/>
    </div>
</template>

<script>
    import ConfEngraving from "@/components/configurator/full-view/ConfEngraving";
    import ConfBackground from "@/components/configurator/full-view/ConfBackground";
    import ConfLogo from "@/components/configurator/full-view/ConfLogo";
    import ConfRotation from "@/components/configurator/full-view/ConfRotation";
    // import ConfShadow from "@/components/configurator/full-view/ConfShadow"
    import { mapGetters } from "vuex";

    export default {
        name: "configurator",
        data() {
            return {
                min: 0,
                max: 4,
                tooltipAt: null
            }
        },
        components: {
            ConfRotation,
            ConfLogo,
            ConfBackground,
            ConfEngraving
            // ConfShadow
        },
        props: {
            disableControls: {
                type: Boolean,
                default: false
            },
            currentFile3d: {
                type: Object,
                default: () => {
                }
            },
            controlFiles: {
                type: Object,
                default: () => {
                }
            },
            typeViews: {
                type: Array,
                default: () => ([])
            },
            backgroundFile: {
                type: Object,
                default: null
            },
            bc: {
                type: String,
                default: null
            },
            value: {
                type: Number,
                default: -1
            }
        },
        created() {

            if(this.showHelp) {
                this.tooltipAt = 0
            }
        },
        methods: {
            setStep (step) {
                if (this.disableControls) {
                    return;
                }
                this.step = step;
                this.tooltipAt = step
            },
            colorSelection (step) {
                return this.isMobile && this.isStep(step) ? "#fb3805" : "#747474";
            },
            isSelected (step) {
                return this.isMobile && this.isStep(step);
            },
            decStep () {
                const step = this.step - 1;
                if (step >= this.min) {
                    this.step = step;
                } else {
                    this.step = 0
                }
            },
            incStep () {
                const step = this.step + 1;
                if (step < this.max) {
                    this.step = step;
                }
            },
            isStep (step) {
                return this.step === step;
            },
            close () {
                if (this.disableControls) {
                    return;
                }
                this.$emit("close", this.step);
                this.step = -1;
            },
            toDropShadow(param) {
                this.$emit("add-drop-shadow", param);
            },
            dismissInstructions() {
                this.tooltipAt=null
                this.$store.dispatch("setHelpConfigurator", 0 )
            },

            openInstructions(idx) {
                this.tooltipAt=idx
                this.$store.dispatch("setHelpConfigurator", 1 )
            }
        },
        computed: {
            ...mapGetters([
                "isManager",
                "isAdmin",
                "isAdminProject"
            ]),
            isMobile () {
                return this.$vuetify.breakpoint.smAndDown;
            },
            mdAndDown () {
                return this.$vuetify.breakpoint.mdAndDown;
            },
            step: {
                get () {
                    return this.value;
                },
                set (val) {
                    this.$emit("input", val);
                }
            },
            isAdminOrManager() {
                return this.isManager || this.isAdmin
            },
            showHelp () {
                return (!this.isAdminOrManager || this.isAdminProject) && this.$store.state.help.configurator === 1;
            },
            hideBtnControl() {
                return !this.isAdminOrManager || this.isAdminProject
            }
        }
    };
</script>
<style>
    .vc-sketch-presets {
        display: none !important;
    }
</style>
<style lang="scss" scoped>
    .configurator {
        max-height: 62px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &__btn-text {
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-size: 12px;
            line-height: 24px;
            text-align: right;
            color: #464646;
        }
        &__icon {
            cursor: pointer;
            width: 62px;
            height: 62px;
            margin-right: 7px;
            @media screen and (max-width: 500px) {
                width: 42px;
                height: 42px;
            }
            @media screen and (max-width: 315px) {
                width: 32px;
                height: 32px;
            }
            @media screen and (max-width: 1050px) {
                margin-right: 10px;
            }
            @media screen and (max-width: 400px) {
                margin-right: 10px;
            }
        }
        &__previous {
            margin-right: 16px;
            @media screen and (max-width: 400px) {
                margin-right: 10px;
            }
            @media screen and (min-width: 959px) and (max-width: 1050px) {
                min-width: 24px !important;
                padding: 0 10px !important;
            }
            @media screen and (max-width: 500px) {
                min-width: 24px !important;
                padding: 0 10px !important;
            }
            @media screen and (max-width: 315px) {
                min-width: 10px !important;
                padding: 0 5px !important;
            }
        }
        &__next {
            @media screen and (min-width: 959px) and (max-width: 1050px) {
                min-width: 24px !important;
                padding: 0 10px !important;
            }
            @media screen and (max-width: 500px) {
                min-width: 24px !important;
                padding: 0 10px !important;
            }
            @media screen and (max-width: 315px) {
                min-width: 10px !important;
                padding: 0 5px !important;
            }
        }
        &.disabled {
            .configurator__icon {
                cursor: not-allowed;
            }
        }
        &-btn{
            background: #9ff4a9;
            border: 1px solid #888888;
            padding: 0 8px !important;
            height: 22px !important;
            margin-bottom: 18px;
            &:disabled{
                background: none;
                border: 1px solid #464646;
                cursor: not-allowed;
            }
        }
    }
    .help-window {
        display: none;
    }
    .help-context {
        display: none;
    }
    .help-wrapper {
        //  z-index: 1000!important;
        //position: fixed;
        .help-text {
            font-family: "Futura PT", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #0B3C49;
        }
        .help-window {
            display: block;
            z-index: 100;
            height: 200px;
            background: #FFFFFF;
            padding: 24px 18px 18px 14px;
            width: 360px;
            //border: 1px solid red;
            position: relative;
        }
        .help-hide {
            visibility: hidden;
        }
        .help-context {
            top: 10px;
            position: relative;
            z-index: 1000 !important;
            display: flex;
            max-height: 80px;
        }
        .help-visible {
            z-index: 1000 !important;
        }
    }

    .items-container {
        .tooltip-wrapper {
            position: relative;
            .tooltip {
                left: -134px;
                position: absolute;
                transform: none;
                z-index: 7;
                bottom: calc(100% + 45px);
                .tooltip-content {
                    padding: 17px 17px;
                    background: #ffffff;
                    border: 1px solid #888888;
                    border-radius: 4px;
                    width: 345px;
                    background-size: cover;
                    position: relative;
                    z-index: 8;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    &:before{
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 35px 40px 0 0;
                        border-color: #ffffff transparent transparent transparent;
                        position: absolute;
                        bottom: -34px;
                        left: calc(50% + 10px);
                        transform:translateX(-50%) ;
                        z-index: 1;
                    }

                    &:after{
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 35px 40px 0 0;
                        border-color: #888888 transparent transparent transparent;
                        position: absolute;
                        bottom: -36px;
                        left: calc(50% + 9px);
                        transform:translateX(-50%) ;
                        z-index: 0;
                    }

                    .video-wrapper {
                        width: 100%;
                        height: 174px;
                        background: #C4C4C4;
                        border-radius: 5px;
                        position: relative;
                        //display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none;
                        margin-bottom: 13px;

                        video {
                            z-index: 8;
                        }

                        button {
                            cursor: pointer;
                            z-index: 9;
                            position: absolute;
                        }
                    }

                    .info-block {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #000000;
                        margin: 0 0 10px 0;

                        .title {
                            font-size: 14px !important;
                            line-height: 18px !important;
                            font-weight: 600;
                            margin-bottom: 4px;
                        }

                        .text {
                            line-height: 1.5;
                        }
                    }

                    .controls {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        button {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        .style-link {
                            color: #3F7359;
                            text-decoration: underline;
                        }

                        .next-step {
                            width: 160px;
                            height: 27px;
                            background: #9FF4A9;
                            border: 1px solid #464646;
                            box-sizing: border-box;
                            border-radius: 50px;
                            color: #464646;
                        }
                    }
                }
                &::after {
                    content: " ";
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(84, 84, 84, 0.75);
                }
            }
        }
    }

    .highlighted {
        z-index: 7;
        position: relative;
    }
</style>
