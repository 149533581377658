<template>
    <div
        :class="[mainClass]"
        class="drop-item-menu">
        <!--    && commentsThreads[0] && commentsThreads[0].comment !== ''-->
        <div v-if="commentsThreads.length">
            <project-reference-marker-comment
                v-for="(comment, idx) in commentsThreads"
                :key="idx"
                :comment="comment"
                :current-file="currentFile"
                :idx="idx"
                :is-replying-status="isReplying"
                :store="store"
                :current-customer-id="profile.id"
                @deleteComment="deleteComment"
                @deleteReply="deleteReply"
                @editSave="editSave"
                @setNewIcon="setNewIcon"/>

            <v-textarea
                v-if="isReplying"
                v-model="commentText"
                auto-grow
                class="placeholder ma-4 mb-0 comment__input"
                counter
                dense
                maxlength="500"
                outlined
                placeholder="Add Comment"
                rows="1"
                style="width: 238px"
                @blur="store.commit('updateCommentEditStatus', false)"
                @focus="store.commit('updateCommentEditStatus', true)"/>

            <div class="d-flex justify-space-between px-4 py-3">
                <a
                    v-if="!isReplying"
                    class="drop-item-menu__btn drop-item-menu__btn--close"
                    role="button"
                    @click="$emit('hideComment', $event)">
                    Close
                </a>
                <a
                    v-if="!isReplying"
                    class="drop-item-menu__btn"
                    role="button"
                    @click="isReplying = true">
                    Reply
                </a>
                <a
                    v-if="isReplying"
                    class="drop-item-menu__btn drop-item-menu__btn--close"
                    role="button"
                    @click="reply($event, false)">
                    Close
                </a>
                <a
                    v-if="isReplying"
                    class="drop-item-menu__btn"
                    role="button"
                    @click="reply($event)">
                    Save
                </a>
            </div>
        </div>

        <div
            v-if="!commentsThreads.length"
            class="comment pa-4">
            <div class="comment__username mb-2">
                {{ profile.firstName }} {{ profile.lastName }}
            </div>

            <autocomplete
                v-model="commentText"
                :disabled="false"
                :items="$store.getters.getAutocompleteData"
                :placeholder="
                    hideAutocomplite
                        ? 'Leave comment'
                        : 'Leave comment, or type “/” to tag'
                "
                :text-trigger="
                    currentFile &&
                        (currentFile.type === 4 || currentFile.type === 5)
                        ? 'fuck/'
                        : '/'
                "
                class="mb-2 comment__input placeholder"
                style="width: 238px"
                @blur="store.commit('updateCommentEditStatus', false)"
                @focus="store.commit('updateCommentEditStatus', true)"
                @selected="
                    iconObj =>
                        $emit('setNewIcon', {
                            commentData: commentData,
                            iconObj
                        })
                "/>

            <div class="d-flex justify-space-between">
                <v-link
                    class="drop-item-menu__btn"
                    @click.stop="cancelComment(commentData)">
                    Close
                </v-link>
                <v-btn
                    :disabled="commentText.length === 0"
                    class="drop-item-menu__btn white--text"
                    color="#0046AF"
                    depressed
                    rounded
                    :loading="loading"
                    @click="postComment($event)">
                    Post
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Autocomplete from "@/components/ui/autocomplete";
    import ProjectReferenceMarkerComment from "@/views/project/start/review-project/ProjectReferenceMarkerComment";

    const srcs = {
        1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        5: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
    };

    export default {
        name: "project-reference-marker-menu",
        components: {
            ProjectReferenceMarkerComment,
            Autocomplete
        },
        data: () => ({
            autoUpdate: true,
            isUpdating: false,
            commentText: "",
            isReplying: false,
            parentID: null,
            loading: false
        }),
        watch: {
            isReplyOpen(open) {
                this.isReplying = open;
            }
        },
        props: {
            currentFile: {
                type: [Object, File],
                default: null
            },
            store: {
                type: Object,
                required: true
            },
            commentData: {
                type: Object,
                default: null
            },
            mainClass: {
                type: String,
                default: "preview-menu"
            },
            isReplyOpen: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hideAutocomplite() {
                return (
                    this.currentFile &&
                    (this.currentFile.type === 4 || this.currentFile.type === 5)
                );
            },
            getProjectFiles() {
                return this.store.getters.getProjectFiles;
            },
            getMarkers() {
                return this.store.getters.getMarkers;
            },
            getProjectID() {
                return this.store.getters.getProjectID;
            },
            ...mapGetters(["profile", "GET_ALL_PROJECT_COMMENTS"]),
            commentsThreads() {
                this.setDefaultParams();
                return this.store.getters.getCommentThread(
                    this.commentData.file_id,
                    this.commentData.comment_id
                );
            }
        },
        methods: {
            setNewIcon(props) {
                console.log("New icon", props);
                this.$emit("setNewIcon", props);
            },
            setDefaultParams() {
                this.commentText = "";
                this.isReplying = this.isReplyOpen;
                this.parentID = null;
            },
            reply(event, reply = true) {
                event.stopPropagation();
                if (reply) {
                    const commentThread = this.store.getters.getCommentThread(
                        this.commentData.file_id,
                        this.commentData.comment_id
                    );
                    if (commentThread && commentThread[0] && commentThread[0].id) {
                        this.parentID = commentThread[0].id;
                    }
                    this.postComment();
                }
                this.$emit("save");
                this.setDefaultParams();
            },
            deleteReply(comment) {
                const isMyComment = comment.user_id == this.profile.id;
                if (isMyComment) {
                    this.store.dispatch("deleteComment", comment);
                }
            },
            editSave(comment) {
                const allComments = this.$store.getters.GET_ALL_PROJECT_COMMENTS.map(item => {
                    if (item.id === comment.id) {
                        return {
                            ...item,
                            comment: comment.comment
                        }
                    }
                    return item;
                });

                this.store.dispatch("updateComment", comment);
                this.$store.commit("setAllComments", allComments);

                this.$emit("save");
            },
            async postComment(event) {
                if (event) {
                    event.stopPropagation();
                }

                this.loading = true;

                const commentData = {
                    project_id: this.$store.getters.getProjectID,
                    file_id: this.commentData.file_id,
                    parent_id: this.parentID,
                    comment_id: this.commentData.comment_id,
                    comment: this.commentText,
                    username: `${this.profile.firstName} ${this.profile.lastName}`
                };

                const res = await this.store.dispatch("addComment", commentData);

                if (this.$route.name == "render-configurator") {
                    this.$store.commit("ADD_COMMENT_TO_ALL_COMMENTS", res);
                }

                this.loading = false;
            },
            deleteComment(comment) {
                const isMyComment = comment.user_id == this.profile.id;
                if (isMyComment) {
                    this.$emit("deleteComment", comment);
                }
            },
            cancelComment(coment) {
                this.$emit("cancelComment", coment);
            }
        }
    };
</script>

<style lang="scss" scoped>
.drop-item-menu {
    display: none;
    position: absolute;
    width: 270px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 0 5px grey;
    border-radius: 3px;
    z-index: 1000;
}
.comment {
    border-bottom: 1px solid #ececec;
    &__username,
    &__date,
    &__text {
        font-size: 12px;
        line-height: 16px;
    }
    &__date {
        color: #9faca9;
    }
}
</style>

<style lang="scss">
.drop-item-menu__btn--close {
    color: #8b8b8b !important;
}
.drop-item-menu__btn,
.drop-item-menu__btn .v-btn__content {
    font-size: 12px !important;
    line-height: 16px !important;
}
.comment__input {
    font-size: 12px;
    .v-input__slot {
        min-height: 32px !important;
    }
}
</style>
