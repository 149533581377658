<template>
    <div class="select-product ml-auto mr-auto">
        <v-container class="select-product__container py-0">
            <project-configurator v-if="$route.params.id && $route.name === 'render-configurator'"/>
        </v-container>
    </div>
</template>

<script>
    import ProjectConfigurator from "@/components/project/ProjectConfigurator";
    export default {
        name: "model-specs-tab",
        components: { ProjectConfigurator }
    }
</script>
