<template>
    <div
        class="spec__wrap"
        v-if="data.length">
        <div class="spec">
            <div class="spec__views">
                <template v-if="customTitle">
                    {{ customTitle }}
                </template>
                <template v-else>
                    {{ data.length+"/"+totalViewsCount }} Views Selected
                </template>
            </div>
        </div>
        <div class="spec__list no-select">
            <div
                v-for="file in data"
                :key="file.id"
                class="spec__label-wrap d-inline-flex mr-3">
                <label
                    :class="{'spec__list--active': currentSelected && currentSelected.id === file.id}"
                    class="d-inline-flex align-item"
                    @click="$emit('selected', file, unselect)">
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                         v-if="file.title==='Front'">
                        <path d="M25.8577 1H8.2041V19.3301H25.8577V1Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1.89941 6.68865L8.20425 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M20.1846 6.68865L25.8589 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M25.8589 19.3281L20.1846 25.0168" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1.89941 25.0168L8.20425 19.3281" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M20.1834 6.6875H1.89941V25.0176H20.1834V6.6875Z" fill="#9FF4A9" stroke="#464646" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div v-if="hoverPreview" class="hover-preview align-center justify-center pa-2">
                        <img :src="file.url" alt="">
                    </div>

                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                         xmlns="http://www.w3.org/2000/svg" v-if="file.title==='Side'">
                        <path d="M7.20658 1V19.0786L1.53223 25.0187V6.94012L7.20658 1Z" fill="#9FF4A9" stroke="#464646" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.1858 6.6875H1.53223V25.0176H19.1858V6.6875Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M25.4901 1H7.20605V19.3301H25.4901V1Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M25.4904 1V19.0786L19.1855 25.0187V6.94012L25.4904 1Z" fill="#9FF4A9" stroke="#464646" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none"
                         xmlns="http://www.w3.org/2000/svg" v-if="file.title==='Perspective'">
                        <path d="M13.3228 11.7461L24.041 11.7461V1.00087L13.3228 1.00087L13.3228 11.7461Z" fill="#9FF4A9" stroke="#464646" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.34352 25.0195L18.9971 25.0195L18.9971 6.68944L1.34352 6.68944L1.34352 25.0195Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M24.0409 1L18.9971 6.68865" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M24.0409 11.7461L18.9971 25.0196" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M13.3229 11.7461L1.34375 25.0196" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M13.3229 1L1.34375 6.68865" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none"
                         xmlns="http://www.w3.org/2000/svg" v-if="file.title==='Back'">
                        <path d="M26.3026 1H8.01855V19.3301H26.3026V1Z" fill="#9FF4A9" stroke="#464646" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.71387 6.68865L8.01871 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M19.999 6.68865L26.3039 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M26.3039 19.3281L19.999 25.0168" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1.71387 25.0168L8.01871 19.3281" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M19.9979 6.6875H1.71387V25.0176H19.9979V6.6875Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                         xmlns="http://www.w3.org/2000/svg" v-if="file.title.indexOf('Custom')>=0">
                        <path d="M25.8577 1H8.2041V19.3301H25.8577V1Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1.89941 6.68865L8.20425 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M20.1846 6.68865L25.8589 1" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M25.8589 19.3281L20.1846 25.0168" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1.89941 25.0168L8.20425 19.3281" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M20.1834 6.6875H1.89941V25.0176H20.1834V6.6875Z" stroke="#464646" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <span class="spec__view-title">
                        {{ file.title }}
                    </span>
                </label>
                <v-img
                    :src="require('@/assets/icons/ic-remove.svg')"
                    v-if="(isClient || isManagerOrAdmin) && !disableEdit"
                    class="spec__view-remove"
                    contain
                    height="15"
                    max-height="15"
                    max-width="15"
                    width="15"
                    @click.prevent="remove(file, unselect)"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "spec-view",
        data: () => ({}),
        props: {
            customTitle: {
                type: String,
                default: null
            },
            disableEdit: {
                type: Boolean,
                default: false
            },
            disableControls: {
                type: Boolean,
                default: false
            },
            index: {
                type: Number,
                default: null
            },
            data: {
                type: Array,
                default: () => ([])
            },
            currentSelected: {
                type: Object,
                default: () => ({})
            },
            totalViewsCount: {
                type: Number,
                default: 0
            },
            hoverPreview: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            unselect () {
                this.$emit("selected", {}, this.unselect);//this.selected = {}
            },
            remove (file, unselect) {
                this.$emit("remove", file, unselect);
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManagerOrAdmin"
            ]),
            title () {
                return (this.data.length + " Views");
            },
            menu: {
                get () {
                    return this.value;
                },
                set (val) {
                    this.$emit("input", val);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "spec";

    .spec__wrap {
        background: #FFFFFF;
        border: 1px solid #BCBCBC;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;
        margin: 12px 0 0;
        padding: 16px;
    }

    .spec__views {
        justify-content: flex-start;
        background-color: transparent !important;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #464646;
    }

    .spec__label-wrap {
        position: relative;
        margin-top: 12px;

        label{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 61px;
            height: 58px;
            background: #FFFFFF;
            border: 1px solid #464646;
            border-radius: 4px;

            span {
                background: #F5FEF6;
                border-radius: 0px 0px 4px 4px;
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                text-align: center;
                padding: 2px 8px;
                font-size: 12px;
                line-height: 15px;
            }

            svg {
                margin-top: 10px;
                margin-bottom: 3px;
            }

            .hover-preview {
                display: none;
                position: absolute;
                transform: translate(94%, -94%);
                top: 0;
                right: 0;
                width: 320px;
                height: 298px;
                border-radius: 8px;
                border: 1px solid #000000;
                background-color: #ffffff;
                z-index: 2;
                cursor: initial;

                img {
                    width: 100%;
                    object-fit: contain;
                }

                &:hover {
                    display: flex;
                }
            }

            &:hover .hover-preview {
                display: flex;
            }
        }
    }

    .spec__view-remove {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        right: -5px;
        top: -5px;
    }
</style>
