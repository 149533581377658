<template>
    <div class="project-scroll-section configurator-page">
        <div
            v-if="!$store.getters.getProjectLoading"
            class="grid ml-auto mr-auto">
            <div class="dark-overlay" v-if="true"></div>
            <project-header class="mb-12" edit-title edit-sku/>
            <div class="d-flex flex-wrap">
                <div class="configurator-viewer-model">
                    <div
                        v-show="isCommentReview"
                        class="comment-review">
                        <v-btn
                            class="pa-0 comment-review__close"
                            height="23"
                            width="23"
                            @click="closeCommentReview(false)">
                            X
                        </v-btn>
                        <div
                            v-for="(item, index) in swiperTypeView"
                            v-show="reviewIndex === index"
                            :key="index"
                            class="comment-review__list">
                            <div
                                v-for="file in item"
                                :key="file.id"
                                :class="{
                                    'comment-review--active':
                                        currentSelected &&
                                        currentSelected.id === file.id
                                }"
                                class="comment-review__item"
                                @click="
                                    selectFile(file, 'comment review')
                                ">
                                <div class="comment-review__title">
                                    {{ file.title }}
                                </div>
                                <div class="comment-review__img">
                                    <v-img
                                        :src="file.url"
                                        contain/>
                                </div>
                            </div>
                            <div
                                :class="`pci-prev-btn-a`"
                                class="pci-swiper-btn pci-prev-btn"
                                @click="slideLeft">
                                <v-icon>mdi-chevron-left</v-icon>
                            </div>
                            <div
                                :class="`pci-next-btn-b`"
                                class="pci-swiper-btn pci-next-btn"
                                @click="slideRight">
                                <v-icon>mdi-chevron-right</v-icon>
                            </div>
                        </div>
                    </div>

                    <v-btn
                        v-show="
                            !isCommentReview &&
                                currentSelected &&
                                currentFile3d.id !==
                                currentSelected.id &&
                                currentSelected.id !== undefined &&
                                !isDraw &&
                                !isImageEdit
                        "
                        class="pa-0 mt-2"
                        height="23"
                        style="position: absolute; z-index: 5; right: 20px; top: 10px; min-width: 24px!important;"
                        width="23"
                        @click="closePreview">
                        X
                    </v-btn>
                    <image-edit
                        v-if="showBackgroundChangeCotrols"
                        :store="$store"
                        @exitImageEdit="resetSelectedBakcroundColor"
                        @saveImageEdit="attachBackgroundColor"/>
                    <project-references-slider
                        :id="'left'"
                        :key="currentFile3d.id"
                        :background="backgroundUrl"
                        :background-alpha="color.a"
                        :background-color="color.hex"
                        :hide-captions="true"
                        :hide-drawer="!isDraw || !setImageEdit"
                        :hide-thumbs="true"
                        :store="$store"
                        :z-index="0"
                        @controls="contorlObj => (controlFiles = contorlObj)"
                        @fileSelected="file => (currentSelected = file)"
                        @onDraw="setDraw"
                        @onImageEdit="setImageEdit"
                        @closeLogoView="closeLogoView"
                        @closeDrawMode="closePreview"/>
                    <template v-if="controlFiles">
                        <configurator
                            v-show="!isCommentReview"
                            v-model="step"
                            :background-file="typeBackground[0]"
                            :control-files="controlFiles"
                            :current-file3d="currentFile3d"
                            :disable-controls="
                                (disableControls || isManager || isAdmin ) && !isAdminProject
                            "
                            :type-views="typeViews"
                            @close="$print('close')"
                            @place-engraving="placeEngraving()"
                            @add-drop-shadow="addDropShadow"
                            @upload-logo="uploadLogo"
                            @snapshot-views="
                                arrayRotations =>
                                    $print(
                                        arrayRotations,
                                        'snapshot-views'
                                    )
                            "
                            @remove-view="removeFile"
                            @add-view="addView"
                            @color-changed="changeColor"
                            @upload-background="uploadBackground"
                            @replace-background="
                                backgroundFile =>
                                    $print(
                                        backgroundFile,
                                        'replace-background'
                                    )
                            "
                            @remove-background="
                                backgroundFile => {
                                    removeBackground(backgroundFile);
                                    $print(
                                        backgroundFile,
                                        'remove-background'
                                    );
                                }
                            "
                            @change-color="
                                color => $print(color, 'change-color')
                            "
                            @attachBackgroundColor="
                                attachBackgroundColor
                            "/>
                    </template>
                </div>
                <review-block
                    :class="{
                        'help-wrapper2':
                            $store.state.help.configurator === 2
                    }"
                    class-body="pa-0"
                    title="Specifications">
                    <div
                        class="help-window2"
                        v-if="
                            (!isManager && !isAdmin) &&
                                $store.state.help.configurator === 2
                        ">
                        <b>Leave any final comments</b>
                        <span>
                            by rotating the model and adding a snap
                            comment to add any further specifications to
                            your final rendering.
                        </span>
                        <div
                            class="d-flex justify-end mt-3 align-center ">
                            <v-btn
                                class="mt-2"
                                color="#0046AF"
                                outlined
                                rounded
                                small
                                style="border: 2px solid #0046AF"
                                @click="
                                    $store.dispatch('setHelpConfigurator', 0)
                                ">
                                Ok, got it!
                            </v-btn>
                        </div>
                    </div>

                    <v-btn
                        :disabled="disabledSnapComment"
                        class="help-visible2 snap-comment-btn mb-3"
                        small
                        height="30"
                        width="100%">
                        <v-img src="@/assets/_redesigne/icons/ic-comment.svg" max-width="16" contain/>
                        <span
                            class="ml-2 "
                            @click="snapComment">
                            Snap Comment
                        </span>
                    </v-btn>
                    <spec-view
                        :current-selected="currentSelected"
                        :data="typeViews"
                        :disable-controls="disableControls"
                        :custom-title="viewsTypeTitle"
                        :total-views-count="viewsTemplates.length"
                        @delete="
                            file => $print(file, 'specView delete')
                        "
                        @edit="file => $print(file, 'specView edit')"
                        @open="file => openCommentReview(file)"
                        @remove="removeFile"
                        @replay="$print('specView replay')"
                        @selected="
                            file => {
                                selectFile(file, 'spec-view');
                            }
                        "/>

                    <spec
                        v-for="file in typeBackground"
                        :key="file.id"
                        :current-selected="currentSelected"
                        :data="file"
                        :disable-controls="disableControls"
                        @delete="removeFile"
                        @edit="file => $print(file, 'spec edit')"
                        @open="file => selectFile(file, 'spec open')"
                        @replay="file => $print(file, 'spec replay')"
                        @selected="
                            file => {
                                selectFile(file);
                            }
                        "/>

                    <spec
                        v-for="(file,index) in typeDropShadow"
                        :key="file.id"
                        :current-selected="currentSelected"
                        :data="file"
                        :index="index + 1"
                        :disable-controls="disableControls"
                        @delete="removeFile"
                        @edit="file => $print(file, 'spec edit')"
                        @open="file => selectFile(file, 'spec open')"
                        @replay="file => $print(file, 'spec replay')"
                        @selected="
                            file => {
                                selectFile(file);
                            }
                        "/>

                    <spec
                        v-for="(file, index) in typeEng"
                        :key="file.id"
                        :current-selected="currentSelected"
                        :data="file"
                        :disable-controls="disableControls"
                        :index="index + 1"
                        @delete="removeFile"
                        @edit="editFile"
                        @open="file => selectFile(file, 'spec open')"
                        @replay="replay"
                        @selected="
                            file => {
                                selectFile(file);
                            }
                        "/>

                    <spec
                        v-for="(file, index) in typeLogo"
                        :key="file.id"
                        :current-selected="currentSelected"
                        :data="file"
                        :disable-controls="disableControls"
                        :index="index + 1"
                        @delete="removeFile"
                        @edit="editLogo"
                        @open="file => selectFile(file, 'spec open')"
                        @replay="replay"
                        @selected="
                            file => {
                                selectFile(file);
                            }
                        "/>
                    <!--
                         <spec v-for="(file, index) in typeReference"
                               :key="file.id"
                               :current-selected="currentSelected"
                               :data="file"
                               :disable-edit="isWaitingForRenders"
                               :disable-controls="disableControls"
                               :index="index + 1"
                               @delete="removeFile"
                               @edit="(file)=>$print(file, 'spec edit')"
                               @open="(file)=>selectFile(file, 'spec open')"
                               @replay="(file)=>$print(file, 'spec replay')"
                               @selected="(file)=>{selectFile(file);}"/>
                               -->

                    <project-render-description class="mb-3"/>
                    <project-price-details
                        v-if="getProjectData.type_id !== $config.project.type.custom && !isAdminProject"
                        :price="getProjectData.client_amount"/>
                </review-block>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "@/api/httpClient.api";
    import ReviewBlock from "@/components/ui/ReviewBlock";
    import Spec from "@/components/configurator/spec";
    import SpecView from "@/components/configurator/spec-view";
    import Configurator from "@/components/configurator/configurator";
    import ProjectReferencesSlider from "@/views/project/start/review-project/ProjectReferencesSlider";
    import { FILETYPES } from "@/utils/constans";
    import UploadService from "@/api/uploadFilesService.api";
    import { debounce } from "debounce";
    import { mapGetters } from "vuex";
    import ImageEdit from "@/components/ui/ImageEdit";
    import ProjectHeader from "@/components/project/ProjectHeader";
    import ProjectRenderDescription from "@/components/project/ProjectRenderDescription";
    import ProjectPriceDetails from "@/components/project/ProjectPriceDetails";

    export default {
        name: "project-configurator",
        components: {
            ProjectPriceDetails,
            ProjectReferencesSlider,
            ProjectHeader,
            Configurator,
            SpecView,
            Spec,
            ReviewBlock,
            ImageEdit,
            ProjectRenderDescription
        },
        data() {
            return {
                activeTab: null,
                viewsTypeTitle: null,
                reviewIndex: 0,
                isImageEdit: false,
                isDraw: false,
                color: {},
                isCommentReview: false,
                controlFiles: null,
                viewsTemplates: [
                    { id: 701, title: "Front", rotate: 0 },
                    { id: 702, title: "Back", rotate: 0 },
                    { id: 703, title: "Side", rotate: 0 },
                    { id: 704, title: "Perspective", rotate: 0 },
                    { id: 705, title: "Custom 1", rotate: 0 }
                ],
                currentFile: {},
                currentFile3d: {},
                currentFileID: 0,
                cameraConfig: {},
                currentSelected: null,
                loading: false,
                dialog: false,
                step: 0,
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    },
                    {
                        text: ""
                    }
                ],
                projectStepsLinks: [],
                fullInfo: [],
                designerInfo: null,
                cancelDialog: false,
                showBackgroundChangeCotrols: false
            };
        },
        computed: {
            ...mapGetters(["isClient", "isManager", "isAdmin", "isDesigner", "getProjectData", "getValueProjectStatus", "isAdminProject"]),
            disabledSnapComment() {
                return true || (this.disableControls || this.isManager || !!this.currentSelected)
            },
            assetsSpecifications() {
                return this.$store.getters["getAssetsSpecifications"];
            },
            currentModelAssetsSpecifications() {
                return this.$store.getters["getCurrentModelAssetsSpecifications"](
                    true
                );
            },
            disableControls() {
                return this.isDraw || this.isImageEdit;
            },
            backgroundUrl() {
                const hasBackgroundColor = this.typeBackground[0] ? !!this.typeBackground[0].backgroundColor : false;
                if (hasBackgroundColor) {
                    return null
                }
                return this.typeBackground[0]?.url;
            },
            projectFiles() {
                return this.$store.getters.getProjectFiles || [];
            },
            typeEng() {
                if (!this.currentModelAssetsSpecifications) return [];
                return this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.ENGRAVING;
                });
            },
            typeLogo() {
                if (!this.currentModelAssetsSpecifications) return [];
                return this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.LOGO;
                });
            },
            typeReference() {
                if (!this.currentModelAssetsSpecifications) return [];
                return this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.REFERENCE;
                });
            },
            typeBackground() {
                if (!this.currentModelAssetsSpecifications) return [];
                return this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.BACKGROUND;
                });
            },
            typeDropShadow() {
                if (!this.currentModelAssetsSpecifications) return [];
                return this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.DROP_SHADOW;
                });
            },
            typeViews() {
                if (!this.currentModelAssetsSpecifications) return [];
                const views = this.currentModelAssetsSpecifications.filter(file => {
                    return parseInt(file.type) === FILETYPES.VIEWS;
                });
                return views.map(item => ({
                    ...item,
                    name: this.$store.getters.getProjectData.user
                }));
            },
            swiperTypeView() {
                const result = [];
                let temp = [];
                if (this.$vuetify.breakpoint.mdAndUp) {
                    this.typeViews.forEach((item, index) => {
                        if (index !== 0 && index % 6 === 0) {
                            result.push([...temp]);
                            temp = [];
                        }
                        temp.push(item);
                    });
                } else {
                    this.typeViews.forEach((item, index) => {
                        if (index !== 0 && index % 4 === 0) {
                            result.push([...temp]);
                            temp = [];
                        }
                        temp.push(item);
                    });
                }

                result.push([...temp]);
                return result;
            },
            project() {
                return this.$store.getters.getProjectData;
            },
            projectTypeFullInfo() {
                return this.fullInfo.find(item => item.title === this.project.type);
            },
            type3d() {
                return this.assetsSpecifications.filter(
                    item => item.type === FILETYPES.D3
                );
            },
            hasViews() {
                return this.typeViews.length > 0
            }
        },
        async created() {
            if ((this.isManager || this.isAdmin) && !this.isAdminProject ) {
                this.step = -1;
            }

            this.FILETYPES = FILETYPES;

            await this.$store.dispatch("getAssets", this.project.id);

            this.setInitialConfig();
        },
        watch: {
            controlFiles() {
                if (this.controlFiles) {
                    this.controlFiles.selectIndexFromId(this.currentFile3d.id);
                }
            }
        },
        methods: {
            changeColor(c) {
                if (this.disableControls) {
                    return;
                }
                this.color = c;
                this.showBackgroundChangeCotrols = true
            },
            closeLogoView() {
                this.currentSelected = null;
                this.controlFiles.selectIndexFromId(this.currentFile3d.id);
            },
            slideLeft() {
                if (this.reviewIndex === 0) {
                } else {
                    this.reviewIndex--;
                }
            },
            slideRight() {
                if (this.reviewIndex + 1 >= this.swiperTypeView.length) {
                } else {
                    this.reviewIndex++;
                }
            },
            closeCommentReview() {
                this.isCommentReview = false;
                this.$print("closeCommentReview");
            },
            openCommentReview(file) {
                this.isCommentReview = true;
                this.$print(file, "spec-view open -> closeCommentReview");
            },
            setImageEdit(status) {
                this.isImageEdit = status;
            },
            setDraw(status) {
                this.isDraw = status;
            },
            replay(file) {
                this.closeCommentReview();
                if (this.disableControls) {
                    return;
                }
                this.controlFiles.openFirstComment(file);
                this.$print(file, "spec replay");
            },
            selectFile(file, type = "spec") {
                this.closeCommentReview();
                if (this.disableControls) {
                    return;
                }
                this.controlFiles.selectIndexFromId(file.id);
                this.currentSelected = file;
                this.$print(file, type + " selected");
            },
            async snapComment() {
                if (this.$store.state.help.configurator > 0) {
                    return;
                }
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                this.$print("Snap Comment");
                const { controlFiles } = this;
                if (controlFiles) {
                    controlFiles.uploadSnapshot({
                        type: FILETYPES.REFERENCE,
                        title: "Reference",
                        parent_id: this.currentFile.id
                    });
                }
            },
            async addView(rotation) {
                await this.closePreview();

                if (this.disableControls) {
                    return;
                }
                const { controlFiles } = this;

                if (controlFiles) {
                    controlFiles.rotate(rotation.rotate);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            controlFiles.uploadSnapshot({
                                type: FILETYPES.VIEWS,
                                view_id: rotation.id,
                                title: rotation.title,
                                parent_id: this.currentFile.id,
                                project_id: this.project.id
                            }, () => {
                                this.closePreview();
                                rotation.loading = false
                            });
                        }, 1000);
                    });
                }
            },
            async placeEngraving() {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                this.$print("place-engraving");
                const { controlFiles } = this;

                if (!controlFiles) {
                    return;
                }

                const file = await controlFiles.uploadSnapshot(
                    {
                        type: FILETYPES.ENGRAVING,
                        title: "Engraving",
                        parent_id: this.currentFile.id,
                        project_id: this.project.id
                    },
                    () => {
                        this.$nextTick(() => {
                            this.controlFiles.setNextComment({
                                type: FILETYPES.BACKGROUND
                            });
                            this.$store.commit("drawer/SET_STATUS_DRAW", true);
                            this.$print(file, "spec! editFile");
                        });
                    }
                );
                this.currentSelected = file;
            },
            async addDropShadow(param) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                this.$print("place-drop-shadow");
                const { controlFiles } = this;

                if (!controlFiles) {
                    return;
                }

                const file = await controlFiles.uploadSnapshot(
                    {
                        type: FILETYPES.DROP_SHADOW,
                        title: "Shadow",
                        parent_id: this.currentFile.id,
                        project_id: this.project.id,
                        "props[]": JSON.stringify({ "blur": param.blur, "opacity": param.opacity })
                    }
                );
                this.currentSelected = file;
            },
            async uploadLogo(file) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                this.$print(file, "upload-logo");
                const { controlFiles } = this;

                if (!controlFiles) {
                    return;
                }

                const snapShotFile = await controlFiles.uploadSnapshot({
                    type: FILETYPES.LOGO,
                    title: "Logo",
                    parent_id: this.currentFile.id,
                    project_id: this.project.id
                });

                const iconFile = await UploadService.upload2(file, {
                    type: FILETYPES.ICON_FOR_COMMENT,
                    title: "ICON LOGO",
                    project_id: this.project.id,
                    parent_id: snapShotFile.id
                });

                this.$nextTick(() => {
                    this.controlFiles.setNextComment({
                        type: FILETYPES.VIEWS,
                        file: iconFile
                    });
                    this.controlFiles.setLogo(iconFile);
                    this.$print(iconFile, "spec! uploadLogo");
                });

                if (typeof file.disableLoading === "function") {
                    file.disableLoading();
                }
            },
            async editFile(file) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                //this.controlFiles.setDrawerStatus(true)
                this.controlFiles.selectFirstComment(file, () => {
                    this.$store.commit("drawer/SET_STATUS_DRAW", true);
                    this.$print(file, "spec! editFile");
                });

                this.$print(file, "spec! edit");
            },
            async editLogo(file) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                //this.controlFiles.setDrawerStatus(true)
                this.controlFiles.selectFirstComment(file, () => {
                    this.controlFiles.editLogo(file);
                    //this.$store.commit('drawer/SET_STATUS_DRAW', true);
                    this.$print(file, "spec! editFile");
                });

                this.$print(file, "spec! edit");
            },
            async removeBackground(backgroundFile) {
                await this.closePreview();
                await this.removeFile(backgroundFile);
            },
            async uploadBackground(file) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                this.$print(file, "upload-background");

                const { controlFiles } = this;
                if (!controlFiles) {
                    return;
                }

                const oldFile =
                    this.typeBackground.length > 0 ? this.typeBackground[0] : null;
                if (oldFile) {
                    await this.removeFile(oldFile);
                }

                this.$nextTick(() => {
                    setTimeout(async () => {
                        const backgroundFile = await controlFiles.uploadFile(file, {
                            type: FILETYPES.BACKGROUND,
                            title: "Background",
                            parent_id: this.currentFile.id,
                            project_id: this.project.id
                        });

                        if (typeof file.disableLoading === "function") {
                            file.disableLoading();
                        }
                    }, 0);
                });
            },
            async removeFile(file) {
                if (this.disableControls) {
                    return;
                }
                this.$print(file, "spec delete");

                if (this.controlFiles) {
                    const currentSelectedFile = await this.controlFiles.removeFile(
                        file.id
                    );
                    if (this.currentSelected) {
                        if (this.currentSelected.id === this.currentFile3d.id) {
                            this.currentSelected = null;
                        } else {
                            this.currentSelected = currentSelectedFile;
                        }
                    }
                }
            },
            other() {
                if (this.disableControls) {
                    return;
                }
            },
            saveExit(type) {
                if (this.disableControls) {
                    return;
                }

                this.$store.commit(this.$store.state.dialogs.content[type].set, false);
                this.$router.push("/dashboard");
            },
            getProjectTypes() {
                if (this.disableControls) {
                    return;
                }
                let params = {};
                http.get("/projects/types", { params })
                    .then(res => {
                        if (res.status === 200) {
                            this.fullInfo = res.data.data;
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },
            closePreview() {
                this.currentSelected = null;
                this.controlFiles.selectIndexFromId(this.currentFile3d.id);

                return new Promise((resolve, reject) => {
                    return setTimeout(() => {
                        resolve();
                    }, 100)
                });
            },
            setInitialConfig() {
                const file = this.type3d.find(file => file.dummy);
                if (!file) {
                    console.error("file is not found ", file);
                    return;
                }
                this.$store.commit("setCurrentModel", file);
                this.currentFile = file;
                this.currentFile3d = file;
                this.currentFileID = file.id;
                this.currentSelected = null;
                this.activeTab = file.id;

                this.$store.dispatch(
                    "updateProjectData",
                    JSON.parse(JSON.stringify(this.$store.getters.getProjectData))
                );

                if (file.children.length) {
                    this.$store.commit("setProjectFile", [
                        file,
                        ...file.children.filter(
                            item => item.type !== FILETYPES.ICON_FOR_COMMENT
                        )
                    ]);
                }
            },
            async attachBackgroundColor(color) {
                await this.closePreview();
                if (this.disableControls) {
                    return;
                }
                const { controlFiles } = this;
                if (!controlFiles) {
                    return;
                }
                await UploadService.updateProps(this.currentFile3d.id, {
                    ...this.currentFile3d.props,
                    background: [
                        {
                            hex: this.color.hex,
                            hsl: this.color.hsl,
                            a: this.color.a
                        }
                    ]
                });
                await controlFiles.uploadSnapshot(
                    {
                        type: FILETYPES.BACKGROUND,
                        title: "Background",
                        parent_id: this.currentFile.id,
                        project_id: this.project.id,
                        "props[]": JSON.stringify({ "backgroundColor": this.color.hex })
                    },
                    () => {
                        this.resetSelectedBakcroundColor()
                    }
                );
            },
            async resetSelectedBakcroundColor() {
                await this.changeColor({
                    hex: null,
                    hsl: null,
                    a: null
                })
                this.showBackgroundChangeCotrols = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
.configurator-viewer-model{
    width: 100%;
    max-width: 760px;
    margin-right: 64px;
    position: relative;
    .slider-wrap {
        min-height: 513px;
    }
    @media only screen and (max-width: 1178px) {
        margin: 0 auto;
    }
    @media only screen and (max-width: 768px) {
        .slider-wrap {
            min-height: 260px;
        }
    }
    @media only screen and (max-width: 414px) {
        .slider-wrap {
            min-height: 200px;
        }
    }
}

.project-step-dd {
    margin-left: 22px;
}

.bs-back-link {
    font-size: 14px;
    line-height: 18px;
    color: #3f7359;

    .circle {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        border: 1px solid #888888;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;

        img {
            display: block;
            width: 5px;
            height: 10px;
        }
    }
}

.review {
    flex: 1 1 0;
    width: 286px;
    @media only screen and (max-width: 1178px) {
        width: 100%;
        min-width: 720px;
        margin-top: 24px;
    }
    @media only screen and (max-width: 768px) {
        min-width: 290px;
    }
}

.pci-swiper-wrap {
    position: relative;
}

.pci-swiper-btn {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    cursor: pointer;
    transition: opacity 250ms ease;
    @media screen and (max-width: 400px) {
        top: 90%;
    }

    &:hover {
        opacity: 0.7;
    }

    &.pci-prev-btn {
        left: 15px;
        @media screen and (max-width: 500px) {
            left: 8px;
        }
        @media screen and (max-width: 400px) {
            left: 50px;
        }
    }

    &.pci-next-btn {
        right: 15px;
        @media screen and (max-width: 500px) {
            right: 8px;
        }
        @media screen and (max-width: 400px) {
            right: 50px;
        }
    }
}

.comment-review {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    left: 12px;
    padding-left: 20px;
    padding-right: 20px;
    background: firebrick;
    z-index: 3;
    display: flex;
    background: linear-gradient(0deg, #323232, #323232);
    border: 3px solid #0046af;
    box-sizing: border-box;
    box-shadow: 0 4px 72px rgba(137, 137, 137, 0.25);
    border-radius: 8px;

    &__close {
        position: absolute;
        z-index: 5;
        right: 20px;
        top: 10px;
        min-width: 24px !important;
    }

    &__list {
        display: flex;
        justify-self: center;
        justify-content: stretch;
        flex-grow: 0;
        flex-wrap: wrap;
        margin: auto !important;
        max-width: 750px;
        @media screen and (max-width: 1200px) {
            max-width: 750px * 0.74;
        }
        @media screen and (max-width: 959px) {
            max-width: 750px * 0.77;
        }
        @media screen and (max-width: 733px) {
            max-width: 750px * 0.6;
        }
        @media screen and (max-width: 600px) {
            max-width: 750px * 0.49;
        }
        @media screen and (max-width: 450px) {
            max-width: 760px * 0.36;
        }
        @media screen and (max-width: 344px) {
            max-width: 760px * 0.3;
        }
        @media screen and (max-width: 300px) {
            max-width: 760px * 0.25;
        }
        //margin-bottom: auto;
        //margin-left: auto;
        //margin-right: auto;
    }

    &__item {
        cursor: pointer;
        overflow: hidden;
        margin: 10px;
        flex-basis: auto;
        border: 1px solid #cbcbcb;
        filter: drop-shadow(0px 4px 72px rgba(137, 137, 137, 0.25));
        border-radius: 8px;
    }

    &__title {
        border-bottom: 1px solid #cbcbcb;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        background: #444444;
        /* identical to box height, or 200% */
        text-align: center;
        color: #ffffff;
    }

    .comment-review--active {
        // border: 2px solid #0046AF;
        .comment-review__title {
            background: #0046af; //#fb3805
            /// background: #0046AF;
            //border-bottom: 2px solid #0046AF;
        }
    }

    &__img {
        max-width: 227px;
        max-height: 148px;
        @media screen and (max-width: 1200px) {
            max-width: 227px * 0.71;
            max-height: 148px * 0.71;
        }
        @media screen and (max-width: 959px) {
            max-width: 227px * 0.75;
            max-height: 148px * 0.75;
        }
        @media screen and (max-width: 733px) {
            max-width: 227px * 0.89;
            max-height: 148px * 0.89;
        }
        @media screen and (max-width: 600px) {
            max-width: 227px * 0.7;
            max-height: 148px * 0.7;
        }
        @media screen and (max-width: 450px) {
            max-width: 227px * 0.5;
            max-height: 148px * 0.5;
        }
        @media screen and (max-width: 344px) {
            max-width: 227px * 0.4;
            max-height: 148px * 0.4;
        }
        @media screen and (max-width: 300px) {
            max-width: 227px * 0.3;
            max-height: 148px * 0.3;
        }
    }
}

.main-title {
    font-size: 20px;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        font-weight: 700;
    }
}

.config3d-tab {
    .v-tab {
        position: relative;
        font-size: 12px;
        line-height: 16px;
        color: #0b3c49;
        border: 1px solid #888888;
        box-sizing: border-box;
        border-radius: 5px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        margin: 5px;
        text-transform: none;

        &--active {
            background: #D9FBDD;
            box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        }
    }

    .theme--light.v-icon {
        color: #656565 !important;
    }

    @media screen and (max-width: 600px) {
        .main-title {
            font-size: 14px;
        }
        .v-tab {
            font-size: 12px !important;
        }
    }
}
</style>
<style lang="scss">
.configurator-page {
    .configurator-viewer-model {
        .swiper-slide {
            overflow: hidden;
        }
    }

    .selected-model.is-active {
        background-color: #d9fbdd;
        pointer-events: none;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 1200px) {
    .project-footer__btn span.v-btn__content {
        font-size: 12px !important;
    }
}

.config3d-tab {
    .v-slide-group__prev,
    .v-slide-group__next,
    .v-slide-group__wrapper {
        min-width: 20px !important;
    }
}

.exit-and-save-btn {
    height: 40px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-right: 8px;

    span {
        font-size: 14px;
        line-height: 18px;
        color: #3F7359;
    }
}

.submit-btn-wrap {
    position: relative;

    .tooltip {
        display: none;
    }

    &:hover {
        .tooltip {
            display: block;
        }
    }
}

.submit-btn {
    height: 40px !important;
    background-color: #9FF4A9 !important;
    border: 1px solid #464646;
    box-shadow: none !important;
    border-radius: 50px;
}
</style>
<style lang="scss" scoped>
.project-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &__issues {
        display: flex;
        align-items: center;
    }

    &__source {
        z-index: 2;
    }

    &__submit {
        margin-left: auto;
    }
}

.grid {
    max-width: 1110px;
    width: 100%;
}

.def-blue-link {
    display: inline-block;
    font-size: 13px;
    line-height: 24px;
    color: #0046af;
    cursor: pointer;
    text-decoration: none;
    transition: all 250ms ease;

    &:hover {
        opacity: 0.7;
    }
}
</style>
<style lang="scss" scoped>

.snap-comment-btn {
    background-color: #9FF4A9 !important;
    border: 1px solid #464646;
    border-radius: 50px;
    box-shadow: none;
}

.help-window2 {
    display: none;
}

.help-context {
    display: none;
}

.help-wrapper2 {
    .help-text2 {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0b3c49;
    }

    .help-window2 {
        position: absolute;
        //position: relative;
        left: -400px;
        top: -50px;
        @media screen and (max-width: 800px) {
            position: relative;
            left: 0;
            top: -20px;
        }
        display: block;
        z-index: 100;
        height: 200px;
        background: #ffffff;
        padding: 24px 18px 18px 14px;
        width: 360px;
    }

    .help-visible2 {
        z-index: 1000 !important;
    }
}
</style>
