<template>
    <div
        :class="{'drawer--active': true, 'mb-2': isInProjectView}"
        class="drawer">
        <v-btn
            class="ml-2 mr-4"
            color="white btn__max-height"
            elevation="1"
            small
            @click="$emit('exitImageEdit')">
            Exit Drawing
        </v-btn>
        <v-spacer/>
        <v-btn
            class="ml-2 mr-4"
            color="orange white--text btn__max-height"
            elevation="1"
            small
            @click="$emit('saveImageEdit')">
            Save
        </v-btn>
    </div>
</template>

<script>
    //import { mapGetters  } from 'vuex'

    export default {
        name: "image-edit",
        computed: {
            // isCompleted() {
            //   return this.$store.getters.getProjectData.status === 'Completed';
            // },
            // projectSectionTitle() {
            //     return this.id === 'right' ? 'Pending Model Preview' : 'Reference Image';
            // },
            // showEdit() {
            //     return (this.isClient && this.id === 'left') || (this.isDesigner && this.id === 'right');
            // },
            // isClient() {
            //   return this.$store.getters.isClient;
            // },
            // isDesigner() {
            //   return this.$store.getters.isDesigner;
            // },
            isInProjectView () {
                return this.$store.getters.getIsInProjectView;
            }
            // isBrushHidden() {
            //   return this.store.getters['drawer/isBrushHidden'];
            // },
            // isZoomHidden() {
            //   return this.store.getters['drawer/isZoomHidden'];
            // },
            // isCommentAble() {
            //   return this.store.getters['drawer/isCommentAble'];
            // },
            // isDraw() {
            //   return this.store.getters['drawer/isDraw'];
            // },
            // isColorFreeze() {
            //   return this.store.getters['drawer/isColorFreeze'];
            // },
            // isGrater() {
            //   return this.store.getters['drawer/isGrater'];
            // },
            // getColor() {
            //   return this.store.getters['drawer/getColor'];
            // },
            // getColorList() {
            //   return this.store.getters['drawer/getColorList'];
            // },
            // getBrush() {
            //   return this.store.getters['drawer/getBrush'];
            // },
            // isLarge() {
            //   return this.store.getters['view/isLarge'];
            // },
            // ...mapGetters('drawer', ['isDraw', 'isGrater', 'getColorList', 'getColor', 'getBrush']),
            // ...mapGetters('view', ['isLarge']),
        },
        props: {
            // store: {
            //   type: Object,
            //   required: true,
            // },
            id: {
                type: String,
                default: ""
            },
            currentFileInfo: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        mounted () {
            this.$emit("startDrawBtn", this.$refs.startDraw);
        },
        methods: {
            deleteFile () {
                if (this.currentFileInfo?.id) {
                    this.$root.$emit("removeProjectFile", this.currentFileInfo);
                }
            }
        }
    };
</script>

<style>
    .drawer__menu-brush .v-input__slot {
        top: 10px !important;
    }

    .drawer__menu-brush .v-slider__thumb {
        background-color: rgb(121 119 119) !important;
        border-color: rgb(204, 204, 204) !important;
    }
</style>

<style lang="scss" scoped>
    .btn__max-height {
        min-height: 24px !important;
        max-height: 24px !important;
    }
    .btn__max-height-20 {
        min-height: 20px !important;
        max-height: 20px !important;
    }
    .drawer .v-ripple__container {
        border-radius: 5px !important;
    }
    .drawer {
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F4F4F4;
        border: 1px solid #BCBCBC;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @media screen and (max-width: 600px) {
            flex-wrap: wrap;
        }
        &__block {
            @media screen and (max-width: 600px) {
                order: 1;
                margin-top: 14px;
                margin-left: 8px;
                width: 100%;
                //width: calc(100% - 50px);
            }
        }
        &__link {
            //font-family: "Futura PT", sans-serif;
            //font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #0046AF;
            margin-right: 12px;
        }
        &__menu-item {
            &:hover {
                background: #e5e5e5;
            }
        }
        &__menu-item--active {
            background: rgba(251, 56, 5, 0.2);
            &:hover {
                background: #e5e5e5;
            }
        }
        &__menu-color {
            min-width: 24px !important;
            transform: translate(0, 5px);
        }
        &__menu-brush {
            background: #FFFFFF;
            min-width: 139px !important;
            min-height: 54px !important;
            transform: translate(0, 5px);
            //.v-messages {
            //  display: none;
            //}
            //.v-input__slot {
            //  top: 20px;
            //}
        }
        &__exit {
            outline: 2px solid #E7E7E7;
        }
        &__save {
        }
        &__btn {
            padding: 0;
            min-width: 24px !important;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;
            &--active {
                background: orange !important;
            }
        }
        &--active {
            border: 1px solid #E8E8E8;
            .drawer__edit {
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 5px;
                border: 1px solid #0046AF;
            }
        }
    }
</style>
