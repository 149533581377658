<template>
    <div class="full-view full-view__engraving">
        <div class="full-view__body d-flex align-start mb-4">
            <div class="full-view__icon">
                <v-img
                    :src="require('@/assets/icons/configurator/engraving.svg')"
                    contain/>
            </div>
            <div class="full-view__content">
                <div class="full-view__title">Engraving</div>
                <div class="full-view__text">
                    Rotate image before placing engraving on selected area.
                </div>
            </div>
        </div>
        <div class="full-view__action d-flex align-items-center justify-space-between">
            <button class="full-view--how text-decoration-underline text-unco-dark-green" @click="$emit('openInstruction', idx)">How it works</button>
            <v-btn small class="btn-outline__green px-8" rounded outlined
                   height="23"
                   @click="$emit('place-engraving')">
                Place Engraving
            </v-btn>
        </div>
        <!--        <div class="full-view__title">
            Engraving
            <a
                class="full-view__close no-select"
                role="button"
                @click="$emit('close')">
                X
            </a>
        </div>
        <div class="d-flex">
            <div class="full-view__icon">
                <v-img
                    :src="require('@/assets/icons/configurator/ic-engraving.svg')"
                    contain/>
            </div>
            <div class="full-view__body">
                <div>
                    Rotate image before placing engraving on selected area.
                </div>
                <v-btn
                    :disabled="disabled"
                    class="mt-2"
                    color="#0046AF"
                    outlined
                    rounded
                    small
                    style="border: 2px solid #0046AF"
                    @click="$emit('place-engraving')">
                    Place Engraving
                </v-btn>
            </div>
        </div>-->
    </div>
</template>

<script>
    export default {
        name: "conf-engraving",
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            idx: {
                type: Number,
                default: -1
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "full-view";
</style>
