<template>
    <div class="select-product ml-auto mr-auto">
        <v-container class="select-product__container" @dragenter="toggleDropArea">
            <v-row>
                <v-col class="pa-0">
                    <h1>
                        Great. Now let’s upload something <br>
                        we can work with.
                    </h1>
                    <p class="notice">
                        Please Upload a minimum of
                        <span>
                            3 different views of the item (Top, Side, Front)
                        </span>
                    </p>
                    <h2>
                        <span>Click to upload or drag some files:</span>
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="radio-wrap d-flex flex-wrap align-start py-3 px-0">
                    <!--                    <div
                        class="upload"
                        @click="chooseFiles('input-file-sketch')">
                        <form
                            enctype="multipart/form-data"
                            novalidate>
                            <div
                                :style="{
                                    background: `url('${require('@/assets/img/upload/up-sketch.png')}')  no-repeat center center`
                                }"
                                class="upload__dropbox">
                                <input
                                    :disabled="isSaving"
                                    ref="input-file-sketch"
                                    accept="image/*"
                                    class="input-file"
                                    multiple
                                    name="file"
                                    type="file"
                                    @change="selectFile($event, $config.fileTypes.sketch)">
                                <div class="upload__hover">
                                    <div class="upload__label d-flex">
                                        <v-img
                                            :src="
                                                require('@/assets/icons/ic-upload-lable.svg')
                                            "
                                            contain
                                            height="16"
                                            width="106"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="upload__title">
                            Sketch
                        </div>
                        <h5 class="upload__subtitle">
                            3 Angle drawing
                        </h5>
                    </div>

                    <div class="uploaded sm-file">
                        <h1 class="uploaded__label"/>
                        <file-list
                            :files="getSketch"
                            class="file-list-title pt-0 mt-0"
                            title="Sketches"
                            @remove="removeFile"/>
                    </div>-->

                    <div
                        class="upload"
                        @click="chooseFiles('input-file-reference')">
                        <form
                            enctype="multipart/form-data"
                            novalidate>
                            <div
                                :style="{
                                    background: `url('${require('@/assets/img/upload/up-reference.png')}')  no-repeat center center`
                                }"
                                class="upload__dropbox">
                                <input
                                    :disabled="isSaving"
                                    ref="input-file-reference"
                                    :accept="$config.allowedFileExtension.images.join(',')"
                                    class="input-file"
                                    multiple
                                    type="file"
                                    @change="selectFile($event, $config.fileTypes.reference)">
                                <div class="upload__hover">
                                    <div class="upload__label d-flex">
                                        <v-img
                                            :src="
                                                require('@/assets/icons/ic-upload-lable.svg')
                                            "
                                            contain
                                            height="16"
                                            width="106"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="upload__title">
                            Reference
                        </div>
                        <h5 class="upload__subtitle">
                            Sketches, Magazine or Pictures
                        </h5>
                    </div>

                    <div class="uploaded sm-file">
                        <h5 class="uploaded__label"/>
                        <file-list
                            :files="getReference"
                            class="file-list-title"
                            title="References"
                            @remove="removeFile"/>
                    </div>

                    <div
                        class="upload"
                        @click="chooseFiles('input-file-3d-model')">
                        <form
                            enctype="multipart/form-data"
                            novalidate>
                            <div
                                :disabled="isSaving"
                                :style="{
                                    background: `url('${require('@/assets/img/upload/up-3d.png')}')  no-repeat center center`
                                }"
                                class="upload__dropbox">
                                <input
                                    :accept="$config.allowedFileExtension.models.join(',')"
                                    ref="input-file-3d-model"
                                    class="input-file"
                                    multiple
                                    type="file"
                                    @change="selectFile($event, $config.fileTypes.models)">
                                <div class="upload__hover">
                                    <div class="upload__label d-flex">
                                        <v-img
                                            :src="
                                                require('@/assets/icons/ic-upload-lable.svg')
                                            "
                                            contain
                                            height="16"
                                            width="106"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="upload__title">
                            3D Model
                        </div>
                        <h5 class="upload__subtitle">
                            Recognized Formats (OBJ, STL, FBX)
                        </h5>
                    </div>

                    <div class="uploaded sm-file">
                        <h5 class="uploaded__label"/>
                        <file-list
                            :files="get3D"
                            class="file-list-title"
                            title="3D Models"
                            @remove="removeFile"/>
                    </div>

                    <div
                        class="upload"
                        @click="chooseFiles('input-others')">
                        <form
                            enctype="multipart/form-data"
                            novalidate>
                            <div
                                :disabled="isSaving"
                                :style="{
                                    background: `url('${require('@/assets/img/upload/up-others.png')}')  no-repeat center center`
                                }"
                                class="upload__dropbox">
                                <input
                                    :accept="$config.allowedFileExtension.others.join(',')"
                                    ref="input-others"
                                    class="input-file"
                                    multiple
                                    type="file"
                                    @change="selectFile($event, $config.fileTypes.others)">
                                <div class="upload__hover">
                                    <div class="upload__label d-flex">
                                        <v-img
                                            :src="
                                                require('@/assets/icons/ic-upload-lable.svg')
                                            "
                                            contain
                                            height="16"
                                            width="106"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="upload__title">
                            Others
                        </div>
                        <h5 class="upload__subtitle">
                            Any other file formats
                        </h5>
                    </div>

                    <div class="uploaded">
                        <h3
                            v-if="$vuetify.breakpoint.mdAndUp"
                            class="uploaded__title">
                            Files
                        </h3>
                        <div
                            v-if="$vuetify.breakpoint.mdAndUp"
                            class="notice uploaded__notice">
                            Your images will show up here once you’ve uploaded
                            one.
                        </div>

                        <h5 class="uploaded__label md-file">
                            References
                        </h5>
                        <file-list
                            :files="getReference"
                            class="file-list-title md-file"
                            title="References"
                            @remove="removeFile"/>

                        <h5 class="uploaded__label md-file">
                            3D Models
                        </h5>
                        <file-list
                            :files="get3D"
                            class="file-list-title md-file"
                            title="3D Models"
                            @remove="removeFile"/>

                        <h5 class="uploaded__label">
                            Others
                        </h5>
                        <file-list
                            :files="getOthers"
                            class="file-list-title"
                            title="Others"
                            @remove="removeFile"/>

                        <!--<h5 class="uploaded__label">-->
                        <!--    Dimensions-->
                        <!--</h5>-->
                        <!--<v-textarea v-model="getProjectData.text" placeholder="Type Here" height="112"-->
                        <!--            no-resize-->
                        <!--            solo-->
                        <!--            flat-->
                        <!--            hide-details-->
                        <!--            class="shadow-textarea"/>-->

                        <!--<h5 class="uploaded__label d-flex align-center mt-5">-->
                        <!--    Unique stone count-->
                        <!--    <v-tooltip-->
                        <!--        bottom-->
                        <!--        nudge-top="6"-->
                        <!--        nudge-right="90"-->
                        <!--        max-width="200"-->
                        <!--        min-width="200">-->
                        <!--        <template v-slot:activator="{ on, attrs }">-->
                        <!--            <span v-bind="attrs" v-on="on" class="tooltip-question-mark d-inline-block ml-2 text-center">?</span>-->
                        <!--        </template>-->
                        <!--        <span>How many different shaped stones will there be?</span>-->
                        <!--    </v-tooltip>-->
                        <!--    <warning-stone-count :open="invalidUniqueStoneCount"/>-->
                        <!--</h5>-->
                        <!--<v-text-field-->
                        <!--    v-model="getProjectData.unique_stone_count"-->
                        <!--    solo-->
                        <!--    flat-->
                        <!--    hide-details-->
                        <!--    @keyup="validateNumber($event, 'unique_stone_count')"-->
                        <!--    @keydown="validateNumber($event, 'unique_stone_count')"-->
                        <!--    @keypress="validateNumber($event, 'unique_stone_count')"-->
                        <!--    @blur="validateNumber($event, 'unique_stone_count')"-->
                        <!--    placeholder="Type number 0-999"-->
                        <!--    class="shadow-input"/>-->

                        <!--<h5 class="uploaded__label d-flex align-center mt-5">-->
                        <!--    Overall stone count-->
                        <!--    <v-tooltip-->
                        <!--        bottom-->
                        <!--        nudge-top="6"-->
                        <!--        nudge-right="90"-->
                        <!--        max-width="200"-->
                        <!--        min-width="200">-->
                        <!--        <template v-slot:activator="{ on, attrs }">-->
                        <!--            <span v-bind="attrs" v-on="on" class="tooltip-question-mark d-inline-block ml-2 text-center">?</span>-->
                        <!--        </template>-->
                        <!--        <span>How many stones will there be overall on your jewelry piece, including all shapes and sizes?</span>-->
                        <!--    </v-tooltip>-->
                        <!--    <warning-stone-count :open="invalidOverallStoneCount"/>-->
                        <!--</h5>-->

                        <!--<v-text-field-->
                        <!--    v-model="getProjectData.overall_stone_count"-->
                        <!--    solo-->
                        <!--    flat-->
                        <!--    hide-details-->
                        <!--    @keyup="validateNumber($event, 'overall_stone_count')"-->
                        <!--    @keydown="validateNumber($event, 'overall_stone_count')"-->
                        <!--    @keypress="validateNumber($event, 'overall_stone_count')"-->
                        <!--    @blur="validateNumber($event, 'overall_stone_count')"-->
                        <!--    placeholder="Type number 0-999"-->
                        <!--    class="shadow-input"/>-->
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-show="showDropArea" class="select-product__container drag-area-container">
            <v-row>
                <div class="drag-area d-flex flex-column align-center justify-center overflow-hidden"
                     @dragleave="toggleDropArea" @drop.prevent="dropFiles" @dragover.prevent>
                    <div class="d-flex align-center mb-2" style="pointer-events: none">
                        <img class="mr-2" src="@/assets/icons/ic-upload.svg" alt="">
                        Upload Files
                    </div>
                    <span style="pointer-events: none">(Drag and drop your file here)</span>
                </div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import UploadService from "@/api/uploadFilesService.api";
    import FileList from "@/components/ui/FileList";
    import { projectApi } from "@/api";
    import { mapGetters } from "vuex";
    import { bytesToMb } from "@/utils/index.utils";
    // import WarningStoneCount from "@/components/ui/WarningStoneCount";

    const STATUS_INITIAL = 0,
          STATUS_SAVING = 1,
          STATUS_SUCCESS = 2,
          STATUS_FAILED = 3;

    export default {
        name: "upload-files",
        components: {
            // WarningStoneCount,
            FileList
        },
        props: {
            invalidUniqueStoneCount:{
                type: Boolean,
                default: false
            },
            invalidOverallStoneCount:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedFiles: undefined,
                progressInfos: [],
                message: "",
                currentStatus: STATUS_INITIAL,
                fileSizeLimit: process.env.VUE_APP_FILE_SIZE_LIMIT,
                showDropArea: false
            };
        },
        computed: {
            ...mapGetters(["getProjectData"]),
            fileInfos() {
                return this.$store.getters.getProjectFileInfo;
            },
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            getSketch() {
                return this.fileInfos.filter(
                    (item = {}) =>
                        item.type === "Sketch" ||
                        parseInt(item.type) === this.$config.fileTypes.sketch ||
                        parseInt(item.typeImage) === this.$config.fileTypes.sketch
                );
            },
            getReference() {
                return this.fileInfos.filter(
                    (item = {}) =>
                        item.type === "Digital Picture" ||
                        item.type === "Reference" ||
                        parseInt(item.type) === this.$config.fileTypes.reference ||
                        parseInt(item.typeImage) === this.$config.fileTypes.reference
                );
            },
            get3D() {
                return this.fileInfos.filter(
                    (item = {}) => {
                        return !item.dummy && (item.type === "3d Model" || parseInt(item.type) === this.$config.fileTypes.models || parseInt(item.typeImage) === this.$config.fileTypes.models)
                    }
                );
            },
            getOthers() {
                return this.fileInfos.filter(
                    (item = {}) =>
                        item.type === "Others" ||
                        parseInt(item.type) === this.$config.fileTypes.others ||
                        parseInt(item.typeImage) === this.$config.fileTypes.others
                );
            },
            mapFileTypes() {
                return {
                    [this.$config.fileTypes.sketch]: this.$config.allowedFileExtension.images,
                    [this.$config.fileTypes.reference]: this.$config.allowedFileExtension.images,
                    [this.$config.fileTypes.models]: this.$config.allowedFileExtension.models,
                    [this.$config.fileTypes.others]: this.$config.allowedFileExtension.others
                }
            },
            extensionsByFileTypes() {
                const extensionsByFileTypes = {};

                for (const [key, value] of Object.entries(this.mapFileTypes)) {
                    value.forEach(val => extensionsByFileTypes[val] = +key);
                }

                return extensionsByFileTypes;
            }
        },
        mounted() {
            this.uploadedCount = 0;
        },
        methods: {
            toggleDropArea() {
                this.showDropArea = !this.showDropArea;
            },
            reset() {
                this.currentStatus = STATUS_INITIAL;
            },
            dropFiles($event) {
                const droppedItems = $event.dataTransfer.items || $event.dataTransfer.files;
                const files = [];

                for (let i = 0; i < droppedItems.length; i++) {
                    if (droppedItems[i].kind === "file") {
                        let file = droppedItems[i].getAsFile();
                        files.push(file);
                    }
                }

                if (files.length) {
                    this.validateFiles(files);
                }

                this.toggleDropArea();
            },
            selectFile($event, typeImage) {
                if (!$event.target.files.length) {
                    return;
                }

                const availableExtensions = {};
                this.mapFileTypes[typeImage].forEach(val => availableExtensions[val] = +typeImage);

                this.validateFiles($event.target.files, availableExtensions);
            },
            validateFiles(files, availableExtensions = this.extensionsByFileTypes) {
                const invalidArr = [];
                const filesSize = Array.from(files).reduce((accumulator, file) => {
                    return accumulator + file.size
                }, 0)
                if(bytesToMb(filesSize) > this.$config.fileSizeLimit) {
                    const type = "alertInvalidFileType";

                    this.$store.state.dialogs.content[type].text = `You are trying to upload more than ${this.$config.fileSizeLimit}MB in total. Please use file transfer websites and link it in the description box in the next step`;

                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                    return
                }
                files.forEach(item => {
                    const fileExtension = "." + item.name.split(".").slice(-1).join("").toLowerCase();
                    item.percentage = 0;
                    item.reloadCount = 1;
                    item.typeImage = bytesToMb(item.size) > this.$config.fileSizeLimit3D && availableExtensions[fileExtension] === this.$config.fileTypes.models ? this.$config.fileTypes.others : availableExtensions[fileExtension];

                    if (fileExtension in availableExtensions) {
                        this.fileInfos.push(item);
                    } else {
                        invalidArr.push(item.name);
                    }
                });

                if (invalidArr.length) {
                    const type = "alertInvalidFileType";

                    this.$store.state.dialogs.content[type].text = `
                        Sorry, ${invalidArr.length === 1 ? "file" : "files"}
                        "${invalidArr.join(",")}" is not allowed by size or format.
                        File size should not exceed ${this.$config.fileSizeLimit}Mb. Allowed file formats are "${Object.keys(availableExtensions).join(", ")}"
                    `;

                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                }

                if (this.fileInfos.length) {
                    this.uploadedCount = this.fileInfos.length;
                    this.uploadFiles();
                }
            },
            removeFile(id, index) {
                if (id instanceof File) {
                    const idx =
                        index || this.fileInfos.findIndex(item => item === id);
                    this.fileInfos.splice(idx, 1);
                    if (id.reload) {
                        id.deleted = true;
                        clearTimeout(id.reload);
                    }
                } else {
                    const removeFile = () => {
                        const idx = this.fileInfos.findIndex(
                            item => item.id === id
                        );
                        this.fileInfos.splice(idx, 1);
                        if (this.$store.getters.getProjectID > 0) {
                            projectApi.edit(this.$store.getters.getProjectID, {
                                files: UploadService.filterFiles(this.fileInfos)
                            });
                        }
                    };
                    UploadService.removeFile(id)
                        .then(() => {
                            removeFile();
                        })
                        .catch(e => {
                            if (e.response.status === 410) {
                                removeFile();
                            }
                        });
                }
            },
            async upload(idx, file) {
                console.log("upload", file);
                this.currentStatus = STATUS_SAVING;

                try {
                    const data = await UploadService.upload(file, event => {
                        file.percentage = Math.round(
                            (100 * event.loaded) / event.total
                        );
                    });

                    if (this.getProjectData.id) {
                        await projectApi.bindFile(this.getProjectData.id, {
                            files: [data.id]
                        });
                    }

                    this.fileInfos.splice(idx, 1, data);
                } catch(error) {
                    console.error(error);

                    file.percentage = 0;
                    this.uploadedCount++;
                    file.message = "Could not upload the file:" + file.name;
                    if (file.reloadCount > 100) {
                        //auto delete
                        this.removeFile(file);
                    } else if (!file.deleted) {
                        file.reloadCount++;
                        file.reload = setTimeout(
                            () => this.upload(idx, file),
                            2000 * file.reloadCount
                        );
                    }
                }

                this.uploadedCount--;
                if (this.uploadedCount <= 0) {
                    this.reset();
                }
            },
            uploadFiles() {
                for (let i = 0; i < this.fileInfos.length; i++) {
                    if (this.fileInfos[i].typeImage !== undefined) {
                        this.upload(i, this.fileInfos[i]);
                    }
                }
            },
            chooseFiles(ref) {
                if (!this.$refs[ref]) return;
                this.$refs[ref].click();
            }
            // validateNumber($event, key) {
            //     if ($event.target.value.toString().charAt(0) === "0" && $event.target.value.toString().length > 1) {
            //         $event.target.value = $event.target.value.substring(1, 7);
            //     }
            //
            //     if (/[^0-9]/g.test($event.target.value)) {
            //         $event.target.value = $event.target.value.replace(/[^0-9]/g, "").substring(0, 7);
            //     }
            //
            //     if (parseInt($event.target.value) > 999) {
            //         $event.target.value = 999;
            //     }
            //
            //     $event.target.value = $event.target.value.toString().substring(0, 7);
            //     this.getProjectData[key] = $event.target.value !== "" ? parseInt($event.target.value, 10) : "";
            // }
        }
    };
</script>

<style lang="scss" scoped>
.container {
    @media screen and (max-width: 1280px) {
        max-width: 1000px;
    }
}
.md-file {
    display: block;
}
.sm-file {
    display: none;
}
@media screen and (max-width: 1100px) {
    .md-file {
        display: none;
    }
    .sm-file {
        display: block;
    }
}

h1 {
    font-size: 36px;
    line-height: 40px;
    color: #464646;
    margin-bottom: 12px;
}

h2 {
    font-size: 24px;
    line-height: 27px;
    color: #3f7359;
}

.notice {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #464646;
}

.upload-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: 300px) {
        flex-direction: column;
    }
}

.upload {
    width: 240px;
    margin-top: 33px;
    margin-right: 20px;

    &__dropbox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 248px;
        outline: 1px dashed #bcbcbc;
        border-radius: 4px;
        outline-offset: 0;
        color: dimgray;
        position: relative;
        cursor: pointer;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    }
    &__title {
        margin: 28px 0 4px;
        padding-bottom: 4px;
        border-bottom: 1px solid #bcbcbc;
        font-size: 24px;
        line-height: 27px;
        font-weight: bold;
        color: #464646;
    }
    &__subtitle {
        font-size: 18px;
        line-height: 23px;
        color: #464646;
        font-weight: 400;
    }
    &__hover {
        display: none;
    }
    &:hover {
        .upload__hover {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background: #3f7359;
            opacity: 0.9;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
    @media screen and (max-width: 1280px) {
        width: 180px;
        transform: scale(0.9);
        &__dropbox {
            height: 202px;
        }
    }
    @media screen and (max-width: 1100px) {
        margin-top: 40px;
        margin-right: 0;
        width: 100%;
        transform: scale(1);
        &__dropbox {
            height: 157px;
        }
    }
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;

}

.uploaded {
    max-width: 285px;
    margin-left: 95px;
    padding-bottom: 10px;

    .warning-wrapper{
        position: absolute;
        z-index: 1;
        top: 100%;
        right: -80px;
        width: 100%;
        @media #{map-get($display-breakpoints, 'md-and-down')} {
            right: 0;
        }
    }

    &__notice {
        padding-top: 7px;
        border-top: 1px solid #bcbcbc;
        margin-top: 5px;
        margin-bottom: 44px;
    }
    &__title {
        font-size: 24px;
        line-height: 36px;
        font-weight: normal;
        color: #464646;
    }
    &__label {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #464646;
        position: relative;
        img {
            margin: 2px 0 0 4px;
            width: 18px;
        }
    }
    &__img-block {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__btn-remove {
        margin-left: 10px;
    }
    @media screen and (max-width: 1280px) {
        $font-size: 24 * 0.8;
        &__img-block {
            margin-bottom: $font-size + px;
        }
        &__img-name {
            margin-left: $font-size / 1.71 + px;
            font-size: $font-size / 1.6 + px;
            line-height: $font-size / 1.5 + px;
        }
        &__btn-remove {
            margin-left: 10px;
        }
    }
    @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: 30px;
    }
}

.select-product {
    position: relative;
    max-width: 1160px;
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #0b3c49;
    &__container {
        position: relative;
    }
    &__header {
        font-size: 32px;
        line-height: 48px;
    }
    &__subtitle {
        font-size: 22px;
        line-height: 36px;
    }
}

.drag-area-container {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
}

.drag-area {
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -50px;
    right: -50px;
    border-radius: 5px;
    background-color: rgba(#3F7359, .75);
    font-size: 33px;
    line-height: 1;
    color: #fff;
    z-index: 3;

    &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        border-radius: 5px;
        border: 1px dashed #fff;
        pointer-events: none;
    }

    img {
        width: 36px;
    }

    span {
        font-size: 16px;
        line-height: 1;
    }

}

.shadow-textarea,
.shadow-input {
    padding: 8px 0;
    font-size: 12px;

    ::v-deep .v-input__slot{
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;
        padding: 0 16px 10px !important;
       textarea{
           line-height: 1.2 ;
       }
    }
}

.shadow-input {
    ::v-deep .v-input__slot {
        padding: 0 16px 0 !important;
    }
}
</style>
