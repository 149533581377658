export const FILETYPES = (function (o) {
    o[o["SKETCH"] = 1] = "SKETCH";
    o[o["REFERENCE"] = 2] = "REFERENCE";
    o[o["D3"] = 3] = "D3";
    o[o["ENGRAVING"] = 4] = "ENGRAVING";
    o[o["LOGO"] = 5] = "LOGO";
    o[o["VIEWS"] = 6] = "VIEWS";
    o[o["BACKGROUND"] = 7] = "BACKGROUND";
    o[o["ICON_FOR_COMMENT"] = 8] = "ICON_FOR_COMMENT";
    o[o["AVATAR"] = 20] = "AVATAR";
    o[o["DROP_SHADOW"] = 10] = "DROP_SHADOW";
    return o;
})({});
