<template>
    <review-block
        :class-body="'mt-2'"
        class="details"
        title="Details">
        <div v-if="data.status" :class="$store.getters.getClassProjectStatus(getProjectStatus.status)" class="project-status">
            {{ getProjectStatus.status }}
        </div>
        <v-row>
            <v-col
                class="details__col"
                cols="12"
                lg="7"
                md="7"
                sm="7">
                Request from: <span class="details__bold"> {{ getProjectOwner }} </span>
            </v-col>
            <v-col
                class="details__col"
                cols="12"
                lg="5"
                md="5"
                sm="5">
                <div class="d-flex align-center">
                    {{ isDesigner ? "Model Price" : "Project Price" }}:
                    <span
                        v-if="data.status !== getValueProjectStatus($config.project.status.pricing_needed)"
                        class="details__bold ml-1">
                        ${{ data.amount }}
                    </span>
                    <extra-charge-status-tooltip
                        activator-class="ml-1"
                        v-if="isManagerOrAdmin && data.extra_charge"
                        :status-id="data.extra_charge.status"/>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                class="details__col d-flex align-center text-no-wrap flex-wrap"
                cols="12"
                lg="7"
                md="7"
                sm="7">
                <span class="mr-1">Due Date:</span> <span class="details__bold d-flex align-center">
                    {{ data.due }}
                    <v-tooltip
                        v-if="isClient"
                        top
                        nudge-right="70"
                        max-width="155"
                        min-width="155">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="tooltip-question-mark d-inline-block ml-2 text-center flex-shrink-0">?</span>
                        </template>
                        <span>Deadline is for the first submission only.</span>
                    </v-tooltip>
                </span>
            </v-col>
            <v-col
                class="details__col"
                cols="12"
                lg="5"
                md="5"
                sm="5">
                Project ID: <span class="details__bold">{{ data.id }}</span>
            </v-col>
        </v-row>
    </review-block>
</template>

<script>
    import ReviewBlock from "@/components/ui/ReviewBlock";
    import { mapGetters } from "vuex";
    import ExtraChargeStatusTooltip from "@/components/ExtraCharge/ExtraChargeStatusTooltip";

    export default {
        name: "project-details",
        components: {
            ReviewBlock,
            ExtraChargeStatusTooltip
        },
        props: {
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner",
                "isManagerOrAdmin",
                "getValueProjectStatus",
                "isAdminProject",
                "projectIsOnHold"
            ]),
            getProjectOwner() {
                return !this.isDesigner ? this.data.user : this.data.user.split(" ")[0];
            },
            getProjectStatus() {
                if (this.isDesigner && this.data.status_id !== this.$config.project.status.completed && this.projectIsOnHold) {
                    return {
                        status_id: this.$config.project.status.on_hold,
                        status: this.$config.project.statusLabels[this.$config.project.status.on_hold]
                    }
                }
                if (!this.isDesigner && this.projectIsOnHold) {
                    return {
                        status_id: this.$config.project.status.on_hold_action_needed,
                        status: this.$config.project.statusLabels[this.$config.project.status.on_hold_action_needed]
                    }
                }
                return {
                    status_id: this.data.status_id,
                    status: this.data.status
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .details {
        position: relative;
        //font-family: "Futura PT", sans-serif;
        font-weight: normal;
        background: #FFFFFF;
        border: 1px solid #BCBCBC;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;
        padding: 14px 25px 16px 20px;

        &__col {
            font-size: 14px;
            line-height: 18px;
            color: #464646;
        }
        &__bold {
            font-weight: 900;
        }
        .project-status {
            position: absolute;
            top: 10px;
            right: 24px;
        }
    }
</style>
