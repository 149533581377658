<template>
    <div class="d-flex flex-grow-1 align-center">
        <v-img
            :src="require('@/assets/icons/ic-arrow-right.svg')"
            contain
            height="16"
            sizes="16"
            width="16"/>
    </div>
</template>

<script>
    export default {
        name: "stepper-arrow"
    };
</script>
