<template>
    <v-dialog
        v-model="value"
        max-width="620"
        @click:outside="closeDialog">
        <div
            v-if="data"
            class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon
                @click="closeDialog">
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"/>
            </v-btn>
            <v-card class="pci-accept-dialog pa-5 pa-sm-10">
                <div class="pci-ad-head-sect">
                    <div class="pci-ad-ttl">
                        {{ data.title }}
                    </div>
                    <div class="pci-ad-price">
                        ${{ data.overall_designer_amount }}
                    </div>
                </div>
                <div class="pci-ad-options mt-6">
                    <!--          )-->
                    <div class="pci-ad-opt-item">
                        Due date: <span class="pci-ad-oi-val ttf-lc">{{ data.due }} </span>
                    </div>
                    <div class="pci-ad-opt-item mt-4">
                        Created:
                        <span class="pci-ad-oi-val ttf-lc">{{ data.created_at | moment("created_at") }} ({{ getProjectOwner }})</span>
                    </div>
                    <div class="pci-ad-opt-item mt-4">
                        Project type: <span class="pci-ad-oi-val">{{ data.type }}</span>
                    </div>
                    <div class="pci-ad-opt-item mt-4">
                        Project id: <span class="pci-ad-oi-val">{{ data.id }}</span>
                    </div>
                </div>
                <div
                    v-if="(data.metals && data.metals.length) || (data.stones && data.stones.length)"
                    class="pci-ad-ms-options">
                    <div class="pci-ad-cont-sep"/>
                    <div
                        v-if="data.metals && data.metals.length"
                        class="pci-ad-mso-item">
                        Metal Types: <span class="pci-ad-mso-item-val">{{ data.metals.join(", ") }}</span>
                    </div>
                    <div
                        v-if="data.stones && data.stones.length"
                        class="pci-ad-mso-item mt-4">
                        Stone Type: <span class="pci-ad-mso-item-val">{{ data.stones.join(", ") }}</span>
                    </div>
                </div>

                <div class="pci-ad-cont-sep"/>

                <div v-if="data.description" class="pci-ad-desc-wrap mb-8">
                    <div class="pci-ad-opt-item mb-4">Description</div>
                    <div class="pci-ad-desc" v-html="data.description"/>
                </div>

                <div class="pci-ad-desc-wrap">
                    <div class="pci-ad-opt-item mb-4">Disclaimer</div>
                    <p class="pci-ad-desc mb-0">
                        As a designer you are entitled to provide 1 design change for free. You will get compensated for any
                        extra design changes. Price will be negotiated with a manager. Small changes (<10min) will not be
                        reimbursed. Your model will usually get approved within 2 rounds.
                    </p>
                </div>

                <v-card-actions class="pa-0 mt-10">
                    <a
                        class="pci-ad-link"
                        role="button"
                        @click="closeDialog">
                        View Full Project
                    </a>
                    <v-spacer/>
                    <v-btn
                        :loading="loading"
                        class="disable-btn pl-4 pr-4 pl-sm-10 pr-sm-10"
                        color="#02810F"
                        dark
                        elevation="0"
                        large
                        rounded
                        @click="acceptProject">
                        Accept Project
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import { mapGetters } from "vuex";
    import http from "@/api/httpClient.api";
    import moment from "moment";

    export default {
        name: "dialog-accept",
        data: () => ({
            loading: false
        }),
        props: {
            value: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        filters: {
            moment: function (date, type) {
                if (type === "created_at") {
                    return moment(date).format("MMM. DD, YYYY");
                }
                if (type === "updated_at") {
                    return moment(date).format("MM/DD/YY | h:mmA");
                }
                if (type === "estimated_date") {
                    return moment(date).format("MM/DD/YY");
                }
            }
        },
        computed: {
            ...mapGetters([
                "isDesigner"
            ]),
            getProjectOwner() {
                return !this.isDesigner ? this.data.user : this.data.user.split(" ")[0];
            }
        },
        methods: {
            closeDialog () {
                this.$emit("input", false);
            },
            acceptProject () {
                this.loading = true;
                http.put(`/projects/${this.data.id}/accept`).then(res => {
                    if (res.status === 200) {
                        this.closeDialog();
                        this.$emit("acceptProject", res.data, this.data);
                    }
                }).catch(err => {

                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    // popup
    .dialog-overflow-wrap {
        position: relative;
    }
    .pci-accept-dialog {
        max-height: 90vh;
        overflow: auto !important;
    }
    .pci-ad-cl-btn {
        position: absolute;
        right: -18px;
        top: -18px;
        z-index: 1;
    }
    .pci-ad-head-sect {
        display: flex;
    }
    .pci-ad-ttl {
       	font-size: 24px;
		line-height: 27px;
        color: #464646;
        flex-grow: 1;
        padding-right: 15px;
        padding-top: 15px;
        word-break: break-word;
    }
    .pci-ad-price {
        flex: 0 0 auto;
        height: 72px;
        min-width: 72px;
        background: rgba(2, 129, 15, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #02810F;
        padding: 5px;
    }
    .pci-ad-opt-item {
        font-size: 16px;
        line-height: 20px;
        color: #4E4E4E;
        text-transform: uppercase;
        .pci-ad-oi-val {
            font-weight: 700;
            &.ttf-lc {
                text-transform: none;
            }
        }
    }
    ::v-deep .pci-ad-desc {
        font-size: 16px;
        line-height: 20px;
        color: #4E4E4E;
        h1 {
            margin-bottom: 8px;
            font-size: 26px;
            line-height: 28px;
        }
        h2 {
            margin-bottom: 8px;
            font-size: 24px;
            line-height: 26px;
        }
        h3 {
            margin-bottom: 8px;
            font-size: 22px;
            line-height: 24px;
        }
    }
    .pci-ad-cont-sep {
        border-bottom: 1px solid #E7E7E7;
        margin: 24px 0;
    }
    .pci-ad-mso-item {
        font-size: 16px;
        line-height: 16px;
        color: #4E4E4E;
    }
    .pci-ad-mso-item-val {
        font-weight: 700;
        line-height: 24px;
    }
    .pci-ad-link {
        font-size: 16px;
        line-height: 24px;
        color: #0046AF;
        text-decoration: none;
        transition: opacity 250ms ease;
        &:hover {
            opacity: 0.7;
        }
    }
    // popup

</style>
