<template>
    <div class="review">
        <div class="review__title">
            {{ title }}
        </div>
        <div
            :class="[classBody]"
            class="review__body">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "review-block",
        props: {
            title: {
                type: String,
                default: ""
            },
            classBody: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style lang="scss" scoped>
    .review {
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: normal;
        &__title {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #464646;
            text-transform: uppercase;
            font-weight: 600;
            padding-bottom: 12px;
            border-bottom: 1px solid #BCBCBC;
        }
        &__body {
            margin-top: 16px;
            padding: 0;
        }
    }
</style>
