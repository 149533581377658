<template>
    <div class="render-description">
        <div class="render-description__header d-flex align-center">
            <h5>Description</h5>
            <div class="d-flex ml-auto">
                <v-btn
                    v-if="!showEditor"
                    text
                    height="auto"
                    :ripple="false"
                    @click="showEditor = true"
                    class="ml-3 px-0">
                    + Add description
                </v-btn>
                <v-btn
                    v-if="showEditor"
                    text
                    height="auto"
                    :ripple="false"
                    :loading="loading"
                    @click="save"
                    class="ml-3 px-0">
                    Save
                </v-btn>
                <v-btn
                    v-if="showEditor"
                    text
                    :ripple="false"
                    :disabled="loading"
                    height="auto"
                    @click="cancel"
                    class="ml-3 px-0">
                    Cancel
                </v-btn>
            </div>
        </div>

        <tiptap-vuetify
            v-if="showEditor"
            v-model="description"
            :extensions="extensions"/>

        <div v-if="!showEditor && description" v-html="description" class="render-description__text"></div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { Bold, BulletList, HardBreak, Heading, Italic, ListItem, OrderedList, Paragraph, Strike, TiptapVuetify, Underline } from "tiptap-vuetify";
    import { projectApi } from "@/api";

    export default {
        name: "project-render-description",
        components: {
            TiptapVuetify
        },
        data() {
            return {
                loading: false,
                showEditor: false,
                render_description: "",
                extensions: [
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Paragraph,
                    Bold,
                    Strike,
                    Italic,
                    Underline,
                    ListItem,
                    BulletList,
                    OrderedList,
                    HardBreak
                ]
            }
        },
        computed: {
            ...mapGetters([
                "getProjectData"
            ]),
            description: {
                get() {
                    return this.getProjectData.render_description;
                },
                set(nv) {
                    this.render_description = nv;
                }
            }
        },
        created() {
            this.render_description = this.description;
        },
        methods: {
            async save() {
                this.loading = true;
                const data = { render_description: this.render_description };

                if (this.render_description !== this.description) {
                    await projectApi.edit(this.getProjectData.id, data);
                }

                await this.$store.dispatch("updateProjectData", data);

                this.showEditor = false;
                this.loading = false;
            },
            cancel() {
                this.render_description = this.description;
                this.showEditor = false;
            }
        }
    }
</script>

<style scoped lang="scss">
.render-description {
    margin: 12px 0 0;
    padding: 14px 16px 0;
    border-radius: 5px;
    border: 1px solid #BCBCBC;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    background: #FFFFFF;

    ::v-deep * {
        color: #464646!important;
        font-size: 14px;
        line-height: 18px;
    }

    &__header {
        margin-bottom: 14px;
        h5 {
            font-weight: 600;
        }
        ::v-deep .v-btn {
            &::before {
                display: none;
            }
            .v-btn__content {
                color: #3F7359!important;
                font-weight: 600!important;
            }
        }
    }

    ::v-deep .tiptap-vuetify-editor {
        margin-bottom: 14px;
        .ProseMirror {
            height: 200px;
            overflow-y: auto;
        }
    }

    &__text {
        margin-bottom: 14px;
    }

    ::v-deep h1 {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
    }

    ::v-deep h2 {
        margin-bottom: 8px;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
    }

    ::v-deep h3 {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
    }
}
</style>
