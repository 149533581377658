<template>
    <div class="full-view full-view__logo">
        <div class="full-view__body d-flex align-start mb-4">
            <div class="full-view__icon">
                <v-img
                    :src="require('@/assets/icons/configurator/watermark.svg')"
                    contain/>
            </div>
            <div class="full-view__content">
                <div class="full-view__title">WATERMARK</div>
                <div class="full-view__text">
                    Place your desired logo file directly in the viewer.
                </div>
            </div>
        </div>
        <div class="full-view__action d-flex align-items-center justify-space-between">
            <button class="full-view--how text-decoration-underline text-unco-dark-green" @click="$emit('openInstruction', idx)">How it works</button>
            <label class="upload">
                <input
                    ref="file"
                    accept=".jpg, .svg, .png"
                    class="input-file"
                    name="file"
                    type="file"
                    @change="selectFile($event)">
                <v-btn small class="btn-outline__green px-8" @click="$refs.file.click()" :disabled="disabled"
                       :loading="loading" rounded outlined
                       height="23">
                    Choose File to Upload
                </v-btn>
            </label>
        </div>
    </div>
</template>

<script>
    import { hasExtension } from "@/utils/index.utils";

    //var colors = { h: 150, s: 0.66, v: 0.30 }
    export default {
        name: "conf-logo",
        data: () => ({
            //colors: colors,
            loading: false
        }),
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            idx: {
                type: Number,
                default: -1
            }
        },
        methods: {
            selectFile (event) {
                if (!event.target.files) {
                    return;
                }
                const file = event.target.files[0];
                if (!hasExtension(file?.name, [".jpg", ".svg", ".png"])) {
                    const type = "alertInvalidFileType";

                    this.$store.state.dialogs.content[type].text =
                        `Sorry, file "${file?.name}" is invalid, allowed extensions are: ` + [".jpg", ".svg", ".png"].join(", ");
                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                    return;
                }

                event.target.value = "";
                this.loading = true;
                file.disableLoading = () => this.loading = false;
                this.$emit("upload-logo", file);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "full-view";
    .upload {
        input {
            display: none;
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
</style>
