<template>
    <autocomplete
        :aria-label="placeholder"
        :default-value="value"
        :get-result-value="(item) => { return '' }"
        :placeholder="placeholder"
        :search="inputTrigger"
        @blur="(event)=> this.$emit('blur', event)"
        @focus="(event)=> this.$emit('focus', event)"
        @input="(event)=> this.$emit('input', event.target.value)"
        @submit="(selectedResult)=> this.$emit('selected', selectedResult)"/>
</template>

<script>
    import Autocomplete from "@/components/ui/autocomplete/Autocomplete";

    export default {
        name: "autocomplete2",
        components: {
            Autocomplete
        },
        data: () => ({
            show: true,
            text: "",
            canSearch: false
        }),
        props: {
            placeholder: {
                type: String,
                default: "Leave comment, or type “/” to tag"
            },
            value: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
            textTrigger: {
                type: String,
                default: "/"
            }
        },
        //getAutocompleteData
        computed: {},
        methods: {
            filteredData (text) {
                if (!this.canSearch || this.disabled || !this.show) {
                    return [];
                }
                const isObject = (item) => typeof item === "object" && item instanceof Object;
                const isGroupName = (item) => item.header && !item.value;
                const isDivider = (item) => typeof item.divider === "boolean";
                const isItem = (item) => item.name && item.value;
                const isContain = (item, text) => isItem(item) && item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
                const last = {
                    group: { found: 0 },
                    divider: { found: 0 },
                    inc (item) {
                        this.group.found++;
                        this.divider.found++;
                        this.group.name = item.name + "-group";
                        this.divider.name = item.name + "-div";
                        return true;
                    },
                    set (name, item) {
                        this[name].found = 0;
                        return this[name] = item;
                    }
                };
                const onContainText = item => {
                    
                    if (!isObject(item)) return;
                    if (isGroupName(item)) {
                        return last.set("group", item);
                    } else if (isDivider(item)) {
                        return last.set("divider", item);
                    }
                    return isContain(item, text) && last.inc(item);
                };
                const onRemoveVoidGroup = item => (item.found === undefined || !isNaN(item.found) && item.found > 0);

                let result;
                if (text === "") {
                    result = this.items.filter((item) => isObject(item));
                } else {
                    result = this.items.filter(onContainText).filter(onRemoveVoidGroup);
                }
                return result;
            },
            inputTrigger (text) {
                if (this.disabled) {
                    if (this.show) this.show = false;
                    this.canSearch = false;
                    return [];
                }
                if (typeof text === "string" && text[0] === this.textTrigger) {
                    this.canSearch = true;
                    this.show = true;
                    return this.filteredData(this.text);
                } else {
                    this.canSearch = false;
                    this.show = false;
                }
                return [];
            },
            print (attr, on) {
                console.dir(attr);
                console.dir(on);
            }
        }
    };
</script>

<style lang="scss">
    .autocomplete-header {
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px solid #CBD2D0;
        margin-left: 12px;
        margin-right: 12px;
        padding: 8px 0 14px 0;
        color: #0B3C49;
    }
    .autocomplete-input {
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #0B3C49;
        width: 100%;
        padding: 6px 0 6px 10px;
        position: relative;
        flex: 1;
        background: #FFFFFF;
        border: 1px solid #9FACA9;
        box-shadow: none;
        border-radius: 4px;
    }
    .autocomplete-input:focus, .autocomplete-input[aria-expanded=true] {
        border-color: rgba(0, 0, 0, .12);
        background-color: #fff;
        outline: none;
    }
    [data-position=below] .autocomplete-input[aria-expanded=true] {
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0
    }
    [data-position=above] .autocomplete-input[aria-expanded=true] {
        border-top-color: transparent;
        border-radius: 0 0 8px 8px;
        z-index: 2
    }
    .autocomplete[data-loading=true]:after {
        content: "";
        border: 3px solid rgba(0, 0, 0, .12);
        border-right-color: rgba(0, 0, 0, .48);
        border-radius: 100%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        animation: rotate 1s linear infinite
    }
    .autocomplete-result-list {
        margin: 0 !important;
        border: 1px solid rgba(0, 0, 0, .12);
        padding: 0 !important;
        box-sizing: border-box;
        max-height: 296px;
        overflow-y: auto;
        background: #fff;
        list-style: none;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .16)
    }
    [data-position=below] .autocomplete-result-list {
        margin-top: -1px;
        border-top-color: transparent;
        border-radius: 0 0 8px 8px;
        padding-bottom: 8px
    }
    [data-position=above] .autocomplete-result-list {
        margin-bottom: -1px;
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0;
        padding-top: 8px
    }
    .autocomplete-result {
        cursor: default;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #0B3C49;
        padding: 12px 12px 12px 16px;
    }
    .autocomplete-result:hover, .autocomplete-result[aria-selected=true] {
        background-color: rgba(251, 56, 5, .06)
    }
    @keyframes rotate {
        0% {
            transform: translateY(-50%) rotate(0deg)
        }
        to {
            transform: translateY(-50%) rotate(359deg)
        }
    }
    .autocomplete-result-list {
        z-index: 1040 !important;
    }

</style>
