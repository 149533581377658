<template>
    <div class="project-descr">
        <div v-if="!descriptionIsEditing && !descriptionIsEmpty || readonly">
            <div
                v-if="getProjectDescription"
                class="mb-5 description-plate">
                <h3 class="project-descr__option-title mb-2">
                    Description
                </h3>
                <div v-html="getProjectDescription" class="description-content"/>
            </div>

            <div v-if="getProjectLinks.length" class="links-plate">
                <h3 class="project-descr__option-title mb-2">
                    Links
                </h3>
                <v-chip
                    v-for="(link, idx) in getProjectLinks"
                    :key="idx"
                    class="mb-2 mr-2 project-descr__option-chip"
                    color="#D9FBDD"
                    :href="link"
                    target="_blank"
                    text-color="#464646">
                    {{ link }}
                </v-chip>
            </div>
        </div>

        <div v-else-if="descriptionIsEditing || descriptionIsEmpty">
            <h3 class="project-descr__option-title mb-2">
                Description
            </h3>
            <tiptap-vuetify
                v-model="description"
                :extensions="extensions"
                class="mb-5"
                @blur="updateProjectData"
                @keyup="updateProjectData"/>

            <h3 class="project-descr__option-title mb-2">
                Links
            </h3>
            <v-combobox
                v-model="links"
                chips
                class="project-descr__option-links-input"
                hide-details
                multiple
                outlined
                @change="onChangeCombobox">
                <template v-slot:selection="{ attrs, item}">
                    <v-chip
                        v-bind="attrs"
                        color="rgba(0, 70, 175, 0.07)"
                        text-color="#0046AF"
                        class="project-descr__option-chip">
                        {{ item }}
                        <img
                            :src="require('@/assets/icons/ic-close.svg')"
                            alt=""
                            class="ml-2"
                            style="cursor:pointer;"
                            @click="removeLink(item)">
                    </v-chip>
                </template>
            </v-combobox>
            <p
                v-show="!isLinkValid"
                class="caption red--text">
                Invalid link provided
            </p>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { Bold, BulletList, HardBreak, Heading, Italic, ListItem, OrderedList, Paragraph, Strike, TiptapVuetify, Underline } from "tiptap-vuetify";

    export default {
        name: "project-description",
        props: {
            descriptionIsEditing: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        components: {
            TiptapVuetify
        },
        data: () => ({
            isLinkValid: true,
            description: "",
            links: [],
            extensions: [
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Paragraph,
                Bold,
                Strike,
                Italic,
                Underline,
                ListItem,
                BulletList,
                OrderedList,
                HardBreak
            ]
        }),
        computed: {
            ...mapGetters([
                "getProjectDescription",
                "getProjectLinks",
                "descriptionIsEmpty"
            ])
        },
        methods: {
            onChangeCombobox(items) {
                this.links = items.filter((item) => item.trim().length)
                this.validateLinks()
                this.updateProjectData()
            },
            updateProjectData () {
                this.$emit("updateProjectDescription", {
                    description: this.description,
                    links: this.links
                });
            },
            removeLink (linkName) {
                if (this.readonly) {
                    return;
                }
                this.links = this.links.filter(link => link !== linkName);
                this.$store.dispatch("removeLink", linkName);
                this.validateLinks()
                this.updateProjectData()
                if (!this.links.length) {
                    this.isLinkValid = true
                    this.$store.commit("setLinksStatus", true)
                    this.$emit("openEditDescription", true);
                }
            },
            validateLinks() {
                if (this.links.length) {
                    const rule = /^(ftp|http|https):\/\/[^ "]+$/
                    if (this.links.some(link => !rule.test(link))) {
                        this.isLinkValid = false
                        this.$store.commit("setLinksStatus", false)
                    } else {
                        this.isLinkValid = true
                        this.$store.commit("setLinksStatus", true)
                    }
                }
            }
        },
        mounted () {
            this.description = this.getProjectDescription;
            this.links = this.getProjectLinks;
        }
    };
</script>

<style lang="scss">
    .v-select--chips div[role=combobox] .v-select__slot .v-select__selections {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .description-plate {
        padding: 22px 17px 34px 20px;
        border: 1px solid #BCBCBC;
        border-radius: 5px;
    }
    .links-plate {
        padding: 14px 25px 18px 20px;
        border: 1px solid #BCBCBC;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;

        .project-descr__option-title {
            padding-bottom: 11px;
            border-bottom: 1px solid #BCBCBC;
        }
    }
    .description-content {
        font-size: 14px;
        line-height: 18px;
        color: #4E4E4E;
        font-weight: 400;
        word-break: break-word;
    }
    .project-descr__option-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #4E4E4E;
        text-transform: uppercase;
    }
    .project-descr .tiptap-vuetify-editor__content {
        max-height: 400px;
    }
    .project-descr__option-links-input .v-input__append-inner {
        display: none;
    }
    .project-descr__option-chip {
        border: 1px solid #464646 !important;
        border-radius: 5px !important;
    }
    .project-descr__option-chip.v-chip .v-chip__content {
        word-break: break-all;
        white-space: break-spaces;
        line-height: 16px;
    }
    .project-descr__option-chip.v-chip.v-size--default {
        height: auto;
        min-height: 32px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
</style>
