var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-scroll-section configurator-page"},[(!_vm.$store.getters.getProjectLoading)?_c('div',{staticClass:"grid ml-auto mr-auto"},[(true)?_c('div',{staticClass:"dark-overlay"}):_vm._e(),_c('project-header',{staticClass:"mb-12",attrs:{"edit-title":"","edit-sku":""}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"configurator-viewer-model"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCommentReview),expression:"isCommentReview"}],staticClass:"comment-review"},[_c('v-btn',{staticClass:"pa-0 comment-review__close",attrs:{"height":"23","width":"23"},on:{"click":function($event){return _vm.closeCommentReview(false)}}},[_vm._v(" X ")]),_vm._l((_vm.swiperTypeView),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reviewIndex === index),expression:"reviewIndex === index"}],key:index,staticClass:"comment-review__list"},[_vm._l((item),function(file){return _c('div',{key:file.id,staticClass:"comment-review__item",class:{
                                'comment-review--active':
                                    _vm.currentSelected &&
                                    _vm.currentSelected.id === file.id
                            },on:{"click":function($event){return _vm.selectFile(file, 'comment review')}}},[_c('div',{staticClass:"comment-review__title"},[_vm._v(" "+_vm._s(file.title)+" ")]),_c('div',{staticClass:"comment-review__img"},[_c('v-img',{attrs:{"src":file.url,"contain":""}})],1)])}),_c('div',{staticClass:"pci-swiper-btn pci-prev-btn",class:`pci-prev-btn-a`,on:{"click":_vm.slideLeft}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"pci-swiper-btn pci-next-btn",class:`pci-next-btn-b`,on:{"click":_vm.slideRight}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],2)})],2),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                        !_vm.isCommentReview &&
                            _vm.currentSelected &&
                            _vm.currentFile3d.id !==
                            _vm.currentSelected.id &&
                            _vm.currentSelected.id !== undefined &&
                            !_vm.isDraw &&
                            !_vm.isImageEdit
                    ),expression:"\n                        !isCommentReview &&\n                            currentSelected &&\n                            currentFile3d.id !==\n                            currentSelected.id &&\n                            currentSelected.id !== undefined &&\n                            !isDraw &&\n                            !isImageEdit\n                    "}],staticClass:"pa-0 mt-2",staticStyle:{"position":"absolute","z-index":"5","right":"20px","top":"10px","min-width":"24px!important"},attrs:{"height":"23","width":"23"},on:{"click":_vm.closePreview}},[_vm._v(" X ")]),(_vm.showBackgroundChangeCotrols)?_c('image-edit',{attrs:{"store":_vm.$store},on:{"exitImageEdit":_vm.resetSelectedBakcroundColor,"saveImageEdit":_vm.attachBackgroundColor}}):_vm._e(),_c('project-references-slider',{key:_vm.currentFile3d.id,attrs:{"id":'left',"background":_vm.backgroundUrl,"background-alpha":_vm.color.a,"background-color":_vm.color.hex,"hide-captions":true,"hide-drawer":!_vm.isDraw || !_vm.setImageEdit,"hide-thumbs":true,"store":_vm.$store,"z-index":0},on:{"controls":contorlObj => (_vm.controlFiles = contorlObj),"fileSelected":file => (_vm.currentSelected = file),"onDraw":_vm.setDraw,"onImageEdit":_vm.setImageEdit,"closeLogoView":_vm.closeLogoView,"closeDrawMode":_vm.closePreview}}),(_vm.controlFiles)?[_c('configurator',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCommentReview),expression:"!isCommentReview"}],attrs:{"background-file":_vm.typeBackground[0],"control-files":_vm.controlFiles,"current-file3d":_vm.currentFile3d,"disable-controls":(_vm.disableControls || _vm.isManager || _vm.isAdmin ) && !_vm.isAdminProject,"type-views":_vm.typeViews},on:{"close":function($event){return _vm.$print('close')},"place-engraving":function($event){return _vm.placeEngraving()},"add-drop-shadow":_vm.addDropShadow,"upload-logo":_vm.uploadLogo,"snapshot-views":arrayRotations =>
                                _vm.$print(
                                    arrayRotations,
                                    'snapshot-views'
                                ),"remove-view":_vm.removeFile,"add-view":_vm.addView,"color-changed":_vm.changeColor,"upload-background":_vm.uploadBackground,"replace-background":backgroundFile =>
                                _vm.$print(
                                    backgroundFile,
                                    'replace-background'
                                ),"remove-background":backgroundFile => {
                                _vm.removeBackground(backgroundFile);
                                _vm.$print(
                                    backgroundFile,
                                    'remove-background'
                                );
                            },"change-color":color => _vm.$print(color, 'change-color'),"attachBackgroundColor":_vm.attachBackgroundColor},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}})]:_vm._e()],2),_c('review-block',{class:{
                    'help-wrapper2':
                        _vm.$store.state.help.configurator === 2
                },attrs:{"class-body":"pa-0","title":"Specifications"}},[(
                        (!_vm.isManager && !_vm.isAdmin) &&
                            _vm.$store.state.help.configurator === 2
                    )?_c('div',{staticClass:"help-window2"},[_c('b',[_vm._v("Leave any final comments")]),_c('span',[_vm._v(" by rotating the model and adding a snap comment to add any further specifications to your final rendering. ")]),_c('div',{staticClass:"d-flex justify-end mt-3 align-center"},[_c('v-btn',{staticClass:"mt-2",staticStyle:{"border":"2px solid #0046AF"},attrs:{"color":"#0046AF","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.$store.dispatch('setHelpConfigurator', 0)}}},[_vm._v(" Ok, got it! ")])],1)]):_vm._e(),_c('v-btn',{staticClass:"help-visible2 snap-comment-btn mb-3",attrs:{"disabled":_vm.disabledSnapComment,"small":"","height":"30","width":"100%"}},[_c('v-img',{attrs:{"src":require("@/assets/_redesigne/icons/ic-comment.svg"),"max-width":"16","contain":""}}),_c('span',{staticClass:"ml-2",on:{"click":_vm.snapComment}},[_vm._v(" Snap Comment ")])],1),_c('spec-view',{attrs:{"current-selected":_vm.currentSelected,"data":_vm.typeViews,"disable-controls":_vm.disableControls,"custom-title":_vm.viewsTypeTitle,"total-views-count":_vm.viewsTemplates.length},on:{"delete":file => _vm.$print(file, 'specView delete'),"edit":file => _vm.$print(file, 'specView edit'),"open":file => _vm.openCommentReview(file),"remove":_vm.removeFile,"replay":function($event){return _vm.$print('specView replay')},"selected":file => {
                            _vm.selectFile(file, 'spec-view');
                        }}}),_vm._l((_vm.typeBackground),function(file){return _c('spec',{key:file.id,attrs:{"current-selected":_vm.currentSelected,"data":file,"disable-controls":_vm.disableControls},on:{"delete":_vm.removeFile,"edit":file => _vm.$print(file, 'spec edit'),"open":file => _vm.selectFile(file, 'spec open'),"replay":file => _vm.$print(file, 'spec replay'),"selected":file => {
                            _vm.selectFile(file);
                        }}})}),_vm._l((_vm.typeDropShadow),function(file,index){return _c('spec',{key:file.id,attrs:{"current-selected":_vm.currentSelected,"data":file,"index":index + 1,"disable-controls":_vm.disableControls},on:{"delete":_vm.removeFile,"edit":file => _vm.$print(file, 'spec edit'),"open":file => _vm.selectFile(file, 'spec open'),"replay":file => _vm.$print(file, 'spec replay'),"selected":file => {
                            _vm.selectFile(file);
                        }}})}),_vm._l((_vm.typeEng),function(file,index){return _c('spec',{key:file.id,attrs:{"current-selected":_vm.currentSelected,"data":file,"disable-controls":_vm.disableControls,"index":index + 1},on:{"delete":_vm.removeFile,"edit":_vm.editFile,"open":file => _vm.selectFile(file, 'spec open'),"replay":_vm.replay,"selected":file => {
                            _vm.selectFile(file);
                        }}})}),_vm._l((_vm.typeLogo),function(file,index){return _c('spec',{key:file.id,attrs:{"current-selected":_vm.currentSelected,"data":file,"disable-controls":_vm.disableControls,"index":index + 1},on:{"delete":_vm.removeFile,"edit":_vm.editLogo,"open":file => _vm.selectFile(file, 'spec open'),"replay":_vm.replay,"selected":file => {
                            _vm.selectFile(file);
                        }}})}),_c('project-render-description',{staticClass:"mb-3"}),(_vm.getProjectData.type_id !== _vm.$config.project.type.custom && !_vm.isAdminProject)?_c('project-price-details',{attrs:{"price":_vm.getProjectData.client_amount}}):_vm._e()],2)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }