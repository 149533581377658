<template>
    <div :class="{ 'is-editing': getCommentEditStatus }">
        <v-expansion-panels
            v-model="panel"
            accordion
            class="project-editor"
            flat
            multiple
            tile>
            <v-expansion-panel @change="sendCollapseAction">
                <v-expansion-panel-header class="project-editor__header pa-0">
                    <div
                        class="project-editor__header-name mr-auto">
                        Metal Type
                    </div>
                    <button
                        class="select-all-btn"
                        @click.stop="isMetalAttachment ? deselectAll() : selectAll($store.state.metalTypes)">
                        {{ selectAllMetalBtnText }}
                    </button>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    class="project-editor__content no-select">
                    <div class="project-editor__options d-flex flex-wrap">
                        <div
                            v-for="metal in $store.state.metalTypes"
                            :key="metal.id"
                            class="project-editor__option mb-4"
                            @click="mClick(metal)">
                            <div
                                :class="{
                                    'option--active': isSelectedMetal(metal)
                                }"
                                class="project-editor__option-img-holder"
                                @dragstart="dragStart"
                                @mousedown="mDown"
                                @mouseup="mouseUp">
                                <div
                                    class="project-editor__option-img-wrap d-flex justify-center align-center">
                                    <img
                                        :data-title="metal.name"
                                        :data-value="metal.value"
                                        :src="metal.image"
                                        alt=""
                                        class="drag-drop-img project-editor__option-img"
                                        data-type="3"
                                        @dragend="mouseUp">
                                </div>
                                <div
                                    v-show="!mouseDown"
                                    class="project-editor__hover no-select">
                                    <v-img
                                        :src="
                                            require('@/assets/icons/ic-plus-white.svg')
                                        "
                                        class="mt-2"
                                        contain
                                        max-height="24"
                                        max-width="24"/>
                                    <span class="mt-2">Add</span>
                                </div>
                            </div>
                            <p
                                class=" mb-0 text-center project-editor__option-name">
                                {{ metal.name }}
                            </p>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel @change="sendCollapseAction">
                <v-expansion-panel-header class="project-editor__header pa-0">
                    <div
                        class="project-editor__header-name mr-auto ">
                        Stone Type
                    </div>
                    <button
                        class="select-all-btn"
                        @click.stop="isStoneAttachment ? deselectAll() : selectAll($store.state.stoneTypes)">
                        {{ selectAllStoneBtnText }}
                    </button>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    class="project-editor__content no-select">
                    <div
                        class="project-editor__options d-flex flex-wrap ma-0 pa-0"
                        @dragstart="dragStart">
                        <div
                            v-for="stone in $store.state.stoneTypes"
                            :key="stone.id"
                            class="project-editor__option mb-4"
                            @click="mClick(stone)">
                            <div
                                :class="{
                                    'option--active':
                                        isSelectedStone(stone)
                                }"
                                class="project-editor__option-img-holder"
                                @dragstart="dragStart"
                                @mousedown="mDown"
                                @mouseup="mouseUp">
                                <div class="project-editor__option-img-wrap d-flex justify-center align-center">
                                    <img
                                        :data-title="stone.name"
                                        :data-value="stone.value"
                                        :src="stone.image"
                                        alt=""
                                        class="project-editor__option-img"
                                        data-type="2"
                                        @dragend="mouseUp">
                                </div>
                                <div
                                    v-show="!mouseDown"
                                    class="project-editor__hover">
                                    <v-img
                                        :src="
                                            require('@/assets/icons/ic-plus-white.svg')
                                        "
                                        class="mt-2"
                                        contain
                                        max-height="24"
                                        max-width="24"/>
                                    <span class="mt-2">Add</span>
                                </div>

                                <div
                                    class="checked"
                                    v-if="$store.getters.getItemData &&
                                        $store.getters.getItemData.itemValue ===
                                        stone.value">
                                    <img :src="require('@/assets/icons/ic-checked.svg')">
                                </div>
                            </div>
                            <p
                                class=" mb-0 text-center project-editor__option-name">
                                {{ stone.name }}
                            </p>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel @change="descriptionPanelHandler">
                <v-expansion-panel-header
                    :hide-actions="descriptionIsEditing || descriptionIsEmpty"
                    class="project-editor__header pa-0"
                    :class="{ 'project-editor__blocked': !areLinksValid }">
                    <div
                        class="project-editor__header-name d-flex align-center mr-auto ">
                        Description

                        <a
                            v-if="!descriptionIsEditing && descriptionIsEmpty"
                            class="project-editor__header-btn ml-auto"
                            role="button">
                            + Add Description
                        </a>

                        <a
                            v-if="!descriptionIsEditing && !descriptionIsEmpty"
                            class="project-editor__header-btn ml-auto mr-5"
                            role="button"
                            @click="editDescription($event)">
                            Edit Description
                        </a>

                        <div
                            v-if="descriptionIsEditing"
                            :class="[
                                'ml-auto',
                                { 'mr-5': !descriptionIsEditing }
                            ]">
                            <a
                                class="project-editor__header-btn mr-5"
                                role="button"
                                @click.prevent.stop="submitProjectDescription">
                                Save
                            </a>
                            <a
                                class="project-editor__header-btn"
                                role="button"
                                @click="cancelUpdateProjectDescription($event)">
                                Cancel
                            </a>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="project-editor__content">
                    <project-description
                        :description-is-editing="descriptionIsEditing"
                        @openEditDescription="openEditDescription"
                        @updateProjectDescription="updateProjectDescription"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <project-price-details
                v-if="getProjectData.type_id !== $config.project.type.custom && !isAdminProject && getProjectData.status_id !== $config.project.status.draft"
                class="v-expansion-panel" :price="getProjectData.client_amount"/>
        </v-expansion-panels>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { projectApi } from "@/api";
    import ProjectDescription from "@/views/project/start/review-project/ProjectDescription";
    import ProjectPriceDetails from "@/components/project/ProjectPriceDetails";

    export default {
        name: "project-editor",
        components: {
            ProjectPriceDetails,
            ProjectDescription
        },
        props: {
            selected: {
                type: Array,
                default: null
            }
        },
        watch: {
            selected(val) {
                console.log(val)
            }
        },
        data: () => ({
            panel: [0, 3],
            descriptionIsEditing: false,
            mouseDown: false,
            descriptionData: {
                description: "",
                links: []
            }
        }),
        computed: {
            ...mapGetters([
                "getProjectDescription",
                "getProjectLinks",
                "descriptionIsEmpty",
                "getCommentEditStatus",
                "areLinksValid",
                "getItemData",
                "getProjectData",
                "isAdminProject"
            ]),
            isSelectedMetal() {
                return metal => {
                    return this.selected && this.selected.find(item => item.itemValue === metal.value && item.itemType === this.$config.commentType.metal)
                }
            },
            isSelectedStone() {
                return stone => {
                    return this.selected && this.selected.find(item => item.itemValue === stone.value && item.itemType === this.$config.commentType.stone)
                }
            },
            hasSelected() {
                return this.selected && this.selected.length
            },
            isMetalAttachment() {
                return this.hasSelected && this.selected.every(item => item.itemType === this.$config.commentType.metal)
            },
            isStoneAttachment() {
                return this.hasSelected && this.selected.every(item => item.itemType === this.$config.commentType.stone)
            },
            selectAllMetalBtnText() {
                return this.isMetalAttachment ? "Deselect all" : "Select all"
            },
            selectAllStoneBtnText() {
                return this.isStoneAttachment ? "Deselect all" : "Select all"
            }
        },
        methods: {
            selectAll(attachments ) {
                const items = attachments.map(attachment => {
                    return {
                        itemURL: attachment.image,
                        itemType: attachment.type,
                        itemTitle: attachment.name,
                        itemValue: attachment.value
                    };
                })
                this.emitSelected(items)

            },
            deselectAll() {
                this.emitSelected(null)
            },
            mClick(selectedObject) {
                let itemData = {
                    itemURL: selectedObject.image,
                    itemType: selectedObject.type,
                    itemTitle: selectedObject.name,
                    itemValue: selectedObject.value
                };

                const currentSelected = this.selected?.find(item => item.itemValue === itemData.itemValue)
                const equilTypes = this.selected?.every(item => item.itemType === itemData.itemType)
                if(equilTypes)  {
                    if(!currentSelected) {
                        this.emitSelected(this.hasSelected ? [...this.selected, itemData] : [itemData])
                    } else {
                        this.emitSelected(this.selected.filter(item => item.itemValue !== itemData.itemValue))
                    }
                } else {
                    this.emitSelected([itemData])
                }

            },

            emitSelected(items) {
                this.$emit("selectAtachment", items)
            },
            mDown() {
                this.mouseDown = true;
                console.log("mDown");
            },
            mouseUp() {
                this.mouseDown = false;
                console.log("mouseUp");
            },
            sendCollapseAction() {
                this.$emit("onCollapse");
            },
            descriptionPanelHandler() {
                if (this.descriptionIsEmpty && !this.descriptionIsEditing) {
                    this.openEditDescription(true);
                } else if (this.descriptionIsEmpty && this.descriptionIsEditing) {
                    this.openEditDescription(false);
                }
                this.sendCollapseAction();
            },
            editDescription(event) {
                if (!this.descriptionIsEmpty && !this.descriptionIsEditing) {
                    event.stopPropagation();
                    this.panel.push(2); // 2 it's id of description panel
                } else if (!this.descriptionIsEmpty && this.descriptionIsEditing) {
                    event.stopPropagation();
                }
                this.openEditDescription(true);
            },
            updateProjectDescription(data) {
                this.descriptionData = data;
            },
            cancelUpdateProjectDescription(event) {
                event.stopPropagation();
                if (this.descriptionIsEmpty && this.descriptionIsEditing) {
                    this.panel = this.panel.filter(panel => panel !== 2); // 2 it's id of description panel
                }
                this.openEditDescription(false);
                this.descriptionData.description = this.getProjectDescription;
                this.descriptionData.links = this.getProjectLinks;
            },
            async submitProjectDescription() {
                if (!this.areLinksValid) {
                    return;
                }
                if (!this.descriptionIsEditing) {
                    return;
                }

                if (
                    !this.descriptionData.description.length &&
                    !this.descriptionData.links.length
                ) {
                    this.panel = this.panel.filter(panel => panel !== 2); // 2 it's id of description panel
                }
                this.openEditDescription(false);
                await projectApi.edit(this.getProjectData.id, {
                    description: this.descriptionData.description,
                    links: this.descriptionData.links
                });
                await this.$store.dispatch("updateProjectData", {
                    description: this.descriptionData.description,
                    links: this.descriptionData.links
                });
            },
            openEditDescription(value) {
                this.descriptionIsEditing = value;
            },
            dragStart(e) {
                const target = e.target;
                console.log(target);
                // console.log(event);
                const itemData = {
                    itemURL: target.src,
                    itemType: target.dataset.type,
                    itemTitle: target.dataset.title,
                    itemValue: target.dataset.value
                };
                event.dataTransfer.setData("item", JSON.stringify(itemData));
            // this.$nextTick(()=>this.mouseDown = false);
            }
        },
        mounted() {
            this.$emit("saveEditable", () => this.submitProjectDescription());
            this.descriptionData.description = this.getProjectDescription;
            this.descriptionData.links = this.getProjectLinks;
        // this.$store.dispatch('loadMetals');
        // this.$store.dispatch('loadStones');
        }
    };
</script>

<style lang="scss" scoped>
.project-editor {
    .v-expansion-panel {
        margin-bottom: 11px;
        max-width: 351px;
    }

    &__blocked {
        pointer-events: none !important;
    }

    &__header-name {
        font-size: 20px;
        line-height: 32px;
    }

    &__header-btn {
        font-size: 16px;
        line-height: 24px;
    }

    .v-expansion-panel--active > .v-expansion-panel-header {
    }

    &__option {
        cursor: pointer;
        position: relative;
        width: 102px;
        margin-right: 33px;
    }

    &__color {
        z-index: 2;
        // background: #343434;
        opacity: 0.5;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 18px;
        height: 18px;
        border: 1px solid #9a9a9a;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &__option-img-holder {
        position: relative;
        padding: 27px;
        background-color: #f4f7f6;
    }

    .option--active {
        border-color: #3F7359;
        box-shadow: 0 0 0 2px #3F7359;
    }

    &__hover {
        display: none;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 70, 175, 0.75);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        span {
            color: #ffffff;
            font-size: 12px;
            line-height: 17px;
        }
    }

    &__option:hover {
        .project-editor__hover {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__option-img-wrap {
        width: 48px;
        height: 48px;
    }

    &__option-name {
        font-size: 12px;
        line-height: 16px;
        color: #0b3c49;
    }
}

@media only screen and (max-width: 360px) {
    .project-editor__header-name,
    .project-editor__footer-name {
        font-size: 18px;
        line-height: 28px;
    }
    .project-editor__header-btn {
        font-size: 14px;
        line-height: 20px;
    }
}

.v-expansion-panel {
    background: #ffffff;
    border: 1px solid #bcbcbc;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;
    padding: 16px 16px 16px 13px;
}

.project-editor__header-name,
.project-editor__footer-name {
    color: #464646 !important;
    font-size: 14px;
    line-height: 18px;
}

.v-expansion-panel-header {
    min-height: 0;
}

.project-editor__header-btn {
    color: #3F7359;
    font-size: 18px;
    line-height: 20px;
}

.project-editor__option {
    width: 97px;
}

.project-editor__option-img-holder {
    border: 1px solid #BCBCBC;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #ffff;
    padding: 24px;
    position: relative;

    .checked {
        position: absolute;
        z-index: 2;
        bottom: 4px;
        right: 4px;
        width: 14px;
        height: 14px;
    }
}

.project-editor__option-name {
    font-size: 14px;
    line-height: 18px;
    color: #464646;
    margin-top: 4px;
}

.project-editor__option {
    margin-right: 14px;

    &:nth-child(1) {
        margin-right: 14px !important;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }
}

.project-editor__hover {
    background: #3F7359;
    opacity: 0.9;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);

    span {
        font-size: 14px;
        line-height: 18px;
    }
}

.project-editor__content {
    margin-bottom: 16px;
}

.select-all-btn {
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-decoration-line: underline;
    color: #464646;
    margin-left: auto;
    margin-right: 13px;
    width: fit-content;
    text-align: end;
    display: block;
}

.project-editor__option-img-wrap {
    img {
        width: 100%;
    }
}

</style>

<style lang="scss">
//.v-application--is-ltr .v-input--selection-controls__input {
//    position: absolute;
//    top: 0;
//    left: 0;
//    opacity: 0;
//}
.project-editor .v-expansion-panel-header__icon {
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #3f7359;

    .v-icon {
        font-size: 20px;
    }
}

.is-editing .project-editor {
    pointer-events: none;
}

.is-editing .project-editor .v-expansion-panel-header__icon {
    background-color: #cbd2d0;
}

.is-editing .project-editor__header-btn {
    color: #cbd2d0;
}

.project-editor__content .v-expansion-panel-content__wrap {
    padding: 0;
    margin-top: 20px;
}

.project-editor
.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before {
    opacity: 0;
}

.project-editor .v-expansion-panel-header__icon .v-icon {
    color: #3f7359 !important;
}

.project-editor .v-expansion-panel--active {
    padding-bottom: 0;

    .v-expansion-panel-header__icon .v-icon {
        transform: rotate(-90deg) !important;
    }
}
</style>
