<template>
    <div
        v-if="!loaded"
        class="page-preloader"/>
    <div
        class="d-flex h-100 w-100"
        v-else>
        <start-project v-if="isStartProject() && !isDesigner"/>
        <project-edit v-else-if="isEditProject() && !isDesigner"/>
        <project-in-progress v-else-if="isView() && isInProgressAndComplete" @openExtraChargeDialog="openExtraChargeDialog"/>
        <project-view v-else-if="isView()" @openExtraChargeDialog="openExtraChargeDialog"/>
        <project-in-progress-renders v-else-if="isInRendersReview" @openExtraChargeDialog="openExtraChargeDialog"/>
        <!--  <review-project-tab  v-else-if="loaded && isView()"></review-project-tab>-->
        <not-found-page v-else/>

        <dialog-default
            type="alertCrashProject"
            v-if="isDesigner"/>

        <designer-extra-charge-dialog
            v-if="isDesigner && projectIsOnHold"
            v-model="designerExtraChargeDialog"/>

        <admin-extra-charge-dialog
            v-if="isManagerOrAdmin && projectIsOnHold"
            v-model="adminExtraChargeDialog"/>

        <client-extra-charge-dialog
            v-if="isClient && projectIsOnHold"
            v-model="clientExtraChargeDialog"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    import StartProject from "@/views/project/start/StartProject";
    import ProjectInProgress from "@/components/project/ProjectInProgress";
    import ProjectInProgressRenders from "@/components/project/ProjectInProgressRenders";
    import ProjectView from "@/components/project/ProjecView";
    import NotFoundPage from "@/views/NotFoundPage";
    import ProjectEdit from "@/components/project/ProjectEdit";
    import DialogDefault from "@/components/ui/dialog-default"
    import DesignerExtraChargeDialog from "@/components/ExtraCharge/DesignerExtraChargeDialog";
    import AdminExtraChargeDialog from "@/components/ExtraCharge/AdminExtraChargeDialog";
    import ClientExtraChargeDialog from "@/components/ExtraCharge/ClientExtraChargeDialog";

    export default {
        name: "project",
        data () {
            return {
                loaded: false,
                designerExtraChargeDialog: true,
                adminExtraChargeDialog: true,
                clientExtraChargeDialog: true
            };
        },
        components: {
            ProjectEdit,
            NotFoundPage,
            StartProject,
            ProjectInProgress,
            ProjectInProgressRenders,
            ProjectView,
            DialogDefault,
            DesignerExtraChargeDialog,
            AdminExtraChargeDialog,
            ClientExtraChargeDialog
        },
        computed: {
            ...mapGetters([
                "isDesigner",
                "isClient",
                "isManager",
                "isManagerOrAdmin",
                "getValueProjectStatus",
                "getProjectData",
                "projectIsOnHold"
            ]),
            isInProgressAndComplete () {
                let result = false;
                let status = this.getProjectData.status;
                const acceptedStatuses = [
                    this.getValueProjectStatus(this.$config.project.status.in_progress),
                    this.getValueProjectStatus(this.$config.project.status.ready_for_review),
                    this.getValueProjectStatus(this.$config.project.status.in_progress_rendering),
                    this.getValueProjectStatus(this.$config.project.status.ready_for_review_renders),
                    this.getValueProjectStatus(this.$config.project.status.final_assets_prep),
                    this.getValueProjectStatus(this.$config.project.status.in_cqa),
                    this.getValueProjectStatus(this.$config.project.status.in_qa),
                    this.getValueProjectStatus(this.$config.project.status.completed)
                ];

                if (acceptedStatuses.includes(status)) {
                    result = true;
                }
                return result;
            },
            isInRendersReview() {
                let result = false;
                let status = this.getProjectData.status;
                const acceptedStatuses = [
                    this.getValueProjectStatus(this.$config.project.status.in_progress_rendering),
                    this.getValueProjectStatus(this.$config.project.status.ready_for_review_renders),
                    this.getValueProjectStatus(this.$config.project.status.final_assets_prep),
                    this.getValueProjectStatus(this.$config.project.status.in_cqa),
                    this.getValueProjectStatus(this.$config.project.status.completed)
                ];

                if (acceptedStatuses.includes(status)) {
                    result = true;
                }

                return result;
            }
        },
        methods: {
            isStartProject () {
                return ["project-start", "upload-files", "render-configurator", "project-start-edit"].includes(this.$route.name);
            },
            isEditProject () {
                return ["project-edit"].includes(this.$route.name);
            },
            isView () {
                return ["project"].includes(this.$route.name);
            },
            openExtraChargeDialog() {
                if (this.isClient) {
                    this.clientExtraChargeDialog = true;
                } else if (this.isManagerOrAdmin) {
                    this.adminExtraChargeDialog = true;
                }
            }
        },
        watch: {
            "$route.params": {
                immediate: false,
                handler: function () {
                    this.loaded = false;
                    const getProject = async () => {
                        if (this.$route?.params?.id) {
                            this.$store.commit("setProjectId", parseInt(this.$route?.params?.id));
                            await this.$store.dispatch("loadProject");
                        }
                        this.loaded = true;
                    };
                    getProject();
                }
            },
            "$route.name": {
                immediate: true,
                handler: function () {
                    this.loaded = false;
                    const getProject = async () => {
                        if (this.$route?.params?.id) {
                            this.$store.commit("setProjectId", parseInt(this.$route?.params?.id));
                            await this.$store.dispatch("loadProject");
                        }
                        this.loaded = true;
                    };
                    getProject();
                }
            }
        }
    };
</script>
