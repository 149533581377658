<template>
    <div>
        <v-list class="file-list">
            <div
                v-if="files.length === 0"
                class="mb-5 pa-2 no-items-label">
                {{ "No " + title + " uploaded yet" }}
            </div>

            <v-list-item
                v-for="item in files"
                :key="item.id"
                class="uploaded__img-block  file-list__block">
                <v-progress-circular
                    v-if="isFile(item)"
                    :width="3"
                    color="#3f7359"
                    indeterminate
                    size="32"/>

                <model
                    v-else-if="is3d(item.original_name)"
                    :file-name="item.original_name"
                    :height="48"
                    :src="item.url"
                    :width="48"
                    style="width: 48px; height: 48px"/>

                <v-img
                    v-else-if="isOther(item.original_name)"
                    :src="require('@/assets/img/upload/up-noimg.png')"
                    lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALOSURBVHgB7ZrditpAGIa//JQVxZ8eyKJ4IHgmCuuBx9VL6BWU3lHvoJfQ3kE91gNbBaWCNKJsXFuxKwg1q5nON2wktbE7mh8nWx/IxokK8+zMvJMZI4GNVqtVo6cahINGtVptWAUJ/7Tb7RQh5EMsFqtFo1FQFAVEZz6fg2EYmizL9Uqloql4cbvdvstms7VMJgNhAeuq63p+Op2+p8W6hN0pEol8KpVKEEYGgwEsl8u6TF/fpFIpCCvxeBxPNRRJhWFMPIUMz4SLiGiovB9cr9cwHA4xIVg5kUhAoVCAq6srEAGuFkGJbre7k0DwdafTgdVqBSLAJTKZTGCz2fx1nU6kMBqNQAS4RBaLxcH37K10Tv6v1HqcPR3BQS8CXCL5fN4xnVRVZcklAlwiKFEsFiGdTrPK44EtUS6XhYlf7nkEKyzKf9+Jyy2KV9ze3bHDLdxdyw8Wy3u4nU135ez1NZzK2VpkbRigTca7Mgq5aZmziKDE129Ddotjx41M4CIbWnmUoDsgju+fKhO4yIh2p0MSFqfIBCqClcMBzvXZI2UCE2Exa0soru8cIROIyH7MHgPv93wX2Y9Zv/BMBFeReNg5FLN+4MnMjqtESwJvLvEu+amY9RrXItbuioWmaYA7+tMf3wOTQFyJoESv12NnC+xG/X4fyAsVJFmCoHA1RrAl7BIWuONi4nVCIChOFsEx8a8dFGISMI0HCIqTRJwSyglzswXzIRiZo0XsCcWDadBu5rC55zVHiewnFC8oQ0wT/IRbxCmhuKGDfvvLYOPGL7hFDiUUN1TGzyTj3sT2Yo/XzyTjmhBzuRw7ROayryUaz0rkZxDrBb9Bkc/4gEpYeax7Q8ZHhWiLfByP/V+Oeo2u6zi3scedWPzKsvx2NpvdU94kk0n2+4fI4DKB1pVJKIryGq/9sfJpNps3VOoVIeQlCIwkSQtaxy/2B89+A8LCjP6EObwwAAAAAElFTkSuQmCC"
                    contain
                    height="48"
                    max-width="48"
                    min-width="48"
                    sizes="48">
                    <span class="d-flex justify-center align-center w-100 fill-height file-ext">{{ `.${fileExtension(item.original_name)}` }}</span>
                </v-img>

                <v-img
                    v-else
                    :src="
                        item.url
                            ? item.url
                            : require('@/assets/img/upload/up-noimg.png')
                    "
                    lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALOSURBVHgB7ZrditpAGIa//JQVxZ8eyKJ4IHgmCuuBx9VL6BWU3lHvoJfQ3kE91gNbBaWCNKJsXFuxKwg1q5nON2wktbE7mh8nWx/IxokK8+zMvJMZI4GNVqtVo6cahINGtVptWAUJ/7Tb7RQh5EMsFqtFo1FQFAVEZz6fg2EYmizL9Uqloql4cbvdvstms7VMJgNhAeuq63p+Op2+p8W6hN0pEol8KpVKEEYGgwEsl8u6TF/fpFIpCCvxeBxPNRRJhWFMPIUMz4SLiGiovB9cr9cwHA4xIVg5kUhAoVCAq6srEAGuFkGJbre7k0DwdafTgdVqBSLAJTKZTGCz2fx1nU6kMBqNQAS4RBaLxcH37K10Tv6v1HqcPR3BQS8CXCL5fN4xnVRVZcklAlwiKFEsFiGdTrPK44EtUS6XhYlf7nkEKyzKf9+Jyy2KV9ze3bHDLdxdyw8Wy3u4nU135ez1NZzK2VpkbRigTca7Mgq5aZmziKDE129Ddotjx41M4CIbWnmUoDsgju+fKhO4yIh2p0MSFqfIBCqClcMBzvXZI2UCE2Exa0soru8cIROIyH7MHgPv93wX2Y9Zv/BMBFeReNg5FLN+4MnMjqtESwJvLvEu+amY9RrXItbuioWmaYA7+tMf3wOTQFyJoESv12NnC+xG/X4fyAsVJFmCoHA1RrAl7BIWuONi4nVCIChOFsEx8a8dFGISMI0HCIqTRJwSyglzswXzIRiZo0XsCcWDadBu5rC55zVHiewnFC8oQ0wT/IRbxCmhuKGDfvvLYOPGL7hFDiUUN1TGzyTj3sT2Yo/XzyTjmhBzuRw7ROayryUaz0rkZxDrBb9Bkc/4gEpYeax7Q8ZHhWiLfByP/V+Oeo2u6zi3scedWPzKsvx2NpvdU94kk0n2+4fI4DKB1pVJKIryGq/9sfJpNps3VOoVIeQlCIwkSQtaxy/2B89+A8LCjP6EObwwAAAAAElFTkSuQmCC"
                    contain
                    height="48"
                    max-width="48"
                    min-width="48"
                    sizes="48"/>

                <span class="file-list__name d-block text-truncate">
                    {{ item.original_name || item.name || item.originalName }}
                </span>

                <v-spacer/>

                <v-btn
                    icon
                    @click="$emit('remove', item.id || item)">
                    <v-img
                        :src="require('@/assets/icons/ic-exit.svg')"
                        contain
                        height="14"
                        max-width="14"
                        sizes="14"/>
                </v-btn>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    import Model from "@/components/3d-model/model";
    import { getExtension, hasExtension } from "@/utils/index.utils";

    export default {
        name: "file-item",
        components: {
            Model
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            files: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            fileExtension() {
                return (filename) => getExtension(filename)
            }
        },
        methods: {
            is3d(url = "") {
                return hasExtension(url, this.$config.allowedFileExtension.models);
            },
            isOther(url = "") {
                return hasExtension(url, this.$config.allowedFileExtension.others);
            },
            isFile(item) {
                return item instanceof File;
            }
        }
    };
</script>

<style lang="scss" scoped>
.file-list {
    background-color: transparent;

    &__block:not(:first-child) {
        margin-top: 24px;
    }
    &__block:last-child {
        margin-bottom: 38px;
    }
    &__name {
        margin-left: 14px;
        // margin-bottom: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
    }
    @media screen and (max-width: 1280px) {
        $font-size: 24 * 0.8;
        &__img-name {
            margin-left: $font-size / 1.71 + px;
            font-size: $font-size / 1.6 + px;
            line-height: $font-size / 1.5 + px;
        }
    }

    .no-items-label {
        background: #ffffff;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }

    .uploaded__img-block {
        padding: 12px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
        border-radius: 5px;
    }

    .file-list__name {
        font-family: "Inter";
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #000000;
    }

    .file-list__block:not(:first-child) {
        margin-top: 7px;
    }
}
.file-ext{
    color: #696969;
    font-size: 14px;
}
</style>
