<template>
    <v-container class="pa-6 project-in-progress-page" fluid>
        <div class="project-scroll-section">
            <div class="def-page-cont">
                <div class="head-section">
                    <v-row align="center">
                        <v-col>
                            <project-header :edit-title="!isCompleted"/>
                        </v-col>
                    </v-row>
                </div>

                <div class="project-main-section">
                    <v-row justify="space-between">
                        <v-col class="pms-col slider-column">
                            <div class="slider-title">
                                Reference Images
                            </div>

                            <product-slider
                                v-if="clientSliderFiles && clientSliderFiles.length"
                                id="clients"
                                key="clients"
                                ref="clients-slider"
                                :items="clientSliderFiles"
                                :enable-delete="(isClient || isAdminProject) && clientSliderFiles.length > 1 && !projectIsOnHold"
                                :atachments="null"
                                :extended-mode="isCurrentCustomerProjectsAuthor && isInProgress"
                                :disable-files-update="!(isCurrentCustomerProjectsAuthor && isInProgress)"
                                :initially-opened-comment="initiallyOpenedComment"
                                :allow-change-stone="false"
                                :allow-change-metal="false"
                                @addReply="addReply($event, 'clients')"
                                @updateFile="updateFile($event, 'clients')"
                                @addComment="addComment($event, 'clients')"
                                @addSnapshot="addSnapshot($event, 'clients')"
                                @setModelPreview="setModelPreview"
                                @bindFileToProject="bindFileToProject($event, 'clients')"
                                @deleteComment="deleteComment($event, 'clients')"
                                @updateComment="updateComment($event, 'clients')"
                                @deleteComments="deleteComments($event, 'clients')"
                                @redoComment="addComment($event, 'clients')"
                                @deleteFile="deleteFile($event, 'clients')"
                                @deleteAllFileVersions="toggleDeleteModal($event, 'clients')"
                                @swapFile="replaceFile($event, 'clients')"/>

                            <template v-if="isClient && !clientSliderFiles.length && !initialLoading" style="position: relative">
                                <div v-show="uploadingFileProgress != null" class="page-preloader"/>

                                <validation-provider
                                    ref="provider"
                                    v-slot="{ errors, invalid }"
                                    :rules="{ size: uploadFileSize }"
                                    name="File">
                                    <label class="upload-lbl upload-lbl-model">
                                        <input
                                            :disabled="filesLoading"
                                            accept="image/*"
                                            class="upload-lbl-inp"
                                            multiple
                                            type="file"
                                            @change="selectFile($event, [$config.fileTypes.reference])">
                                        <span class="upload-lbl-cont">
                                            <span class="upload-lbl-ic">
                                                <img src="@/assets/icons/ic-plus-circle.svg" alt="">
                                            </span>
                                            <span class="upload-lbl-desc mt-2">
                                                Upload Reference Image
                                            </span>
                                        </span>
                                    </label>
                                    <div v-if="invalid" class="field-error-txt mt-2">
                                        {{ errors[0] }}
                                    </div>
                                </validation-provider>
                                <div class="files-group-placeholder">
                                    <div class="files-group-notification">
                                        The files will appear here after you uploaded them
                                    </div>
                                </div>
                            </template>

                            <div v-if="!isClient && !clientSliderFiles.length && !initialLoading" class="pms-img-wrap">
                                <div class="placeholder-text">A client is working<br> hard on your project...</div>
                                <img alt="" src="@/assets/img/client-in-progress.png">
                            </div>
                        </v-col>

                        <v-col class="pms-col slider-column">
                            <div class="pms-label slider-title d-flex justify-space-between">
                                Assets Preview
                                <div v-if="!isDesigner || isDesigner && !isInProgress && !isReadyForReview">
                                    Round {{ currentRound }}
                                </div>

                                <v-menu
                                    v-if="isDesigner && (isInProgress || isReadyForReview)"
                                    v-model="roundMenu"
                                    attach=".rounds-selector-menu-anchor"
                                    offset-y
                                    nudge-bottom="10"
                                    left
                                    min-width="150"
                                    max-width="150"
                                    content-class="rounds-selector-menu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs"
                                             v-on="on"
                                             :class="['rounds-selector-menu-anchor d-flex align-center', {'is-active': roundMenu}]">
                                            Round {{ selectedRound }}
                                            <template v-if="selectedFile && selectedFile.type === $config.fileTypes.models">
                                                <span class="mr-1">,</span>
                                                Version {{ selectedFile.version }}
                                            </template>
                                            <img class="ml-2" src="@/assets/icons/ic-arrow-down.svg" alt="">
                                        </div>
                                    </template>
                                    <ul class="rounds-selector pa-0 ma-0">
                                        <li v-for="round in rounds" :key="round" @click="changeRound(round)">
                                            <span class="d-block" :data-round="round">Round {{ round }}</span>
                                            <ul class="pa-0 ma-0" v-if="selectedFile && selectedFile.type === $config.fileTypes.models">
                                                <template v-for="file in selectedFileVersions">
                                                    <li :key="file.id" v-show="file.round === round" @click="changeFileVersion(file)">
                                                        <span class="d-block">Version {{ file.version }}</span>
                                                    </li>
                                                    <li v-for="fileVersion in file.rounds"
                                                        :key="fileVersion.id"
                                                        v-show="fileVersion.round === round"
                                                        @click="changeFileVersion(fileVersion)">
                                                        <span class="d-block">Version {{ fileVersion.version }}</span>
                                                    </li>
                                                </template>
                                            </ul>
                                        </li>
                                    </ul>
                                </v-menu>
                            </div>

                            <product-slider
                                v-if="(!isManagerOrAdmin || !isInProgress) && designerSliderFiles && designerSliderFiles.length"
                                id="designers"
                                ref="designers-slider"
                                key="designers"
                                :items="designerSliderFiles"
                                :enable-delete="isDesigner && isInProgress && selectedRound === currentRound && !projectIsOnHold"
                                :atachments="null"
                                :is-client-view="false"
                                :allow-replay-comment="extendedModeForDesignerView || isInProgress"
                                :allow-edit-comment="extendedModeForDesignerView|| isInProgress"
                                :extended-mode="extendedModeForDesignerView"
                                :disable-files-update="!(isCurrentCustomerProjectsDesigner && isInProgress) || selectedRound !== currentRound"
                                :initially-opened-comment="initiallyOpenedComment"
                                @addReply="addReply($event, 'designers')"
                                @updateFile="updateFile($event, 'designers')"
                                @addComment="addComment($event, 'designers')"
                                @addSnapshot="addSnapshot($event, 'designers')"
                                @setModelPreview="setModelPreview"
                                @bindFileToProject="bindFileToProject($event, 'designers')"
                                @deleteComment="deleteComment($event, 'designers')"
                                @updateComment="updateComment($event, 'designers')"
                                @deleteComments="deleteComments($event, 'designers')"
                                @redoComment="addComment($event, 'designers')"
                                @deleteFile="deleteFile($event, 'designers')"
                                @deleteAllFileVersions="toggleDeleteModal($event, 'designers')"
                                @swapFile="replaceFile($event, 'designers')"
                                @changeActiveFile="file => {this.selectedFile = file; setSelectedFileVersions()}"/>

                            <template v-if="isDesigner && !designerSliderFiles.length && !initialLoading">
                                <div v-show="uploadingFileProgress != null" class="page-preloader"/>

                                <validation-provider
                                    v-if="uploadingFileProgress == null"
                                    ref="provider"
                                    v-slot="{ errors, invalid }"
                                    :rules="{ size: uploadFileSize }"
                                    name="File">
                                    <label class="upload-lbl upload-lbl-model">
                                        <input
                                            :disabled="filesLoading"
                                            :accept="designerAcceptExtension"
                                            class="upload-lbl-inp"
                                            type="file"
                                            multiple
                                            @change="selectFile($event, [$config.fileTypes.models, $config.fileTypes.others])">
                                        <span class="upload-lbl-cont">
                                            <span class="upload-lbl-ic">
                                                <img src="@/assets/icons/ic-plus-circle.svg" alt="">
                                            </span>
                                            <span class="upload-lbl-desc mt-2"> Upload Model </span>
                                        </span>
                                    </label>
                                    <div v-if="invalid" class="field-error-txt mt-2">
                                        {{ errors[0] }}
                                    </div>
                                </validation-provider>

                                <div class="files-group-placeholder">
                                    <div class="top-row">
                                        <div class="title">
                                            Model Files
                                        </div>
                                        <label class="upload-btn">
                                            + Upload Model Images
                                            <input
                                                :disabled="filesLoading"
                                                :accept="designerAcceptExtension"
                                                type="file"
                                                class="d-sr-only"
                                                multiple
                                                @change="selectFile($event, [$config.fileTypes.models, $config.fileTypes.others])">
                                        </label>
                                    </div>

                                    <div class="files-group-notification">
                                        The files will appear here after your designer has
                                        uploaded them
                                    </div>
                                </div>
                            </template>
                            <template v-if="isClient || (isManagerOrAdmin && isInProgress && !initialLoading)">
                                <div class="pms-img-wrap">
                                    <img src="@/assets/img/designer-is-working.png" alt="">
                                </div>
                                <div class="files-group-placeholder">
                                    <div class="top-row">
                                        <div class="title">
                                            Model Files
                                        </div>
                                    </div>

                                    <div class="files-group-notification">
                                        The files will appear here after your designer has
                                        uploaded them
                                    </div>
                                </div>
                            </template>
                            <template v-if="isDesigner && !isInProgress && !isReadyForReview && !isInQA && !initialLoading">
                                <div class="pms-img-wrap absolute-preview">
                                    <img src="@/assets/img/model-is-approved.png" alt="">
                                </div>
                            </template>
                            <template v-if="!isDesigner && !isInProgress && !isReadyForReview && !initialLoading">
                                <div class="pms-img-wrap absolute-preview">
                                    <img src="@/assets/img/client-model-approved.png" alt="">
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </div>

                <div class="desc-label-section mt-6">
                    <v-row
                        align="center"
                        justify="space-between">
                        <v-col
                            v-if="allowedEditDescription"
                            cols="auto">
                            <router-link
                                :to="`/project/edit/${project.id}`"
                                class="def-blue-link">
                                Edit description
                            </router-link>
                        </v-col>
                    </v-row>
                </div>

                <project-in-progress-info/>
            </div>
        </div>

        <project-footer>
            <div class="def-page-cont pos-rel">
                <v-row align="center">
                    <div class="nav-wrapper">
                        <div
                            class="bs-back-link"
                            @click="$router.go(-1)">
                            <div class="circle">
                                <img
                                    src="@/assets/_redesigne/icons/ic-shevron-left.svg"
                                    alt="">
                            </div>
                            Back
                        </div>
                        <breadcrumbs
                            :items="breadcrumbs"
                            class="inline-bc"/>
                        <project-step-dropdown class="project-step-dd" :links="projectStepsLinks" :selected="currentLink"/>
                    </div>

                    <div class="pf-info d-flex flex-column align-center ml-3">
                        <template v-if="isManager || isAdmin">
                            <div class="align-center">
                                <v-dialog
                                    v-model="reassignModelerDialog"
                                    width="650">
                                    <template v-slot:activator="{ attrs, on }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            color="#0046AF"
                                            rounded
                                            small
                                            height="25"
                                            class="px-5 mb-1"
                                            @click.stop="reassignModelerDialog = true">
                                            Reassign Modeler
                                        </v-btn>
                                    </template>
                                    <div class="dialog-overflow-wrap">
                                        <v-btn
                                            class="pci-ad-cl-btn"
                                            icon>
                                            <v-img
                                                :src="require('@/assets/icons/ic-close-modal.svg')"
                                                contain
                                                height="36"
                                                max-width="36"
                                                @click="reassignModelerDialog = false"/>
                                        </v-btn>
                                        <reassign-view
                                            :on-going-project-id="project.id"
                                            :full-name="project.designer_name"
                                            :current-modeler-i-d="project.designer_id"
                                            @closeDialog="closeDialog"/>
                                    </div>
                                </v-dialog>
                            </div>
                        </template>
                        <v-btn
                            v-if="isManager || isAdmin"
                            outlined
                            color="#FB3805"
                            rounded
                            small
                            height="25"
                            class="px-5"
                            @click.stop="cancelDialog = true">
                            Cancel this project
                        </v-btn>
                    </div>

                    <v-col
                        class="pt-1  pl-1 pr-0 ml-auto"
                        cols="12"
                        md="auto">
                        <div class="pf-navs">
                            <div class="btns-mob-separate d-sm-none mt-3 mb-3"/>
                            <router-link
                                v-if="!isManagerOrAdmin"
                                :to="{name: 'support'}"
                                class="support-link">
                                Issues and Support
                            </router-link>

                            <v-btn
                                v-if="projectIsOnHold && (isClient || isManagerOrAdmin)"
                                outlined
                                width="160"
                                height="48"
                                rounded
                                color="main-text"
                                class="bg-primary"
                                @click="$emit('openExtraChargeDialog')">
                                Continue
                            </v-btn>

                            <template v-if="!isClient">
                                <div class="apd-btn-wrap d-inline-block">
                                    <v-btn
                                        v-if="isSubstatusInit && isReadyForReview && !isDesigner && !projectIsOnHold"
                                        :disabled="!enableRequestModification"
                                        class="apd-btn request-changes-btn"
                                        width="160"
                                        height="40"
                                        dark
                                        elevation="0"
                                        rounded
                                        outlined
                                        :loading="submitedLoading"
                                        @click="requestModification">
                                        Request Modifications
                                        <div v-if="!enableRequestModification" class="tooltip-wrapper">
                                            <div class="tooltip">No comments for designer</div>
                                        </div>
                                    </v-btn>
                                </div>
                                <v-btn
                                    v-if="isSubstatusInit && !isDesigner && !projectIsOnHold"
                                    :disabled="!designerSliderFiles.length || !isReadyForReview"
                                    class="apd-btn ml-0 ml-sm-4"
                                    width="160"
                                    height="40"
                                    color="#02810F"
                                    dark
                                    elevation="0"
                                    rounded
                                    @click="openApproveDialog">
                                    Approve 3D Model
                                </v-btn>

                                <v-dialog
                                    v-if="isInProgress && !projectIsOnHold"
                                    v-model="showSubmitDialog"
                                    width="620">
                                    <template v-slot:activator="{ attrs, on }">
                                        <div class="apd-btn-wrap d-inline-block ml-0 ml-sm-5">
                                            <v-btn
                                                v-if="isDesigner && isSubmited"
                                                :disabled="!isDesignerUploaded3dFile"
                                                class="apd-btn btn-h-48 pl-9 pr-9"
                                                color="#02810F"
                                                dark
                                                elevation="0"
                                                rounded
                                                v-bind="attrs"
                                                v-on="on">
                                                Submit for review
                                                <div v-if="!isDesignerUploaded3dFile" class="tooltip-wrapper">
                                                    <div class="tooltip">Missing 3D model</div>
                                                </div>
                                            </v-btn>
                                        </div>
                                    </template>

                                    <div class="dialog-overflow-wrap">
                                        <v-btn
                                            class="pci-ad-cl-btn"
                                            icon
                                            @click="showSubmitDialog = false">
                                            <v-img
                                                :src="require('@/assets/icons/ic-close-modal.svg')"
                                                contain
                                                height="36"
                                                max-width="36"/>
                                        </v-btn>
                                        <v-card class="pa-12">
                                            <div class="d-flex justify-space-between align-center mb-4">
                                                <v-card-title class="px-0 py-0 blueish">
                                                    Ready to Submit for Client Review?
                                                </v-card-title>
                                            </div>
                                            <p class="grey-lightend">
                                                Submitting this model will notify the client that a design is ready for them to view. All previously uploaded versions will be deleted. The newest one will be sent to client.
                                            </p>
                                            <div
                                                class="d-flex justify-space-between align-center mt-12">
                                                <v-btn
                                                    text
                                                    color="#0046AF"
                                                    @click="showSubmitDialog = false">
                                                    Return
                                                </v-btn>
                                                <v-btn
                                                    class="apd-btn btn-h-48 ml-0 ml-sm-5 pl-9 pr-9"
                                                    color="#02810F"
                                                    dark
                                                    width="225"
                                                    elevation="0"
                                                    rounded
                                                    :loading="submitedLoading"
                                                    @click="submitToClient">
                                                    Submit Model
                                                </v-btn>
                                            </div>
                                        </v-card>
                                    </div>
                                </v-dialog>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </project-footer>

        <v-dialog
            v-model="approveDialog"
            max-width="640"
            @click:outside="closeApproveDialog">
            <div class="dialog-overflow-wrap">
                <v-btn
                    class="dialog-cl-btn"
                    icon
                    @click="closeApproveDialog">
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"/>
                </v-btn>
                <v-card class="dialog-overflow-cont pa-5 pa-sm-10">
                    <h2 class="dialog-ttl">
                        Approve <strong>{{ project.title }}</strong> Model?
                    </h2>
                    <div class="dialog-txt mt-4">
                        Approving this model will take you to the render uploading stage. This step is <strong>undoable</strong>.
                    </div>
                    <v-card-actions class="pa-0 mt-10">
                        <div
                            class="def-blue-link fs-16"
                            @click="closeApproveDialog">
                            Return to Compare Model
                        </div>
                        <v-spacer/>
                        <v-btn
                            v-if="isSubstatusInit"
                            :loading="approving"
                            class="btn-h-48 bw-3 fs-16 pl-4 pr-4 pl-sm-10 pr-sm-10"
                            color="#3F7359"
                            dark
                            elevation="0"
                            large
                            rounded
                            @click="approveDesign">
                            Approve and Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>

        <dialog-delete-project-file
            :delete-modal-data="deleteModalData"
            @cancel="toggleDeleteModal"
            @delete="deleteFile(deleteModalData.file, deleteModalData.slider, isDesigner)"/>

        <dialog-default type="alertInvalidFileType"/>

        <template v-if="isClient">
            <dialog-cancel-project
                v-model="cancelDialog"
                :project="project"/>
        </template>
        <template v-if="isManager || isAdmin">
            <dialog-cancel-project-manager
                v-model="cancelDialog"
                :project="project"/>
        </template>

        <v-snackbar
            v-model="filesLoadingSnackbar"
            :timeout="3000"
            class="project-title__snackbar"
            top>
            File upload completed
        </v-snackbar>
        <v-snackbar
            v-model="errorSubmitingToClient"
            :timeout="3000"
            color="danger"
            bottom>
            Something went wrong while submitting to client
        </v-snackbar>
        <v-snackbar
            v-model="submitedToClient"
            :timeout="3000"
            class="project-title__snackbar"
            bottom>
            Success! Model uploaded and submitted to client.
        </v-snackbar>
        <v-snackbar
            v-model="fileDeleteSnackbar"
            :timeout="3000"
            class="project-title__snackbar"
            top>
            File deleted
        </v-snackbar>
    </v-container>
</template>

<script>
    import Breadcrumbs from "@/components/Breadcrumbs";
    import ProjectStepDropdown from "@/components/ProjectStepDropdown"
    import UploadService from "@/api/uploadFilesService.api";
    import { projectApi, commentApi } from "@/api";
    import { extend, ValidationProvider } from "vee-validate";
    import { size } from "vee-validate/dist/rules";
    import { mapGetters } from "vuex";
    import DialogDefault from "../ui/dialog-default";
    import DialogCancelProject from "../ui/dialog-cancel-project";
    import DialogCancelProjectManager from "../ui/dialog-cancel-project-manager";
    import DialogDeleteProjectFile from "../ui/dialog-delete-project-file";
    import ReassignView from "../../views/manager/modelers-view/ReassignView";
    import ProductSlider from "@/components/ProductSlider";
    import ProjectHeader from "@/components/project/ProjectHeader";
    import ProjectFooter from "@/components/project/ProjectFooter";
    import ProjectInProgressInfo from "@/components/project/ProjectInProgressInfo";
    import { MbToKb } from "@/utils/index.utils";
    import config from "@/utils/config";

    function hasExtension(file = {}, exts = []) {
        var fileName = file.name || file.original_name;
        const result = new RegExp(
            "(" + exts.join("|").replace(/\./g, "\\.") + ")$"
        ).test(fileName.toLowerCase());
        if (result) {
            return result;
        }
    }

    const STATUS_INITIAL = 0,
          STATUS_SAVING = 1,
          STATUS_SUCCESS = 2,
          STATUS_FAILED = 3;

    extend("size", {
        ...size,
        message: `The File field size must be less than ${config.fileSizeLimit}MB`
    });

    export default {
        name: "project-in-progress",
        components: {
            ProjectInProgressInfo,
            ProjectFooter,
            DialogDefault,
            DialogCancelProjectManager,
            DialogCancelProject,
            DialogDeleteProjectFile,
            Breadcrumbs,
            ValidationProvider,
            ProjectStepDropdown,
            ProductSlider,
            ReassignView,
            ProjectHeader
        },

        data() {
            return {
                initialLoading: true,
                designerSliderFiles: [],
                clientSliderFiles: [],
                allFiles:[],

                reassignModelerDialog: false,
                errorSubmitingToClient: false,
                submitedLoading: false,
                isSubmited: true,
                submitedToClient: false,
                showSubmitDialog: false,
                dialog: false,
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    },
                    {
                        text: ""
                    }
                ],
                projectStepsLinks: null,

                // File upload
                uploadedCount: 0,
                currentStatus: STATUS_INITIAL,
                // File upload

                fileInfos: [],
                userUploadedFilesId: [],
                userUploadedFiles: [],
                filesLoading: false,
                filesLoadingSnackbar: false,
                uploadFileSize: MbToKb(config.fileSizeLimit),

                approveDialog: false,

                fileDeleteSnackbar: false,
                cancelDialog: false,
                approving: false,

                uploadingFileProgress: null,

                roundMenu: false,
                rounds: [],
                filesByRounds: {},
                selectedRound: 1,
                selectedFile: null,
                selectedFileVersions: [],
                lastRoundFiles: [],
                deleteModalData: {
                    showModal: false,
                    file: null,
                    slider: ""
                },
                isReplyLoading: false
            };
        },
        provide() {
            const isReplyLoading = {}

            Object.defineProperty(isReplyLoading, "loading", {
                enumerable: true,
                get: () => this.isReplyLoading
            })

            return {
                isReplyLoading
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManager",
                "isAdmin",
                "isDesigner",
                "profile",
                "getProjectData",
                "getValueProjectStatus",
                "isAdminProject",
                "isManagerOrAdmin",
                "projectIsOnHold"
            ]),

            currentLink(){
                return this.projectStepsLinks ? this.projectStepsLinks.find(link => link.to.name === this.$route.name) || this.projectStepsLinks : null
            },

            otherFormatsFile() {
                return this.isDesigner ? this.$config.allowedFileExtension.others_for_designer : this.$config.allowedFileExtension.others
            },
            designerAcceptExtension() {
                return [...this.$config.allowedFileExtension.models, ...this.otherFormatsFile].join(", ")
            },
            mapExts() {
                return {
                    [this.$config.fileTypes.reference]: this.$config.allowedFileExtension.images,
                    [this.$config.fileTypes.models]: this.$config.allowedFileExtension.models,
                    [this.$config.fileTypes.others]: this.otherFormatsFile
                };
            },
            extendedModeForDesignerView() {
                return !this.projectIsOnHold && (!this.isDesigner && this.isReadyForReview || this.isCurrentCustomerProjectsDesigner && this.isInProgress && this.selectedRound === this.currentRound);
            },
            initiallyOpenedComment() {
                const routeHasQuery = this.$route.query && this.$route.query.showComment;
                if (routeHasQuery) {
                    return this.$route.query.showComment;
                } else {
                    return false;
                }
            },
            project() {
                return this.$store.getters.getProjectData;
            },
            allowedEditDescription() {
                return !this.projectIsOnHold && (this.isClient || this.isAdminProject) && !this.isDesigner && (this.isDraftOrCreated || this.isInProgress);
            },
            isDraft() {
                return this.project.status_id === this.$config.project.status.draft
            },
            isCreated() {
                return this.project.status_id === this.$config.project.status.created
            },
            isDraftOrCreated() {
                return this.isDraft || this.isCreated
            },
            isCompleted() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.completed);
            },
            isInProgress() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.in_progress);
            },
            isReadyForReview() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.ready_for_review);
            },
            isInQA() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.in_qa);
            },

            isCurrentCustomerProjectsAuthor(){
                const projectsAuthorId = this.getProjectData ? this.getProjectData.user_id : null
                const currentCustomerId = this.profile ? this.profile.id : null

                return projectsAuthorId === currentCustomerId || this.isAdminProject && this.isManagerOrAdmin
            },
            isCurrentCustomerProjectsDesigner(){
                const projectsDesignerId = this.getProjectData ? this.getProjectData.designer_id : null
                const currentCustomerId = this.profile ? this.profile.id : null

                return projectsDesignerId === currentCustomerId
            },

            isSubstatusInit() {
                return (
                    this.project.substatus === 0 || this.project.substatus === "Initial"
                );
            },
            currentRound() {
                return this.getProjectData.current_round.round;
            },
            isDesignerUploaded3dFile() {
                let files = this.lastRoundFiles.filter(file => file.created_by === this.project.designer_id);
                return files.some(file => hasExtension(file, [...this.mapExts[this.$config.fileTypes.models], ...this.mapExts[this.$config.fileTypes.others]]));
            },
            enableRequestModification() {
                return this.designerSliderFiles.some(file => file.round === null && file.created_by === this.profile.id && file.comments.length);
            },
            getProjectOwner() {
                return !this.isDesigner ? this.project.user : this.project.user.split(" ")[0];
            }
        },
        async created() {

            const { in_progress_rendering, ready_for_review_renders, in_qa, in_cqa } = this.$config.project.status
            const statuses = [in_progress_rendering, ready_for_review_renders, in_qa, in_cqa]
            if(statuses.includes(this.project.status_id) && this.isClient) {
                return this.$router.replace({ name: "render-review", params: { id: this.$route.params.id } })
            }
            this.$store.commit("setIsInProjectView", true);
            if(this.isClient || this.isManagerOrAdmin) {
                await this.$store.dispatch("getAssets", this.project.id);
            }
            this.allFiles = await projectApi.getFiles(this.$route.params.id);
            this.setFiles();
            this.setProjectStepsLinks();

            this.initialLoading = false;
        },
        mounted() {
            this.breadcrumbs[1].text = this.project.title;
        },
        destroyed() {
            this.$store.commit("setIsInProjectView", false);
        },
        methods: {
            setFiles() {
                let designerSliderFiles = [];
                let clientSliderFiles = [];

                const rounds = new Set(this.allFiles.reduce((acc, item) => {
                    if (item.round !== null) {
                        acc.push(item.round)
                    }
                    return acc;
                }, []));

                this.rounds = rounds.size !== 0 ? [...rounds].sort((a, b) => a - b) : [this.currentRound];
                this.selectedRound = this.currentRound;

                this.allFiles.forEach((file) => {
                    const fileComments = this.getProjectData.comments[file.id];
                    let formatedComments = [];
                    for (const key in fileComments) {
                        const element = fileComments[key];
                        formatedComments.push(...element);
                    }
                    file.comments = formatedComments;

                    const fileAttachedToDesignersSlider = file.props && file.props[0] && file.props[0].attachToDesigner;
                    const fileAttachedToManagerSlider = file.props && file.props[0] && file.props[0].attachToManager;
                    if (this.isDesigner && fileAttachedToDesignersSlider && file.round === this.selectedRound ||
                        !this.isDesigner && !this.isInProgress && fileAttachedToDesignersSlider && (!file.round || (file.round === this.selectedRound && file.type))  ||
                        !this.isDesigner && this.isInProgress && file.created_by === this.profile.id && fileAttachedToDesignersSlider) {
                        designerSliderFiles.push(file);
                    } else if (!fileAttachedToDesignersSlider && !fileAttachedToManagerSlider) {
                        clientSliderFiles.push(file);
                    }
                });

                this.setFilesByRounds(this.allFiles.filter(file => file.round !== null));

                if (this.filesByRounds[this.selectedRound]) {
                    const firstFileInRound = this.filesByRounds[this.selectedRound][0];
                    if (firstFileInRound?.rounds?.length) {
                        this.selectedFile = firstFileInRound.rounds[firstFileInRound.rounds.length-1];
                    } else {
                        this.selectedFile = this.filesByRounds[this.selectedRound][0];
                    }
                }

                this.setSelectedFileVersions();

                this.clientSliderFiles = clientSliderFiles;
                this.designerSliderFiles = designerSliderFiles.map(slide => {
                    if (slide.rounds.length) {
                        return slide.rounds[slide.rounds.length-1];
                    }
                    return slide;
                });
            },
            setFilesByRounds(files) {
                files.forEach(file => {
                    file.round_base_file_id = file.id;
                    if (this.filesByRounds[file.round]) {
                        this.filesByRounds[file.round] = [...this.filesByRounds[file.round], file];
                    } else {
                        this.filesByRounds[file.round] = [file];
                    }
                });
                this.lastRoundFiles = this.filesByRounds[this.currentRound] || [];
            },
            setSelectedFileVersions() {
                if (this.filesByRounds[this.selectedRound]) {
                    this.selectedFileVersions = this.filesByRounds[this.selectedRound].filter(file => file.round_base_file_id === this.selectedFile.round_base_file_id);
                }
                this.lastRoundFiles = this.filesByRounds[this.currentRound] || [];
            },
            changeRound(round) {
                if (round === this.selectedRound) return;

                this.selectedRound = round;
                this.designerSliderFiles = this.filesByRounds[this.selectedRound];

                const firstFileInRound = this.designerSliderFiles[0];
                if (firstFileInRound?.rounds?.length) {
                    this.selectedFile = firstFileInRound.rounds[firstFileInRound.rounds.length-1];
                    this.designerSliderFiles = this.designerSliderFiles.map(slide => {
                        if (slide?.rounds?.length) {
                            return slide.rounds[slide.rounds.length-1];
                        }
                        return slide;
                    });
                } else {
                    this.selectedFile = firstFileInRound;
                }

                this.setSelectedFileVersions();
            },
            changeFileVersion(file) {
                this.selectedFile = file;
                this.designerSliderFiles = this.designerSliderFiles.map(sliderFile => sliderFile.round_base_file_id === file.round_base_file_id ? file : sliderFile);
            },
            setModelPreview(data) {
                const { slider, file } = data;
                if (slider === "clients") {
                    this.clientSliderFiles = this.clientSliderFiles.map(slide => slide.id === file.id ? file : slide);
                } else {
                    this.designerSliderFiles = this.designerSliderFiles.map(slide => slide.id === file.id ? file : slide);
                }
            },
            async addReply(reply, slider) {
                this.isReplyLoading = true
                try {
                    const response = await commentApi.reply(reply.content.parent_id, {
                        canvas_data: [],
                        comment: reply.content.comment,
                        comment_id: +new Date,
                        file_id: reply.fileId,
                        parent_id: reply.content.parent_id,
                        project_id: this.$route.params.id,
                        type: 1,
                        username: this.$store.getters.profile.firstName + " " +this.$store.getters.profile.lastName
                    })

                    if (slider === "clients") {
                        this.clientSliderFiles.forEach((file, index) => {
                            if (file.id === response.file_id) {
                                if (file.comments && Array.isArray(file.comments)) {
                                    const commentsList = file.comments;
                                    commentsList.push(response);
                                    this.$set(this.clientSliderFiles[index], "comments", commentsList);
                                } else {
                                    this.$set(this.clientSliderFiles[index], "comments", [response]);
                                }
                            }
                        });
                    } else if(slider === "designers") {
                        this.designerSliderFiles.forEach((file, index) => {
                            if (file.id === response.file_id) {
                                if (file.comments && Array.isArray(file.comments)) {
                                    const commentsList = file.comments;
                                    this.$set(this.designerSliderFiles[index], "comments", commentsList);
                                } else {
                                    this.$set(this.designerSliderFiles[index], "comments", [response]);
                                }
                            }
                        });
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    this.isReplyLoading = false
                }

            },
            updateFile({ fileIndex, canvas_data }, slider) {
                if(slider === "designers") {
                    if (this.designerSliderFiles[fileIndex]) {
                        this.$set(this.designerSliderFiles[fileIndex], "canvas_data", canvas_data);
                    }
                } else if (slider === "clients") {
                    if (this.clientSliderFiles[fileIndex]) {
                        this.$set(this.clientSliderFiles[fileIndex], "canvas_data", canvas_data);
                    }
                }
            },
            async addComment(comment, slider) {
                const createdComment = await commentApi.create({
                    comment_id: +new Date(),
                    canvas_data: [
                        {
                            brush_width: comment.brush_width,
                            canvas_width: comment.canvas_width,
                            canvas_height: comment.canvas_height,
                            points: comment.points
                        }
                    ],
                    type: this.$config.commentType.comment,
                    username:
                        this.$store.getters.profile.firstName +
                        " " +
                        this.$store.getters.profile.lastName,
                    comment: comment.text,
                    parent_id: null,
                    project_id: parseInt(this.$route.params.id),
                    file_id: comment.fileId,
                    x: Math.round(comment.position.x),
                    y: Math.round(comment.position.y)
                });

                if (slider === "clients") {
                    console.log("ADD comment", slider);
                    this.clientSliderFiles.forEach((file, index) => {
                        if (file.id === createdComment.file_id) {
                            if (file.comments && Array.isArray(file.comments)) {
                                console.log("Comments is Array");
                                const commentsList = file.comments;
                                commentsList.push(createdComment);
                                this.$set(
                                    this.clientSliderFiles[index],
                                    "comments",
                                    commentsList
                                );
                            } else {
                                console.log("Comments is NOT Array");
                                this.$set(this.clientSliderFiles[index], "comments", [
                                    createdComment
                                ]);
                            }
                        }
                    });
                } else if (slider === "designers") {
                    this.designerSliderFiles.forEach((file, index) => {
                        if (file.id === createdComment.file_id) {
                            if (file.comments && Array.isArray(file.comments)) {
                                const commentsList = file.comments;
                                commentsList.push(createdComment);
                                this.$set(
                                    this.designerSliderFiles[index],
                                    "comments",
                                    commentsList
                                );
                            } else {
                                this.$set(this.designerSliderFiles[index], "comments", [
                                    createdComment
                                ]);
                            }
                        }
                    });
                }
            },

            closeDialog() {
                this.$store.dispatch("loadProject");
                this.reassignModelerDialog = false;
            },
            async requestModification() {
                this.submitedLoading = true;
                const clientReferences = this.designerSliderFiles.filter(file => file.created_by === this.profile.id && (!file.round || file.round === this.selectedRound));

                const res = await projectApi.requestModification(this.project.id, {
                    files: clientReferences.filter(ref => !ref.round).map(file => file.id)
                });

                this.designerSliderFiles = clientReferences;
                await this.$store.dispatch("updateProjectData", {
                    current_round: res.data.data.current_round,
                    status: this.getValueProjectStatus(this.$config.project.status.in_progress)
                });
                this.submitedLoading = false;
            },
            async submitToClient() {
                const lastUpdatedFiles = this.lastRoundFiles.map(file => {
                    if (file.rounds.length) {
                        return file.rounds[file.rounds.length - 1]
                    } else {
                        return file;
                    }
                });

                this.designerSliderFiles = lastUpdatedFiles;
                this.filesByRounds[this.currentRound] = lastUpdatedFiles;
                this.selectedFile = lastUpdatedFiles.find(file => file.round_base_file_id === this.selectedFile.round_base_file_id);
                this.setSelectedFileVersions();

                await this.$store.dispatch("updateProjectData", {
                    status: this.getValueProjectStatus(this.$config.project.status.in_qa)
                });
                this.submitedLoading = true;

                try {
                    await projectApi.submitToClient(this.project.id);
                    this.submitedToClient = true;
                    this.showSubmitDialog = false;
                    this.submitedLoading = false;
                    this.isSubmited = false;
                } catch (e) {
                    this.showSubmitDialog = false;
                    this.submitedLoading = false;
                    this.errorSubmitingToClient = true;
                }
            },
            async approveDesign() {
                const data = {
                    id: 1,
                    user_id: this.project.user_id,
                    designer_id: this.project.designer_id,
                    manager_id: this.project.manager_id,
                    title: this.project.title,
                    description: this.project.description,
                    type: this.project.type,
                    status: this.project.status,
                    designer_amount: this.project.designer_amount,
                    links: this.project.links,
                    estimated_date: this.project.estimated_date,
                    created_at: this.project.created_at,
                    updated_at: this.project.updated_at
                };

                this.approving = true;

                try {
                    await projectApi.approveDesign(this.project.id, data)
                    await this.$store.dispatch("updateProjectData", {
                        status: this.getValueProjectStatus(this.$config.project.status.in_progress_rendering)
                    });
                    await this.$router.push({ name: "render-review", params: { id: this.$route.params.id } })
                } catch (err) {
                    console.error(err);
                } finally {
                    this.approving = false;
                    this.closeApproveDialog();
                }
            },

            openApproveDialog() {
                this.approveDialog = true;
            },
            closeApproveDialog() {
                this.approveDialog = false;
            },
            toggleDeleteModal(file = null, slider = "") {
                this.deleteModalData = {
                    showModal: !this.deleteModalData.showModal,
                    file, slider
                };
            },

            // File upload
            reset() {
                this.currentStatus = STATUS_INITIAL;
            },

            async replaceFile(fileData, slider) {
                const removedFile = await UploadService.removeFile(fileData.target);
                if(removedFile) {
                    await UploadService.updateProps(fileData.newFile.id, [{ attachToDesigner: slider==="designers" }]);
                    await projectApi.bindFile(this.$route.params.id, { files:[fileData.newFile.id] });

                    if (slider === "clients") {
                        const fileIndex = this.clientSliderFiles.findIndex(file=>file.id === fileData.target)
                        this.clientSliderFiles.splice(fileIndex, 1 , fileData.newFile)
                    } else if (slider === "designers") {
                        const fileIndex = this.designerSliderFiles.findIndex(file=>file.id === fileData.target)
                        this.designerSliderFiles.splice(fileIndex, 1 , fileData.newFile)
                    }
                }
            },
            async deleteFile(fileData, slider, allVersions = false) {
                const removedFile = await UploadService.removeFile(fileData.id, allVersions);

                if (removedFile) {
                    if (slider === "clients") {
                        this.clientSliderFiles = this.clientSliderFiles.filter(file=>file.id !== fileData.id);
                    } else if (slider === "designers") {
                        if (allVersions) {
                            const newFileList = this.filesByRounds[this.selectedRound].filter(file => file.id !== fileData.round_base_file_id);
                            this.filesByRounds[this.selectedRound] = [...newFileList];
                            this.designerSliderFiles = [...newFileList];
                            this.selectedFile = newFileList[0];
                            this.setSelectedFileVersions();
                        } else {
                            let newFileList = []
                            if(!fileData.round_base_file_id) {
                                newFileList = this.designerSliderFiles.filter(file => file.id !== fileData.id)
                            } else {
                                newFileList = this.filesByRounds[this.selectedRound].map(file => {
                                    if (file.id === fileData.round_base_file_id) {
                                        return {
                                            ...file,
                                            rounds: file.rounds.filter((fileVersion, idx, arr) => {
                                                if (fileVersion.id === fileData.id) {
                                                    this.selectedFile = arr.length > 1 ? arr[idx-1] || arr[idx+1] : file;
                                                } else {
                                                    return fileVersion;
                                                }
                                            })
                                        }
                                    } else {
                                        return file;
                                    }
                                })
                            }
                            this.designerSliderFiles = [...newFileList];
                            this.filesByRounds[this.selectedRound] = [...newFileList];
                            this.setSelectedFileVersions();
                        }
                    }
                }

                if (this.deleteModalData.file) {
                    this.toggleDeleteModal();
                }
            },

            async selectFile(event, typeImage= [], forceAttachToDesignerSlider = false) {
                if (!event.target.files || !event.target.files.length) {
                    return;
                }
                const validateModel = await this.$refs.provider.validate(event);
                const fileInfosCount = this.fileInfos.length;
                if (!validateModel.valid) {
                    return;
                }
                const invalidArr = [];

                const allowedExts = typeImage.flatMap(type => this.mapExts[type])

                event.target.files.forEach((item) => {
                    item.percentage = 0;
                    item.reloadCount = 1;

                    for (let key in this.mapExts) {
                        const fileExtension = "." + item.name.split(".").slice(-1).join("").toLowerCase();
                        const fileExtensionMatchAllowedType = this.mapExts[key].includes(fileExtension);

                        if (fileExtensionMatchAllowedType) {
                            item.typeImage = parseInt(key);
                        }
                    }

                    if (hasExtension(item, allowedExts)) {
                        this.fileInfos.push(item);
                    } else {
                        invalidArr.push(item.name);
                    }
                });

                if (invalidArr.length > 0) {
                    const type = "alertInvalidFileType";
                    this.$store.state.dialogs.content[type].text = `Sorry, ${invalidArr.length === 1 ? "file" : "files"}
                        "${invalidArr.join(",")}" is invalid, allowed extensions are: ${allowedExts.join(", ")}`;
                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                }
                event.target.value = "";
                this.uploadedCount = event.target.files.length;
                if (this.fileInfos.length !== fileInfosCount) {
                    this.uploadFiles(forceAttachToDesignerSlider);
                }
            },
            async upload(idx, file, forceAttachToDesignerSlider) {
                this.currentStatus = STATUS_SAVING;
                try {
                    let data = await UploadService.upload(file, (event) => {
                        const uploadProgeressPercentage = Math.round(
                            (100 * event.loaded) / event.total
                        );
                        file.percentage = uploadProgeressPercentage;

                        if (uploadProgeressPercentage < 100) {
                            this.uploadingFileProgress = uploadProgeressPercentage;
                        }
                    });
                    const isTypeModelsOrOthers = data.type === this.$config.fileTypes.models || data.type === this.$config.fileTypes.others
                    if ((this.isDesigner && isTypeModelsOrOthers) || forceAttachToDesignerSlider) {
                        data = await UploadService.updateProps(data.id, [
                            { attachToDesigner: true }
                        ]);
                        this.designerSliderFiles.push(data)
                    } else if (this.isClient && !forceAttachToDesignerSlider) {
                        this.clientSliderFiles.push(data)
                    }

                    this.fileInfos.splice(idx, 1, data);
                    if (this.$store.getters.getProjectID > 0) {
                        if (data?.id) {
                            this.userUploadedFilesId.push(data.id);
                            this.userUploadedFiles.push(data);
                        }
                    }
                } catch {
                    file.percentage = 0;
                    this.uploadedCount++;
                    file.message = "Could not upload the file:" + file.name;
                    if (file.reloadCount > 100) {
                        //auto delete
                        this.fileInfos.splice(idx, 1);
                    } else if (!file.deleted) {
                        file.reloadCount++;
                        file.reload = setTimeout(
                            () => this.upload(idx, file),
                            2000 * file.reloadCount
                        );
                    }
                }
                this.uploadedCount--;
                if (this.uploadedCount <= 0) {
                    this.reset();
                }
                this.uploadingFileProgress = null;
            },
            uploadFiles(forceAttachToDesignerSlider) {
                const arr = [];
                this.fileInfos.forEach((file, idx) => {
                    if (file.typeImage !== undefined) {
                        arr.push(this.upload(idx, file, forceAttachToDesignerSlider));
                    }
                })

                Promise.allSettled(arr).then(async () => {
                    await this.addProjectFiles();
                });
            },
            async addProjectFiles() {
                let res = false;
                this.filesLoading = true;
                let data = {
                    files: this.userUploadedFilesId
                };

                if (this.isDesigner) {
                    let uploadedFile = null;
                    res = await projectApi.bindFileToRound(this.project.id, {
                        files: this.userUploadedFilesId,
                        round: this.selectedRound,
                        version: this.generateFileVersion()
                    });
                    this.designerSliderFiles = this.designerSliderFiles.map(file => {
                        const userUploadedFile = this.userUploadedFiles.find(uploadedFile => file.id === uploadedFile.id)
                        if (userUploadedFile) {
                            uploadedFile = res.data.data.find(item => item.id === userUploadedFile.id);
                            uploadedFile.round_base_file_id = uploadedFile.id;
                            return uploadedFile;
                        }
                        return file;
                    });
                    this.setFilesByRounds([...this.designerSliderFiles]);
                    this.selectedFile = uploadedFile;
                    this.setSelectedFileVersions();
                } else {
                    res = await projectApi.bindFile(this.$route.params.id, data);
                }

                if (res) {
                    this.userUploadedFilesId = [];
                    this.userUploadedFiles = [];
                    this.filesLoading = false;
                    this.filesLoadingSnackbar = true;
                }
            },
            async addSnapshot(snapshot, slider) {
                const file = snapshot[0];
                file.typeImage = this.$config.fileTypes.reference;

                let bindedToProject = null;
                const response = await UploadService.upload(file);
                const responseFile = await UploadService.updateProps(response.id, [{ isSnapshot: true, attachToDesigner: slider==="designers" }]);

                if (slider === "designers" && this.isDesigner) {
                    responseFile.round_base_file_id = response.id;
                    responseFile.version = this.generateFileVersion();
                    bindedToProject = await projectApi.bindFileToRound(this.project.id, {
                        files: [responseFile.id],
                        round: this.selectedRound,
                        version: responseFile.version
                    });
                    this.filesByRounds[this.selectedRound] = [...this.filesByRounds[this.selectedRound], responseFile];
                    this.selectedFile = responseFile;
                } else {
                    bindedToProject = await projectApi.bindFile(this.$route.params.id, { files: [responseFile.id] });
                }

                if(bindedToProject){
                    let sliderComponent = null

                    if(slider === "designers") {
                        sliderComponent = this.$refs["designers-slider"]
                        this.designerSliderFiles.push(responseFile)
                        setTimeout(()=>{
                            sliderComponent.slideTo(this.designerSliderFiles.length-1)
                        }, 50)
                    } else {
                        sliderComponent = this.$refs["clients-slider"]
                        this.clientSliderFiles.push(responseFile)
                        setTimeout(()=>{
                            sliderComponent.slideTo(this.clientSliderFiles.length-1)
                        }, 50)
                    }
                }
            },
            async bindFileToProject(file, slider) {
                let sliderComponent = null;
                if (slider === "designers") {
                    const originalModel = this.filesByRounds[this.selectedRound].find(item => item.id === file.round_base_file_id);
                    file.version = this.generateFileVersion(originalModel?.rounds?.length ? originalModel.rounds[originalModel.rounds.length - 1] : originalModel);
                    file.comments = [];

                    const response = await projectApi.bindFileToRound(this.project.id, {
                        files: [file.id],
                        round: this.selectedRound,
                        version: file.version
                    });

                    await UploadService.updateProps(file.id, [{ attachToDesigner: true }]);
                    if (!response) return;

                    if (file.round_base_file_id) {
                        originalModel.rounds.push(file);
                        this.filesByRounds[this.selectedRound] = this.filesByRounds[this.selectedRound].map(item => {
                            return item.id === file.round_base_file_id ? originalModel : item;
                        });
                        file.round = file.round || this.selectedRound
                        this.selectedFile = originalModel.rounds[originalModel.rounds.length - 1];
                        this.setSelectedFileVersions();
                        this.changeFileVersion(file);
                    } else {
                        file.round_base_file_id = file.id;
                        file.round = file.round || this.selectedRound
                        sliderComponent = this.$refs["designers-slider"];
                        this.filesByRounds[this.selectedRound] = [...this.filesByRounds[this.selectedRound], file];
                        this.selectedFile = file;
                        this.designerSliderFiles.push(file);
                        this.setSelectedFileVersions();

                        setTimeout(() => {
                            sliderComponent.slideTo(this.designerSliderFiles.length - 1);
                        }, 50);
                    }
                } else {
                    const response = await projectApi.bindFile(this.$route.params.id, { files:[file.id] });
                    if (!response) return;

                    sliderComponent = this.$refs["clients-slider"]
                    this.clientSliderFiles.push(file)
                    setTimeout(()=>{
                        sliderComponent.slideTo(this.clientSliderFiles.length-1)
                    }, 50)
                }
            },
            async deleteComment({ file, comment }, slider){
                console.log("File and comment: ",  file, comment);

                const deleted = await commentApi.remove(comment)

                if(slider==="designers"){
                    const fileIndex = this.designerSliderFiles.findIndex(slide=>slide.id == file);
                    const commentIndex = this.designerSliderFiles[fileIndex].comments.findIndex(c=>c.id == comment);
                    if(deleted){
                        const updatedComments = this.designerSliderFiles[fileIndex].comments;
                        updatedComments.splice(commentIndex,1)
                        this.$set(this.designerSliderFiles[fileIndex], "comments", updatedComments)
                    }
                } else if(slider==="clients") {
                    const fileIndex = this.clientSliderFiles.findIndex(slide=>slide.id == file);
                    const commentIndex = this.clientSliderFiles[fileIndex].comments.findIndex(c=>c.id == comment);
                    if(deleted){
                        const updatedComments = this.clientSliderFiles[fileIndex].comments;
                        updatedComments.splice(commentIndex,1)
                        this.$set(this.clientSliderFiles[fileIndex], "comments", updatedComments)
                    }
                }
            },

            async deleteComments(commentsData, slider){
                const commentsToStay = commentsData.commentsId
                let fileComments = null
                if(slider === "designers"){
                    fileComments = this.designerSliderFiles[this.designerSliderFiles.findIndex(slide=>slide.id === commentsData.file)].comments;
                } else if(slider === "clients") {
                    fileComments = this.clientSliderFiles[this.clientSliderFiles.findIndex(slide=>slide.id === commentsData.file)].comments;
                }
                if(fileComments){
                    const commentsToDelete = fileComments.filter(comment=>{
                        return !commentsToStay.includes(comment.id);
                    })
                    if(commentsToDelete && commentsToDelete.length>0) {
                        commentsToDelete.forEach(comment=>{
                            this.deleteComment({ file: commentsData.file, comment: comment.id })
                        })
                    }
                }

            },
            async updateComment(commentData, slider){
                const updatedComment = await commentApi.update(commentData.comment.id, {
                    comment: commentData.comment.text
                });

                if(slider === "designers"){
                    const fileIndex = this.designerSliderFiles.findIndex(slide=>slide.id == commentData.file);
                    const commentIndex = this.designerSliderFiles[fileIndex].comments.findIndex(comment=>comment.id == commentData.comment.id);

                    const updatedComments = this.designerSliderFiles[fileIndex].comments;
                    updatedComments.splice(commentIndex,1,updatedComment)
                    this.$set(this.designerSliderFiles[fileIndex], "comments", updatedComments)
                } else {
                    const fileIndex = this.clientSliderFiles.findIndex(slide=>slide.id == commentData.file);
                    const commentIndex = this.clientSliderFiles[fileIndex].comments.findIndex(comment=>comment.id == commentData.comment.id);

                    const updatedComments = this.clientSliderFiles[fileIndex].comments;
                    updatedComments.splice(commentIndex,1,updatedComment)
                    this.$set(this.clientSliderFiles[fileIndex], "comments", updatedComments)
                }
            },

            generateFileVersion(file = null) {
                if (file) {
                    const currentVersion = parseInt(file.version.replace(/^[^.]*./, ""), 10);
                    return `${this.selectedRound}.${currentVersion+1}`
                }
                return `${this.selectedRound}.0`;
            },
            setProjectStepsLinks() {
                const { in_progress, ready_for_review, in_progress_rendering, ready_for_review_renders } = this.$config.project.status;


                this.projectStepsLinks = [
                    {
                        to: { name: "project", params: { id: this.$route.params.id } },
                        title: "References & Comments",
                        disabled: false,
                        visible: !this.isClient
                    },
                    {
                        to: { name:"project-assets", params: { id: this.$route.params.id } },
                        title: "Final Assets",
                        disabled: [in_progress, ready_for_review, in_progress_rendering, ready_for_review_renders].includes(this.getProjectData.status_id),
                        visible: !this.isDesigner
                    },
                    {
                        to: { name: this.isClient ? "project" : "render-review", params: { id: this.$route.params.id } },
                        title: "Render Review",
                        disabled: [in_progress, ready_for_review].includes(this.getProjectData.status_id),
                        visible: !this.isDesigner
                    }
                ].filter(link => link.visible);
            }
        }
    };
</script>

<style lang="scss" scoped>

.nav-wrapper {
    display: flex;
    align-items: center;
}

.project-step-dd {
    margin-left: 22px;
}

.bs-back-link {
  font-size: 14px;
  line-height: 18px;
  color: #3f7359;

  .circle {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 1px solid #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;

    img {
      display: block;
      width: 5px;
      height: 10px;
    }
  }
}

.support-link {
  font-size: 14px;
  line-height: 18px;
  color: #3F7359;
  text-decoration: underline;
  margin-right: 30px;
}

.files-group-placeholder {
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-bottom: 1px solid #bcbcbc;
      padding-bottom: 10px;

    .title {
      font-size: 14px !important;
      line-height: 18px;
      color: #464646;
      font-family: inherit !important;
    }

    .upload-btn {
      position: relative;
      background-color: transparent;
      color: #3f7359;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      display: block;
      cursor: pointer !important;
      z-index: 1;

      input {
        position: relative;
        opacity: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .files-group-notification {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #464646;
    margin-top: 64px;
  }
}

.project-scroll-section {

  .def-page-cont {
    max-width: 1200px;
  }
}

.slider-column {
    position: relative;
  width: calc(50% - 32px);

  &:first-child {
    margin-right: 32px;
  }

  &:last-child {
    margin-left: 32px;
  }

  .slider-title {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #464646;
    padding: 7px 0;
    border-bottom: 1px solid #bcbcbc;
    margin-bottom: 12px;
  }

    div {
        font-size: 14px;
        img {
            transition: all .3s ease;
        }
        &.is-active img {
            transform: rotate(-180deg);
        }
    }
}

.rounds-selector-menu {
    border-radius: 5px!important;
    padding: 6px 0;
    border: 1px solid #BCBCBC;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    background-color: #fff;
    ul {
        list-style-type: none;
    }
    .rounds-selector {
        max-height: 300px;
        overflow-y: auto;
        & > li {
            margin-bottom: 4px;
            & > span {
                margin-bottom: 4px;
                font-weight: 700;
            }
        }
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        span {
            padding: 4px 16px;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }
}

.project-header {
  font-size: 24px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #464646;
}

.link-btn-bold {
  .v-btn__content,
  button .v-btn__content {
    font-weight: bold !important;
  }
}
.apd-btn {
  border: 1px solid #464646 !important;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 14px!important;
  line-height: 18px;
  letter-spacing: 0px;
  color: #464646;

    &:not(.request-changes-btn) {
        background-color: #9ff4a9 !important;
    }

  &.v-btn.v-btn--disabled {
    background-color: #e8e8e8 !important;
    color: #bcbcbc !important;
    border: 1px solid #bcbcbc !important;
  }

    .tooltip-wrapper {
        display: none;
        position: absolute;
        left: -30px;
        bottom: 22px;
        height: 72px;
        width: 180px;
        border-top: 1px solid #c4c4c4;
        background: transparent url('./../../assets/img/configurator-tooltip.png') no-repeat -2px -130px;
        background-size: 184px;
        pointer-events: none;

        .tooltip {
            position: static !important;
            transform: none;
            padding: 12px;
            color: #000;
        }
    }

    &.request-changes-btn .tooltip-wrapper {
        width: 194px;
        background-size: 198px;
        background-position: -2px -144px;
    }
}
.apd-btn-wrap:hover {
    .apd-btn.v-btn.v-btn--disabled .tooltip-wrapper {
        display: block;
    }
}
.pfi-designer {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #545454;
}
.pms-label {
  font-size: 20px;
  line-height: 32px;
  color: #0b3c49;
}
.pms-col {
  &:nth-child(2) {
  }

  @media screen and (max-width: 959px) {
  }
}
.pms-col-sep {
  @media screen and (min-width: 960px) {
    &:before {
      content: "";
      display: block;
      height: 398px;
      border-left: 1px solid #d2d2d2;
      margin-top: 80px;
    }
  }
  @media screen and (max-width: 959px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px 12px;
    &:before {
      content: "";
      display: block;
      border-top: 1px solid #d2d2d2;
    }
  }
}
.pms-img-wrap {
    position:  relative;

    &.absolute-preview {
        position: absolute;
        top: 47px;
        left: 0;
        height: calc(100% - 228px);
        padding: inherit;
        z-index: 2;

        img {
            height: 100%;
        }
    }

    .placeholder-text {
        position: absolute;
        z-index: 2;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        top: 28px;
        left: 30px
    }

  img {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      height: 454px;
      object-fit: cover;
      border-radius: 4px;
      display: block;
  }
}

.hs-edit-ttl {
  position: absolute;
  top: 5px;
  right: -20px;
  cursor: pointer;
  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.7;
  }
}
</style>

<style lang="scss">
.delete-all-versions-modal {
    border-radius: 0!important;
    background-color: #fff;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15)!important;
    color: #464646;
    h4 {
        font-size: 24px;
        line-height: 27px;
        font-weight: 600;
        color: #464646;
    }
    p {
        font-size: 18px;
        line-height: 23px;
    }
    .v-btn {
        font-size: 14px;
        letter-spacing: 0px;
        &.delete-btn {
            background-color: #9ff4a9;
        }
    }
}
.project-in-progress-page {
    .apd-btn .v-btn__content {
        font-size: 14px;
    }
}
</style>
