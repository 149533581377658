<template>
    <div ref="root">
        <slot
            :inputListeners="inputListeners"
            :inputProps="inputProps"
            :resultListListeners="resultListListeners"
            :resultListProps="resultListProps"
            :resultProps="resultProps"
            :results="results"
            :rootProps="rootProps">
            <div v-bind="rootProps">
                <div class="v-text-field__details">
                    <div class="v-messages theme--light">
                        <div class="v-messages__wrapper"/>
                    </div>
                    <div class="v-counter theme--light">
                        {{ value.length }} / {{ COMMENT_TEXT_LIMIT }}
                    </div>
                </div>
                <input
                    ref="input"
                    v-bind="inputProps"
                    v-on="$listeners"
                    @blur="handleBlur"
                    @focus="handleFocus"
                    @input="handleInput"
                    @keydown="handleKeyDown">
                <ul
                    ref="resultList"
                    v-bind="resultListProps"
                    v-on="resultListListeners">
                    <template v-for="(result, index) in results">
                        <slot
                            :props="resultProps[index]"
                            :result="result"
                            name="result">
                            <li
                                v-if="result.header"
                                :key="resultProps[index].id"
                                class="autocomplete-header">
                                {{ result.header }}
                            </li>
                            <li
                                v-else-if="result.name"
                                :key="resultProps[index].id"
                                v-bind="resultProps[index]">
                                {{ "/" + result.name }}
                            </li>
                            <!--              <li v-else style="display: none" v-bind="resultProps[index]">-->
                            <!--              </li>-->
                        </slot>
                    </template>
                </ul>
            </div>
        </slot>
    </div>
</template>

<script>
    import AutocompleteCore from "../autocomplete/AutocompleteCore.js";
    import uniqueId from "../autocomplete/util/uniqueId.js";
    import getRelativePosition from "../autocomplete/util/getRelativePosition.js";
    import debounce from "../autocomplete/util/debounce.js";

    export const COMMENT_TEXT_LIMIT = 500;

    export default {
        name: "autocomplete",
        inheritAttrs: false,

        props: {
            search: {
                type: Function,
                required: true
            },
            baseClass: {
                type: String,
                default: "autocomplete"
            },
            autoSelect: {
                type: Boolean,
                default: false
            },
            getResultValue: {
                type: Function,
                default: result => result
            },
            defaultValue: {
                type: String,
                default: ""
            },
            debounceTime: {
                type: Number,
                default: 0
            }
        },

        data () {
            const core = new AutocompleteCore({
                search: this.search,
                autoSelect: this.autoSelect,
                setValue: this.setValue,
                onUpdate: this.handleUpdate,
                onSubmit: this.handleSubmit,
                onShow: this.handleShow,
                onHide: this.handleHide,
                onLoading: this.handleLoading,
                onLoaded: this.handleLoaded
            });
            if (this.debounceTime > 0) {
                core.handleInput = debounce(core.handleInput, this.debounceTime);
            }

            return {
                COMMENT_TEXT_LIMIT,
                core,
                value: this.defaultValue,
                resultListId: uniqueId(`${this.baseClass}-result-list-`),
                results: [],
                selectedIndex: -1,
                expanded: false,
                loading: false,
                position: "below",
                resetPosition: true
            };
        },

        computed: {
            checkTextLimit() {
                if (this.value.length >= this.COMMENT_TEXT_LIMIT) {
                    return this.value.substring(0, this.COMMENT_TEXT_LIMIT - 1);
                }
                return this.value;
            },
            rootProps () {
                return {
                    class: this.baseClass,
                    style: { position: "relative" },
                    "data-expanded": this.expanded,
                    "data-loading": this.loading,
                    "data-position": this.position
                };
            },
            inputProps () {
                return {
                    class: `${this.baseClass}-input`,
                    value: this.checkTextLimit,
                    role: "combobox",
                    autocomplete: "off",
                    autocapitalize: "off",
                    autocorrect: "off",
                    spellcheck: "false",
                    "aria-autocomplete": "list",
                    "aria-haspopup": "listbox",
                    "aria-owns": this.resultListId,
                    "aria-expanded": this.expanded ? "true" : "false",
                    "aria-activedescendant":
                        this.selectedIndex > -1
                            ? this.resultProps[this.selectedIndex].id
                            : "",
                    ...this.$attrs
                };
            },
            inputListeners () {
                return {
                    input: this.handleInput,
                    keydown: this.handleKeyDown,
                    focus: this.handleFocus,
                    blur: this.handleBlur
                };
            },
            resultListProps () {
                const yPosition = this.position === "below" ? "top" : "bottom";
                return {
                    id: this.resultListId,
                    class: `${this.baseClass}-result-list`,
                    role: "listbox",
                    style: {
                        position: "absolute",
                        zIndex: 1,
                        width: "100%",
                        visibility: this.expanded ? "visible" : "hidden",
                        pointerEvents: this.expanded ? "auto" : "none",
                        [yPosition]: "100%"
                    }
                };
            },
            resultListListeners () {
                return {
                    mousedown: this.core.handleResultMouseDown,
                    click: this.core.handleResultClick
                };
            },
            resultProps () {
                return this.results.map((result, index) => ({
                    id: `${this.baseClass}-result-${index}`,
                    class: `${this.baseClass}-result`,
                    "data-result-index": index,
                    role: "option",
                    ...(this.selectedIndex === index ? { "aria-selected": "true" } : {})
                }));
            }
        },

        mounted () {
            document.body.addEventListener("click", this.handleDocumentClick);
        },

        beforeDestroy () {
            document.body.removeEventListener("click", this.handleDocumentClick);
        },

        updated () {
            if (!this.$refs.input || !this.$refs.resultList) {
                return;
            }
            if (this.resetPosition && this.results.length > 0) {
                this.resetPosition = false;
                this.position = getRelativePosition(
                    this.$refs.input,
                    this.$refs.resultList
                );
            }
            this.core.checkSelectedResultVisible(this.$refs.resultList);
        },

        methods: {
            setValue (result) {
                this.value = result ? this.getResultValue(result) : "";
            },

            handleUpdate (results, selectedIndex) {
                this.results = results;
                this.selectedIndex = selectedIndex;
                this.$emit("update", results, selectedIndex);
            },

            handleShow () {
                this.expanded = true;
            },

            handleHide () {
                this.expanded = false;
                this.resetPosition = true;
            },

            handleLoading () {
                this.loading = true;
            },

            handleLoaded () {
                this.loading = false;
            },

            handleInput (event) {
                this.value = event.target.value;
                this.$emit("input", event);
                this.core.handleInput(event);
            },
            handleKeyDown (event) {
                this.$emit("keydown", event);
                this.core.handleKeyDown(event);
            },
            handleFocus (event) {
                this.$emit("focus", event);
                this.core.handleFocus(event);
            },
            handleBlur (event) {
                this.$emit("blur", event);
                this.core.handleBlur(event);
            },
            handleSubmit (selectedResult) {
                this.$emit("submit", selectedResult);
            },

            handleDocumentClick (event) {
                if (this.$refs.root.contains(event.target)) {
                    return;
                }
                this.core.hideResults();
            }
        }
    };
</script>
