<template>
    <div class="drop-down" v-on-clickaway="away">
        <div class="selected item" @click="opened = !opened">
            <div class="name">{{ selected }}</div>
            <button>
                <img src="@/assets/icons/ic-arrow-down.svg">
            </button>
        </div>
        <div class="options" v-show="opened">
            <div
                class="item"
                v-for="(item, index) of items"
                :key="index"
                @click="$emit('select', item)">
                {{ item }}
            </div>  
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from "vue-clickaway";

    export default {
        mixins: [ clickaway ],
        props: {
            items: {
                type: Array,
                default: () => []
            },
            selected: {
                default: null
            }
        },
        data: ()=>({
            opened: false
        }),
        methods: {
            away(){
                this.opened = false
            }
        }
    };
</script>

<style
  scoped
  lang="scss"
  src="./style.scss"
></style>