<template>
    <div
        :class="{'disabled': disabled || hasLoading}"
        class="full-view full-view__rotation">
        <div
            v-if="isAdd">
            <a
                class="btn--link"
                role="button"
                @click="close">
                Back to Preset Views
            </a>
            <div class="full-view__title my-2">
                Add View
            </div>
            <div class="d-flex">
                <div class="full-view__icon">
                    <v-img
                        :src="require('@/assets/icons/configurator/view.svg')"
                        contain/>
                </div>
                <div class="full-view__body full-view__body--logo">
                    <div>
                        Rotate image and create new view
                    </div>
                    <v-btn
                        :disabled="disabled || hasLoading"
                        class="mt-2"
                        color="#0046AF"
                        outlined
                        rounded
                        small
                        style="border: 2px solid #0046AF"
                        @click="save">
                        Confirm Custom View
                    </v-btn>
                </div>
            </div>
        </div>
        <template v-else>
            <div class="full-view__text mb-4 mx-2">
                <b>{{ selectedCount }}/{{ templateViews.length }} Views Selected</b> <br>
                Additional views will incur an extra charge
            </div>
            <div v-if="error" class="mb-2 ml-2" style="color: darkred; font-size: 12px"> {{ error }} </div>
            <div

                class="d-flex full-view__checkbox-wrap">
                <div
                    v-for="item in templateViews"
                    :key="item.id"
                    style="position: relative">
                    <v-img
                        v-if="item.type === 'CUSTOM'"
                        :src="require('@/assets/icons/ic-remove.svg')"
                        class="full-view__remove"
                        contain
                        height="15"
                        max-height="15"
                        max-width="15"
                        width="15"
                        @click.prevent="remove(item)"/>
                    <div
                        :class="{
                            'full-view--active': selectedRotation[item.id] && selectedRotation[item.id].type !== 'CUSTOM',
                            'full-view--custom': selectedRotation[item.id] && selectedRotation[item.id].type === 'CUSTOM',
                        }"
                        class="full-view__checkbox"
                        @click="toggle(item)">
                        <div class="loading" v-if="item.loading">
                            <v-progress-circular indeterminate size="24"/>
                        </div>
                        <v-img :src="item.type === 'CUSTOM' ? CustomImage :item.icon" width="24"/>
                        <div class="full-view__label no-select">
                            {{ item.title }}
                        </div>
                    </div>
                </div>

                <div
                    class="full-view__checkbox pb-0"
                    @click="setAdd(true)">
                    <svg
                        fill="none"
                        height="26"
                        viewBox="0 0 26 26" class="flex-shrink-0"
                        width="26">
                        <rect
                            :fill="colorAdd"
                            :stroke="colorAdd"
                            height="25"
                            rx="2.5"
                            width="25"
                            x="0.5"
                            y="0.5"/>
                        <path
                            d="M11 6C11 5.44772 11.4477 5 12 5H14C14.5523 5 15 5.44772 15 6V20C15 20.5523 14.5523 21 14 21H12C11.4477 21 11 20.5523 11 20V6Z"
                            fill="#464646"/>
                        <path
                            d="M20 11C20.5523 11 21 11.4477 21 12V14C21 14.5523 20.5523 15 20 15L6 15C5.44772 15 5 14.5523 5 14L5 12C5 11.4477 5.44772 11 6 11L20 11Z"
                            fill="#464646"/>
                    </svg>
                    <div class="full-view__label full-view__label--add no-select">
                        Add View
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import UploadService from "@/api/uploadFilesService.api";

    import FrontSideImage from "@/assets/icons/configurator/front-side.svg"
    import BackSideImage from "@/assets/icons/configurator/back-side.svg"
    import SideImage from "@/assets/icons/configurator/side.svg"
    import PerspectiveImage from "@/assets/icons/configurator/perspective.svg"
    import CustomImage from "@/assets/icons/configurator/custom-side.svg"

    // const defaultViews = [
    //     { id: 701, title: "Front", rotate: { x: -219.18572907905357, y: -2.522469131545652, z: 1.26117193283469 } },
    //     { id: 702, title: "Back", rotate: { x: 219.04062005420056, y: 7.566071287966618, z: -3.7813448159201126 } },
    //     { id: 703, title: "Side", rotate: { x: 0, y: 0, z: 219.20387140075866 } },
    //     { id: 704, title: "Perspective", rotate: { x: -195.49995780670133, y: 1.915401180450826, z: 99.30341657250403 } }
    // ];
    const defaultViews = [
        { id: 701, title: "Front", rotate: { x: 0, y: 0, z: 40.26117193283469 }, icon: FrontSideImage, loading: false },
        { id: 702, title: "Back", rotate: { x: 0, y: 0, z: -40.26117193283469 }, icon: BackSideImage, loading: false },
        { id: 703, title: "Side", rotate: { x: -50.26117193283469, y: 0, z: 0 }, icon:  SideImage, loading: false },
        { id: 704, title: "Perspective", rotate: { x: 60.26117193283469, y: 40.26117193283469, z: 5.26117193283469 }, icon: PerspectiveImage, loading: false }
    ];
    export default {
        name: "conf-rotation",
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            typeViews: {
                type: Array,
                default: () => ([])
            },
            controlFiles: {
                type: Object,
                default: () => ([])
            },
            currentFile3d: {
                type: Object,
                default: () => {
                }
            }
        },
        data () {
            return {
                CustomImage,
                error: null,
                defaultViews: defaultViews,
                templateViews: [
                    ...defaultViews
                ],
                selectedRotation: {},
                angleSource: 0,
                title: "",
                isAdd: false
            };
        },
        computed: {
            hasLoading() {
                return this.templateViews.some(item => item.loading)
            },
            countCustom () {
                return this.templateViews.filter((i) => i.type === "CUSTOM").length;
            },
            angle: {
                get () {
                    return this.angleSource;
                },
                set (val) {
                    const number = parseInt(val);
                    if (number > 360) {
                        this.angleSource = 360;
                    } else if (number < 0) {
                        this.angleSource = 0;
                    } else {
                        this.angleSource = number;
                    }
                }
            },
            colorAdd() {
                if (this.disabled || this.hasLoading) {
                    return "#cccccc";
                } else {
                    return "#9FF4A9";
                }
            },
            selectedCount(){
                if(this.selectedRotation){
                    let count = 0
                    for (const key in this.selectedRotation) {
                        const field = this.selectedRotation[key];
                        if(field && field.id){
                            count++
                        }
                    }
                    return count
                }
                return 0
            }
        },
        methods: {
            setAdd (status) {
                if (this.disabled || this.hasLoading) {
                    return;
                }
                this.isAdd = status;
            },
            getColor (item) {
                if (this.disabled || this.hasLoading) {
                    return "#747474";
                }
                const { selectedRotation } = this;
                if (selectedRotation[item.id] && selectedRotation[item.id].type !== "CUSTOM") {
                    return "#FB3805";
                }
                if (selectedRotation[item.id] && selectedRotation[item.id].type === "CUSTOM") {
                    return "#02810F";
                }
                return "#747474";
            },
            toggle (item) {
                if (this.disabled || this.hasLoading) {
                    return;
                }
                item.loading = true
                if (this.selectedRotation[item.id]) {
                    const find = this.typeViews.find((obj) => parseInt(obj.view_id) === item.id);
                    if (find) {
                        this.$emit("remove-view", find);
                    }
                    delete this.selectedRotation[item.id];
                    this.selectedRotation = { ...this.selectedRotation };
                    item.loading = false
                } else {
                    if(this.typeViews.length >= 5) {
                        this.error = "Limit is 5  views";
                        item.loading = false
                        return;
                    }
                    this.$emit("add-view", item);
                    this.selectedRotation = { ...this.selectedRotation, [item.id]: item };
                }

                this.$emit("setViewSpecsTitle", this.selectedCount +"/"+this.templateViews.length);
            },
            async save () {
                if (this.disabled || this.hasLoading) {
                    return;
                }
                if (this.countCustom >= 5) {
                    this.error = "Limit is 5 custom views";
                    return;
                }
                this.isAdd = false;
                const customView = {
                    id: new Date().getTime(),
                    type: "CUSTOM",
                    rotate: JSON.parse(JSON.stringify(this.controlFiles.getRotation(this.currentFile3d.id))),
                    title: this.title || `Custom ${this.countCustom + 1}`,
                    loading: false
                };
                this.templateViews.push(customView);
                if (this.currentFile3d.id) {
                    if (!this.currentFile3d.props) {
                        this.currentFile3d.props = {};
                    }
                    if (!this.currentFile3d.props.rotation) {
                        this.currentFile3d.props.rotation = [];
                    }

                    this.currentFile3d.props = {
                        ...this.currentFile3d.props, rotation: [
                            ...this.currentFile3d.props.rotation,
                            customView
                        ]
                    };
                    await UploadService.updateProps(this.currentFile3d.id, this.currentFile3d.props);
                    this.toggle(customView)
                }
            },
            remove (item) {
                if (this.disabled || this.hasLoading) {
                    return;
                }
                if (this.selectedRotation[item.id]) {
                    delete this.selectedRotation[item.id];
                    this.selectedRotation = { ...this.selectedRotation };
                }
                this.templateViews = this.templateViews.filter((i) => i.id !== item.id);

                if (this.currentFile3d.id) {
                    if (!this.currentFile3d.props) {
                        this.currentFile3d.props = {};
                    }
                    if (!this.currentFile3d.props.rotation) {
                        this.currentFile3d.props.rotation = [];
                    }

                    this.currentFile3d.props.rotation = this.currentFile3d.props.rotation.filter((i) => i.id !== item.id);
                    this.currentFile3d.props = {
                        ...this.currentFile3d.props, rotation: [
                            ...this.currentFile3d.props.rotation
                        ]
                    };
                    UploadService.updateProps(this.currentFile3d.id, this.currentFile3d.props);
                    const find = this.typeViews.find((obj) => parseInt(obj.view_id) === item.id);
                    if (find) {
                        this.$emit("remove-view", find);
                    }
                }
                if (this.countCustom < 5) {
                    this.error = null;
                }
            },
            close () {
                if (this.disabled || this.hasLoading) {
                    return;
                }
                this.isAdd = false;
                this.angle = 0;
                this.title = "";
            }
        },
        watch: {
            currentFile3d: {
                immediate: true,
                deep: true,
                handler (currentFile3d) {
                    if (!currentFile3d.props || !currentFile3d.props.rotation) {
                        return;
                    }

                    this.templateViews = [...this.defaultViews, ...currentFile3d.props.rotation];
                }
            },
            typeViews: {
                immediate: true,
                handler (nval, val) {
                    if(nval.length <= 5) {
                        this.error = null
                    }
                    const index = (nval, view_id) => {
                        const result = {};
                        nval.forEach((val) => {
                            if (view_id) {
                                result[val.view_id] = val;
                            } else {
                                result[val.id] = val;
                            }
                        });
                        return result;
                    };

                    if (val) {
                        let indexedTypeView = index(nval);
                        val.forEach(file => {
                            if (!indexedTypeView[file.id] && this.selectedRotation[file.view_id]) {
                                delete this.selectedRotation[file.view_id];
                            }
                        });
                    }

                    const indexedTemplateViews = index(this.templateViews);
                    nval.forEach(file => {
                        if (!this.selectedRotation[file.view_id]) {
                            this.selectedRotation[file.view_id] = indexedTemplateViews[file.view_id];
                        }
                    });
                    this.selectedRotation = { ...this.selectedRotation };
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "full-view";
    .full-view.disabled .full-view--active {
        border: 1px solid #cccccc;
    }
</style>
