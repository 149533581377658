<template>
    <v-dialog
        v-bind="$attrs"
        v-on="$listeners"
        :max-width="480">
        <div class="dialog-overflow-wrap">
            <v-btn
                class="pci-ad-cl-btn"
                icon>
                <v-img
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    contain
                    height="36"
                    max-width="36"
                    @click="$emit('input', false)"/>
            </v-btn>

            <div class="white pa-8">
                <h4 class="mb-3 text-h5 font-weight-semibold main-text--text">Project is on hold</h4>
                <p class="mb-7 main-text--text" style="font-size: 14px;">
                    Due to the unforeseen complexity of a project, this project is on hold.
                    Please wait until the complexity matter is resolved and continue with the project.
                </p>
                <div class="d-flex justify-center">
                    <v-btn
                        width="90"
                        height="38"
                        outlined
                        rounded
                        color="main-text"
                        class="bg-primary"
                        @click="$emit('input', false)">
                        OK
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "designer-extra-charge-dialog"
    }
</script>
