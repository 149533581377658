<template>
    <v-container
        class="pa-6"
        fluid>
        <div class="bread-section mt-2 mb-6">
            <div class="def-page-cont pos-rel d-flex flex-wrap">
                <div
                    class="bs-back-link"
                    @click="$router.go(-1)">
                    <v-icon color="#0046AF">
                        mdi-menu-left
                    </v-icon>
                    Back
                </div>
                <breadcrumbs
                    :items="breadcrumbs"
                    class="inline-bc"/>
            </div>
        </div>

        <div class="project-scroll-section">
            <review-project-page @saveEditable="(saveFnc)=> this.saveEditable = saveFnc"/>
        </div>

        <dialog-cancel-project
            v-model="cancelDialog"
            :project="project"/>

        <project-footer>
            <div class="def-page-cont pos-rel">
                <v-row align="center">
                    <v-col class="d-flex">
                        <v-btn
                            v-if="(allowedCancel(project.status_id) && isClient ) || isManager || isAdmin"
                            elevation="0"
                            text
                            rounded
                            color="#0046AF"
                            class="btn--link btn-h-48 pl-10 pr-10 mr-3"
                            @click.stop="cancelDialog = true">
                            Cancel Project
                        </v-btn>
                    </v-col>
                    <v-col
                        class="pt-1 pt-md-3"
                        cols="12"
                        md="auto">
                        <btn-green
                            class="btn--link btn-h-48 px-10"
                            v-if="isClient || isAdminProject"
                            :loading="loading"
                            @click="republish">
                            Save and Re-publish
                        </btn-green>
                    </v-col>
                </v-row>
            </div>
        </project-footer>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import moment from "moment";
    import Breadcrumbs from "@/components/Breadcrumbs";
    //import Disabled from "@/components/ui/Disabled";
    // import ReviewBlock from '@/components/ui/ReviewBlock';
    // // import ReviewSubBlock from "@/components/ui/ReviewSubBlock";
    // import ProjectDescription from "@/views/project/start/review-project/ProjectDescription";
    // import ProjectTitle from "@/views/project/start/review-project/ProjectTitle";
    // import ProjectDetails from "@/components/project/ProjectDetails";
    import ReviewProjectPage from "@/views/project/start/steps/ReviewProjectTab";

    import BtnGreen from "@/components/ui/btn/BtnGreen";
    import DialogCancelProject from "../ui/dialog-cancel-project";
    import ProjectFooter from "@/components/project/ProjectFooter";

    export default {
        name: "project-edit",
        components: {
            ProjectFooter,
            DialogCancelProject,
            BtnGreen,

            ReviewProjectPage,
            // ReviewSubBlock,
            Breadcrumbs
            // Disabled
        },
        filters: {
            moment: function (date, type) {
                if (type === "created_at") {
                    return moment(date).format("M/D/YY");
                }
                if (type === "updated_at") {
                    return moment(date).format("M/D/YY | h:mmA");
                }
            }
        },
        data () {
            return {
                loading: false,
                dialog: false,
                cancelDialog: false,
                saveEditable: () => {
                },
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    },
                    {
                        text: ""
                    }
                ],
                designerInfo: null
            };
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManager",
                "isDesigner",
                "isAdmin",
                "allowedCancel",
                "isAdminProject"
            ]),
            project () {
                return this.$store.getters.getProjectData;
            }
        },
        mounted () {
            this.breadcrumbs[1].text = this.project.title;
        },
        methods: {
            republish () {
                this.loading = true;
                this.saveEditable();

                this.loading = false;

                this.$router.push({ name: "dashboard" });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .grid {
        max-width: 1074px;
        width: 100%;
    }
    .def-blue-link {
        display: inline-block;
        font-size: 13px;
        line-height: 24px;
        color: #0046AF;
        cursor: pointer;
        text-decoration: none;
        transition: all 250ms ease;
        &:hover {
            opacity: 0.7;
        }
    }
    .apd-btn {
        &.v-btn.v-btn--disabled.v-btn--has-bg {
            background-color: #C5C5C5 !important;
            color: #fff !important;
        }
    }
    .pfi-designer {
        display: inline-block;
        font-size: 14px;
        line-height: 24px;
        color: #545454;
    }
    .pb-label {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #8D8D8D;
    }
    .project-description {
        font-size: 13px;
        line-height: 19px;
        color: #202121;
        word-break: break-word;
    }
    .hs-ttl-wrap {
        display: inline-block;
    }
    .hs-type {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #737373;
        text-transform: uppercase;
    }
    .pms-label {
        font-size: 20px;
        line-height: 32px;
        color: #0B3C49;
    }
    .pms-col {
        flex: 0 0 calc(50% - 1px);
        max-width: 480px;
        @media screen and (max-width: 959px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .pms-col-sep {
        @media screen and (min-width: 960px) {
            &:before {
                content: "";
                display: block;
                height: 398px;
                border-left: 1px solid #D2D2D2;
                margin-top: 80px;
            }
        }
        @media screen and (max-width: 959px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 20px 12px;
            &:before {
                content: "";
                display: block;
                border-top: 1px solid #D2D2D2;
            }
        }
    }
    .pms-img-wrap {
        img {
            max-width: 100%;
        }
    }
    .pl-iw {
        text-align: right;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        @media screen and (max-width: 959px) {
            text-align: left;
        }
    }
    .pl-item {
        background: rgba(0, 70, 175, 0.07);
        border-radius: 20px;
        display: inline-block;
        vertical-align: top;
        padding: 3px 7px;
        font-size: 13px;
        line-height: 18px;
        color: #0046AF;
        text-align: left;
        text-decoration: none;
        word-break: break-word;
    }
    a.pl-item {
        transition: all 250ms ease;
        &:hover {
            opacity: 0.7;
        }
    }
</style>
