<template>
    <div class="comment px-4 pt-2">
        <div class="d-flex align-center">
            <div class="comment__username">
                {{ comment.user || comment.user_name }}
            </div>
            <div class="comment__date ml-2 mr-1">
                {{ $moment(comment.date).format("MMM DD, YYYY") }}
            </div>
            <v-menu
                v-model="menu"
                bottom
                class="red"
                left
                max-width="182"
                offset-x
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="comment.user_id === currentCustomerId"
                        v-bind="attrs"
                        v-on="on"
                        icon>
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                </template>

                <div
                    class="drop-item-menu__links"
                    style="background-color:#fff;">
                    <a
                        class="drop-item-menu__btn pa-3 d-block"
                        role="button"
                        @click="edit($event, comment.comment)">
                        Edit
                    </a>
                    <a
                        v-if="idx === 0"
                        class="drop-item-menu__btn pa-3 d-block"
                        role="button"
                        @click="$emit('deleteComment', comment)">
                        Delete
                    </a>
                    <a
                        v-if="idx > 0"
                        class="drop-item-menu__btn pa-3 d-block "
                        role="button"
                        @click="deleteReply($event, comment)">
                        Delete
                    </a>
                </div>
            </v-menu>
        </div>

        <p
            v-if="!isEditing"
            class="comment__text mt-3 mb-6">
            {{ comment.comment }}
        </p>

        <div v-if="isEditing">
            <autocomplete
                v-if="comment.parent_id === null"
                v-model="editText"
                :disabled="false"
                :counter="500"
                :counter-value="1"
                :items="$store.getters.getAutocompleteData"
                :placeholder="hideAutocomplite ? 'Leave comment' : 'Leave comment, or type “/” to tag' "
                :text-trigger="hideAutocomplite ? 'fuck/' : '/'"
                class="mb-2 comment__input placeholder"
                style="width: 238px"
                @blur="store.commit('updateCommentEditStatus', false);"
                @focus="store.commit('updateCommentEditStatus', true);"
                @selected="(iconObj)=>$emit('setNewIcon', {commentData: comment, iconObj})"/>

            <v-textarea
                v-else
                v-model="editText"
                autofocus
                auto-grow
                class="comment__input"
                counter
                dense
                maxlength="500"
                outlined
                placeholder="Add Comment"
                rows="1"
                style="width: 238px"
                @blur="store.commit('updateCommentEditStatus', false);"
                @focus="store.commit('updateCommentEditStatus', true);"/>

            <div class="d-flex justify-space-between px-4 py-3">
                <a
                    class="drop-item-menu__btn"
                    role="button"
                    @click="editSave($event, comment)">
                    Save
                </a>
                <a
                    class="drop-item-menu__btn drop-item-menu__btn--close"
                    role="button"
                    @click="editCancel">
                    Close
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Autocomplete from "@/components/ui/autocomplete";

    export default {
        name: "project-reference-marker-comment",
        components: {
            Autocomplete
        },
        data: () => ({
            menu: false,
            editText: "",
            isEditing: false
        }),
        props: {
            currentFile: {
                type: Object,
                default () {
                    return {};
                }
            },
            store: {
                type: Object,
                required: true
            },
            comment: {
                type: Object,
                default: null
            },
            idx: {
                type: Number,
                default: 0
            },
            currentCustomerId: {
                required: true,
                type: Number
            }
        },
        computed: {
            hideAutocomplite () {
                return this.currentFile && (this.currentFile.type === 4 || this.currentFile.type === 5);
            }
        },
        methods: {
            edit (event, editText) {
                if(this.currentCustomerId == this.comment.user_id){
                    this.menu = false;
                    this.isEditing = true;
                    this.editText = editText;
                    event.stopPropagation();
                }
            },
            editSave (event, commentObj) {
                event.stopPropagation();
                this.$emit("editSave", {
                    file_id: commentObj.file_id,
                    id: commentObj.id,
                    comment_id: commentObj.comment_id,
                    comment: this.editText
                });
                this.isEditing = false;
            },
            editCancel () {
                event.stopPropagation();
                this.editText = "";
                this.isEditing = false;
            },
            deleteReply (event, comment) {
                event.stopPropagation();
                this.$emit("deleteReply", comment);
                this.menu = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .drop-item-menu {
        display: none;
        position: absolute;
        width: 270px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0 0 5px grey;
        border-radius: 3px;
        z-index: 2;
    }
    .drop-item-menu__links {
        a:hover {
            background: #ececec;
        }
    }
    .comment {
        border-bottom: 1px solid #ECECEC;
        &__username {
            flex: 1
        }
        &__username,
        &__date,
        &__text {
            color: #0B3C49;
            font-size: 12px;
            line-height: 16px;
            overflow-wrap: break-word;
        }
        &__date {
            color: #9FACA9;
        }
    }
</style>

<style lang="scss">
    .drop-item-menu__btn,
    .drop-item-menu__btn .v-btn__content {
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .comment__input {
        font-size: 12px;
        .v-input__slot {
            min-height: 32px !important;
        }
    }
</style>
