<template>
    <div class="select-product ml-auto mr-auto">
        <v-container class="select-product__container">
            <v-row>
                <v-col class="pa-0">
                    <h1 class="select-product__header">
                        Hi {{ profile.firstName }},
                    </h1>
                    <h5 class="select-product__subtitle">
                        Let’s select a <span>Project Type:</span>
                    </h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0 radio-wrap d-flex flex-wrap">
                    <label
                        v-for="item in buttons"
                        :key="item.value"
                        :class="{'radio--disabled': item.disabled}"
                        :style="{visibility: item.hide ? 'hidden': ''}"
                        class="radio  mr-14 mt-14">
                        <input
                            v-model="typeProduct"
                            :disabled="item.disabled"
                            :value="item.value"
                            name="start-project"
                            type="radio">

                        <div class="radio__img-block">
                            <v-img
                                :height="item.size.h"
                                :src="require('@/assets/icons/' + item.icon + '.svg')"
                                :width="item.size.w"
                                contain/>
                        </div>

                        <div class="radio__title mt-2">
                            {{ item.title }}
                        </div>
                    </label>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "start-project-tab-index",
        data() {
            return {
                //typeProduct: null,
                buttons: [
                    { title: "Bracelet", value: this.$config.project.type.bracelet, size: { w: 99, h: 100 }, icon: "ic-pt-bracelet" },
                    { title: "Earrings", value: this.$config.project.type.earrings, size: { w: 95, h: 101 }, icon: "ic-pt-earrings" },
                    { title: "Cuffs", value: this.$config.project.type.cuffs, size: { w: 158, h: 74 }, icon: "ic-pt-cuffs" },
                    { title: "Ring", value: this.$config.project.type.ring, size: { w: 84, h: 101 }, icon: "ic-pt-ring" },
                    { title: "Necklace", value: this.$config.project.type.necklace, size: { w: 83, h: 101 }, icon: "ic-pt-necklace" },
                    { title: "Band", value: this.$config.project.type.band, size: { w: 70, h: 70 }, icon: "ic-pt-band" },
                    { title: "Custom / Other", value: this.$config.project.type.custom, size: { w: 70, h: 70 }, icon: "ic-pt-plus" }
                ]
            }
        },
        created () {
            if(this.$route.name === "project-start") {
                this.typeProduct = this.$config.project.type.bracelet;
                this.$store.dispatch("updateProjectData", {
                    type: this.typeProduct
                });
            }
        },
        computed: {
            typeProduct: {
                get () {
                    return this.$store.getters.getProjectType;
                },
                set (val) {
                    this.$store.dispatch("updateProjectData", {
                        type: val
                    });
                }
            },
            // mix the getters into computed with object spread operator
            ...mapGetters([
                "profile", "getProjectData"
            ])
        }
    };
</script>

<style lang="scss" scoped>
    .select-product {
        &__container {
            @media screen and (max-width: 1265px) {
                max-width: 837px;
            }
            @media screen and (max-width: 635px) {
                max-width: 446px;
            }
            @media screen and (max-width: 450px) {
                max-width: 360px;
            }
        }
        max-width: 1160px;
        font-family: "Futura PT", sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #0B3C49;
        &__header {
            font-size: 32px;
            line-height: 48px;
        }
        &__subtitle {
            font-size: 22px;
            line-height: 36px;
        }
    }

    .radio-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @media screen and (max-width: 300px) {
            flex-direction: column;
        }
    }

    .radio {
        cursor: pointer;
        font-family: "Futura PT", sans-serif;
        max-width: 47%;
        @media screen and (max-width: 900px) {
            flex-grow: 1;
            margin-right: 10px !important;
        }
        @media screen and (max-width: 680px) {
            .v-image {
                transform: scale(0.8);
            }
            flex-grow: 1;
            margin-top: 20px !important;
            margin-right: 10px !important;
        }
        @media screen and (max-width: 450px) {
            .v-image {
                transform: scale(0.7);
            }
            margin-right: 10px !important;
            // max-width: 100%;
        }
        @media screen and (max-width: 300px) {
            .v-image {
                transform: scale(0.8);
            }
            max-width: 100%;
        }
        input[type=radio] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }
        &__img-block {
            border-radius: 5px;
            border: 1px solid #BCBCBC;
            box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 223px;
            max-width: 223px;
            height: 170px;

            @media screen and (max-width: 771px) {
                width: 100%;
                flex-grow: 1;
                min-width: 180px;
            }
            @media screen and (max-width: 450px) {
                width: 100%;
                flex-grow: 1;
                min-width: 100px !important;
            }
            @media screen and (max-width: 300px) {
                width: 100%;
                flex-grow: 1;
                min-width: 100%;
            }
        }
        [type=radio]:checked + &__img-block {
            border: 3px solid #3F7359;
        }
        [type=radio]:disabled + &__img-block {
            border: 0;
            cursor: not-allowed;
        }
        &__title {
            font-size: 18px;
            line-height: 20px;
            color: #464646;
            padding-bottom: 9px;
            border-bottom: 1px solid #BCBCBC;
        }
    }
    .radio--disabled {
        cursor: not-allowed;
    }

    .select-product__subtitle {
        font-family: "Futura PT";
        font-weight: normal;
        font-size: 24px;
        line-height: 27px;
        color: #464646;
        margin-top: 17px;

        span {
            color: #3F7359;
        }
    }
</style>
