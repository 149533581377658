<template>
    <div class="specification">
        <div class="specification-title mb-7">SPECIFICATIONS</div>
        <v-row>
            <v-col v-for="(col, col_idx) in specificationColumns" :key="col_idx">
                <template v-for="(view, view_idx) in col">
                    <spec-view
                        v-if="view.type === FILETYPES.VIEWS"
                        :key="view_idx"
                        :data="view.children"
                        :disable-edit="true"
                        :disable-controls="true"
                        :hover-preview="true"
                        custom-title="Views"
                        class="mt-0 mb-5"/>

                    <spec
                        v-if="view.type === FILETYPES.ENGRAVING"
                        :key="view_idx"
                        :index="view.idx"
                        :data="view.children[0]"
                        :image-loop="true"
                        :loop-btn="true"
                        :disable-edit="true"
                        :disable-controls="true"
                        class="mt-0 mb-5"
                        @open="openSpecsPreviewModal"/>

                    <spec
                        v-if="view.type === FILETYPES.LOGO"
                        :key="view_idx"
                        :index="view.idx"
                        :data="view.children[0]"
                        :image-loop="true"
                        :loop-btn="true"
                        :disable-edit="true"
                        :disable-controls="true"
                        class="mt-0 mb-5"
                        @open="openSpecsPreviewModal"/>

                    <spec
                        v-if="view.type === FILETYPES.BACKGROUND"
                        :key="view_idx"
                        :data="view.children[0]"
                        :image-loop="true"
                        :loop-btn="true"
                        :disable-edit="true"
                        :disable-controls="true"
                        class="mt-0 mb-5"
                        @open="openSpecsPreviewModal"/>
                </template>
            </v-col>
            <v-dialog
                v-model="specsPreviewModal"
                eager
                width="980">
                <div class="dialog-overflow-wrap">
                    <v-btn
                        class="pci-ad-cl-btn"
                        icon>
                        <v-img
                            :src="require('@/assets/icons/ic-close-modal.svg')"
                            contain
                            height="36"
                            max-width="36"
                            @click="specsPreviewModal = false"/>
                    </v-btn>

                    <v-card class="pa-16">
                        <project-references-slider
                            v-if="clientSliderFiles && clientSliderFiles.length && showX2Slider"
                            id="modal-x2"
                            :render-review-slider="true"
                            :hide-drawer="true"
                            :hide-captions="true"
                            :hide-thumbs="true"
                            :thumbs-header="false"
                            :x2-modal="false"
                            @controls="obj => modalSliderControlsObj = obj"
                            :store="$store"
                            :z-index="0"/>
                    </v-card>
                </div>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import ProjectReferencesSlider from "@/views/project/start/review-project/ProjectReferencesSlider";
    import SpecView from "@/components/configurator/spec-view";
    import Spec from "@/components/configurator/spec";
    import { FILETYPES } from "@/utils/constans";
    import { mapGetters } from "vuex";

    export default {
        name: "project-specification",
        data() {
            return {
                FILETYPES: FILETYPES,
                specsPreviewModal: false,
                showX2Slider: false,
                modalSliderControlsObj: {}
            }
        },
        components: {
            ProjectReferencesSlider,
            SpecView,
            Spec
        },
        computed: {
            ...mapGetters([
                "getAssetsSpecifications"
            ]),
            specificationColumns () {
                const columns = [[], []];
                const items = [
                    ...this.getSpecModuleByType(FILETYPES.VIEWS),
                    ...this.getSpecModuleByType(FILETYPES.ENGRAVING),
                    ...this.getSpecModuleByType(FILETYPES.LOGO),
                    ...this.getSpecModuleByType(FILETYPES.BACKGROUND)
                ];

                for (let i=0; i<items.length; i++) {
                    columns[i % 2 === 0 ? 0 : 1].push(items[i]);
                }
                return columns;
            },
            clientSliderFiles() {
                console.log(JSON.parse(JSON.stringify(this.getAssetsSpecifications)).reduce((acc, asset) => {
                    acc.push(...asset.children);
                    return acc;
                }, []))
                return JSON.parse(JSON.stringify(this.getAssetsSpecifications)).reduce((acc, asset) => {
                    acc.push(...asset.children);
                    return acc;
                }, []);
            }
        },
        methods:{
            getSpecModuleByType(type) {
                return this.getAssetsSpecifications
                    .filter(model => model.children.find(child => child.type === type))
                    .map((model, idx) => {
                        return { ...model, type, idx: idx+1, children: model.children.filter(child => child.type === type) }
                    });
            },
            openSpecsPreviewModal(file) {
                this.specsPreviewModal = true;

                this.$nextTick(() => {
                    this.showX2Slider = true;

                    this.$nextTick(() => {
                        this.modalSliderControlsObj.selectIndexFromId(file.id);
                    })
                })
            }
        }
    }
</script>


<style scoped>
.specification-title {
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 11px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}
</style>
