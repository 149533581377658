<template>
    <div
        class="pricing-panel">
        <div class="project-editor__header pb-2">
            <div class="project-editor__header-name">3D Model Pricing</div>
        </div>
        <div class="project-editor__content mb-0 py-2 pl-7">
            <p
                class="d-flex justify-space-between mb-0 text-capitalize">
                <span>Project price</span>
                <span>$ {{ price }}</span>
            </p>
        </div>
        <div class="project-editor__footer d-flex align-center justify-space-between pt-2">
            <div class="project-editor__footer-name font-weight-bold">Subtotal</div>
            <div class="project-editor__footer-name font-weight-bold">${{ price }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "project-price-details",
        props: {
            price: {
                type: [String, Number],
                default: 0
            }
        }
    }
</script>

<style scoped lang="scss">
.pricing-panel {
    background: #ffffff;
    border: 1px solid #bcbcbc;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgb(70 70 70 / 15%);
    border-radius: 5px;
    padding: 16px 16px 16px 13px;
    width: 100%;

    .project-editor__header-name,
    .project-editor__footer-name {
        color: #464646 !important;
        font-size: 14px;
        line-height: 18px;
    }
    .project-editor__content {
        border-top: 1px solid #BCBCBC;
        border-bottom: 1px solid #BCBCBC;
        p {
            font-size: 14px;
            line-height: 18px;
            color: #464646;
        }
    }
}
</style>
