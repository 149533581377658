var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'is-editing': _vm.getCommentEditStatus }},[_c('v-expansion-panels',{staticClass:"project-editor",attrs:{"accordion":"","flat":"","multiple":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{on:{"change":_vm.sendCollapseAction}},[_c('v-expansion-panel-header',{staticClass:"project-editor__header pa-0"},[_c('div',{staticClass:"project-editor__header-name mr-auto"},[_vm._v(" Metal Type ")]),_c('button',{staticClass:"select-all-btn",on:{"click":function($event){$event.stopPropagation();_vm.isMetalAttachment ? _vm.deselectAll() : _vm.selectAll(_vm.$store.state.metalTypes)}}},[_vm._v(" "+_vm._s(_vm.selectAllMetalBtnText)+" ")])]),_c('v-expansion-panel-content',{staticClass:"project-editor__content no-select"},[_c('div',{staticClass:"project-editor__options d-flex flex-wrap"},_vm._l((_vm.$store.state.metalTypes),function(metal){return _c('div',{key:metal.id,staticClass:"project-editor__option mb-4",on:{"click":function($event){return _vm.mClick(metal)}}},[_c('div',{staticClass:"project-editor__option-img-holder",class:{
                                'option--active': _vm.isSelectedMetal(metal)
                            },on:{"dragstart":_vm.dragStart,"mousedown":_vm.mDown,"mouseup":_vm.mouseUp}},[_c('div',{staticClass:"project-editor__option-img-wrap d-flex justify-center align-center"},[_c('img',{staticClass:"drag-drop-img project-editor__option-img",attrs:{"data-title":metal.name,"data-value":metal.value,"src":metal.image,"alt":"","data-type":"3"},on:{"dragend":_vm.mouseUp}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mouseDown),expression:"!mouseDown"}],staticClass:"project-editor__hover no-select"},[_c('v-img',{staticClass:"mt-2",attrs:{"src":require('@/assets/icons/ic-plus-white.svg'),"contain":"","max-height":"24","max-width":"24"}}),_c('span',{staticClass:"mt-2"},[_vm._v("Add")])],1)]),_c('p',{staticClass:"mb-0 text-center project-editor__option-name"},[_vm._v(" "+_vm._s(metal.name)+" ")])])}),0)])],1),_c('v-expansion-panel',{on:{"change":_vm.sendCollapseAction}},[_c('v-expansion-panel-header',{staticClass:"project-editor__header pa-0"},[_c('div',{staticClass:"project-editor__header-name mr-auto"},[_vm._v(" Stone Type ")]),_c('button',{staticClass:"select-all-btn",on:{"click":function($event){$event.stopPropagation();_vm.isStoneAttachment ? _vm.deselectAll() : _vm.selectAll(_vm.$store.state.stoneTypes)}}},[_vm._v(" "+_vm._s(_vm.selectAllStoneBtnText)+" ")])]),_c('v-expansion-panel-content',{staticClass:"project-editor__content no-select"},[_c('div',{staticClass:"project-editor__options d-flex flex-wrap ma-0 pa-0",on:{"dragstart":_vm.dragStart}},_vm._l((_vm.$store.state.stoneTypes),function(stone){return _c('div',{key:stone.id,staticClass:"project-editor__option mb-4",on:{"click":function($event){return _vm.mClick(stone)}}},[_c('div',{staticClass:"project-editor__option-img-holder",class:{
                                'option--active':
                                    _vm.isSelectedStone(stone)
                            },on:{"dragstart":_vm.dragStart,"mousedown":_vm.mDown,"mouseup":_vm.mouseUp}},[_c('div',{staticClass:"project-editor__option-img-wrap d-flex justify-center align-center"},[_c('img',{staticClass:"project-editor__option-img",attrs:{"data-title":stone.name,"data-value":stone.value,"src":stone.image,"alt":"","data-type":"2"},on:{"dragend":_vm.mouseUp}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mouseDown),expression:"!mouseDown"}],staticClass:"project-editor__hover"},[_c('v-img',{staticClass:"mt-2",attrs:{"src":require('@/assets/icons/ic-plus-white.svg'),"contain":"","max-height":"24","max-width":"24"}}),_c('span',{staticClass:"mt-2"},[_vm._v("Add")])],1),(_vm.$store.getters.getItemData &&
                                    _vm.$store.getters.getItemData.itemValue ===
                                    stone.value)?_c('div',{staticClass:"checked"},[_c('img',{attrs:{"src":require('@/assets/icons/ic-checked.svg')}})]):_vm._e()]),_c('p',{staticClass:"mb-0 text-center project-editor__option-name"},[_vm._v(" "+_vm._s(stone.name)+" ")])])}),0)])],1),_c('v-expansion-panel',{on:{"change":_vm.descriptionPanelHandler}},[_c('v-expansion-panel-header',{staticClass:"project-editor__header pa-0",class:{ 'project-editor__blocked': !_vm.areLinksValid },attrs:{"hide-actions":_vm.descriptionIsEditing || _vm.descriptionIsEmpty}},[_c('div',{staticClass:"project-editor__header-name d-flex align-center mr-auto"},[_vm._v(" Description "),(!_vm.descriptionIsEditing && _vm.descriptionIsEmpty)?_c('a',{staticClass:"project-editor__header-btn ml-auto",attrs:{"role":"button"}},[_vm._v(" + Add Description ")]):_vm._e(),(!_vm.descriptionIsEditing && !_vm.descriptionIsEmpty)?_c('a',{staticClass:"project-editor__header-btn ml-auto mr-5",attrs:{"role":"button"},on:{"click":function($event){return _vm.editDescription($event)}}},[_vm._v(" Edit Description ")]):_vm._e(),(_vm.descriptionIsEditing)?_c('div',{class:[
                            'ml-auto',
                            { 'mr-5': !_vm.descriptionIsEditing }
                        ]},[_c('a',{staticClass:"project-editor__header-btn mr-5",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submitProjectDescription.apply(null, arguments)}}},[_vm._v(" Save ")]),_c('a',{staticClass:"project-editor__header-btn",attrs:{"role":"button"},on:{"click":function($event){return _vm.cancelUpdateProjectDescription($event)}}},[_vm._v(" Cancel ")])]):_vm._e()])]),_c('v-expansion-panel-content',{staticClass:"project-editor__content"},[_c('project-description',{attrs:{"description-is-editing":_vm.descriptionIsEditing},on:{"openEditDescription":_vm.openEditDescription,"updateProjectDescription":_vm.updateProjectDescription}})],1)],1),(_vm.getProjectData.type_id !== _vm.$config.project.type.custom && !_vm.isAdminProject && _vm.getProjectData.status_id !== _vm.$config.project.status.draft)?_c('project-price-details',{staticClass:"v-expansion-panel",attrs:{"price":_vm.getProjectData.client_amount}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }