<template>
    <div class="full-view full-view__background px-4">
        <div class="d-flex flex-column full-view__background--left">
            <div class="full-view__text mb-6"><b>Background</b></div>
            <div class="full-view__icon mt-auto">
                <v-img
                    :src="require('@/assets/icons/configurator/background.svg')"
                    contain/>
            </div>
        </div>
        <div>
            <div
                :style="{
                    '--thumb-color': saturation,
                    '--thumb-opacity': hexColor,
                }"
                v-if="!file && !onlySecondary">
                <label class="upload">
                    <input
                        ref="file"
                        accept=".jpg, .svg, .png"
                        class="input-file"
                        name="file"
                        type="file"
                        @change="selectFile($event)"/>
                    <!--          <a role="button" @click="$emit('upload-background')"-->
                    <!--             style="display: block" class="mt-4 btn--link no-select">Upload image as background</a>-->
                    <v-btn
                        :disabled="disabled"
                        :loading="loading"
                        class="mt-2 px-0"
                        color="#0046AF"
                        rounded
                        small
                        text
                        @click="uploadModalOpened = true">
                        Upload image as background
                    </v-btn>
                </label>
                <drop-down
                    v-if="false"
                    :items="[
                        'Solid Color',
                        'Transparent Color',
                        'Multicolor Gradient',
                        'Upload custom Image',
                    ]"
                    :selected="backgroundAttachmentType"
                    @select="selectBackgroundAttachmentType"/>
                <sketch-picker
                    v-model="colors"
                    ref="colorPicker"
                    @input="getSaturation"
                    v-if="isRender"/>
                <!--            <v-color-picker v-model="colors" hide-inputs width="175" canvas-height="75"></v-color-picker>-->
                <div class="d-flex mt-1">
                    <div class="full-view__input full-view__input--alpha">
                        <input type="text" v-model="alphaColor" @change="updatePicker"/>
                    </div>
                    <div class="full-view__input">
                        <input
                            type="text"
                            v-model="hexColor"
                            @change="updatePicker"
                            @blur="fixColor"/>
                    </div>

                    <!--                <v-text-field v-model="alphaColor" suffix="%"-->
                    <!--                              outlined dense hide-details class="alpha-input" height="24"></v-text-field>-->
                    <!--                <v-text-field v-model="hexColor" outlined dense hide-details height="24"></v-text-field>-->
                </div>
            </div>
            <div v-else class="d-flex">
                <div class="d-flex">
                    <div v-if="file" class="full-view__bg-preview">
                        <v-img
                            :src="file.url"
                            height="77"
                            max-height="77"
                            max-width="95"
                            width="95"/>
                        <div class="full-view__preview-title pl-1">
                            {{ file.original_name }}
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-center align-center pl-3 pr-1">
                        <label class="upload">
                            <input
                                ref="file"
                                accept=".jpg, .svg, .png"
                                class="input-file"
                                name="file"
                                type="file"
                                @change="selectFile($event, 'replace')"/>
                            <v-btn
                                :disabled="disabled"
                                :loading="loading"
                                class="full-view__replace"
                                color="#0046AF"
                                rounded
                                small
                                text
                                @click="$refs.file.click()">
                                Replace
                            </v-btn>
                        </label>
                        <a
                            class="full-view__remove2 mt-2"
                            role="button"
                            @click="$emit('remove-background', file)">
                            Remove
                        </a>
                    </div>
                </div>
            </div>

            <!--                <div class="full-view__title full-view__title&#45;&#45;logo">
            Background
            <a
                class="full-view__close no-select"
                role="button"
                @click="$emit('close')">
                X
            </a>
        </div>
        <div
            v-if="!file && !onlySecondary"
            class="d-flex">
            <div class="full-view__icon full-view&#45;&#45;hide">
                <v-img
                    :src="require('@/assets/icons/configurator/ic-background.svg')"
                    contain/>
            </div>
            <div
                class="full-view__body full-view__body&#45;&#45;logo"
                style="position: relative">
                <sketch-picker
                    v-model="colors"
                    :aria-disabled="disabled"/>
                <label class="upload">
                    <input
                        ref="file"
                        accept=".jpg, .svg, .png"
                        class="input-file"
                        name="file"
                        type="file"
                        @change="selectFile($event)">
                    &lt;!&ndash;          <a role="button" @click="$emit('upload-background')"&ndash;&gt;
                    &lt;!&ndash;             style="display: block" class="mt-4 btn&#45;&#45;link no-select">Upload image as background</a>&ndash;&gt;
                    <v-btn
                        :disabled="disabled"
                        :loading="loading"
                        class="mt-2"
                        color="#0046AF"
                        rounded
                        small
                        text
                        @click="$refs.file.click()">
                        Upload image as background
                    </v-btn>
                </label>
                <div
                    v-if="disabled"
                    style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 10; background: rgba(150, 150, 150, 0.2)"/>
            </div>
        </div>
        <div
            v-else
            class="d-flex">
            <div class="full-view__icon full-view&#45;&#45;hide">
                <v-img
                    :src="require('@/assets/icons/configurator/ic-background.svg')"
                    contain/>
            </div>
            <div class="d-flex">
                <div
                    v-if="file"
                    class="full-view__bg-preview">
                    <v-img
                        :src="file.url"
                        height="77"
                        max-height="77"
                        max-width="95"
                        width="95"/>
                    <div class="full-view__preview-title pl-1">
                        {{ file.original_name }}
                    </div>
                </div>
                <div class="d-flex flex-column justify-center align-center pl-3 pr-1">
                    <label class="upload">
                        <input
                            ref="file"
                            accept=".jpg, .svg, .png"
                            class="input-file"
                            name="file"
                            type="file"
                            @change="selectFile($event, 'replace');">
                        <v-btn
                            :disabled="disabled"
                            :loading="loading"
                            class="full-view__replace"
                            color="#0046AF"
                            rounded
                            small
                            text
                            @click="$refs.file.click();">
                            Replace
                        </v-btn>
                    </label>
                    <a
                        class="full-view__remove2 mt-2"
                        role="button"
                        @click="$emit('remove-background', file)">
                        Remove
                    </a>
                </div>
            </div>
        </div>-->
        </div>

        <file-upload-modal
            :upload-modal-opened="uploadModalOpened"
            :mode="fileUploadMode"
            :accept="fileUploadTypes"
            placeholder="Image resolution must be at least 300 PPI for best results"
            without-upload
            @open="uploadModalOpened = true"
            @close="uploadModalOpened = false"
            @select="selectFile({ target: { files: $event } })"/>
    </div>
</template>

<script>
    import { Sketch } from "vue-color";
    import { hasExtension } from "@/utils/index.utils";
    import tinycolor from "tinycolor2";
    import FileUploadModal from "@/components/FileUploadModal";
    import dropDown from "../DropDown";

    let defaultColor = "red";
    const formatedColor = tinycolor(defaultColor);
    export default {
        name: "conf-background",
        components: {
            "sketch-picker": Sketch,
            "file-upload-modal": FileUploadModal,
            "drop-down": dropDown
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            file: {
                type: Object,
                default: null
            },
            bc: {
                type: String,
                default: null
            },
            currentFile3d: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            fileUploadMode: "add",
            fileUploadTypes: ".jpg, .svg, .png, .jpeg",
            isRender: true,
            uploadModalOpened: false,
            saturation: formatedColor.toHexString(),
            colorsSource: {
                hex: formatedColor.toHexString(),
                hex8: formatedColor.toHex8String(),
                hsl: formatedColor.toHsl(),
                hsv: formatedColor.toHsv(),
                rgba: formatedColor.toRgb(),
                a: 1
            },
            loading: false,
            onlySecondary: false,
            backgroundAttachmentType: "Solid Color"

        }),
        mounted() {
            if (
                this.currentFile3d.props &&
                this.currentFile3d.props.background &&
                this.currentFile3d.props.background[0]
            ) {
                this.colors = { ...this.currentFile3d.props.background[0] };
            }
        },
        computed: {
            hexColor: {
                get() {
                    return this.colorsSource.hex;
                },
                set(val) {
                    const color = tinycolor(val);
                    const colors = {
                        hex: val,
                        hex8: color.toHex8String(),
                        hsl: color.toHsl(),
                        hsv: color.toHsv(),
                        rgba: color.toRgb()
                    };
                    this.colorsSource = { ...this.colorsSource, ...colors };

                    this.$emit("color-changed", this.colorsSource);
                    this.updatePicker();
                }
            },
            alphaColor: {
                get() {
                    return (this.colorsSource.a * 100).toFixed(0);
                },
                set(val) {
                    let alpha = parseFloat(val);

                    if (alpha > 100) {
                        alpha = 100;
                    } else if (!alpha || alpha < 0) {
                        alpha = 0;
                    }
                    this.colorsSource.a = +(alpha / 100).toFixed(2);
                    this.$emit("color-changed", this.colorsSource);
                    this.updatePicker();
                }
            },
            colors: {
                get() {
                    return this.colorsSource;
                },
                set(val) {
                    this.colorsSource = val;
                    this.$emit("color-changed", this.colorsSource);
                }
            }
        },
        methods: {
            selectBackgroundAttachmentType(e){
                this.backgroundAttachmentType = e
            },
            setBackgroundType(type) {
                this.currentBackgroundType = type;
            },
            fixColor() {
                const color = tinycolor(this.hexColor);
                const colors = {
                    hex: color.toHexString(),
                    hex8: color.toHex8String(),
                    hsl: color.toHsl(),
                    hsv: color.toHsv(),
                    rgba: color.toRgb()
                };
                this.colors = { ...this.colors, ...colors };
            },
            updatePicker() {
                this.isRender = false;
                this.$nextTick(() => {
                    this.isRender = true;
                    this.getSaturation();
                });
            },
            getSaturation() {
                this.$nextTick(() => {
                    const picker = this.$refs.colorPicker?.$el;
                    if (!picker) return;

                    const currentHue = picker.querySelector(".vc-saturation");

                    this.saturation = currentHue.style.background;
                });
            },
            selectFile(event, type) {
                if (!event.target.files) {
                    return;
                }
                const file = event.target.files[0];
                if (!hasExtension(file?.name, [".jpg", ".svg", ".png", ".jpeg"])) {
                    const type = "alertInvalidFileType";
                    console.warn(
                        `Sorry, file "${file?.name}" is invalid, allowed extensions are: ` +
                            [".jpg", ".svg", ".png"].join(", ")
                    );
                    this.$store.state.dialogs.content[type].text =
                        `Sorry, file "${file?.name}" is invalid, allowed extensions are: ` +
                        [".jpg", ".svg", ".png"].join(", ");
                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                    return;
                }
                this.uploadModalOpened = false;
                event.target.value = "";
                if (type === "replace") {
                    this.onlySecondary = true;
                }
                this.loading = true;
                file.disableLoading = () => {
                    this.loading = false;
                    this.onlySecondary = false;
                };
                this.$nextTick(() => {
                    this.$emit("upload-background", file);
                });
            },
            attachBackgroundColor(event) {
                this.$emit("attachBackgroundColor", this.colorsSource.hexa);
            }
        }
    };
</script>
<style lang="scss">
.full-view {
  &__background {
    * {
      box-sizing: border-box !important;
    }
    .vc-sketch {
      width: 175px;
      box-shadow: none;
      padding: 0;
    }

    .vc-sketch-saturation-wrap {
      padding-bottom: 49.71%;
      border-radius: 4px;
    }

    .vc-sketch-field {
      display: none;
    }
    .vc-hue-picker,
    .vc-alpha-picker {
      width: 8px !important;
      height: 8px !important;
      border-radius: 4px;
      margin: 0;
      background: var(--thumb-color);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .vc-alpha-picker {
      background: var(--thumb-opacity);
    }
    .vc-saturation-circle {
      background-color: transparent;
    }
    .vc-sketch-sliders .vc-hue,
    .vc-sketch-sliders .vc-alpha-gradient {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
    .vc-hue-container,
    .vc-alpha-container {
      margin: 0 6px 0 2px;
    }
    .vc-sketch-alpha-wrap {
      margin-top: 8px;
    }
    .vc-sketch-hue-wrap,
    .vc-sketch-alpha-wrap {
      height: 8px;
    }

    .vc-sketch-color-wrap {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "full-view";
.full-view {
  &__input {
    font-size: 12px;
    height: 24px;
    input {
      height: 100%;
      width: 100%;
      padding: 0 8px;
      text-align: center;
      border: 1px solid #dbdbdb;
      border-radius: 2px;
      &:focus {
        outline: none;
      }
    }

    &--alpha {
      max-width: 47px;
      display: flex;
      position: relative;
      margin-right: 9px;
      input {
        text-align: left;
      }
      &:after {
        content: "%";
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__replace {
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0046af;
  }
  &__remove2 {
    font-family: "Futura PT", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #c81c31;
  }
  &__bg-preview {
    position: relative;
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
  }
  &__preview-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: "Futura PT", sans-serif;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 24px;
    text-align: center;
    background: rgba(56, 56, 56, 0.83);
    border: 1px solid #cbcbcb;
    // box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
  }
}
.upload {
  input {
    display: none;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}
</style>
