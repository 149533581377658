<template>
    <v-dialog
        v-model="dialog"
        max-width="700">
        <template v-slot:activator="{ attrs, on }">
            <v-btn
                v-if="!designerId"
                v-on="on"
                v-bind="attrs"
                rounded
                class="text--white ml-3"
                height="48"
                width="210"
                color="#02810F">
                Assign Modeler
            </v-btn>
            <v-btn
                v-else
                v-on="on"
                v-bind="attrs"
                rounded
                outlined
                height="48"
                color="#FB3805">
                Assign Different Modeler
            </v-btn>
        </template>

        <div class="dialog-overflow-wrap">
            <v-btn
                icon
                class="pci-ad-cl-btn">
                <v-img
                    contain
                    max-width="36"
                    height="36"
                    :src="require('@/assets/icons/ic-close-modal.svg')"
                    @click="dialog = false"/>
            </v-btn>

            <v-card class="pa-0 py-12 px-15 blueish">
                <div class="d-flex justify-space-between align-center">
                    <v-card-title class="px-0">
                        {{ designerId ? 'Assign new modeler?' : 'Assign A Modeler to This Project' }}
                    </v-card-title>
                    <v-card-subtitle
                        v-if="designerId"
                        class="pa-0 ma-0 blueish">
                        <span class="font-weight-bold">Current Modeler:  </span>
                        <span>{{ fullName }}</span>
                    </v-card-subtitle>
                </div>

                <v-card-text class="px-0 pb-9 grey-lightend body-1">
                    Assigning a modeler will notify the modeler.
                    {{ designerId ? 'The current modeler will be notified that they have been moved off the project.' : '' }}
                </v-card-text>

                <v-row class="d-flex align-center">
                    <v-col cols="4">
                        <div class="grey-lightend font-weight-bold">
                            Assign Modeler:
                        </div>
                    </v-col>
                    <v-col cols="8">
                        <v-select
                            dense
                            outlined
                            v-model="modeler"
                            hide-details
                            item-value="id"
                            :items="modelers"
                            :menu-props="{ button: true, offsetY: true }">
                            <template v-slot:selection="{ item }">
                                {{ item.first_name }} {{ item.last_name }}
                            </template>
                            <template v-slot:item="{ item }">
                                {{ item.first_name }} {{ item.last_name }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col/>
                </v-row>

                <div v-if="project">
                    <div class="d-flex align-center justify-space-between mt-8">
                        <div>
                            <div class="text-h6 font-weight-regular">
                                {{ project.title }}
                            </div>
                            <!-- <v-chip
                class="pf-chip mr-4 mb-4"
                :style="chipStyles"
                :class="{
                  'in-progress': project.status === 'In Progress',
                  'input-needed': project.status === 'Input Needed',
                  'pending-approval': project.status === 'Pending Approval',
                  'open-for-work': project.status === 'Open For Work',
                  'ready-for-review': project.status === 'Ready For Review',
                }"
              >
                {{ project.status }}
              </v-chip> -->
                        </div>
                        <div class="pci-ad-price">
                            ${{ project.overall_designer_amount }}
                        </div>
                    </div>

                    <div class="pci-ad-options mt-6">
                        <div class="pci-ad-opt-item">
                            Due date: TODO
                            <!-- {{ moment().add(project.due_date, "days").format("D/M/YY") }} -->
                            <!-- <span class="pci-ad-oi-val ttf-lc"
                            >{{ dueDate }} ({{ projectTypeFullInfo.due_date }} Days)</span
                            > -->
                        </div>
                        <div class="pci-ad-opt-item mt-4">
                            Created:
                            <span class="pci-ad-oi-val">
                                {{ this.$moment(project.created_at).format('M/D/YY') }}
                                ({{ project.user }})
                            </span>
                        </div>
                        <div class="pci-ad-opt-item mt-4">
                            Project type:
                            <span class="pci-ad-oi-val">{{ project.type }}</span>
                        </div>
                        <div class="pci-ad-opt-item mt-4">
                            Project id:
                            <span class="pci-ad-oi-val">{{ project.id }}</span>
                        </div>
                    </div>

                    <div>
                        <template v-if="project.metals || project.stones || project.description">
                            <p
                                class="bright-blue mt-7 cursor-pointer"
                                @click="isInfoVisible = !isInfoVisible">
                                Show more information
                                <v-icon
                                    v-if="!isInfoVisible"
                                    color="#656565">
                                    mdi-chevron-right
                                </v-icon>
                                <v-icon
                                    v-if="isInfoVisible"
                                    color="#656565">
                                    mdi-chevron-down
                                </v-icon>
                            </p>

                            <template v-if="isInfoVisible">
                                <v-divider v-if="project.metals || project.stones"/>
                                <p
                                    class="bright-grey mt-4"
                                    v-if="project.metals">
                                    <span>Metal Types:</span>
                                    <span class="font-weight-bold"> {{ project.metals.join(', ') }}</span>
                                </p>
                                <p
                                    class="bright-grey mb-4"
                                    v-if="project.stones">
                                    <span>Stone Types:</span>
                                    <span class="font-weight-bold"> {{ project.stones.join(', ') }}</span>
                                </p>
                                <v-divider/>
                                <p
                                    class="bright-grey mt-4"
                                    v-if="project.description">
                                    DESCRIPTION: {{ project.description }}
                                </p>
                            </template>
                        </template>

                        <div class="d-flex mt-7 justify-space-between align-center ">
                            <!-- <p-->
                            <!--     class="bright-blue pa-0 ma-0 cursor-pointer"-->
                            <!--     @click="dialog = false">-->
                            <!--     View Full Project-->
                            <!-- </p>-->
                            <v-btn
                                :loading="processing"
                                :disabled="processing || !modeler"
                                large
                                rounded
                                outlined
                                min-width="200"
                                :class="['white--text ml-auto', processing || !modeler ? 'disabledBtn' : 'bright-green']"
                                @click="assignModeler">
                                {{ designerId ? 'Reassign' : 'Assign' }} Modeler
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
    import http from "@/api/httpClient.api";

    export default {
        name: "project-assign-modeler",
        props: {
            designerId: {
                type: Number
            }
        },
        data: () => ({
            dialog: false,
            project: null,
            processing: false,
            modelers: [],
            modeler: "",
            isInfoVisible: false,
            fullName: ""
        }),
        methods: {
            fetchProject() {
                http
                    .get(`/projects/${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.project = data.data
                    })
            },
            fetchModelers() {
                http
                    .get("/manager/modelers")
                    .then(({ data }) => {
                        this.modelers = data.data

                        if (this.designerId) {
                            this.modeler = this.designerId

                            let modeler = this.modelers.find(modeler => modeler.id === this.designerId)
                            this.fullName = `${modeler.first_name} ${modeler.last_name}`
                        }
                    })
            },
            assignModeler() {
                if (this.modeler) {
                    this.processing = true
                    http
                        .put(`/manager/modelers/${this.modeler}/projects/${this.project.id}/assign`)
                        .then(() => {
                            location.reload()
                        })
                        .catch(e)
                }
            }
        },
        created() {
            this.fetchProject()
            this.fetchModelers()
        }
    }
</script>
