import { render, staticRenderFns } from "./ReviewBlock.vue?vue&type=template&id=3657ec19&scoped=true"
import script from "./ReviewBlock.vue?vue&type=script&lang=js"
export * from "./ReviewBlock.vue?vue&type=script&lang=js"
import style0 from "./ReviewBlock.vue?vue&type=style&index=0&id=3657ec19&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3657ec19",
  null
  
)

export default component.exports