<template>
    <div
        :class="{ 'is-editing': getCommentEditStatus }"
        class="slider-wrap">
        <image-edit
            v-if="currentFile.type === FILETYPES.LOGO && isLogoEdit && (isClient || isManagerOrAdmin)"
            :store="store"
            @exitImageEdit="removeEditLogo"
            @saveImageEdit="uploadLogo"/>

        <drawer
            v-else-if="!hideDrawer || (hideDrawer && isDraw)"
            :id="id"
            :current-file-info="currentFileInfo"
            :render-review-slider-toolbar="true"
            :disable-drawing-tools="disableDrawingTools || renderReviewSlider"
            :disable-painting-tool="disablePaintingTool || renderReviewSlider"
            :store="store"
            :block-drawer-options="isManager || isAdmin"
            @startDrawBtn="btn => (this.startDrawBtn = btn)"
            @repplaceFile="repplaceFile"/>

        <v-dialog
            v-if="x2Modal"
            v-model="dialog"
            class="modal"
            max-width="1270"
            type="large">
            <v-card class="pa-1 pb-4 pa-sm-13 pb-sm-7 d-flex flex-column">
                <v-btn
                    class="modal__close"
                    icon
                    @click="store.dispatch('drawer/largeView', !isLarge)">
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"/>
                </v-btn>
                <template v-if="!renderReviewSlider">
                    <drawer
                        v-if="!is3dLarge && (!hideDrawer || (hideDrawer && isDraw))"
                        :disable-drawing-tools="disableDrawingTools"
                        :disable-painting-tool="disablePaintingTool"
                        :block-drawer-options="isManager || isAdmin"
                        :store="store"/>

                    <div
                        v-else
                        class="d-flex justify-end">
                        <v-btn
                            :style="{ background: isCommentAble ? '#CCCCCC' : '' }"
                            class="mr-4 drawer__btn"
                            @click="store.dispatch('drawer/toggleCommentAble')">
                            <v-img
                                :src="require('@/assets/icons/ic-comment-blue.svg')"
                                contain
                                max-height="16"
                                max-width="16"/>
                        </v-btn>
                    </div>
                </template>
                <div
                    ref="largeWrapper"
                    :class="[`large__wrapper-${id}`, { 'no-cursor-': isDraw }]"
                    class="large__wrapper flex-grow-1"
                    style="position: relative">
                    <model
                        v-if="is3dLarge && largeCalculated"
                        :background="background"
                        :background-alpha="backgroundAlpha"
                        :background-color="backgroundColor"
                        :camera-config="cameraConfig[currentFile.id]"
                        :file-name="fileLarge.original_name"
                        :height="largeHeight"
                        :rotation="rotation"
                        :src="fileLarge.url"
                        :width="largeWidth"/>
                    <div
                        v-show="!is3dLarge"
                        :id="`large-canvas-${id}`"
                        ref="largeСanvas"
                        @mousedown="startDrawing"
                        @mouseup="endDrawing"/>

                    <project-reference-marker-menu
                        :is-reply-open="isReplyFor"
                        :comment-data="commentData"
                        :current-file="currentFile"
                        :main-class="`large-comments-${id}`"
                        :store="store"
                        @deleteComment="deleteComment"
                        @cancelComment="cancelComment"
                        @hideComment="hideComment"
                        @setNewIcon="replaceMarker"
                        @save="isReplyFor = null"/>
                </div>
            </v-card>
        </v-dialog>

        <div
            ref="sliderWrap"
            style="position: relative">
            <div
                :class="[`gallery-top-${id}`]"
                :style="{ 'z-index': zIndex }"
                class="swiper-container gallery-top">
                <div
                    :class="[`swiper-wrapper-${id}`]"
                    class="swiper-wrapper">
                    <div
                        v-for="(reference, i) in getProjectFiles"
                        :key="reference.id"
                        :style="{ width: currentWidth, height: currentHeight }"
                        class="swiper-slide reference-item">
                        <div
                            :class="{ 'no-cursor-': isDraw }"
                            class="d-flex justify-center responsive-canvas"
                            @mousedown="startDrawing"
                            @mouseup="endDrawing">
                            <model
                                v-if="
                                    is3d(reference.original_name) &&
                                        sizeCalculated
                                "
                                :ref="'refModel-' + i"
                                :background="background"
                                :background-alpha="backgroundAlpha"
                                :background-color="backgroundColor"
                                :file-name="reference.original_name"
                                :height="currentHeight"
                                :rotation="rotation"
                                :src="reference.url"
                                :width="currentWidth"
                                @getSnapshot="fnc => (getSnapshot = fnc)"
                                @onObject="
                                    obj =>
                                        (last3dConfig = cameraConfig[
                                            reference.id
                                        ] = obj)
                                "
                                @sideLoading="sideLoading"/>
                            <div
                                v-else
                                :id="`reference-${id}_${reference.id}`"/>
                        </div>
                        <div
                            v-if="!hideCaptions && !isInProjectView"
                            class="reference-item__info d-flex align-center justify-space-between justify-sm-start flex-wrap mt-3 px-3">
                            <div
                                :title="
                                    reference.name || reference.original_name
                                "
                                class="reference-item__name mr-8">
                                {{ reference.name || reference.original_name }}
                            </div>
                            <div class="reference-item__type">
                                <strong>Type:</strong> {{ reference.type }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <project-reference-marker-menu
                :is-reply-open="isReplyFor"
                :comment-data="commentData"
                :current-file="currentFile"
                :main-class="`preview-menu-${id}`"
                :store="store"
                @deleteComment="deleteComment"
                @cancelComment="cancelComment"
                @hideComment="hideComment"
                @setNewIcon="replaceMarker"
                @save="isReplyFor = null"/>
        </div>

        <div
            v-if="isInProjectView && thumbsHeader"
            class="pmsb-slider-header mb-7">
            <div class="d-flex align-center">
                <div>
                    <template v-if="renderReviewSlider">Render Specs Files</template>
                    <template v-else>{{ id === 'right' ? 'Model Images' : 'Reference Images' }}</template>
                </div>
                <div class="ml-auto" v-if="isManagerOrAdmin">
                    <button
                        class="add-reference-btn text-no-wrap"
                        @click="downloadAllAssets">
                        Download approved 3D models
                    </button>
                </div>

                <div v-if="showUpload && !isCompleted">
                    <div
                        class="def-blue-link"
                        @click="openUploadDialog">
                        <template v-if="id === 'right'">
                            + Upload Model Images
                        </template>
                        <template v-else>
                            + Add reference images
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-show="!hideThumbs"
            :class="{ 'mt-8': !isInProjectView }"
            class="gallery-thumbs-wrap no-select">
            <div
                :class="[`gallery-thumbs-${id}`]"
                class="swiper-container gallery-thumbs">
                <div class="swiper-wrapper">
                    <div
                        v-for="file in getProjectFiles"
                        :key="file.id"
                        class="swiper-slide reference-item reference-item-thumbs">
                        <div
                            v-if="file.type === $config.fileTypes.reference || !isInProjectView || is3d(file.original_name)"
                            class="reference-item__thumbs-type text-uppercase px-3">
                            {{ getFileType(file.type) }}
                        </div>
                        <div
                            v-if="getCommentsByFileLength(file.id) && !file.hideComments"
                            class="reference-item__comments-counter d-flex align-center">
                            <v-img
                                v-if="getCommentEditStatus"
                                :src="require('@/assets/icons/ic-comment.svg')"
                                class="mr-1"
                                max-width="12"/>
                            <v-img
                                v-else
                                :src="
                                    require('@/assets/_redesigne/icons/ic-comment.svg')
                                "
                                class="mr-1"
                                max-width="17"/>
                            {{ getCommentsByFileLength(file.id) }}
                        </div>
                        <div class="slide">
                            <model
                                v-if="is3d(file.original_name) && sizeCalculated"
                                :background="background"
                                :background-alpha="backgroundAlpha"
                                :background-color="backgroundColor"
                                :camera-config="cameraConfig[file.id]"
                                :file-name="file.original_name"
                                :height="thumbHeight"
                                :rotation="rotation"
                                :src="file.url"
                                :width="thumbWidth"
                                disable-loading/>
                            <v-img
                                v-else
                                :src="file.url"
                                class="reference-item__img"
                                contain
                                max-height="87"/>
                        </div>
                    </div>
                </div>
            </div>

            <model
                v-if="currentFileIs3d || last3dFile"
                :background="background"
                :background-alpha="backgroundAlpha"
                :background-color="backgroundColor"
                :camera-config="last3dConfig"
                :file-name="last3dFileName"
                :height="1200 / (canvasWidth / canvasHeight)"
                :on-before="() => this.hideStartDrawBtn()"
                :rotation="rotation"
                :src="last3dFile"
                :width="1200"
                style="display: none"
                @getSnapshot="fnc => (getSnapshot = fnc)"
                @loading="loading => (this.sideLoading = loading)"/>
            <div
                :class="!!id ? `review__prev-btn-${id}` : ''"
                class="review__swiper-btn review__prev-btn">
                <v-img
                    :src="require('@/assets/_redesigne/icons/ic-arrow-previous.svg')"
                    contain
                    height="8"
                    width="5"/>
            </div>
            <div
                :class="!!id ? `review__next-btn-${id}` : ''"
                class="review__swiper-btn review__next-btn">
                <v-img
                    :src="require('@/assets/_redesigne/icons/ic-arrow-next.svg')"
                    contain
                    height="8"
                    width="5"/>
            </div>
            <disabled v-if="isDraw"/>
        </div>
    </div>
</template>

<script>
    import Disabled from "@/components/ui/Disabled";
    import Swiper, { Navigation, Thumbs } from "swiper";
    import "swiper/swiper.min.css";
    import {
        dataURLtoFile,
        dragBoundFunc,
        getWidth,
        hasExtension,
        showCommentMenu
    } from "@/utils/index.utils";
    import Konva from "konva";
    import { mapGetters } from "vuex";
    import ProjectReferenceMarkerMenu from "@/views/project/start/review-project/ProjectReferenceMarkerMenu";
    import Drawer from "@/components/ui/Drawer";
    import { FILETYPES } from "@/utils/constans";
    import { debounce } from "debounce";

    //import Model from "@/components/3d-model/model";

    import UploadService from "@/api/uploadFilesService.api";
    import { projectApi } from "@/api";
    import ImageEdit from "@/components/ui/ImageEdit";

    const globalLines = [];

    const commentIcon = require("@/assets/icons/ic-canvas-comment.png");

    const colorMap = {
        client: "#0046AF",
        designer: "#C81C31",
        get(role) {
            return this[(role + "").toLowerCase()] || "#02810F";
        }
    };

    function getViewPort() {
        let win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName("body")[0],
            x = win.innerWidth || docElem.clientWidth || body.clientWidth,
            y = win.innerHeight || docElem.clientHeight || body.clientHeight;
        return { w: x, h: y };
    }

    Swiper.use([Thumbs, Navigation]);

    function commentsCounter() {
        return new Date().getTime();
    }

    function shadeColor(color, percent) {
        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        let RR =
            R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
        let GG =
            G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
        let BB =
            B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
    }

    function getCommentSVG(option) {
        const commentIconHoverPath = new Konva.Path({
            x: 0,
            y: 0,
            data:
                "M18.2548 14.5482C18.2548 14.9567 17.9219 15.2895 17.5134 15.2895H14.5482C14.3147 15.2895 14.0953 15.3992 13.9551 15.586L12.3243 17.7603L10.6934 15.586C10.5533 15.3992 10.3338 15.2895 10.1003 15.2895H7.13507C6.72661 15.2895 6.39376 14.9567 6.39376 14.5482V7.13507C6.39376 6.72661 6.72661 6.39376 7.13507 6.39376H17.5134C17.9219 6.39376 18.2548 6.72661 18.2548 7.13507V14.5482ZM17.5134 4.91113H7.13507C5.90894 4.91113 4.91113 5.90894 4.91113 7.13507V14.5482C4.91113 15.7743 5.90894 16.7721 7.13507 16.7721H9.72967L11.7312 19.4409C11.8713 19.6277 12.0907 19.7374 12.3243 19.7374C12.5578 19.7374 12.7772 19.6277 12.9173 19.4409L14.9189 16.7721H17.5134C18.7396 16.7721 19.7374 15.7743 19.7374 14.5482V7.13507C19.7374 5.90894 18.7396 4.91113 17.5134 4.91113ZM16.0308 8.6177H8.6177C8.20849 8.6177 7.87638 8.9498 7.87638 9.35901C7.87638 9.76821 8.20849 10.1003 8.6177 10.1003H16.0308C16.44 10.1003 16.7721 9.76821 16.7721 9.35901C16.7721 8.9498 16.44 8.6177 16.0308 8.6177ZM8.6177 11.5829H16.0308C16.44 11.5829 16.7721 11.9151 16.7721 12.3243C16.7721 12.7335 16.44 13.0656 16.0308 13.0656H8.6177C8.20849 13.0656 7.87638 12.7335 7.87638 12.3243C7.87638 11.9151 8.20849 11.5829 8.6177 11.5829Z",
            fill: "green",
            scale: {
                x: 1,
                y: 1
            },
            ...option
        });
        return commentIconHoverPath;
    }

    function getCommentIcon(option, border = false) {
        const group = new Konva.Group();
        const commentIconHoverBorder = new Konva.Rect({
            width: 22,
            height: 22,
            x: 0.5,
            y: 1,
            fill: "#fff",
            strokeWidth: 1,
            ...option,
            stroke: border ? option.stroke : "#fff"
        });
        group.add(commentIconHoverBorder);
        group.add(getCommentSVG({ fill: option.stroke }));
        return group;
    }

    export default {
        name: "project-references-slider",
        components: {
            ImageEdit,
            Model: () => import("@/components/3d-model/model"),
            Disabled,
            Drawer,
            ProjectReferenceMarkerMenu
        },
        props: {
            backgroundColor: {
                type: [String, Number],
                default: 0xffffff
            },
            backgroundAlpha: {
                type: Number,
                default: 1
            },
            background: {
                type: String,
                default: null
            },
            zIndex: {
                type: Number,
                default: null
            },
            hideCaptions: {
                type: Boolean,
                default: false
            },
            hideDrawer: {
                type: Boolean,
                default: false
            },
            hideThumbs: {
                type: Boolean,
                default: false
            },
            thumbsHeader: {
                type: Boolean,
                default: true
            },
            // we should definitely remove this madness
            store: {
                type: Object,
                required: true
            },
            // WTF? Really???
            store2: {
                type: Object,
                default: null
            },
            id: {
                type: String,
                default: ""
            },
            initiallyOpenedComment: {
                type: [String, Boolean],
                default: null
            },
            renderReviewSlider: {
                type: Boolean,
                default: false
            },
            x2Modal: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            maxLogoSize: 200,
            isLogoEdit: false,
            FILETYPES,
            sideLoading: false,
            startDrawBtn: null,
            last3dFile: null,
            last3dFileName: "",
            last3dConfig: null,
            largeWidth: 0,
            largeHeight: 0,
            cameraConfig: {},
            is3dLarge: false,
            fileLarge: null,
            currentWidth: 615,
            currentHeight: 460,
            sizeCalculated: false,
            largeCalculated: false,
            thumbWidth: 0,
            thumbHeight: 0,
            getSnapshot: null,
            currentSlideIndex: 0,
            currentFileId: 0,
            currentMarkerID: 0,
            swiper: null,
            swiperGallery: null,
            comments: [],
            commentData: {
                idx: null,
                file_id: null,
                comment_id: null,
                type: null
            },
            referenceMenu: null,
            nextComment: null, //{ type: 8, file}
            canvasStages: {},
            canvasLayers: {},
            canvasWidth: 615,
            canvasHeight: 460,
            tempKonvaStage: null,
            drawInProgress: false,
            isReplyFor: null
        }),
        computed: {
            ...mapGetters([
                "isClient",
                "isManager",
                "isAdmin",
                "isDesigner",
                "isManagerOrAdmin",
                "GET_ALL_PROJECT_COMMENTS",
                "GET_DESIGNERS_SLIDER_FILES",
                "GET_USERS_SLIDER_FILES",
                "getMarkerType"
            ]),
            project() {
                return this.$store.getters.getProjectData;
            },
            currentFile() {
                if (this.isLogoEdit) {
                    return this.getProjectFiles[this.currentSlideIndex] || {};
                }

                return this.getProjectFiles[this.currentSlideIndex] || {};
            },
            currentFileIs3d() {
                return (
                    this.currentFile && this.is3d(this.currentFile.original_name)
                );
            },
            isCompleted() {
                return this.$store.getters.getProjectData.status === "Completed";
            },
            showUpload() {
                return (
                    ((this.isClient || this.isManagerOrAdmin) && this.id === "left") ||
                    (this.isDesigner && this.id === "right")
                );
            },
            isInProjectView() {
                return this.$store.getters.getIsInProjectView;
            },
            currentFileInfo() {
                return {
                    id: this.getProjectFiles[this.currentSlideIndex]?.id,
                    index: this.currentSlideIndex
                };
            },
            dialog: {
                get() {
                    console.log("large get", this.isLarge);
                    return this.isLarge;
                },
                set(nv) {
                    console.log("large set", nv);
                    this.store.commit("view/SET_STATUS_LARGE_VIEW", nv);
                }
            },
            isCommentAble() {
                return this.store.getters["drawer/isCommentAble"];
            },
            isDraw() {
                return this.store.getters["drawer/isDraw"];
            },
            isGrater() {
                return this.store.getters["drawer/isGrater"];
            },
            isExit() {
                return this.store.getters["drawer/isExit"];
            },
            getColor() {
                return this.store.getters["drawer/getColor"];
            },
            getBrush() {
                return this.store.getters["drawer/getBrush"];
            },
            isLarge() {
                return this.store.getters["view/isLarge"];
            },

            fileAttachedToDesignersSlider(){
                return (file) => {
                    return  file.props && file.props[0] && file.props[0].attachToDesigner
                }
            },
            fileAttachedToManagerSlider(){
                return (file) => {
                    return  file.props && file.props[0] && file.props[0].attachToManager
                }
            },
            getProjectFiles() {
                if (
                    this.$route.name === "render-configurator" ||
                    this.$route.name === "render-configurator-model" ||
                    this.$route.name === "render-review" ||
                    this.$route.name === "project"
                ) {
                    const filesProject = this.$store.getters["getProjectFiles"].filter(file => {
                        return file.type === this.$config.fileTypes.reference && !this.fileAttachedToDesignersSlider(file) && !this.fileAttachedToManagerSlider(file)
                    }).map(file => {
                        return {
                            ...file,
                            hideComments: true
                        }
                    })
                    let files = this.$store.getters.getCurrentModelAssetsSpecifications(false, this.renderReviewSlider);
                    if (files && !this.renderReviewSlider) {
                        let mainSlide = { ...files };
                        delete mainSlide.children;
                        return [{ ...mainSlide }, ...files.children];
                    } else if (this.renderReviewSlider) {
                        return [...filesProject, ...files];
                    }
                    return [];
                }

                console.log("sideslidersideslidersideslidersideslider", this.id);

                if (this.id === "left") {
                    console.log(
                        "sideslidersideslidersideslidersideslider",
                        this.id,
                        this.GET_USERS_SLIDER_FILES
                    );
                    return this.GET_USERS_SLIDER_FILES;
                }

                if (this.id === "right") {
                    console.log(
                        "sideslidersideslidersideslidersideslider",
                        this.id,
                        this.GET_DESIGNERS_SLIDER_FILES
                    );
                    return this.GET_DESIGNERS_SLIDER_FILES;
                    //return this.$store.getters["GET_DESIGNERS_SLIDER_FILES"];
                }
                return this.$store.getters["getProjectFiles"];
            },
            designers3D() {
                return this.store.getters["designers3D"];
            },
            getMarker() {
                return this.store.getters["getMarker"];
            },
            getCommentsByFile() {
                return this.$store.getters["getCommentsByFile"];
            },
            getCommentEditStatus() {
                return this.$store.getters["getCommentEditStatus"];
            },
            getCommentsByFileLength() {
                return this.$store.getters["getCommentsByFileLength"];
            },
            ...mapGetters(["profile"]),
            disableDrawingTools() {
                return this.isDraw;
            },
            disablePaintingTool() {
                if (this.swiper) {
                    const currentSlideIndex = this.swiper.activeIndex;

                    if (this.getProjectFiles[currentSlideIndex]) {
                        const currentSlideItemName = this.getProjectFiles[
                            currentSlideIndex
                        ].original_name;
                        const currentSlideItemIs3dModel = !!this.is3d(
                            currentSlideItemName
                        );
                        return currentSlideItemIs3dModel;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },
        created() {
            this.afterSlideOpened = {};
            this.inProgressLoading = {};
        },
        async mounted() {
            this.currentFileId = this.getProjectFiles[this.currentSlideIndex]?.id;
            this.$emit("recalculateSlider", this.resize);

            // console.log('ProjectReferencesSlider mounted')
            //
            // store.dispatch('inc');
            // store.dispatch('inc');
            // store.dispatch('inc');
            // store.dispatch('inc');
            //
            // console.log('store.state.count', store.state.count);
            //
            // store.commit('drawer/SET_STATUS_DRAW', true);
            // console.log('store.state SET_STATUS_DRAW', this.store.getters["drawer/isDraw"]);
            // console.log('store.state SET_STATUS_DRAW LOCAL', store.getters["drawer/isDraw"]);

            // ; //TODO

            this.cursorEvent = null;
            this.updateCurrentLine = debounce(
                line => this.store.commit("drawer/UPDATE_LINE", line),
                200
            );

            //
            //await this.store.dispatch('getFilesByProject');
            this.store.watch(
                (state, getters) => getters["view/isLarge"],
                val => {
                    if (!this.x2Modal) return;

                    console.log("watcher store.getters.isLarge", val);
                    this.fileLarge = this.getProjectFiles[this.currentSlideIndex];
                    this.is3dLarge = this.is3d(this.fileLarge.original_name);

                    if (this.is3dLarge) {
                        if (val) {
                            this.$nextTick(() => this.largeView());
                        } else {
                            this.closeLarge();
                        }
                    } else {
                        if (!this.largeStage) {
                            this.largeStage = new Konva.Stage({
                                container: `large-canvas-${this.id}`,
                                width: this.canvasWidth,
                                height: this.canvasHeight
                            });
                            this.setBrushEvents(
                                this.largeStage,
                                () => this.canvasLayers[this.currentFileId]
                            );
                        }

                        if (val) {
                            this.$nextTick(() => this.largeView());
                        } else {
                            this.closeLarge();
                        }
                    }
                }
            );

            this.$nextTick(() => {
                this.initSwiper();
            })
            this.referenceMenu = document.querySelector(`.preview-menu-${this.id}`);
            this.$nextTick(() => {
                this.initCanvas();
                this.$emit("sliderMounted");
            });
            window.addEventListener("resize", this.resize);

            this.$emit("controls", {
                getSnapshot: this.getSnapshot,
                uploadSnapshot: this.uploadSnapshot,
                uploadFile: this.uploadFile,
                setNextComment: this.setNextComment,
                setDrawerStatus: this.setDrawerStatus,
                selectFirstComment: this.selectFirstComment,
                openFirstComment: this.openFirstComment,
                getFile: () => this.getProjectFiles[this.currentSlideIndex],
                removeFile: this.removeFile,
                selectIndex: this.selectIndex,
                selectIndexFromId: this.selectIndexFromId,
                setLogo: this.setLogo,
                uploadLogo: this.uploadLogo,
                editLogo: this.editLogo,
                rotate: this.rotate,
                getRotation: file_id => {
                    if (!file_id) {
                        file_id = this.currentFileId;
                    }
                    return this.cameraConfig[file_id].getRotation();
                }
            });

            this.$nextTick(() => {
                if (this.initiallyOpenedComment) {
                    setTimeout(() => {
                        this.openComment(this.initiallyOpenedComment, true);
                    }, 1000);
                }
            });
        },
        methods: {
            async downloadAllAssets() {
                const { data } = await projectApi.downloadApproved3dModel(this.$route.params.id)
                await this.forceFileDownload(data)
            },
            forceFileDownload (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                const filename= `project_${this.$route.params.id}_designer_assets.zip`
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            },
            startDrawing() {
                this.drawInProgress = true;
            },
            endDrawing() {
                this.store.dispatch("drawer/saveDrawing");
                this.drawInProgress = false;
            },
            // async selectFile(event) {
            //   if (!event.target.files)
            //     return;
            //   const file = event.target.files[0];
            //   if (!hasExtension(file?.name, ['.jpg', '.svg', '.png'])){
            //     const type = 'alertInvalidFileType'
            //     console.log(`Sorry, file "${ file?.name }" is invalid, allowed extensions are: ` + ['.jpg', '.svg', '.png'].join(", "));
            //     this.$store.state.dialogs.content[type].text =
            //         `Sorry, file "${ file?.name }" is invalid, allowed extensions are: ` + ['.jpg', '.svg', '.png'].join(", ");
            //     this.$store.commit(this.$store.state.dialogs.content[type].set, true);
            //     return
            //   }
            //
            //   event.target.value = '';
            //
            //   const iconFile = await UploadService.upload2(file, {
            //     type: FILETYPES.ICON_FOR_COMMENT,
            //     title: 'ICON LOGO',
            //     // parent_id: this.currentFile.id
            //   });
            //
            // },
            rotate(cameraRotation) {
                // console.log('this.cameraConfig', this.cameraConfig[this.currentFileId]);
                // console.log('this.cameraConfig cameraRotation', { ...cameraRotation});
                // console.log('this.CONTROL ORBIT', this.cameraConfig[this.currentFileId].controls);
                // const confg3d = this.cameraConfig[this.currentFileId];
                // this.rotation = {
                //     "x":-3.0892173112965717,
                //     "y":-0.8508870694490898,
                //     "z":-3.1021977822665927,
                // }
                //this.cameraConfig[this.currentFileId].rotation.set( rotation.x, rotation.y, rotation.z );
                // this.cameraConfig[this.currentFileId].controls.update();
                //this.cameraConfig[this.currentFileId].cloneCamera.rotation.set(rotation.x, rotation.y, rotation.z);
                //this.cameraConfig[this.currentFileId].controls.update();
                console.warn("cameraRotation", cameraRotation);
                this.rotation = { ...cameraRotation };
                console.log("ttttteeeesssstttt", this.rotation);
                // this.rotation = { ...cameraRotation};
                //  this.cameraConfig[this.currentFileId].position.set(cameraRotation.x, cameraRotation.y, cameraRotation.z);
                //this.cameraConfig[this.currentFileId].cloneCamera.rotation.set(cameraRotation.x, cameraRotation.y, cameraRotation.z);
                //    confg3d.cloneCamera.rotation.x = Math.cos(-3.0892173112965717);
                //  confg3d.cloneCamera.rotation.y = -0.8508870694490898;
                // confg3d.cloneCamera.position.x =  Math.cos(-3.0892173112965717);
                //  confg3d.cloneCamera.position.x =  -3.0892173112965717;
                //  confg3d.cloneCamera.position.y =  -0.8508870694490898;
                //  confg3d.cloneCamera.position.z = -3.1021977822665927;
                //
                //   confg3d.cloneCamera.scale.x =  0;
                //   confg3d.cloneCamera.scale.y = 0;
                //   confg3d.cloneCamera.scale.z = 0;
                // confg3d.cloneCamera.rotation.z = -3.1021977822665927;
                //  console.log('confg3d.cloneCamera.rotation', confg3d.cloneCamera.rotation);
                // confg3d.cloneCamera.rotation._x = 0;
                // confg3d.cloneCamera.rotation.set(0, 0, 0, "XYZ");
                //confg3d.controls.target.set(-3.0892173112965717, -0.8508870694490898, -3.1021977822665927)
                // {
                //       "x":-3.0892173112965717,
                //       "y":-0.8508870694490898,
                //       "z":-3.1021977822665927,
                //   };

                // this.cameraConfig[this.currentFileId].controls.update();
            },
            removeEditLogo() {
                this.store.dispatch("markersShowAll");

                this.removeEditImage();
                const oldMarker = this.store.getters.getMarker(
                    this.currentMarkerID
                );

                if (this.isLogoEdit && (!oldMarker || !oldMarker.icon_id)) {
                    this.removeFile(this.currentFileId);
                }

                this.isLogoEdit = false;
                this.$emit("onImageEdit", this.isLogoEdit);
            },
            setLogo(file) {
                this.store.dispatch("markersHideAll");
                if (this.isClient || this.isManagerOrAdmin) {
                    this.isLogoEdit = true;
                    this.$emit("onImageEdit", this.isLogoEdit);
                }
                // function to calculate crop values from source image, its visible size and a crop strategy
                const getCrop = (image, size, clipPosition = "center-middle") => {
                    const width = size.width;
                    const height = size.height;
                    const aspectRatio = width / height;

                    let newWidth;
                    let newHeight;

                    const imageRatio = image.width / image.height;

                    if (aspectRatio >= imageRatio) {
                        newWidth = image.width;
                        newHeight = image.width / aspectRatio;
                    } else {
                        newWidth = image.height * aspectRatio;
                        newHeight = image.height;
                    }

                    let x = 0;
                    let y = 0;
                    if (clipPosition === "left-top") {
                        x = 0;
                        y = 0;
                    } else if (clipPosition === "left-middle") {
                        x = 0;
                        y = (image.height - newHeight) / 2;
                    } else if (clipPosition === "left-bottom") {
                        x = 0;
                        y = image.height - newHeight;
                    } else if (clipPosition === "center-top") {
                        x = (image.width - newWidth) / 2;
                        y = 0;
                    } else if (clipPosition === "center-middle") {
                        x = (image.width - newWidth) / 2;
                        y = (image.height - newHeight) / 2;
                    } else if (clipPosition === "center-bottom") {
                        x = (image.width - newWidth) / 2;
                        y = image.height - newHeight;
                    } else if (clipPosition === "right-top") {
                        x = image.width - newWidth;
                        y = 0;
                    } else if (clipPosition === "right-middle") {
                        x = image.width - newWidth;
                        y = (image.height - newHeight) / 2;
                    } else if (clipPosition === "right-bottom") {
                        x = image.width - newWidth;
                        y = image.height - newHeight;
                    } else if (clipPosition === "scale") {
                        x = 0;
                        y = 0;
                        newWidth = width;
                        newHeight = height;
                    } else {
                        console.error(
                            new Error(
                                "Unknown clip position property - " + clipPosition
                            )
                        );
                    }

                    return {
                        cropX: x,
                        cropY: y,
                        cropWidth: newWidth,
                        cropHeight: newHeight
                    };
                };

                // function to apply crop
                const applyCrop = pos => {
                    const currentLayer = this.canvasLayers[this.currentFileId];
                    const img = currentLayer.findOne(".image");
                    img.setAttr("lastCropUsed", pos);
                    const crop = getCrop(
                        img.image(),
                        { width: img.width(), height: img.height() },
                        pos
                    );
                    img.setAttrs(crop);
                    currentLayer.draw();
                };

                console.log("file 111", file);
                Konva.Image.fromURL(
                    file.url, //'https://konvajs.org/assets/darth-vader.jpg',
                    img => {
                        const pos = this.getPointerPosition();

                        img.setAttrs({
                            // width: img.width() / pos.scale,
                            // height: img.height() / pos.scale,
                            width: 100,
                            height: 100,
                            // scaleX: this.canvasStages[this.currentFileId].scaleX(),
                            // scaleY: this.canvasStages[this.currentFileId].scaleY(),
                            x: file.x || 80,
                            y: file.y || 100,
                            name: "image",
                            draggable: this.isLogoEdit
                        });
                        const layer = this.canvasLayers[this.currentFileId];
                        layer.add(img);
                        this.transormImage = img;
                        // apply default left-top crop
                        applyCrop("center-middle");

                        const tr = new Konva.Transformer({
                            nodes: [img],
                            keepRatio: false,
                            boundBoxFunc: (oldBox, newBox) => {
                                if (newBox.width < 10 || newBox.height < 10) {
                                    return oldBox;
                                }
                                return newBox;
                            }
                        });

                        if (this.isClient || this.isManagerOrAdmin)  {
                            layer.add(tr);
                        }
                        layer.draw();
                        this.removeEditImage = () => {
                            img.destroy();
                            tr.destroy();
                            layer.draw();
                        };

                        // img.addEventListener('end', event => {
                        //   event.preventDefault();
                        //
                        //   const marker = this.getMarker(group.comment_id);
                        //   marker.x = group.position().x;// pos.x - itemWidth; // 2 // Math.round((image.position().x + itemWidth / 2) / this.canvasWidth * 100);
                        //   marker.y = group.position().y;//pos.y - itemHeight; // 2 // Math.round((image.position().y + itemHeight / 2) / this.canvasHeight * 100);
                        //
                        //   this.store.dispatch('setMarker', marker);
                        //   const comment = this.store.getters.getCommentThread(marker.file_id, marker.comment_id)[0];
                        //   console.log('comment', comment)
                        //   this.store.dispatch('updateMarker', comment);
                        // });

                        img.on("transform", () => {
                            // reset scale on transform
                            img.setAttrs({
                                scaleX: 1,
                                scaleY: 1,
                                width: img.width() * img.scaleX(),
                                height: img.height() * img.scaleY()
                            });
                            applyCrop(img.getAttr("lastCropUsed"));
                        });
                    }
                );
            },
            editLogo(file) {
                this.editedMarkerID = this.currentMarkerID;
                const oldMarker = this.store.getters.getMarker(
                    this.currentMarkerID
                );
                if (!oldMarker) {
                    const markers = this.store.getters.getMarkers;
                    console.log("markers", markers);
                    let noSavedComment;
                    Object.values(markers).forEach(item => {
                        if (item.file_id === file.id) {
                            noSavedComment = item;
                        }
                    });
                    if (noSavedComment) {
                        alert("Pls, add some comment to the Logo");
                        return;
                    } else {
                        alert(
                            "You have no any logos. This snapshot will be removed"
                        );
                        this.removeFile(file.id);
                        return;
                    }
                }
                const fileIcon = {
                    file_id: oldMarker.icon_id,
                    url: oldMarker.icon_url,
                    x: oldMarker.x,
                    y: oldMarker.y
                };
                console.log("fileIcon", fileIcon);
                this.setLogo(fileIcon);
            },
            async uploadLogo() {
                let currentSnapshot = this.currentFile;
                this.isLogoEdit = false;
                this.$emit("onImageEdit", this.isLogoEdit);
                if (!this.transormImage) {
                    return;
                }
                // console.log("this.transormImage", this.transormImage);
                // console.log("this.transormImage toDataURL", this.transormImage.toDataURL());

                //this.base64 = this.$refs.model.renderer.domElement.toDataURL('image/png', 1);
                const pos = this.getPointerPosition();
                console.log("uploadLogo pos", pos);
                // await UploadService.updateProps(this.currentFileId,  {
                //   size: [{
                //     width: this.transormImage.width(),
                //     height: this.transormImage.height()
                //   }]
                // });
                this.base64 = this.transormImage.toDataURL(
                    { pixelRatio: 1, mimeType: "image/png" },
                    1
                );
                const file = dataURLtoFile(this.base64, "logo.png");
                const posX = this.transormImage.position().x;
                const posY = this.transormImage.position().y;
                const iconFile = await UploadService.upload2(file, {
                    type: FILETYPES.ICON_FOR_COMMENT,
                    title: "ICON LOGO",
                    parent_id: currentSnapshot.id
                });
                console.log("this.editedMarkerID", this.editedMarkerID);
                console.log("this.currentMarkerIDj", this.currentMarkerIDj);
                console.log(
                    "this.oldMarker",
                    this.store.getters.getMarker(this.editedMarkerID)
                );
                console.log(
                    "this.currentMarkerIDj",
                    this.store.getters.getMarker(this.currentMarkerIDj)
                );
                const oldMarker = this.store.getters.getMarker(this.editedMarkerID);

                if (oldMarker) {
                    this.store.dispatch("removeMarker", oldMarker.comment_id);
                    this.createImageMarker(
                        {
                            fileIndex: oldMarker.fileIndex,
                            file_id: oldMarker.file_id,
                            role: oldMarker.role,
                            isReplace: true,
                            openComment: true,
                            icon_id: iconFile.id,
                            comment_id: oldMarker.comment_id,
                            itemTitle: oldMarker.itemTitle, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                            itemURL: iconFile.url, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                            itemType: 5,
                            itemValue: null,
                            posX: posX, //oldMarker.x,
                            posY: posY, //oldMarker.y,
                            onAfterCreateMarker: () => {
                                console.log("replceMarker", oldMarker);
                                const comments = this.store.getters.getCommentThread(
                                    oldMarker.file_id,
                                    oldMarker.comment_id
                                );
                                if (comments && comments[0]) {
                                    this.store.dispatch("updateCommentFull", {
                                        ...comments[0],
                                        type: 5
                                    });
                                }
                            }
                        },
                        true
                    );
                    this.editedMarkerID = null;
                } else {
                    this.createImageMarker(
                        {
                            fileIndex: this.currentSlideIndex,
                            file_id: this.currentFileId, //file_id: this.currentFile.id,
                            hasInit: true,
                            //role: oldMarker.role,
                            //isReplace: true,
                            openComment: true,
                            comment_id: commentsCounter(),
                            icon_id: iconFile.id,
                            itemTitle: "LOGO", // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                            itemURL: iconFile.url, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                            itemType: 5,
                            //itemValue: itemData.itemValue,
                            // posX: 100,
                            ///posY: 80,
                            posX: posX, //oldMarker.x,
                            posY: posY //oldMarker.y,
                            // onAfterCreateMarker: ()=>{
                            //   console.log('replceMarker', oldMarker);
                            //   const comments = this.store.getters.getCommentThread(file_id, oldMarker.comment_id);
                            //   if (comments && comments[0])
                            //     this.store.dispatch('updateCommentFull', comments[0]);
                            // }
                        },
                        true
                    );
                }

                this.store.dispatch("markersShowAll");
                this.removeEditLogo(true);
                // commentApi.create({
                //   // project_id: 201,
                //   // file_id: 201,
                //   // type: 8,
                //   //
                //
                //   parent_id: null,
                //   project_id: 201,
                //   file_id: this.currentFile.id,
                //   icon_id: iconFile.id,
                //   type: 5,
                //   x: 200,
                //   y: 100,
                //   comment: '',
                // });
                this.transormImage = null;
            },
            setNextComment(config) {
                this.nextComment = config;
            },
            openFirstComment(file, callback) {
                this.selectIndexFromId(file.id, () => {
                    let arr = [];
                    if (
                        this.store.getters &&
                        this.store.getters.getProjectData &&
                        this.store.getters.getProjectData.comments
                    ) {
                        arr = Object.keys(
                            this.store.getters.getProjectData.comments[file.id] ||
                                {}
                        );
                    }

                    if (arr.length) {
                        const comment_id = parseInt(arr[0]);
                        const marker = this.store.getters.getMarker(comment_id);

                        if (typeof marker.markerClick === "function") {
                            const [select] = marker.markerClick(null);
                            document.body.style.cursor = "default";
                        }
                    }
                    this.$nextTick(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
            },
            selectFirstComment(file, callback) {
                this.selectIndexFromId(file.id, () => {
                    let arr = [];
                    if (
                        this.store.getters &&
                        this.store.getters.getProjectData &&
                        this.store.getters.getProjectData.comments
                    ) {
                        arr = Object.keys(
                            this.store.getters.getProjectData.comments[file.id] ||
                                {}
                        );
                    }

                    if (arr.length) {
                        //this.currentMarkerID = parseInt(arr[0]);
                        const comment_id = parseInt(arr[0]);
                        const marker = this.store.getters.getMarker(comment_id);

                        if (typeof marker.markerClick === "function") {
                            const [select] = marker.markerClick(null, false);
                            select();
                            document.body.style.cursor = "default";
                        }
                    }

                    //
                    // console.log('this.currentMarkerID ', this.currentMarkerID );
                    // console.log('Click selectFirstComment comemnt', this.store.getters.getProjectData.comments[file.id]);
                    // console.log('Click selectFirstComment files in comments', this.store.getters.getProjectData.comments);
                    this.$nextTick(() => {
                        if (callback) {
                            callback();
                        }
                    });
                });
                // console.log('Click comment currentSlideIndex', this.currentSlideIndex);
                // console.log('Click comment', this.store.getters['getProjectFiles']);
                //  console.log('Click comment currentFile', this.currentFile);
                ///  console.log('Click comment comemntCount', this.geCommentsByFileLength(this.currentFile.id));

                //this.currentMarkerID = comment_id;
                // console.log('selectFirstComment', this.geCommentsByFile());
                // console.log('project Markers', this.store.state.projectData.markers);
            },
            hideStartDrawBtn() {
                console.log("this.hideStartDrawBtn", this.startDrawBtn);
                const find = document.querySelector(".start-draw");
                if (find) {
                    find.style.display = "none";
                }
            },
            openUploadDialog() {
                this.$root.$emit("openUploadDialog");
            },
            is3d(url = "") {
                return hasExtension(url, [
                    ".obj",
                    ".fbx",
                    ".FBX",
                    "3DS",
                    "3ds",
                    ".stl",
                    ".dae",
                    ".ply",
                    ".gltf"
                ]);
            },
            getFileType(typeID) {
                const mapFileType = {
                    1: "Sketch",
                    2: "Reference",
                    3: "3D Model"
                };
                return mapFileType[typeID];
            },
            recalculateCommentIndices(layer) {
                const lay = layer || this.canvasLayers[this.currentFileId];
                const filterComment = child => {
                    return (
                        typeof child.name() === "string" &&
                        child.name().indexOf("comment-") > -1 &&
                        child.children.length > 0
                    );
                };
                lay.children.filter(filterComment).forEach(label => {
                    console.log(
                        "label filterComment",
                        this.store.getters.getCommentNumber(
                            label.attrs.file_id,
                            label.attrs.comment_id
                        ),
                        label
                    );

                label?.children[1]?.children[1]?.setAttrs({
                    text: this.store.getters.getCommentNumber(
                        label.attrs.file_id,
                        label.attrs.comment_id
                    )
                });
                });
                lay.draw();
            },
            preventIfDraw(e) {
                e.stopPropagation();
                e.preventDefault();
                e.preventDefault();
            },
            resize() {
                console.log("resize", this.largeStage);
                if (this.largeStage) {
                    this.fitStageIntoParentContainer(
                        this.largeStage,
                        this.$refs.largeWrapper,
                        true
                    );
                } else {
                    const stages = Object.keys(this.canvasStages);
                    for (let i = 0; i < stages.length; i++)
                        this.fitStageIntoParentContainer(
                            this.canvasStages[stages[i]],
                            this.$refs.sliderWrap
                        );
                }
            },
            replaceMarker({ commentData, iconObj }) {
                //const kes = Object.keys(this.store.getters.getMarkers)
                const oldMarker = this.store.getters.getMarker(
                    commentData.comment_id
                );
                //const itemType = 'EM'
                this.store.dispatch("removeMarker", oldMarker.comment_id);

                const color = iconObj.color;
                let lines = oldMarker.lines;
                if (color) {
                    lines = lines.map(lines => ({ ...lines, stroke: color }));
                    //this.store.commit('drawer/SET_COLOR', color); //selectedObject.color
                    //this.store.commit('drawer/SET_COLOR_FREEZE', true);
                }

                this.createImageMarker(
                    {
                        fileIndex: oldMarker.fileIndex,
                        file_id: oldMarker.file_id,
                        lines: lines,
                        role: oldMarker.role,
                        isReplace: true,
                        openComment: true,
                        comment_id: oldMarker.comment_id,
                        itemTitle: iconObj.name, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                        itemURL: iconObj.image, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                        itemType: iconObj.type,
                        itemValue: iconObj.value,
                        posX: oldMarker.x,
                        posY: oldMarker.y,
                        onAfterCreateMarker: () => {
                            console.log("replceMarker", oldMarker);
                            const comments = this.store.getters.getCommentThread(
                                oldMarker.file_id,
                                oldMarker.comment_id
                            );
                            if (comments && comments[0]) {
                                this.store.dispatch(
                                    "updateCommentFull",
                                    comments[0]
                                );
                            }
                        }
                    },
                    true
                );

                //if (typeof this.currentShowLines === 'function')
                // this.currentShowLines();
            },
            fitStageIntoParentContainer(stage, parent, large = false) {
                console.log("fitStageIntoParentContainer");
                //
                if (!stage) {
                    return;
                }
                let three = false; //3d
                if (!stage.width) {
                    three = true;
                }

                let container = parent; //document.querySelector('.slider-wrap');
                console.log("parent", parent);
                // now we need to fit stage into parent
                let view = getViewPort();
                let wprocent = (view.w / 100) * 20;
                let hprocent = (view.h / 100) * 20;
                let containerWidth = large
                    ? // container.offsetWidth >  view.h ? view.h: container.offsetWidth
                        Math.min(view.w, view.h) -
                        (view.w < view.h ? wprocent : hprocent)
                    : container.offsetWidth;
                // Math.min(wrapper, container.offsetWidth);
                let stageWidth = this.canvasWidth;
                let stageHeight = this.canvasHeight;
                // to do this we need to scale the stage
                //let scale = large &&  wrapper < container.offsetWidth ? containerWidth / stageHeight : containerWidth / stageWidth;
                let scale = containerWidth / stageWidth;
                this.currentScale = scale;
                //
                const thumb = document.querySelector(
                    ".slider-wrap .reference-item-thumbs"
                );
                //  const thumbWrapper  = document.querySelector(`.gallery-thumbs-${this.id} .swiper-wrapper`);
                if (thumb) {
                    this.$nextTick(() => {
                        this.thumbWidth = parseInt(thumb.style.width);
                        this.thumbHeight =
                            this.thumbWidth /
                            (this.currentWidth / this.currentHeight);
                        // console.log(' thumb.style.width',  thumb.style.width);
                        // console.log(' thumb.style.height',  thumb.offsetHeight);
                        //  console.log(' thumb.style.height',  thumbWrapper.offsetHeight);
                    });
                }

                this.$nextTick(() => {
                    this.sizeCalculated = true;
                    console.log("this.sizeCalculated !!!!!!!!!!!!!!!");
                    if (!large || !this.is3dLarge) {
                        this.currentWidth = stageWidth * scale;
                        this.currentHeight = stageHeight * scale;
                        if (!three) {
                            stage.width(stageWidth * scale);
                            stage.height(stageHeight * scale);
                        }
                    } else {
                        this.calculateLargeSize();
                        if (!three) {
                            stage.width(this.largeWidth);
                            stage.height(this.largeHeight);
                        }
                    }

                    //this.largeHeight = getHeight();
                    console.log(this.currentWidth);
                    console.log(this.currentHeight);

                    if (three) {
                        return;
                    }
                    stage.scale({ x: scale, y: scale });
                    stage.draw();
                });
                //if (this.canvasLayers[this.currentFileId])
                // this.canvasLayers[this.currentFileId].draw();
            },
            calculateLargeSize(create = true) {
                const scale = this.canvasWidth / this.canvasHeight;
                const dialog = document.querySelector(".v-dialog--active > div");
                if (dialog && this.sizeCalculated) {
                    this.largeWidth = getWidth(dialog, "width");
                    this.largeHeight = this.largeWidth / scale;

                    const view = getViewPort();
                    const height = view.h * 0.8;
                    if (this.largeHeight > height) {
                        this.largeWidth = height * scale;
                        this.largeHeight = height;
                    }

                    if (create) {
                        this.largeCalculated = true;
                    }
                }
            },
            largeView() {
                // this.$nextTick(()=>{

                //});

                this.calculateLargeSize();
                this.hideComment();
                if (!this.is3dLarge) {
                    this.referenceMenu = document.querySelector(
                        `.large-comments-${this.id}`
                    );
                    this.hideComment();
                }
                //this.largeStage.add(this.canvasLayers[this.currentFileId]);
                this.largeUpdate();
            },
            largeUpdate() {
                // if (this.largeLayer)
                // this.largeLayer.destroy();
                // this.largeLayer = this.canvasLayers[this.currentFileId].clone({ listening: true,  });
                // this.largeStage.add(this.largeLayer);
                // console.log('this.largeStage', this.largeStage);

                console.log("this.is3dLarge", this.is3dLarge);
                if (!this.is3dLarge && this.largeStage.add) {
                    this.largeStage.add(this.canvasLayers[this.currentFileId]);

                    this.$nextTick(() => {
                        this.fitStageIntoParentContainer(
                            this.largeStage,
                            this.$refs.largeWrapper,
                            true
                        );
                    });
                }
            },
            closeLarge() {
                console.log("closeLarge");
                this.largeCalculated = false;
                this.hideComment();
                this.referenceMenu = document.querySelector(
                    `.preview-menu-${this.id}`
                );
                this.hideComment();

                // if (this.canvasLayers[this.currentFileId])
                //  this.canvasLayers[this.currentFileId].destroy();

                if (
                    this.canvasStages[this.currentFileId] &&
                    this.largeStage &&
                    this.canvasStages[this.currentFileId].add
                ) {
                    // this.canvasLayers[this.currentFileId] = this.largeLayer.clone({ listening: true });
                    this.canvasStages[this.currentFileId].add(
                        this.canvasLayers[this.currentFileId]
                    );
                //this.canvasLayers[this.currentFileId].draw();
                this.largeStage?.destroy();
                    this.largeStage = null;
                }
                // console.log('this.largeStage.getLayers', this.largeStage.getLayers());
                // this.canvasStages[this.currentFileId].add(this.canvasLayers[this.currentFileId]);
                // this.canvasStages[this.currentFileId].draw();
                // if (this.largeLayer) {
                //   this.largeLayer.destroy();
                //   this.largeLayer = null;
                // }
            },
            initSwiper() {
                this.swiperGallery = new Swiper(".gallery-thumbs-" + this.id, {
                    observer: true,
                    spaceBetween: 28,
                    slidesPerView: 3,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    followFinger: false,
                    longSwipes: false,
                    shortSwipes: false,
                    allowTouchMove: false,
                    resizeObserver: true,
                    breakpoints: {
                        280: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 3
                        }
                    }
                });

                this.swiper = new Swiper(".gallery-top-" + this.id, {
                    observer: true,
                    followFinger: false,
                    longSwipes: false,
                    shortSwipes: false,
                    allowTouchMove: false,
                    resizeObserver: true,
                    navigation: {
                        nextEl: this.id
                            ? ".review__next-btn-" + this.id
                            : ".review__next-btn",
                        prevEl: this.id
                            ? ".review__prev-btn-" + this.id
                            : ".review__prev-btn"
                    },
                    thumbs: {
                        swiper: this.swiperGallery
                    }
                });
                this.swiper.on("transitionEnd", () => {
                    console.log("*** mySwiper.realIndex", this.swiper.realIndex);

                    //const index = this.swiper.realIndex;
                    // const is3d = this.getProjectFiles[index] && this.is3d(this.getProjectFiles[index]);
                    // if (is3d) {
                    // this.last3dFile = this.currentFile.url;
                    // this.store.commit('drawer/SET_COMMENT_STATUS', false);
                    // this.store.commit('drawer/SET_HIDE_BRUSH', true);
                    // } else {
                    //  this.store.commit('drawer/SET_COMMENT_STATUS', true);
                    //  this.store.commit('drawer/SET_HIDE_BRUSH', false);
                    // }

                    //this.$nextTick(()=>{

                    this.currentSlideIndex = this.swiper.realIndex;
                    this.currentFileId = this.getProjectFiles[
                        this.swiper.realIndex
                    ].id;
                    if (this.swiper.realIndex > 0) {
                        this.$emit(
                            "fileSelected",
                            this.getProjectFiles[this.swiper.realIndex]
                        );
                    }
                    //})
                });
                this.swiper.on("observerUpdate", () => {
                    console.log("swiper observerUpdate!!!!!");
                    this.initCanvas();
                });
            },
            getScale() {
                const stage2 =
                    this.largeStage || this.canvasStages[this.currentFileId];
                return stage2.scaleX();
            },
            getPointerPosition() {
                const stage =
                    this.largeStage || this.canvasStages[this.currentFileId];
                let oldScale = stage.scaleX();
                let pointer = stage.getPointerPosition();
                if (!pointer) {
                    return {
                        x: stage.x() / oldScale,
                        y: stage.y() / oldScale,
                        scale: oldScale
                    };
                }
                return {
                    x: (pointer.x - stage.x()) / oldScale,
                    y: (pointer.y - stage.y()) / oldScale,
                    scale: oldScale
                };
            },
            draw({ fileIndex, file_url, file_id, file_comments }) {
                console.log("stage konva", `reference-${this.id}_${file_id}`);
                if (this.is3d(this.getProjectFiles[fileIndex].original_name)) {
                    this.canvasStages[file_id] = {};

                    this.canvasLayers[file_id] = new Konva.Layer();
                    this.fitStageIntoParentContainer({}, this.$refs.sliderWrap);
                    return;
                }

                this.tempKonvaStage = new Konva.Stage({
                    container: `reference-${this.id}_${file_id}`,
                    width: this.canvasWidth,
                    height: this.canvasHeight
                });

                this.fitStageIntoParentContainer(
                    this.tempKonvaStage,
                    this.$refs.sliderWrap
                );
                this.canvasStages[file_id] = this.tempKonvaStage;

                const layer = new Konva.Layer();
                this.canvasLayers[file_id] = layer;
                this.setBrushEvents(
                    this.tempKonvaStage,
                    () => this.canvasLayers[this.currentFileId]
                );
                Konva.Image.fromURL(file_url, response => {
                    const img = response.attrs.image;
                    const scale = Math.min(
                        this.canvasWidth / img.width,
                        this.canvasHeight / img.height
                    );
                    const width = img.width * scale;
                    const height = img.height * scale;
                    response.setAttrs({
                        width,
                        height,
                        x: (this.canvasWidth - width) / 2,
                        y: (this.canvasHeight - height) / 2,
                        draggable: false
                    });

                    layer.add(response);
                    layer.batchDraw();
                    //
                    //file_markers.map(marker => {
                    //const marker = file_comments[0][0];
                    //

                    let typeItem;
                    file_comments.forEach(commentThread => {
                        const marker = commentThread[0] || {}; //exactly comment Object
                        console.log(
                            "marker marker marker marker marker marker marker marker marker marker marker marker",
                            marker
                        );
                        switch (marker.type) {
                            case 1:
                            case 4:
                                //
                                this.createCommentMarker(
                                    {
                                        fileIndex,
                                        file_id,
                                        lines: marker.canvas_data,
                                        role: marker.user_position,
                                        hasInit: true,
                                        comment_id: marker.id, //marker.comment_id,
                                        x: marker.x,
                                        y: marker.y,
                                        itemType: marker.type
                                    },
                                    true
                                );
                                break;
                            case 2:
                                typeItem = this.$store.getters.getStone(
                                    marker.stone_type
                                );
                                this.createImageMarker(
                                    {
                                        fileIndex,
                                        file_id,
                                        hasInit: true,
                                        lines: marker.canvas_data,
                                        role: marker.user_position,
                                        comment_id: marker.id,
                                        itemTitle: typeItem?.name,
                                        itemURL: typeItem?.image,
                                        itemType: 2,
                                        itemValue: marker.stone_type,
                                        posX: marker.x,
                                        posY: marker.y
                                    },
                                    true
                                );
                                break;
                            case 3:
                                typeItem = this.$store.getters.getMetal(
                                    marker.metal_type
                                );
                                this.createImageMarker(
                                    {
                                        fileIndex,
                                        file_id,
                                        hasInit: true,
                                        lines: marker.canvas_data,
                                        role: marker.user_position,
                                        comment_id: marker.id,
                                        itemTitle: typeItem?.name,
                                        itemURL: typeItem?.image,
                                        itemType: 3,
                                        itemValue: marker.metal_type,
                                        posX: marker.x,
                                        posY: marker.y
                                    },
                                    true
                                );
                                break;
                            case 5:
                                this.createImageMarker(
                                    {
                                        fileIndex,
                                        file_id,
                                        hasInit: true,
                                        lines: marker.canvas_data,
                                        role: marker.user_position,
                                        comment_id: marker.id,
                                        icon_id: marker.icon_id,
                                        itemTitle: "LOGO",
                                        itemURL: marker?.icon_url,
                                        itemType: 5,
                                        // itemValue: marker.metal_type,
                                        posX: marker.x,
                                        posY: marker.y
                                    },
                                    true
                                );
                                break;
                        }
                    });
                    Object.values(this.canvasLayers).forEach(layer =>
                        this.recalculateCommentIndices(layer)
                    );
                    response.addEventListener("click", () => {
                        if (this.isDraw || !this.isCommentAble) {
                            return;
                        }
                        //Background
                        if (
                            this.currentFile.type === 7 ||
                            this.currentFile.type === 5
                        ) {
                            return;
                        }

                        if (
                            this.currentFile.type === 4 &&
                            this.getCommentsByFileLength(this.currentFile.id) > 0
                        ) {
                            return;
                        }
                        this.hideComment();
                        this.deleteCommentMarker(file_id);
                        this.store.dispatch("clearItemData");
                        let pos = this.getPointerPosition();
                        console.log("stage", this.tempKonvaStage);

                        this.createCommentMarker({
                            file_id,
                            openComment: true,
                            //role: this.$store.getters.profile.role,
                            comment_id: commentsCounter(),
                            x: pos.x,
                            y: pos.y, //event.offsetY,
                            fileIndex,
                            itemType:
                                this.currentFile.type === FILETYPES.ENGRAVING
                                    ? 4
                                    : 1
                        });
                    });
                    this.inProgressLoading[file_id] = false;
                    const callback = this.afterSlideOpened[file_id];
                    if (typeof callback === "function") {
                        delete this.afterSlideOpened[file_id];
                        callback();
                    }
                });

                const con = this.tempKonvaStage.container();
                con.addEventListener("dragover", event => {
                    event.preventDefault(); // !important
                });

                con.addEventListener("drop", event => {
                    console.log("img drop!!!");
                    event.preventDefault();
                    if (this.isDraw || !this.isCommentAble) {
                        return;
                    }
                    const unicID = commentsCounter();
                    this.tempKonvaStage.setPointersPositions(event);
                    let { itemTitle, itemURL, itemType, itemValue } = JSON.parse(
                        event.dataTransfer.getData("item")
                    );
                    itemType = parseInt(itemType);
                    this.deleteCommentMarker(file_id);
                    let pos = this.getPointerPosition();

                    this.createImageMarker({
                        itemTitle,
                        itemURL,
                        itemType,
                        itemValue,
                        openComment: true,
                        event,
                        //posX: pos.x,
                        // posY: pos.y,
                        file_id,
                        fileIndex,
                        comment_id: unicID,
                        stage: this.tempKonvaStage
                    });

                    // this.showCommentMenu(event, this.largeLayer || this.canvasLayers[fileIndex], null, {
                    //   fileIndex, file_id, comment_id: unicID, type: itemType,
                    // });
                });

                this.tempKonvaStage.add(layer);
            },
            showCommentMenu: showCommentMenu,
            async createImageMarker(data, initialRender = false) {
                const {
                    itemURL,
                    hasInit,
                    isReplace,
                    onAfterCreateMarker,
                    itemType,
                    event = {},
                    itemTitle,
                    itemValue,
                    comment_id,
                    file_id,
                    icon_id = null,
                    fileIndex,
                    lines = [],
                    stage,
                    openComment = false,
                    posX,
                    posY,
                    role = this.$store.getters.profile.role
                } = data;
                if (typeof this.currentHideLines === "function") {
                    this.currentHideLines();
                }
                const itemWidth = 18;
                const itemHeight = 18;
                const canvasPadding = 0;
                const minX = canvasPadding;
                const minY = canvasPadding;
                const maxX = this.canvasWidth - (itemWidth + canvasPadding);
                const maxY = this.canvasHeight - (itemHeight + canvasPadding);
                const stage2 = this.largeStage || this.canvasStages[file_id]; //this.canvasStages[this.currentFileId];
                let oldScale = stage2.scaleX();
                //stage.getCurrentPosition()
                const pos =
                    hasInit || isReplace
                        ? { x: posX, y: posY }
                        : dragBoundFunc(
                            {
                                pos: {
                                    x: Math.round(event.offsetX) / oldScale,
                                    y: Math.round(event.offsetY) / oldScale
                                },
                                minX,
                                maxX,
                                minY,
                                maxY
                            },
                            this.getScale()
                        );
                const group = new Konva.Group();
                this.currentShowLines = null;
                this.currentHideLines = null;

                //Math.round((event.offsetX + itemWidth / 2) / this.canvasWidth * 100),
                //Math.round((event.offsetY + itemHeight / 2) / this.canvasHeight * 100),
                //
                const commentIconHoverWidth = 24;
                const defaultColor = colorMap.get(role);
                console.log("role", role, colorMap.get(role));

                // Konva.Image.fromURL(commentIconHover, commentIconHover => {
                const commentIconHover = getCommentIcon(
                    { stroke: defaultColor },
                    true
                );
                const commentIcon = getCommentIcon({ stroke: defaultColor }, false);
                //commentIconHover.children.each((child)=>{
                // child
                // })
                console.log("commentIconHover", commentIconHover);
                console.log("commentIcon", commentIcon);

                //commentIconHover.fill("green");
                //commentIcon.fill("red");
                commentIconHover.attrs.width = commentIconHoverWidth;
                commentIconHover.attrs.height = commentIconHoverWidth;
                // Konva.Image.fromURL(commentIcon, commentIcon => {
                commentIcon.attrs.width = 21;
                commentIcon.attrs.height = 21;
                commentIcon.position({ x: -6, y: 12 });
                Konva.Image.fromURL(itemURL, image => {
                    const currentLayer = () =>
                        this.largeLayer ||
                        this.canvasLayers[file_id] || {
                            draw: () => {}
                        };
                    const isLogo = () => itemType === this.$config.fileTypes.logo;

                    function createLabel(text) {
                        const comment = new Konva.Label({
                            file_id,
                            comment_id,
                            x: 0,
                            y: 0,
                            draggable: false
                        });

                        comment.add(
                            new Konva.Tag({
                                fill: defaultColor
                            })
                        );

                        comment.add(
                            new Konva.Text({
                                text: text,
                                height: 24,
                                padding: 8,
                                fontSize: 12,
                                lineHeight: 0.7,
                                fill: "#fff"
                            })
                        );
                        comment.draggable(false);
                        return comment;
                    }

                    group.comment_id = comment_id;
                    group.file_id = file_id;
                    //group.attrs.width = 150;
                    //group.attrs.height = 150;
                    if (!isLogo()) {
                        // image.attrs.width = itemWidth;
                        // image.attrs.height = itemHeight;
                        // } else {
                        image.attrs.width = itemWidth;
                        image.attrs.height = itemHeight;
                        image.strokeWidth(2);
                    } else {
                        //const pos = this.getPointerPosition()
                        // img.setAttrs({
                        //   width: img.width() / pos.scale,
                        //   height: img.height() / pos.scale,
                        // const scal = image.width() / image.height();

                        //image.attrs.width = image.width() / this.currentScale;
                        // image.attrs.height = image.height() / this.currentScale;

                        const stage = this.largeStage || this.canvasStages[file_id];
                        let scale = stage.scaleX();
                        image.attrs.width = image.width() / scale;
                        image.attrs.height = image.height() / scale;

                        // const scal = image.width() / image.height();
                        // image.attrs.width = this.maxLogoSize;
                        // image.attrs.height = this.maxLogoSize / scal;
                    }
                    console.log("add log marker", itemURL);
                    const marker = {
                        fileIndex,
                        file_id,
                        lines,
                        parent_id: null,
                        role: role,
                        comment_id,
                        x: pos.x,
                        y: pos.y,
                        type: itemType,
                        itemType,
                        itemTitle, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                        itemURL, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                        itemValue,

                        comment: "",
                        shape: group,
                        draw: () => currentLayer().draw()
                    };
                    console.log("createImageMarker itemType", itemType);
                    console.log("createImageMarker icon_id", icon_id);
                    console.log("createImageMarker itemURL", itemURL);
                    if (itemType === 5) {
                        marker.icon_id = icon_id;
                        marker.icon_url = itemURL;
                    }

                    if (itemType === 2 || itemType === 3) {
                        marker[
                            itemType === 2 ? "stone_type" : "metal_type"
                        ] = itemValue;
                    }

                    //if (!isLogo()) {
                    const comment = createLabel(itemTitle, pos.x - 24, pos.y - 24);
                    const rect = new Konva.Rect({
                        width: 22,
                        height: 22,
                        x: 0,
                        y: 1,
                        fill: "#fff",
                        strokeWidth: 2,
                        stroke: defaultColor
                        //offsetX : 50,
                        //scaleY : 2
                    });
                    const groupLines = new Konva.Group();

                    comment.name("commentFUCK");
                    comment.position({ x: -comment.width(), y: 0 });
                    commentIconHover.position({
                        x: -comment.width() - 28 + 6,
                        y: 0
                    });
                    // }

                    //init drawn lines
                    if (lines.length) {
                        lines.forEach(lineOption => {
                            const line = this.createKonvaLine(lineOption);
                            groupLines.add(line);
                        });
                    }

                    const previewIcon = new Konva.Group();
                    previewIcon.name("previewIcon");
                    group.name("commentImage");
                    if (!isLogo()) {
                        previewIcon.add(rect);
                        previewIcon.add(image);
                        group.add(comment);
                        group.add(commentIconHover);
                        group.add(previewIcon);
                        group.add(commentIcon);
                    } else {
                        group.add(image);
                    }

                    currentLayer().add(groupLines);

                    const setCommentColor = color => {
                        if (commentIconHover.children.length) {
                            commentIconHover.children[0].stroke(color);
                            commentIconHover.children[1].fill(color);
                        }
                        if (commentIcon.children.length === 2) {
                            commentIcon.children[1].fill(color);
                        }
                        if (comment.children[0]) {
                            comment.children[0].fill(color);
                        }
                        //rect.stroke(color);
                    };
                    const selectMarker = () =>
                        setCommentColor(shadeColor(defaultColor, 60));
                    const unselectMarker = () => setCommentColor(defaultColor);

                    const showLines = () => {
                        console.log("comment mouseover");
                        document.body.style.cursor = "pointer";
                        groupLines.show();
                        currentLayer().draw();
                    };
                    const hideLines = () => {
                        console.log("comment mouseout");
                        document.body.style.cursor = "default";
                        groupLines.hide();
                        currentLayer().draw();
                    };

                    const iconFocus = () => {
                        comment.show();
                        commentIconHover.show();
                        commentIcon.hide();
                        showLines();
                    };
                    const iconFocusout = () => {
                        if (this.currentMarkerID !== comment_id) {
                            comment.hide();
                            commentIconHover.hide();
                            commentIcon.show();
                            unselectMarker();
                            hideLines();
                        }
                    };

                    if (!openComment) {
                        groupLines.hide();
                    }

                    if (openComment) {
                        this.currentShowLines = () => {
                            this.currentMarkerID = comment_id;
                            iconFocus();
                        };
                        this.currentHideLines = () => {
                            this.currentMarkerID = null;
                            iconFocusout();
                        };
                        console.log("isReplace", isReplace);
                    }
                    iconFocusout();

                    const markerClick = (evnt, showComment = true) => {
                        console.log("markerClick createImageComment");
                        this.hideComment();
                        this.currentShowLines = () => {
                            this.currentMarkerID = comment_id;
                            selectMarker();
                            iconFocus();
                        };
                        this.currentHideLines = () => {
                            this.currentMarkerID = null;
                            iconFocusout();
                        };
                        const pos = {
                            offsetX: group.position().x,
                            offsetY: group.position().y
                        };

                        if (showComment) {
                            this.showCommentMenu(pos, currentLayer(), null, {
                                fileIndex,
                                file_id,
                                comment_id,
                                type: itemType
                            });
                        }

                        return [this.currentShowLines, this.currentHideLines];
                    };
                    marker.markerClick = markerClick;
                    this.store.dispatch("setMarker", marker);
                    group.addEventListener("click", markerClick);

                    group.addEventListener("dragstart", event => {
                        event.preventDefault();
                        this.hideComment();
                    });

                    group.addEventListener("dragend", event => {
                        event.preventDefault();

                        const marker = this.getMarker(group.comment_id);
                        marker.x = group.position().x; // pos.x - itemWidth; // 2 // Math.round((image.position().x + itemWidth / 2) / this.canvasWidth * 100);
                        marker.y = group.position().y; //pos.y - itemHeight; // 2 // Math.round((image.position().y + itemHeight / 2) / this.canvasHeight * 100);

                        this.store.dispatch("setMarker", marker);
                        const comment = this.store.getters.getCommentThread(
                            marker.file_id,
                            marker.comment_id
                        )[0];
                        console.log("comment", comment);
                        this.store.dispatch("updateMarker", comment);
                    });

                    // if (!initialRender) {
                    // image.position(stage.getPointerPosition());
                    //image.position(pos);
                    //} else {
                    currentLayer().add(group);
                    //group.position({x: 0, y: 0});
                    image.position({ x: 2, y: 3 });
                    group.position(pos);
                    group.draggable(false);
                    if (!isLogo()) {
                        group.dragBoundFunc(pos => {
                            return dragBoundFunc(
                                {
                                    pos,
                                    minX,
                                    maxX,
                                    minY,
                                    maxY
                                },
                                this.getScale()
                            );
                        });
                    } else if (isLogo()) {
                        // const itemWidth = 18;
                        // const itemHeight = 18;
                        //const canvasPadding = 0;
                        //  const minX = 0;
                        //  const minY = 0;
                        // const maxX = this.canvasWidth;// - (itemWidth + canvasPadding);
                        // const maxY = this.canvasHeight;//- (itemHeight + canvasPadding);
                        // group.dragBoundFunc(pos => {
                        //   return dragBoundFunc({
                        //     pos, minX, maxX, minY, maxY
                        //   }, this.getScale());
                        // });
                    }

                    previewIcon.on("mouseover", () => {
                        document.body.style.cursor = "pointer";
                        iconFocus();
                        console.log("shape mouseover");
                    });
                    previewIcon.on("mouseout", () => {
                        document.body.style.cursor = "default";
                        console.log("shape mouseout");
                        iconFocusout();
                        //currentLayer().draw();
                        //this.largeStage.drahis.largeStage || this.canvasStages[this.currew();
                    });

                    currentLayer().draw();

                    const showCommentPopUp = () => {
                        setTimeout(() => {
                            this.showCommentMenu(pos, currentLayer(), comment, {
                                fileIndex,
                                file_id,
                                comment_id,
                                type: 1
                            });
                        }, 0);
                    };
                    //isReplace &&
                    if (openComment) {
                        selectMarker();
                        this.currentShowLines();
                        showCommentPopUp();
                    }

                    if (hasInit && openComment) {
                        // showCommentPopUp();
                    } else {
                        // setTimeout(()=>{
                        //   this.showCommentMenu(null, currentLayer(), comment, {
                        //     fileIndex, file_id, comment_id, type: 1,
                        //   });
                        // },0)
                    }
                    if (typeof onAfterCreateMarker === "function") {
                        onAfterCreateMarker();
                    }

                    // if (isLogo() && hasInit) {
                    //     console.log('addComment', '');
                    //     this.store.dispatch('addComment', {
                    //       project_id: this.store.getters.getProjectID,
                    //       file_id: file_id,
                    //       parent_id: null,
                    //       comment_id: comment_id,
                    //       comment: '',
                    //       username: `${this.profile.firstName } ${ this.profile.lastName}`,
                    //     });
                    // }

                    //this.largeUpdate()
                    // return new Promise((resolve)=>{
                    //  resolve({ fileIndex, file_id, comment_id });
                    // });
                });
                // });
                // });
            },
            createCommentMarker(data) {
                const {
                    comment_id,
                    hasInit,
                    file_id,
                    x,
                    y,
                    fileIndex,
                    number = null,
                    openComment = false,
                    onAfterCreateMarker = null,
                    role = this.$store.getters.profile.role,
                    itemType
                } = data;

                let { lines = [] } = data;
                if (lines === null) {
                    lines = [];
                }

                if (typeof this.currentHideLines === "function") {
                    this.currentHideLines();
                }

                this.currentShowLines = null;
                this.currentHideLines = null;

                //
                let commentTextNumber =
                    number !== null
                        ? number
                        : this.store.getters
                            .getCommentsByFile(file_id)
                            .filter(comments => {
                                const firstComment = comments[0];
                                return firstComment && firstComment.type === 1; //&& !firstComment.parent_id  && !firstComment.isEmpty
                            }).length + (hasInit ? 0 : 1);

                if (itemType === FILETYPES.ENGRAVING) {
                    commentTextNumber = "Engraving";
                }

                const currentLayer = () =>
                    this.largeLayer ||
                    this.canvasLayers[file_id] || {
                        draw: () => {}
                    };
                //
                // const commentTextNumber = this.getMarkers.filter(marker => {
                //  //
                //   if (marker.file_id === file_id && marker.type === 1 && !marker.isEmpty && !marker.parent_id) {
                //     return marker;
                //   }
                // }).length + 1;

                const comment = new Konva.Label({
                    file_id,
                    comment_id,
                    x,
                    y,
                    draggable: this.isClient || this.isManagerOrAdmin
                });

                console.log("role", role, colorMap.get(role));
                const defaultColor = colorMap.get(role);
                const tag = new Konva.Tag({
                    fill: defaultColor
                });
                comment.add(tag);

                comment.add(
                    new Konva.Text({
                        text: commentTextNumber,
                        height: 24,
                        padding: 8,
                        fontSize: 16,
                        lineHeight: 0.7,
                        fill: "#fff"
                    })
                );

                const canvasPadding = 0;
                const minX = canvasPadding;
                const minY = canvasPadding;
                const maxX =
                    this.canvasWidth -
                    (comment.children[0].width() + canvasPadding);
                const maxY =
                    this.canvasHeight -
                    (comment.children[0].height() + canvasPadding);

                comment.dragBoundFunc(pos => {
                    const fuck = dragBoundFunc(
                        {
                            pos,
                            minX,
                            maxX,
                            minY,
                            maxY
                        },
                        this.getScale()
                    );

                    return fuck;
                });

                const group = new Konva.Group();
                const groupLines = new Konva.Group();
                // group.attrs.x = x;
                // group.attrs.y = y;
                if (lines.length) {
                    lines.forEach(lineOption => {
                        const line = this.createKonvaLine(lineOption);
                        groupLines.add(line);
                    });
                }

                comment.children[1].setAttrs({
                    text: commentTextNumber
                });
                // this.deleteCommentMarker();
                //currentLayer().add(group);

                const selectMarker = () => tag.fill(shadeColor(defaultColor, 60));
                const unselectMarker = () => tag.fill(defaultColor);

                const showLines = () => {
                    console.log("comment mouseover");
                    document.body.style.cursor = "pointer";
                    groupLines.show();
                    selectMarker();
                    currentLayer().draw();
                };

                const hideLines = () => {
                    console.log("comment mouseout");
                    document.body.style.cursor = "default";
                    groupLines.hide();
                    unselectMarker();
                    currentLayer().draw();
                };

                comment.on("mouseover", evt => {
                    showLines();
                });

                comment.on("mouseout", evt => {
                    //if (this.currentMarkerID === comment_id) {
                    // this.currentShowedDraw = hideLines;
                    //} else
                    if (this.currentMarkerID !== comment_id) {
                        hideLines();
                    }
                });

                const currentShowLines = () => {
                    this.currentMarkerID = comment_id;
                    showLines();
                };
                const currentHideLines = () => {
                    this.currentMarkerID = null;
                    hideLines();
                };

                const markerClick = (event, showComment = true) => {
                    console.log("click", "largeComment", event);
                    console.log("markerClick");
                    this.hideComment(); // hide prev draw lines

                    this.currentShowLines = currentShowLines;
                    this.currentHideLines = currentHideLines;

                    const pos = {
                        offsetX: comment.position().x,
                        offsetY: comment.position().y
                    };
                    //showLines();

                    if (showComment) {
                        this.showCommentMenu(pos, currentLayer(), null, {
                            fileIndex,
                            file_id,
                            comment_id,
                            type: 1
                        });
                    }
                    return [this.currentShowLines, this.currentHideLines];
                };
                comment.addEventListener("click", markerClick);

                group.add(groupLines);
                group.add(comment);
                if (itemType !== FILETYPES.ENGRAVING) {
                    group.name("comment-" + comment_id);
                }
                group.setAttr("file_id", file_id);
                group.setAttr("comment_id", comment_id);
                currentLayer().add(group);

                if (!openComment) {
                    groupLines.hide();
                }

                if (openComment) {
                    this.currentShowLines = () => {
                        this.currentMarkerID = comment_id;
                        showLines();
                    };
                    this.currentHideLines = () => {
                        this.currentMarkerID = null;
                        hideLines();
                    };
                    //this.currentShowLines();
                    // groupLines.hide();
                    // this.currentGroupLines = hideLines;
                }

                comment.addEventListener("dragstart", event => {
                    event.preventDefault();

                    this.hideComment();
                    console.log("CommentMarker dragstart");
                });

                comment.addEventListener("dragend", event => {
                    event.preventDefault();
                    const marker = this.getMarker(comment_id);
                    marker.x = comment.position().x;
                    marker.y = comment.position().y;
                    this.store.dispatch("setMarker", marker);
                    this.store.dispatch(
                        "updateMarker",
                        this.store.getters.getCommentThread(
                            marker.file_id,
                            marker.comment_id
                        )[0]
                    );
                    console.log("CommentMarker dragend");
                });

                currentLayer().draw();
                // const showComment = ()=>this.showCommentMenu(null, currentLayer(), comment, {
                //   fileIndex, file_id, comment_id, type: 1,
                // });

                if (hasInit) {
                    this.store.dispatch("setMarker", {
                        fileIndex,
                        file_id,
                        number: commentTextNumber,
                        parent_id: null,
                        id: comment_id,
                        lines,
                        x,
                        y,
                        type: itemType ?? 1,
                        comment_id,
                        shape: group,
                        currentShowLines,
                        currentHideLines,
                        markerClick,
                        draw: () => currentLayer().draw()
                        //isEmpty: false
                    });
                    if (openComment) {
                        //select()

                        setTimeout(() => {
                            this.showCommentMenu(null, currentLayer(), comment, {
                                fileIndex,
                                file_id,
                                comment_id,
                                type: 1
                            });
                        }, 0);
                    }
                } else {
                    this.store.dispatch("setMarker", {
                        fileIndex,
                        file_id,
                        number: commentTextNumber,
                        parent_id: null,
                        comment_id,
                        lines,
                        x: Math.round(x), // / this.canvasWidth * 100
                        y: Math.round(y), // / this.canvasHeight * 100
                        type: itemType ?? 1,
                        comment: "",
                        shape: group,
                        currentShowLines,
                        currentHideLines,
                        markerClick,
                        draw: () => currentLayer().draw() //currentLayer.draw()
                        //isEmpty: true,
                    });

                    //select()
                    setTimeout(() => {
                        this.showCommentMenu(null, currentLayer(), comment, {
                            fileIndex,
                            file_id,
                            comment_id,
                            type: 1
                        });
                    }, 0);

                    //this.largeUpdate()
                }

                if (typeof onAfterCreateMarker === "function") {
                    onAfterCreateMarker();
                }
            },
            async openComment(commentId, isReplyFor = null) {
                const comment = this.GET_ALL_PROJECT_COMMENTS.find(
                    comment => comment.id == commentId
                );
                console.log(
                    "************************************************* OPENING TARGET COMMENT",
                    comment
                );
                const pos = {
                    offsetX: comment.x,
                    offsetY: comment.y
                };

                const currentLayer = () =>
                    this.largeLayer ||
                    this.canvasLayers[comment.file_id] || { draw: () => {} };
                const fileIndex =
                    this.id == "right"
                        ? this.GET_DESIGNERS_SLIDER_FILES.findIndex(
                            file => file.id == comment.file_id
                        )
                        : this.GET_USERS_SLIDER_FILES.findIndex(
                            file => file.id == comment.file_id
                        );

                if (fileIndex > -1) {
                    this.commentData = {
                        idx: comment.id,
                        comment_id: comment.id,
                        file_id: comment.file_id,
                        type: comment.type
                    };

                    await this.swiper.slideTo(fileIndex, 50, false);

                    this.showCommentMenu(pos, currentLayer(), null, {
                        fileIndex: fileIndex,
                        file_id: comment.file_id,
                        comment_id: comment.id,
                        type: comment.type
                    });
                }

                this.isReplyFor = isReplyFor;
            },
            deleteCommentMarker(file_id) {
                Object.keys(this.store.getters.getMarkers).forEach(commentID => {
                    //console.log("deleteCommentMarker", file_id, commentID, this.store.getters.isCommentThread(file_id, commentID))
                    if (
                        this.store.getters.getMarker(commentID).file_id ===
                        file_id &&
                        !this.store.getters.isCommentThread(file_id, commentID)
                    ) {
                        this.store.dispatch("removeMarker", commentID);
                    }
                });
            },
            async deleteComment(data) {
                this.isReplyFor = null;

                if (data.type == 1 || data.type == 2 || data.type == 3) {
                    //await this.store.dispatch("addComment", data);
                    data.project_id = this.$route.params.id;
                    this.store.dispatch("deleteComment", data);
                    this.hideComment();

                    return;
                }

                if (!this.$route.name === "render-configurator") {
                    this.hideComment();
                    if (this.currentFile.type !== this.$config.fileTypes.logo) {
                        this.deleteCommentMarker(data.file_id);
                        const callBack = () => {
                            console.log("recalculateCommentIndices");
                            this.recalculateCommentIndices();
                        };
                        this.store.dispatch("deleteComment", { ...data, callBack });
                    }
                } else {
                    this.hideComment();
                }
            },
            cancelComment(data) {
                this.isReplyFor = null;
                if (
                    this.currentFile.type === this.$config.fileTypes.logo &&
                    this.$route.name === "render-configurator"
                ) {
                    data.project_id = this.$route.params.id;
                    this.store.dispatch("addComment", data);
                    this.hideComment();
                    this.$emit("closeDrawMode");
                    return;
                }

                if ((this.isManager || this.isAdmin) || this.$route.name === "render-configurator") {
                    this.hideComment();
                    return;
                }

                if (
                    (this.getMarkerType == 3 || this.getMarkerType == 2) &&
                    (this.$route.name === "project-start-edit" ||
                        this.$route.name === "project-edit")
                ) {
                    data.project_id = this.$route.params.id;
                    this.store.dispatch("addComment", data);
                    this.hideComment();
                    this.$emit("closeDrawMode");

                    return;
                } else if (
                    this.store.getters.getMarkerType === 5 &&
                    this.$route.name === "render-configurator"
                ) {
                    console.log("configurator");
                    data.project_id = this.$route.params.id;
                    this.store.dispatch("addComment", data);
                    this.hideComment();
                    this.$emit("closeDrawMode");

                    return;
                } else {
                    data.project_id = this.$route.params.id;
                    this.store.dispatch("deleteComment", data);
                    this.hideComment();
                    this.$emit("closeDrawMode");

                    return;
                }

                // if (this.getMarkerType == 1) {
                //     data.project_id = this.$route.params.id;
                //     this.store.dispatch("deleteComment", data);
                //     this.hideComment();

                //     return;
                // }
            },
            hideComment() {
                this.isReplyFor = null;

                if (!this.referenceMenu) return;
                this.referenceMenu.style.display = "none";
                if (typeof this.currentHideLines === "function") {
                    this.currentHideLines();
                }
                //!this.isDraw && this.currentMarkerID === null &&
                //
                // if ( this.currentGroupLines)
                // this.currentGroupLines();

                //this.currentMarkerID = null;
            },
            hideMenu(event) {
                //
                // console.log("event", event.path);
                const slider = document.querySelector(".swiper-wrapper-" + this.id);
                const large = document.querySelector(".large__wrapper-" + this.id);
                if (
                    this.referenceMenu &&
                    !this.referenceMenu.contains(event.target) &&
                    !event.path.includes(slider) &&
                    !event.path.includes(large)
                ) {
                    this.hideComment();
                }
            },
            initCanvas() {
                console.warn("stage konva ", this.id, this.getProjectFiles);
                this.getProjectFiles.forEach((file, fileIndex) => {
                    if (!this.canvasStages[file.id]) {
                        this.inProgressLoading[file.id] = true;
                        this.draw({
                            fileIndex,
                            file_id: file.id,
                            file_url: file.url,
                            file_comments: file.hideComments ? [] : this.getCommentsByFile(file.id) //this.getMarkers.filter(marker => marker.reference_id === file.id),
                        });
                    }
                });
                window.addEventListener("click", this.hideMenu);
            },
            destroyCanvas() {
                this.getProjectFiles.forEach((file, idx) => {
                    if (this.canvasLayers[idx]) {
                        if (this.canvasLayers[idx].destroy) {
                            this.canvasLayers[idx].destroy();
                        }
                        if (this.canvasStages[idx].destroy) {
                            this.canvasStages[idx].destroy();
                        }
                    }
                    return file;
                });
                this.canvasLayers = [];
                this.hideComment();
                window.removeEventListener("click", this.hideMenu);
            },
            createKonvaLine(options = {}) {
                let currentLine;
                currentLine = new Konva.Line({
                    opacity: 0.5,
                    stroke: options.stroke || this.getColor,
                    strokeWidth: options.strokeWidth || this.getBrush,
                    globalCompositeOperation: "source-over",
                    points: options.points || [0, 0],
                    tension: 1,
                    lineCap: "round",
                    lineJoin: "round"
                });
                currentLine.attrs.id = new Date().getTime();
                currentLine.addEventListener("click", () => {
                    if (this.isDraw && this.isGrater) {
                        console.log("remove line");
                        this.store.commit(
                            "drawer/REMOVE_LINE_BY_OBJECT",
                            currentLine
                        );
                        currentLine.destroy();
                        this.canvasLayers[this.currentFileId].draw();
                    }
                });
                // currentLine.zIndex(0);
                return currentLine;
            },
            setBrushEvents(stage, currentLayer) {
                //const layer = new Konva.Layer();
                //if (this.isPaint)
                //return;
                console.log("startBrushLayer");
                //const stage = this.canvasStages[this.currentFileId];
                //const layer = currentLayer();
                let isPaint = false;
                let mode = "brush";
                let lastLine;
                //
                // function lineToLineForSave(lastLine) {
                //   return {
                //    // globalCompositeOperation:"source-over",
                //    // points:Array[2]
                //     stroke: lastLine.attrs.stroke,
                //     strokeWidth: lastLine.attrs.strokeWidth,
                //     points: lastLine.points()
                //   }
                // }
                //
                //stage.add(layer);
                stage.on("mousedown touchstart", () => {
                    if (!this.isDraw || this.isGrater) {
                        return;
                    }

                    console.log("startBrushLayer mousedown touchstart");
                    console.log("this.isPaint", isPaint);
                    isPaint = true;
                    let pos = this.getPointerPosition();
                    lastLine = this.createKonvaLine({
                        points: [pos.x, pos.y]
                    });
                    globalLines.push(lastLine);
                    this.store.commit("drawer/ADD_LINE", lastLine);
                    // new Konva.Line({
                    //   stroke: '#df4b26',
                    //   strokeWidth: 5,
                    //   globalCompositeOperation:
                    //       mode === 'brush' ? 'source-over' : 'destination-out',
                    //   points: [pos.x, pos.y],
                    // });
                    currentLayer().add(lastLine);
                });

                stage.on("mouseup touchend", () => {
                    console.log("startBrushLayer MOUSEUP touchend");
                    isPaint = false;
                    this.store.dispatch("drawer/forceUpdateLines");
                });

                stage.on("mousemove touchmove", () => {
                    if (!isPaint) {
                        return;
                    }
                    const pos = this.getPointerPosition();
                    let newPoints = lastLine.points().concat([pos.x, pos.y]);
                    lastLine.points(newPoints);
                    currentLayer().batchDraw();
                });
            },
            setDrawerStatus(status) {
                this.store.commit("drawer/SET_STATUS_DRAW", status);
            },
            async uploadSnapshot(type, callback) {
                //const [file] = this.getSnapshot();
                const [file] = this.$refs[
                    "refModel-" + this.currentSlideIndex
                ][0].getSnapshot();
                const formData =
                    typeof type === "object"
                        ? type
                        : {
                            type,
                            parent_id: this.currentFile.id
                        };
                console.log("|||||SNAPSHOT|||||", formData);
                let data = await UploadService.upload2(file, formData);

                if (this.id == "right") {
                    data = await UploadService.updateProps(data.id, [
                        { attachToDesigner: true }
                    ]);
                }

                if (
                    this.$route.name === "render-configurator" ||
                    this.$route.name === "render-configurator-model"
                ) {
                    let asset = {
                        id: data.id,
                        created_at: data.created_at,
                        title: data.title,
                        type: parseInt(data.type),
                        url: data.url,
                        user: data.user_name,
                        view_id: data.view_id
                    };

                    console.log("||||||||||||||DATAPROPS|||||||||||", data.props);

                    if (data.props && data.props.length > 0) {
                        const props = data.props.map(prop => JSON.parse(prop));
                        const targetIndex = props.findIndex(
                            prop => prop["backgroundColor"]
                        );
                        console.log(props);
                        if (asset.type === 10) {
                            asset["shadow"] = props[0];
                        }
                        console.log(asset);
                        if (targetIndex >= 0) {
                            asset["backgroundColor"] =
                                props[targetIndex]["backgroundColor"];
                        }
                    }
                    this.$store.commit("pushFileToAssetsSpecifications", asset);
                } else {
                }

                //if (this.$route.name !== "project-start-edit") {
                this.$store.commit("setProjectFiles", data);
                //}

                this.selectIndexFromId(data.id, callback);

                return data;
            },
            async uploadFile(file, type) {
                const formData = typeof type === "object" ? type : { type };
                const data = await UploadService.upload2(file, formData);
                if (
                    this.$route.name === "render-configurator" ||
                    this.$route.name === "render-configurator-model"
                ) {
                    this.$store.commit("pushFileToAssetsSpecifications", {
                        id: data.id,
                        created_at: data.created_at,
                        title: data.title,
                        type: parseInt(data.type),
                        url: data.url,
                        user: data.user_name,
                        view_id: data.view_id
                    });
                } else {
                    this.$store.commit("setProjectFiles", data);
                }
                return data;
            },
            async removeFile(file_id) {
                //const currentSliderIndex = this.findIndex(file_id);
                this.store.commit("removeFile", file_id);
                if (this.canvasStages[file_id]) {
                    this.canvasStages[file_id].destroy();
                }
                if (this.canvasLayers[file_id]) {
                    this.canvasLayers[file_id].destroy();
                }
                delete this.canvasStages[file_id];
                delete this.canvasLayers[file_id];
                await UploadService.removeFile(file_id);
                this.$store.commit("removeFileToAssetsSpecifications", file_id);
                return this.selectIndex(
                    this.currentSlideIndex < this.getProjectFiles.length
                        ? this.currentSlideIndex
                        : 0
                );
            },
            findIndex(file_id) {
                return this.getProjectFiles.findIndex(item => item.id === file_id);
            },
            selectIndex(sliderIndex) {
                this.swiper.slideTo(sliderIndex, 0);
                this.currentSlideIndex = sliderIndex;
                this.currentFileId = this.getProjectFiles[sliderIndex]?.id;

                return this.getProjectFiles[sliderIndex];
            },
            selectIndexFromId(file_id, callback) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        console.log(this.getProjectFiles, "test 0000", file_id);
                        const index = this.getProjectFiles.findIndex(item => {
                            return item.id === file_id;
                        });
                        console.log("index", index);
                        if (index >= 0) {
                            this.selectIndex(index);
                            if (typeof callback === "function") {
                                if (this.inProgressLoading[this.currentFileId]) {
                                    this.afterSlideOpened[
                                        this.currentFileId
                                    ] = callback;
                                } else {
                                    callback();
                                }
                            }
                        }
                    });
                });
            },
            drawSavedLines() {
                this.editedMarkerID = this.currentMarkerID;
                console.log("drawSavedLines");

                if (this.currentMarkerID) {
                    const marker = this.store.getters.getMarker(
                        this.currentMarkerID
                    );
                    if (marker?.lines?.length) {
                        console.log("drawSavedLines drawing 100%");
                        marker.lines.forEach(lineOption => {
                            const line = this.createKonvaLine(lineOption);
                            this.store.commit("drawer/ADD_LINE", line);
                            this.canvasLayers[this.currentFileId].add(line);
                        });
                    }
                }
                this.canvasLayers[this.currentFileId].draw();
            },
            repplaceFile() {
                const payload = Object.assign({}, this.currentFileInfo);
                payload.type = this.currentFile.type;
                this.$root.$emit("replaceProjectFile", payload);
            }
        },
        watch: {
            currentFileIs3d: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.last3dFileName = this.currentFile.original_name;
                        this.last3dFile = this.currentFile.url;
                        this.last3dConfig = this.cameraConfig[this.currentFile.id];
                        console.log("this.startDrawBtn", this.startDrawBtn);
                        //if (this.startDrawBtn) {
                        //  this.startDrawBtn.$el.style.display =  'none'
                        //  }
                        //   const find = document.querySelector('.start-draw');
                        //   if (find)
                        //     find.style.display = 'none';
                        this.store.commit("drawer/SET_COMMENT_STATUS", false);
                        //this.store.commit('drawer/SET_HIDE_BRUSH', true);
                    }
                    // else {
                    // const find = document.querySelector('.start-draw');
                    // if (find)
                    //   find.style.display = '';
                    //  if (this.startDrawBtn) {
                    //   this.startDrawBtn.$el.style.display =  ''
                    // }
                    // this.store.commit("drawer/SET_COMMENT_STATUS", true);
                    //this.store.commit('drawer/SET_HIDE_BRUSH', false);
                    // }
                }
            },
            "store.state.drawer.comment": {
                handler(isCommentAble) {
                    if (this.currentFileIs3d && isCommentAble) {
                        this.store.commit("drawer/SET_COMMENT_STATUS", false);
                        this.uploadSnapshot(2).then(data => {
                            if (this.isLarge && this.store2 && !(this.isClient || this.isManagerOrAdmin)) {
                                this.store.dispatch("drawer/largeView", false);
                            } else if (this.isLarge) {
                                this.closeLarge();
                            }

                            // Replace 3d image with snapshot
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.swiper.slideTo(
                                        this.getProjectFiles.length
                                    );
                                    this.currentSlideIndex =
                                        this.getProjectFiles.length - 1;
                                    this.currentFileId = this.getProjectFiles[
                                    this.currentSlideIndex
                                ]?.id;
                                    const file = this.getProjectFiles[
                                        this.currentSlideIndex
                                    ];

                                    if (this.isLarge) {
                                        this.is3dLarge = this.is3d(
                                            file.original_name
                                        );

                                        this.largeStage = new Konva.Stage({
                                            container: `large-canvas-${this.id}`,
                                            width: this.canvasWidth,
                                            height: this.canvasHeight
                                        });
                                        this.setBrushEvents(
                                            this.largeStage,
                                            () =>
                                                this.canvasLayers[
                                                    this.currentFileId
                                                ]
                                        );
                                        this.largeView();
                                    }
                                }, 0);
                            });
                            projectApi.bindFile(this.store.getters.getProjectID, {
                                files: [data.id]
                            });
                        });
                    }
                }
            },
            "store.state.drawer.status": {
                handler(isDraw) {
                    console.log("SET_STATUS_DRAW");

                    console.warn("this.currentFileIs3d", this.currentFileIs3d);
                    if (this.currentFileIs3d) {
                        alert("You can not draw on 3d image, pls take a snapshot");
                    }
                    this.$emit("onDraw", isDraw);

                    function lineToSaveObj(lastLine) {
                        return {
                            // globalCompositeOperation:"source-over",
                            // points:Array[2]
                            stroke: lastLine.attrs.stroke,
                            strokeWidth: lastLine.attrs.strokeWidth,
                            points: JSON.parse(JSON.stringify(lastLine.points()))
                        };
                    }

                    console.log("watch draw", isDraw);
                    console.log("drawer markerID", this.currentMarkerID);
                    if (isDraw) {
                        this.$nextTick(() => {
                            if (this.editedMarkerID) {
                                const currentMarker = this.store.getters.getMarker(
                                    this.editedMarkerID
                                );
                                const color = this.$store.getters.getBrushColor(
                                    currentMarker.stone_type ||
                                        currentMarker.metal_type
                                );
                                console.warn("color", color);
                                console.warn("this", this);
                                if (color) {
                                    this.$store.commit("drawer/SET_COLOR", color); //selectedObject.color
                                    this.$store.commit(
                                        "drawer/SET_COLOR_FREEZE",
                                        true
                                    );
                                }
                            }
                        });

                        this.store.dispatch("markersHideAll");
                        this.drawSavedLines();
                        //this.startBrushLayer()
                    } else {
                        const file_id = this.currentFileId; // this.store.getters.getProjectFiles[this.currentSlideIndex].id; //
                        //this.deleteCommentMarker(file_id);

                        if (this.isExit) {
                            this.store.dispatch("clearItemData");
                            this.deleteCommentMarker(file_id);
                            this.store.dispatch("markersShowAll");
                            //set previews drawing
                            this.canvasLayers[this.currentFileId].draw();
                            console.log(
                                "isExit getLineExport",
                                JSON.stringify(
                                    this.store.getters["drawer/getLineExport"]
                                )
                            );
                        } else {
                            const lines = this.store.getters[
                                "drawer/getLineExport"
                            ];
                            //if (lines.length > 0) {
                            //marker = this.store.getters.getMarker(this.currentMarkerID)
                            const oldMarker = this.store.getters.getMarker(
                                this.editedMarkerID
                            );

                            if (this.editedMarkerID === null || !oldMarker) {
                                if (lines.length > 0) {
                                    const itemData = this.store.getters.getItemData;
                                    if (!itemData) {
                                        this.createCommentMarker(
                                            {
                                                fileIndex: this.currentSlideIndex,
                                                file_id,
                                                hasInit: false,
                                                openComment: true,
                                                lines,
                                                comment_id: commentsCounter(), //marker.comment_id,
                                                x: lines[0].points[0],
                                                y: lines[0].points[1],
                                                itemType:
                                                    this.currentFile.type ===
                                                    FILETYPES.ENGRAVING
                                                        ? 4
                                                        : 1
                                            },
                                            true
                                        );
                                    } else {
                                        this.createImageMarker(
                                            {
                                                fileIndex: this.currentSlideIndex,
                                                file_id,
                                                lines,
                                                hasInit: true,
                                                //role: oldMarker.role,
                                                //isReplace: true,
                                                openComment: true,
                                                comment_id: commentsCounter(),
                                                itemTitle: itemData.itemTitle, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                                                itemURL: itemData.itemURL, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                                                itemType: itemData.itemType,
                                                itemValue: itemData.itemValue,
                                                posX: lines[0].points[0],
                                                posY: lines[0].points[1]
                                                // onAfterCreateMarker: ()=>{
                                                //   console.log('replceMarker', oldMarker);
                                                //   const comments = this.store.getters.getCommentThread(file_id, oldMarker.comment_id);
                                                //   if (comments && comments[0])
                                                //     this.store.dispatch('updateCommentFull', comments[0]);
                                                // }
                                            },
                                            true
                                        );
                                    }
                                }
                                // this.store.dispatch('clearItemData');
                            } else {
                                const number = this.store.getters.getCommentNumber(
                                    oldMarker.file_id,
                                    oldMarker.comment_id
                                );
                                this.store.dispatch(
                                    "removeMarker",
                                    oldMarker.comment_id
                                );
                                if (oldMarker.metal_type || oldMarker.stone_type) {
                                    this.createImageMarker(
                                        {
                                            fileIndex: oldMarker.fileIndex,
                                            file_id: oldMarker.file_id,
                                            lines,
                                            role: oldMarker.role,
                                            isReplace: true,
                                            openComment: true,
                                            comment_id: oldMarker.comment_id,
                                            itemTitle: oldMarker.itemTitle, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                                            itemURL: oldMarker.itemURL, // this.store.getters[iconObj.type===2 ? 'getStone' : 'getMetal'](iconObj.value)?.image,
                                            itemType: oldMarker.itemType,
                                            itemValue: oldMarker.itemValue,
                                            posX: oldMarker.x,
                                            posY: oldMarker.y,
                                            onAfterCreateMarker: () => {
                                                console.log(
                                                    "replceMarker",
                                                    oldMarker
                                                );
                                                const comments = this.store.getters.getCommentThread(
                                                    oldMarker.file_id,
                                                    oldMarker.comment_id
                                                );
                                                if (comments && comments[0]) {
                                                    this.store.dispatch(
                                                        "updateCommentFull",
                                                        comments[0]
                                                    );
                                                }
                                            }
                                        },
                                        true
                                    );

                                    // this.createImageMarker({
                                    //   fileIndex: oldMarker.fileIndex,
                                    //   file_id: oldMarker.file_id,
                                    //   hasInit: true,
                                    //   lines,
                                    //   role: oldMarker.user_position,
                                    //   comment_id: oldMarker.id,
                                    //   itemTitle: oldMarker?.name,
                                    //   itemURL: oldMarker?.image,
                                    //   itemType: 3,
                                    //   itemValue: oldMarker.metal_type,
                                    //   posX: oldMarker.x,
                                    //   posY: oldMarker.y,
                                    // }, true);
                                } else {
                                    const comments = this.store.getters.getCommentThread(
                                        oldMarker.file_id,
                                        oldMarker.comment_id
                                    );
                                    this.createCommentMarker(
                                        {
                                            fileIndex: oldMarker.fileIndex,
                                            file_id: oldMarker.file_id,
                                            hasInit: true,
                                            number:
                                                comments && comments[0]
                                                    ? number
                                                    : number + 1,
                                            lines,
                                            openComment: true,
                                            comment_id: oldMarker.comment_id,
                                            x: oldMarker.x,
                                            y: oldMarker.y,
                                            itemType:
                                                this.currentFile.type ===
                                                FILETYPES.ENGRAVING
                                                    ? 4
                                                    : 1,
                                            onAfterCreateMarker: () => {
                                                console.log(
                                                    "saveMarker after EDIT",
                                                    oldMarker
                                                );

                                                if (comments && comments[0]) {
                                                    this.store.dispatch(
                                                        "updateCommentFull",
                                                        comments[0]
                                                    );
                                                }
                                            }
                                        },
                                        true
                                    );
                                }
                                //console.log('recal after save');
                            }
                            this.store.dispatch("clearItemData");
                            this.editedMarkerID = null;
                            console.log("getLineExport", lines);
                            //}
                            this.store.dispatch("drawer/clearLines");
                            this.store.dispatch("markersShowAll");
                        }
                    }
                }
            },
            "store.state.drawer.progress": {
                handler(val) {
                    if (!this.isDraw) {
                        return;
                    }

                    console.log("watch progress", val);
                    this.store.dispatch("drawer/setVisibilityOfLines");
                    this.canvasLayers[this.currentFileId].draw();
                }
            },
            "store.state.drawer.grater": {
                handler(val) {
                    // if (!this.isDraw)
                    //   return
                    // ${ require('@/assets/img/cursor-grater.png') }
                    if (val) {
                        this.$refs.sliderWrap.classList.add("cursor-grater");
                    } else {
                        this.$refs.sliderWrap.classList.remove("cursor-grater");
                    }
                    console.log("watch grater", val);
                }
            },
            "store.state.drawer.color": {
                handler(val) {
                    if (!this.isDraw) {
                        return;
                    }
                    console.log("watch grater", val);
                }
            },
            "store.state.drawer.brush": {
                handler(val) {
                    if (!this.isDraw) {
                        return;
                    }
                    console.log("watch grater", val);
                }
            }
        },
        beforeDestroy() {
            console.log("ProjectReferencesSlider beforeDestroy");
            window.removeEventListener("resize", this.resize);
            if (this.swiper) {
                this.swiper.destroy();
            }
            if (this.swiperGallery) {
                this.swiperGallery.destroy();
            }
            this.destroyCanvas();
        }
    };
</script>

<style>
.cursor {
    width: 3rem;
    height: 3rem;
    border: 2px solid black;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.no-cursor {
    cursor: none;
}

.konvajs-content {
    margin-left: auto !important;
    margin-right: auto !important;
}
</style>

<style lang="scss" scoped>
.reference-item__name,
.reference-item__type {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #464646;
}

.reference-item {
    position: relative;
    &__name {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__comment-btn {
        text-transform: none;
        letter-spacing: 0;
    }
    &__img {
        width: 100%;
    }
    .slide{
        height: calc(100% - 10px);
        position: relative;
        background: #ffffff;
        border: 1px solid #bcbcbc;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    &__thumbs-type {
        position: absolute;
        z-index: 2;
        border: 1px solid #bcbcbc;
        box-sizing: border-box;
        border-radius: 5px;
        background: #ffffff;
        top: 0px;
        right: -5px;
        font-size: 12px;
        line-height: 15px;
        color: #696969;
        max-width: 80%;
        width: 95px;
        text-align: center;
        box-shadow: 4px 4px 4px rgb(70 70 70 / 15%);
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__comments-counter {
        position: absolute;
        left: 6px;
        bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        height: 26px;
        width: 36px;
        border: 1px solid #464646;
        box-sizing: border-box;
        background: #9ff4a9;
        z-index: 2;
    }
}
.gallery-thumbs-wrap {
    position: relative;
}
.gallery-thumbs {
    width: calc(100% - 100px);
    .swiper-slide {
        height: 104px;
        padding-top: 10px;
        .reference-item__img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: calc(50% + 8px);
    left: 0;
    background: #fff;
    z-index: 2;
    cursor: pointer;
}
.swiper-button-next {
    left: auto;
    right: 0;
}
.swiper-button-disabled > div {
    opacity: 0.5;
}
.is-editing {
    .reference-item__comments-counter {
        color: #cbd2d0;
    }
    .gallery-thumbs-wrap {
        pointer-events: none;
    }
}
</style>

<style lang="scss" scoped>
.add-reference-btn{
    font-size: 14px;
    line-height: 18px;
    color: #3f7359;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: auto;
    position: relative;
}
.review__swiper-btn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 22px;
    height: 22px;
    margin-right: 28px;
    border: 1px solid #888888;
    border-radius: 50%;
    color: #464646;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
    transition: opacity 250ms ease;
    z-index: 1;

    &.review__prev-btn {
        left: 0;
    }
    &.review__next-btn {
        right: -28px;
    }
}

.pmsb-slider-header {
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #bcbcbc;
    font-size: 14px;
    line-height: 18px;
    color: #464646;
}
</style>
