<template>
    <div class="tools-panel">
        <div class="left-group">
            <button class="act-btn delete" v-if="extendedMode && !edit && !disableDelete" @click="$emit('deleteFile')">
                Delete
            </button>
            <button class="act-btn replace" v-if="extendedMode && !edit && !disableReplace" @click="$emit('replace')">
                {{ isDesigner || isRendersView ? "New version" : "Replace" }}
            </button>
            <button
                v-if="edit"
                class="exit-edition"
                @click="$emit('exit')">
                Exit Drawing Mode
            </button>
            <button
                v-if="edit"
                class="action-btn"
                @click="$emit('undo')">
                <img src="@/assets/_redesigne/icons/ic-undo.svg">
            </button>
            <button
                v-if="edit"
                class="action-btn"
                @click="$emit('redo')">
                <img src="@/assets/_redesigne/icons/ic-redo.svg">
            </button>
        </div>
        <div class="right-group">
            <div class="comments-hide-control" @click="toggleComments" v-if="!edit && extendedMode">
                comments
                <div class="slide">
                    <div class="dot" :class="{'at-right': commentsHidden}"></div>
                    <div class="text" :class="{'text-left': commentsHidden, 'text-right': !commentsHidden}">{{ commentsHidden ? 'OFF' : 'ON' }}</div>
                </div>
            </div>
            <button
                class="action-btn"
                v-if="edit"
                @click="$emit('delete')">
                <img src="@/assets/_redesigne/icons/ic-trash-can.svg">
            </button>
            <button
                v-if="!edit && extendedMode"
                class="action-btn"
                @click="$emit('add-comment')"
                :class="{'active-mode': mode==='comment'}">
                <img src="@/assets/_redesigne/icons/ic-add-comment.svg">
            </button>
            <button
                v-if="!edit && extendedMode"
                class="action-btn"
                @click="$emit('start-draw')"
                :class="{'active-mode': mode==='draw'}">
                <img src="@/assets/_redesigne/icons/ic-start-edition.svg">
            </button>
            <button
                class="action-btn"
                @click="$emit('scale-up')">
                <img v-if="x2Modal" src="@/assets/_redesigne/icons/ic-exit.svg" width="12" height="13">
                <img v-else src="@/assets/_redesigne/icons/ic-scale-up.svg">
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        props: {
            edit: Boolean,
            extendedMode: {
                type: Boolean,
                default: false
            },
            x2Modal: {
                type: Boolean,
                default: false
            },
            disableDelete: {
                type: Boolean,
                default: false
            },
            disableReplace: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                validator: (value)=>{
                    return ["draw", "comment"].includes(value)
                }
            },
            isRendersView: {
                type: Boolean,
                default: false
            }
        },
        data:()=>({
            commentsHidden: false
        }),
        computed: {
            ...mapGetters([
                "isDesigner"
            ])
        },
        methods: {
            toggleComments(){
                this.commentsHidden=!this.commentsHidden
                this.$emit("commentsVisibilityChange", this.commentsHidden)
            }
        }
    };
</script>

<style
  scoped
  lang="scss"
  src="./style.scss"
></style>
