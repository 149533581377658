<template>
    <v-container class="pa-6 project-view" fluid>
        <div class="project-scroll-section">
            <div
                v-if="!$store.getters.getProjectLoading"
                class="grid ml-auto mr-auto">
                <project-header
                    :edit-title="false"
                    :show-type="false"
                    :show-status="false"
                    class="mb-4"/>
                <v-row>
                    <v-col cols="12" md="7">
                        <!--
                <project-references-slider
                :id="'left'"
                :store="$store"/>
            -->
                        <product-slider
                            :hide-controls="true"
                            :allow-change-metal="false"
                            :allow-change-stone="false"
                            :disable-files-update="true"
                            ref="slider"
                            :items="slides"
                            :atachments="null"
                            :enable-delete="false"
                            :allow-replay-comment="isClient || isManagerOrAdmin || (isDesigner && !isCreated && !isDraft)"
                            @addReply="addReply"
                            @attachmentPlaced="jewelryAttachments = null"
                            @updateFile="updateFile"
                            @addComment="addComment"
                            @addMetall="addMetall"
                            @addStone="addStone"
                            @setModelPreview="setModelPreview"
                            @addSnapshot="addSnapshot"/>
                    </v-col>

                    <v-col cols="12" md="5" style="position: relative">
                        <project-details class="mb-6" :data="getDetails"/>
                        <review-block v-if="project.description || getProjectLinks.length" title="Additional Information">
                            <project-description readonly/>
                        </review-block>
                        <modeling-tech-specs v-if="isDesigner" class="mt-5"/>
                        <div v-if="(isClient || isManager || isAdmin) && getProjectData.status === getValueProjectStatus($config.project.status.pricing_needed)">
                            <div class="conteiner-title mb-5">
                                CUSTOM PROJECT OFFER
                            </div>

                            <div class="deatils-container" v-if="isClient && getProjectData.status === getValueProjectStatus($config.project.status.pricing_needed)">
                                <div class="content">
                                    <p class="mb-0" style="font-weight: 600;">Our internal team is having a look at your project.</p>
                                </div>
                            </div>

                            <div class="deatils-container" v-if="(isManager || isAdmin) && getProjectData.status === getValueProjectStatus($config.project.status.pricing_needed)">
                                <div class="content flex-column">
                                    <p class="mb-3" style="font-weight: 600;">Enter custom project amount</p>
                                    <number-input
                                        v-model="projectPrice"
                                        type="number"
                                        :disabled="priceBtnLoading"
                                        hide-controls
                                        suffix="$"
                                        prefix=""/>
                                    <div class="d-flex justify-end mt-4">
                                        <v-btn
                                            width="180"
                                            height="40"
                                            depressed
                                            rounded
                                            outlined
                                            :loading="priceBtnLoading"
                                            @click="setProjectPrice">
                                            Send Offer to Client
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <dialog-accept
            v-model="dialog"
            :data="getDetails"
            @acceptProject="$store.dispatch('loadProject')"/>

        <template v-if="isClient">
            <dialog-cancel-project v-model="cancelDialog" :project="project"/>
        </template>
        <template v-if="isManager || isAdmin">
            <dialog-cancel-project-manager
                v-model="cancelDialog"
                :project="project"/>
        </template>

        <project-footer v-if="!isCancelled">
            <div class="def-page-cont pos-rel">
                <v-row align="center">
                    <v-col class="d-flex align-center">
                        <v-btn
                            v-if="!isCancelled && ((allowedCancel(project.status_id) && isClient ) || isManager || isAdmin)"
                            elevation="0"
                            text
                            rounded
                            color="#0046AF"
                            class="px-10 btn-h-48 mr-3"
                            @click.stop="cancelDialog = true">
                            Cancel Project
                        </v-btn>
                    </v-col>
                    <v-col class="pt-1 pt-md-3" cols="12" md="auto">
                        <div class="pf-navs">
                            <v-btn
                                v-if="!isDesigner && !projectIsOnHold && (isClient || isAdminProject) && getProjectData.status !== getValueProjectStatus($config.project.status.pricing_needed)"
                                class="cwu-btn btn-h-48 pl-10 pr-10"
                                color="orange"
                                elevation="0"
                                outlined
                                rounded
                                @click="goEdit">
                                Edit Project
                            </v-btn>
                            <btn-green
                                class="btn-h-48 px-10 green-btn "
                                v-if="!isDesigner && isAdminProject && getProjectData.status_id !== $config.project.status.created &&
                                    getProjectData.status_id !== $config.project.status.in_progress_rendering"
                                :loading="loading"
                                @click="paidAdminProject">
                                Create Project
                            </btn-green>
                            <v-btn
                                v-if="canPay"
                                text
                                rounded
                                color="#02810F"
                                class="cwu-btn btn-h-48 px-10 ml-3"
                                @click="paymentDialog = true">
                                {{ getProjectData.status === getValueProjectStatus($config.project.status.pricing_pending) ? 'Accept and Pay' : 'Back to Payment' }}
                            </v-btn>
                            <dialog-payment
                                v-model="paymentDialog"
                                :autocloseable="false"
                                :amount="$store.getters.getProjectAmount"
                                @loading="dialogLoading"/>

                            <v-tooltip
                                v-if="isDesigner && project.payment_status && !projectIsOnHold"
                                top content-class="tooltip-information" :disabled="!isDesigner || isDesigner && profile.approved">
                                <template #activator="{on, attrs}">
                                    <div v-on="on" v-bind="attrs" class="ml-0 ml-sm-5">
                                        <btn-green
                                            :disabled="!profile.approved"
                                            @click="dialog = true"
                                            style="margin-left: 0!important;"
                                            class="btn-h-48 px-10 green-btn">
                                            Accept Project
                                        </btn-green>
                                    </div>
                                </template>

                                <div class="text-center font-weight-regular">Contact our support to get <br> approved</div>
                            </v-tooltip>

                            <template v-if="(isManager || isAdmin) && project.payment_status === 1 && !projectIsOnHold">
                                <project-assign-modeler :designer-id="project.designer_id"/>
                            </template>

                            <v-btn
                                v-if="projectIsOnHold && (isClient || isManagerOrAdmin)"
                                outlined
                                width="160"
                                height="48"
                                rounded
                                color="main-text"
                                class="bg-primary"
                                @click="$emit('openExtraChargeDialog')">
                                Continue
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </project-footer>
    </v-container>
</template>

<script>
    import { mapGetters } from "vuex";
    import http from "@/api/httpClient.api";
    import moment from "moment";
    import ReviewBlock from "@/components/ui/ReviewBlock";
    import ProjectDescription from "@/views/project/start/review-project/ProjectDescription";
    import ProjectHeader from "@/components/project/ProjectHeader";
    import ProjectDetails from "@/components/project/ProjectDetails";
    import NumberInput from "@/components/ui/number-input";
    import ProductSlider from "@/components/ProductSlider";
    import BtnGreen from "@/components/ui/btn/BtnGreen";
    import DialogAccept from "@/components/ui/DialogAccept";
    import DialogCancelProject from "@/components/ui/dialog-cancel-project";
    import ProjectAssignModeler from "./ProjectAssignModeler.vue";
    import DialogCancelProjectManager from "../ui/dialog-cancel-project-manager";
    import DialogPayment from "@/components/ui/DialogPayment";

    import { projectApi, commentApi, paymentApi } from "@/api";
    import ProjectFooter from "@/components/project/ProjectFooter";
    import ModelingTechSpecs from "@/components/ModelingTechSpecs";

    export default {
        name: "project-view",
        components: {
            ModelingTechSpecs,
            ProjectFooter,
            DialogCancelProjectManager,
            DialogCancelProject,
            DialogAccept,

            BtnGreen,
            ProjectDetails,
            ProjectHeader,
            ProjectDescription,
            NumberInput,
            ReviewBlock,
            ProjectAssignModeler,
            DialogPayment,
            ProductSlider
        },
        async beforeMount() {
            const files = await projectApi.getFiles(this.$route.params.id);
            files.forEach((file) => {
                const fileComments = this.project.comments[file.id];
                let formatedComments = [];
                for (const key in fileComments) {
                    const element = fileComments[key];
                    formatedComments.push(element[0]);
                }
                file.comments = formatedComments;
            });

            this.slides = files.filter(file => {
                const fileAttachedToDesignersSlider = file.props && file.props[0] && file.props[0].attachToDesigner;
                const fileAttachedToManagerSlider = file.props && file.props[0] && file.props[0].attachToManager;
                if (!fileAttachedToDesignersSlider && !fileAttachedToManagerSlider) {
                    return file;
                }
            });
        },
        data() {
            return {
                paymentDialog: false,
                loading: false,
                dialog: false,
                cancelDialog: false,
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    },
                    {
                        text: ""
                    }
                ],
                slides: [],
                fullInfo: [],
                designerInfo: null,
                projectPrice: 1000,
                priceBtnLoading: false,
                isReplyLoading: false
            };
        },
        provide() {
            const isReplyLoading = {}

            Object.defineProperty(isReplyLoading, "loading", {
                enumerable: true,
                get: () => this.isReplyLoading
            })

            return {
                isReplyLoading
            }
        },
        computed: {
            ...mapGetters([
                "isAdminProject",
                "isClient",
                "isManager",
                "isAdmin",
                "isManagerOrAdmin",
                "isDesigner",
                "allowedCancel",
                "getProjectData",
                "getValueProjectStatus",
                "getProjectLinks",
                "getProjectId",
                "profile",
                "projectIsOnHold"
            ]),
            isDraft() {
                return this.project.status_id === this.$config.project.status.draft
            },
            isCreated() {
                return this.project.status_id === this.$config.project.status.created
            },
            isCancelled() {
                return !this.project.status_id || this.project.status_id === this.$config.project.status.canceled
            },
            project() {
                return this.$store.getters.getProjectData;
            },
            projectTypeFullInfo() {
                return this.fullInfo.find((item) => item.title === this.project.type);
            },
            dueDate() {
                let dueDateInHours = this.projectTypeFullInfo?.due_dates[0] ?? 0
                const dueDate = moment().add(dueDateInHours, "hours")
                const diffDays = dueDate.diff(moment().startOf("day"), "days")
                const dueDateInDays = diffDays ? `(${diffDays} days)`: ""
                return this.project.estimated_date
                    ? moment(this.project.estimated_date).format("MMM. DD, YYYY")
                    : dueDateInHours
                        ? `${moment().add(dueDateInHours, "hours").format("MMM. DD, YYYY")} ${dueDateInDays}`
                        : ""

            },
            getDetails() {
                return {
                    ...this.project,
                    amount: this.isManager || this.isAdmin ? this.project.client_amount : this.project.overall_designer_amount || this.project.client_amount,
                    due: this.dueDate
                };
            },
            canPay() {
                const { draft, pricing_needed } = this.$config.project.status;
                if (this.project.type_id === this.$config.project.type.custom) {
                    return this.isClient && !this.project.payment_status && ![pricing_needed, draft].includes(this.project.status_id);
                }
                return this.isClient && !this.project.payment_status;
            }
        },
        mounted() {
            this.breadcrumbs[1].text = this.project.title;
            this.getProjectTypes();
        },
        methods: {
            setModelPreview(data) {
                const { slider, file } = data;
                this.slides = this.slides.map(slide => slide.id === file.id ? file : slide);

            },
            goEdit() {
                if(this.getProjectData.status_id === this.$config.project.status.created) {
                    this.$router.push({ name: "project-edit", params: { id: this.$route.params.id } });
                } else {
                    this.$router.push({ name: "project-start-edit", params: { id: this.$route.params.id } });
                }
            },
            dialogLoading(val) {
                this.loading = val;
            },
            getProjectTypes() {
                let params = {};
                http
                    .get("/projects/types", { params })
                    .then((res) => {
                        if (res.status === 200) {
                            this.fullInfo = res.data.data;
                        }
                    })
                    .catch((err) => {

                    });
            },
            addReply(){

            },
            updateFile(){

            },
            addComment(){

            },
            addMetall(){

            },
            addStone(){

            },
            async deleteComment({ file, comment }){
                const fileIndex = this.slides.findIndex(slide=> +slide.id === +file);
                const commentIndex = this.slides[fileIndex].comments.findIndex(cmt => +cmt.id === +comment);

                const deleted = await commentApi.remove(comment)
                if(deleted){
                    const updatedComments = this.slides[fileIndex].comments;
                    updatedComments.splice(commentIndex,1)
                    this.$set(this.slides[fileIndex], "comments", updatedComments)
                }
            },
            async updateComment(commentData){
                const fileIndex = this.slides.findIndex(slide=>slide.id === commentData.file);
                const commentIndex = this.slides[fileIndex].comments.findIndex(comment=>comment.id === commentData.comment.id);
                const updateData = Object.assign({ comment: commentData.comment.text }, commentData.comment)

                delete updateData.text
                delete updateData.id

                const updatedComment = await commentApi.update(commentData.comment.id, updateData);

                const updatedComments = this.slides[fileIndex].comments;
                updatedComments.splice(commentIndex,1,updatedComment)
                this.$set(this.slides[fileIndex], "comments", updatedComments)
            },
            addSnapshot(){

            },
            async setProjectPrice() {
                this.priceBtnLoading = true;
                const res = await projectApi.setCustomTypeProjectPrice(this.project.id, {
                    customer_amount: this.projectPrice
                });

                const { client_amount, designer_amount, overall_designer_amount } = res.data.data;
                await this.$store.dispatch("updateProjectData", {
                    client_amount, designer_amount, overall_designer_amount,
                    status: this.getValueProjectStatus(this.$config.project.status.pricing_pending)
                });
                this.priceBtnLoading = false;
            },
            async paidAdminProject(){
                this.loading = true;
                try {
                    await paymentApi.paidAdminProject(this.getProjectId)
                    await this.$router.push("/dashboard");
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }

            }
        }
    };
</script>

<style lang="scss" scoped>
.green-btn {
  background-color: #9ff4a9 !important;
  border: 1px solid #464646;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 14px;
  line-height: 18px;
  color: #464646;
}

.grid {
  max-width: 1160px;
  width: 100%;
}
.def-blue-link {
  display: inline-block;
  font-size: 13px;
  line-height: 24px;
  color: #0046af;
  cursor: pointer;
  text-decoration: none;
  transition: all 250ms ease;
  &:hover {
    opacity: 0.7;
  }
}
.pfi-designer {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #545454;
}
.pb-label {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #8d8d8d;
}
.project-description {
  font-size: 13px;
  line-height: 19px;
  color: #202121;
  word-break: break-word;
}
.hs-ttl-wrap {
  display: inline-block;
}
.hs-type {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  text-transform: uppercase;
}
.pms-label {
  font-size: 20px;
  line-height: 32px;
  color: #0b3c49;
}
.pms-col {
  flex: 0 0 calc(50% - 1px);
  max-width: 480px;
  @media screen and (max-width: 959px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.pms-col-sep {
  @media screen and (min-width: 960px) {
    &:before {
      content: "";
      display: block;
      height: 398px;
      border-left: 1px solid #d2d2d2;
      margin-top: 80px;
    }
  }
  @media screen and (max-width: 959px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px 12px;
    &:before {
      content: "";
      display: block;
      border-top: 1px solid #d2d2d2;
    }
  }
}
.pms-img-wrap {
  img {
    max-width: 100%;
  }
}
.pl-iw {
  text-align: right;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 959px) {
    text-align: left;
  }
}
.pl-item {
  background: rgba(0, 70, 175, 0.07);
  border-radius: 20px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 7px;
  font-size: 13px;
  line-height: 18px;
  color: #0046af;
  text-align: left;
  text-decoration: none;
  word-break: break-word;
}
a.pl-item {
  transition: all 250ms ease;
  &:hover {
    opacity: 0.7;
  }
}

::v-deep .deatils-container {
    background: #ffffff;
    border: 1px solid #bcbcbc;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    border-radius: 5px;
    margin-bottom: 18px;
    padding: 14px 25px 16px 20px;
    color: #464646;

    .content {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        ul {
            font-size: 14px;
            color: #464646;
            li + li {
                margin-top: 5px;
            }
        }

        .info-row {
            font-weight: normal;
            margin-top: 10px;

            span {
                font-weight: bold;
            }
        }

        ::v-deep input {
            max-width: 252px;
        }

        .v-btn {
            color: #464646;
            background-color: #9ff4a9;
        }
    }
}

::v-deep .conteiner-title {
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 11px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    &.dimensions-title{
        font-weight: 400;
        font-size: 18px;
        text-transform: capitalize;
    }
}
</style>
