<template>
    <v-container class="pa-6 project-in-progress-page" fluid>
        <div class="project-scroll-section">
            <div class="def-page-cont">
                <div class="head-section">
                    <v-row align="center">
                        <v-col>
                            <project-header :edit-title="!isCompleted"/>
                        </v-col>
                    </v-row>
                </div>

                <div class="project-main-section">
                    <v-row justify="space-between">
                        <v-col class="pms-col slider-column">
                            <div class="slider-title">
                                Reference Images
                            </div>

                            <project-references-slider
                                v-if="clientSliderFiles && clientSliderFiles.length"
                                class="assets-slider"
                                :render-review-slider="true"
                                :hide-captions="true"
                                :store="$store"
                                :z-index="0"/>
                        </v-col>

                        <v-col class="pms-col slider-column d-flex flex-column">
                            <div class="pms-label slider-title d-flex justify-space-between">
                                Assets Preview
                                <div v-if="!isManagerOrAdmin || isManagerOrAdmin && !isInProgress && !isReadyForReview">
                                    Round {{ currentRound }}
                                </div>

                                <v-menu
                                    v-if="isManagerOrAdmin && (isInProgress || isReadyForReview)"
                                    v-model="roundMenu"
                                    attach=".rounds-selector-menu-anchor"
                                    offset-y
                                    nudge-bottom="10"
                                    left
                                    min-width="150"
                                    max-width="150"
                                    content-class="rounds-selector-menu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs"
                                             v-on="on"
                                             :class="['rounds-selector-menu-anchor d-flex align-center', {'is-active': roundMenu}]">
                                            Round {{ selectedRound }}
                                            <template v-if="selectedFile && selectedFile.version">
                                                <span class="mr-1">,</span>
                                                Version {{ selectedFile.version }}
                                            </template>
                                            <img class="ml-2" src="@/assets/icons/ic-arrow-down.svg" alt="">
                                        </div>
                                    </template>
                                    <ul class="rounds-selector pa-0 ma-0">
                                        <li v-for="round in rounds" :key="round" @click="changeRound(round)">
                                            <span class="d-block" :data-round="round">Round {{ round }}</span>
                                            <ul class="pa-0 ma-0" v-if="selectedFile && selectedFile.version">
                                                <template v-for="file in selectedFileVersions">
                                                    <li v-show="file.round.includes(round) && file.version && file.version[0] == round"
                                                        :key="file.id"
                                                        @click="changeFileVersion(file)">
                                                        <span class="d-block">Version {{ file.version }}</span>
                                                    </li>
                                                    <li v-for="fileVersion in file.rounds"
                                                        :key="fileVersion.id"
                                                        v-show="Array.isArray(fileVersion.round) ? fileVersion.round.includes(round) && fileVersion.version : fileVersion.round === round && fileVersion.version"
                                                        @click="changeFileVersion(fileVersion)">
                                                        <span class="d-block">Version {{ fileVersion.version }}</span>
                                                    </li>
                                                </template>
                                            </ul>
                                        </li>
                                    </ul>
                                </v-menu>
                            </div>

                            <product-slider
                                v-if="(!isClient || !isInProgress) && managerSliderFiles && managerSliderFiles.length"
                                id="designers"
                                ref="designers-slider"
                                key="designers"
                                :items="managerSliderFiles"
                                :disabled-delete-toolbar="disabledDeleteToolbar"
                                :enable-delete="isManagerOrAdmin && isInProgress && selectedRound === currentRound && !projectIsOnHold"
                                :atachments="null"
                                :is-client-view="false"
                                :is-renders-view="true"
                                :allow-replay-comment="extendedModeForDesignerView || isInProgress"
                                :allow-edit-comment="extendedModeForDesignerView|| isInProgress"
                                :extended-mode="extendedModeForDesignerView"
                                :disable-files-update="!(isManagerOrAdmin && isInProgress) || selectedRound !== currentRound"
                                @addReply="addReply($event, 'designers')"
                                @updateFile="updateFile($event, 'designers')"
                                @addComment="addComment($event, 'designers')"
                                @setModelPreview="setModelPreview"
                                @bindFileToProject="bindFileToProject($event, 'designers')"
                                @deleteComment="deleteComment($event, 'designers')"
                                @updateComment="updateComment($event, 'designers')"
                                @deleteComments="deleteComments($event, 'designers')"
                                @redoComment="addComment($event, 'designers')"
                                @deleteFile="deleteFile($event, 'designers')"
                                @deleteAllFileVersions="toggleDeleteModal($event, 'designers')"
                                @swapFile="replaceFile($event, 'designers')"
                                @changeActiveFile="file => {this.selectedFile = file; setSelectedFileVersions()}"/>

                            <template v-if="isManagerOrAdmin && !managerSliderFiles.length && !initialLoading">
                                <div v-show="uploadingFileProgress != null" class="page-preloader"/>

                                <validation-provider
                                    v-if="uploadingFileProgress == null"
                                    ref="provider"
                                    v-slot="{ errors, invalid }"
                                    :rules="{ size: uploadFileSize }"
                                    name="File">
                                    <label class="upload-lbl upload-lbl-model">
                                        <input
                                            :disabled="filesLoading"
                                            :accept="rendersAcceptExtension"
                                            class="upload-lbl-inp"
                                            type="file"
                                            multiple
                                            @change="selectFile">
                                        <span class="upload-lbl-cont">
                                            <span class="upload-lbl-ic">
                                                <img src="@/assets/icons/ic-plus-circle.svg" alt="">
                                            </span>
                                            <span class="upload-lbl-desc mt-2"> Upload Model </span>
                                        </span>
                                    </label>
                                    <div v-if="invalid" class="field-error-txt mt-2">
                                        {{ errors[0] }}
                                    </div>
                                </validation-provider>

                                <div class="files-group-placeholder">
                                    <div class="top-row">
                                        <div class="title">
                                            Model Files
                                        </div>
                                        <label class="upload-btn">
                                            + Upload Model Images
                                            <input
                                                :disabled="filesLoading"
                                                :accept="rendersAcceptExtension"
                                                type="file"
                                                multiple
                                                class="d-sr-only"
                                                @change="selectFile">
                                        </label>
                                    </div>

                                    <div class="files-group-notification">
                                        The files will appear here after your designer has
                                        uploaded them
                                    </div>
                                </div>
                            </template>

                            <template v-if="!isManagerOrAdmin && isInProgress && !initialLoading">
                                <div class="pms-img-wrap absolute-preview">
                                    <img src="@/assets/img/admin-is-working.png" alt="">
                                </div>
                                <div class="files-group-placeholder mt-auto" style="margin-bottom: 60px;">
                                    <div class="top-row">
                                        <div class="title">
                                            Renders
                                        </div>
                                    </div>

                                    <div class="files-group-notification">
                                        Renders will appear here once they are uploaded.
                                    </div>
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </div>

                <project-in-progress-info
                    :show-render-description="true"/>
            </div>
        </div>

        <project-footer>
            <div class="def-page-cont pos-rel">
                <v-row align="center">
                    <div class="nav-wrapper">
                        <div
                            class="bs-back-link"
                            @click="$router.go(-1)">
                            <div class="circle">
                                <img
                                    src="@/assets/_redesigne/icons/ic-shevron-left.svg"
                                    alt="">
                            </div>
                            Back
                        </div>
                        <breadcrumbs
                            :items="breadcrumbs"
                            class="inline-bc"/>
                        <project-step-dropdown class="project-step-dd" :links="projectStepsLinks" :selected="currentLink"/>
                    </div>

                    <div class="pf-info d-flex flex-column align-center ml-3">
                        <v-btn
                            v-if="isManager || isAdmin"
                            outlined
                            color="#FB3805"
                            rounded
                            small
                            height="25"
                            class="px-5"
                            @click.stop="cancelDialog = true">
                            Cancel this project
                        </v-btn>
                    </div>

                    <v-col
                        class="pt-1 pt-md-3 ml-auto"
                        cols="12"
                        md="auto">
                        <div class="pf-navs d-flex align-center">
                            <div class="btns-mob-separate d-sm-none mt-3 mb-3"/>
                            <router-link
                                v-if="!isManagerOrAdmin"
                                :to="{name: 'support'}"
                                class="support-link">
                                Issues and Support
                            </router-link>

                            <v-btn
                                v-if="projectIsOnHold && (isClient || isManagerOrAdmin)"
                                outlined
                                width="160"
                                height="48"
                                rounded
                                color="main-text"
                                class="bg-primary"
                                @click="$emit('openExtraChargeDialog')">
                                Continue
                            </v-btn>

                            <div v-if="isManagerOrAdmin && isInProgress && !projectIsOnHold" class="d-inline-flex justify-center" style="width: 170px;">
                                <v-progress-circular v-if="autoDeliverRendersLoading" :value="20" indeterminate width="2"/>
                                <simple-checkbox
                                    v-model="project.auto_deliver_renders"
                                    hide-details
                                    :simple="false"
                                    :class="['deliver-renders-checkbox pt-0 mt-0', autoDeliverRendersLoading ? 'd-none' : 'd-flex']"
                                    @change="toggleAutoDeliverRenders({
                                        field: 'auto_deliver_renders',
                                        value: project.auto_deliver_renders
                                    })"
                                    label="Use uploaded renders as final deliverables"/>
                            </div>

                            <div class="apd-btn-wrap d-inline-block">
                                <p v-if="isReadyForReview && isManagerOrAdmin && !isAdminProject" class="dark-grey--9A body-2 ma-0 pa-0 ml-6">
                                    Awaiting client approval
                                </p>
                                <v-btn
                                    v-if="isReadyForReview && !isManagerOrAdmin && (isClient || isAdminProject) && !projectIsOnHold"
                                    :disabled="!enableRequestModification"
                                    class="apd-btn request-changes-btn"
                                    width="160"
                                    height="40"
                                    dark
                                    elevation="0"
                                    rounded
                                    outlined
                                    :loading="submitedLoading"
                                    @click="requestConfirmDialog = true">
                                    Request Modifications
                                    <div v-if="!enableRequestModification" class="tooltip-wrapper">
                                        <div class="tooltip">No comments for designer</div>
                                    </div>
                                </v-btn>
                            </div>

                            <v-btn
                                v-if="!isManagerOrAdmin && (isClient || isAdminProject) && !projectIsOnHold"
                                :disabled="!managerSliderFiles.length || !isReadyForReview"
                                class="apd-btn ml-0 ml-sm-4"
                                width="160"
                                height="40"
                                color="#02810F"
                                dark
                                elevation="0"
                                rounded
                                @click="openApproveDialog">
                                Approve Renders
                            </v-btn>

                            <v-dialog
                                v-if="isInProgress && !isAdminProject && !projectIsOnHold"
                                v-model="showSubmitDialog"
                                width="620">
                                <template v-slot:activator="{ attrs, on }">
                                    <div class="apd-btn-wrap d-inline-block ml-0 ml-sm-5">
                                        <v-btn
                                            v-if="isManagerOrAdmin && isSubmited"
                                            :disabled="!isRendersUploaded"
                                            class="apd-btn btn-h-48 pl-9 pr-9"
                                            color="#02810F"
                                            dark
                                            elevation="0"
                                            rounded
                                            v-bind="attrs"
                                            v-on="on">
                                            Submit to Client
                                            <div v-if="!isRendersUploaded" class="tooltip-wrapper">
                                                <div class="tooltip">Missing renders</div>
                                            </div>
                                        </v-btn>
                                    </div>
                                </template>

                                <div class="dialog-overflow-wrap">
                                    <v-btn
                                        class="pci-ad-cl-btn"
                                        icon
                                        @click="showSubmitDialog = false">
                                        <v-img
                                            :src="require('@/assets/icons/ic-close-modal.svg')"
                                            contain
                                            height="36"
                                            max-width="36"/>
                                    </v-btn>
                                    <v-card class="pa-12">
                                        <div class="d-flex justify-space-between align-center mb-4">
                                            <v-card-title class="px-0 py-0 blueish">
                                                Ready to Submit for Client Review?
                                            </v-card-title>
                                        </div>
                                        <p class="grey-lightend">
                                            Submitting this model will notify the client that a design is ready for them to view. All previously uploaded versions will be deleted. The newest one will be sent to client.
                                        </p>
                                        <div
                                            class="d-flex justify-space-between align-center mt-12">
                                            <v-btn
                                                text
                                                color="#0046AF"
                                                @click="showSubmitDialog = false">
                                                Return
                                            </v-btn>
                                            <v-btn
                                                class="apd-btn btn-h-48 ml-0 ml-sm-5 pl-9 pr-9"
                                                color="#02810F"
                                                dark
                                                width="225"
                                                elevation="0"
                                                rounded
                                                :loading="submitedLoading"
                                                @click="submitToClient">
                                                Submit Model
                                            </v-btn>
                                        </div>
                                    </v-card>
                                </div>
                            </v-dialog>

                            <v-dialog
                                v-if="isInProgress && isAdminProject"
                                v-model="assignClientModal"
                                eager
                                width="610">
                                <template v-slot:activator="{ attrs, on }">
                                    <div class="apd-btn-wrap d-inline-block ml-0 ml-sm-5">
                                        <v-btn
                                            v-if="isManagerOrAdmin && isSubmited && !projectIsOnHold"
                                            :disabled="!isRendersUploaded"
                                            :loading="submitedLoading"
                                            class="apd-btn btn-h-48 pl-9 pr-9"
                                            color="#02810F"
                                            dark
                                            elevation="0"
                                            rounded
                                            v-bind="attrs"
                                            v-on="on">
                                            Submit to Client
                                        </v-btn>
                                    </div>
                                </template>
                                <div class="dialog-overflow-wrap">
                                    <v-btn
                                        class="pci-ad-cl-btn"
                                        icon>
                                        <v-img
                                            :src="require('@/assets/icons/ic-close-modal.svg')"
                                            contain
                                            height="36"
                                            max-width="36"
                                            @click="assignClientModal = false"/>
                                    </v-btn>

                                    <v-card class="pt-12 px-12 pb-10">
                                        <h4 class="mb-7 text-center main-text--text font-weight-regular headline">
                                            You are about to send a project to a client
                                        </h4>

                                        <validation-observer v-slot="{ handleSubmit }" tag="div" class="mb-5" ref="client_email_form">
                                            <v-form @submit.prevent="handleSubmit(assignClient)">
                                                <validation-provider v-slot="{ errors, invalid }" tag="label" class="d-block"
                                                                     mode="eager"
                                                                     rules="email|required" vid="email" name="Email" style="max-width: 250px">
                                                    <p class="mb-1 main-text--text" style="font-size: 18px;">Client e-mail</p>
                                                    <v-text-field
                                                        v-model="clientEmail"
                                                        type="email"
                                                        height="34"
                                                        dense
                                                        full-width
                                                        outlined
                                                        hide-details
                                                        class="rounded-0 client-email-input"
                                                        placeholder="Client email"/>
                                                    <div v-if="invalid" class="field-error-txt mt-1">
                                                        {{ errors[0] }}
                                                    </div>
                                                </validation-provider>

                                                <div class="d-flex justify-space-between mt-8">
                                                    <v-btn
                                                        :loading="submitedLoading"
                                                        type="submit"
                                                        outlined
                                                        dark
                                                        rounded
                                                        width="190"
                                                        height="48"
                                                        color="#464646"
                                                        style="background-color:#9ff4a9;">
                                                        Assign Client
                                                    </v-btn>
                                                    <v-btn
                                                        @click="approveDesign"
                                                        :loading="approving"
                                                        outlined
                                                        dark
                                                        rounded
                                                        width="190"
                                                        height="48"
                                                        color="#464646"
                                                        style="background-color:#9ff4a9;">
                                                        Complete Project
                                                    </v-btn>
                                                </div>
                                            </v-form>
                                        </validation-observer>
                                    </v-card>
                                </div>
                            </v-dialog>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </project-footer>

        <dialog-confirm-request-modification v-model="requestConfirmDialog" @request="requestModification"/>

        <v-dialog
            v-model="approveDialog"
            width="640"
            @click:outside="closeApproveDialog">
            <div class="dialog-overflow-wrap">
                <v-btn
                    class="dialog-cl-btn"
                    icon
                    @click="closeApproveDialog">
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"/>
                </v-btn>
                <v-card class="dialog-overflow-cont pa-5 pa-sm-10">
                    <h2 class="dialog-ttl">
                        Approve {{ project.title }} renders?
                    </h2>
                    <div class="dialog-txt mt-4">
                        <p class="font-weight-bold mb-0">This step cannot be undone.</p>
                        <p class="font-weight-medium">Approving renders will take this project to the next step where a manager will prepare your final renders.</p>

                        <div class="text-body-2 font-futura">
                            Reminder to check these aspects:
                            <ul class="px-4">
                                <li>Is the <strong>logo</strong> or <strong>watermark</strong> applied?</li>
                                <li>Are all the <strong>angles</strong> provided?</li>
                                <li>Is the <strong>background</strong> correct?</li>
                            </ul>
                        </div>
                    </div>
                    <v-card-actions class="pa-0 mt-10">
                        <div
                            class="def-blue-link fs-16"
                            @click="closeApproveDialog">
                            Return to Compare Model
                        </div>
                        <v-spacer/>
                        <v-btn
                            :loading="approving"
                            class="btn-h-48 bw-3 fs-16 pl-4 pr-4 pl-sm-10 pr-sm-10"
                            color="#3F7359"
                            dark
                            elevation="0"
                            large
                            rounded
                            @click="approveDesign">
                            Approve and Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>

        <dialog-delete-project-file
            :delete-modal-data="deleteModalData"
            @cancel="toggleDeleteModal"
            @delete="deleteFile(deleteModalData.file, deleteModalData.slider, isManagerOrAdmin)"/>

        <dialog-default type="alertInvalidFileType"/>

        <v-dialog
            v-model="specsPreviewModal"
            eager
            width="980">
            <div class="dialog-overflow-wrap">
                <v-btn
                    class="pci-ad-cl-btn"
                    icon>
                    <v-img
                        :src="require('@/assets/icons/ic-close-modal.svg')"
                        contain
                        height="36"
                        max-width="36"
                        @click="specsPreviewModal = false"/>
                </v-btn>

                <v-card class="pa-16">
                    <project-references-slider
                        v-if="clientSliderFiles && clientSliderFiles.length && showX2Slider"
                        id="modal-x2"
                        :render-review-slider="true"
                        :hide-drawer="true"
                        :hide-captions="true"
                        :hide-thumbs="true"
                        :thumbs-header="false"
                        :x2-modal="false"
                        @controls="obj => modalSliderControlsObj = obj"
                        :store="$store"
                        :z-index="0"/>
                </v-card>
            </div>
        </v-dialog>

        <template v-if="isClient">
            <dialog-cancel-project
                v-model="cancelDialog"
                :project="project"/>
        </template>
        <template v-if="isManager || isAdmin">
            <dialog-cancel-project-manager
                v-model="cancelDialog"
                :project="project"/>
        </template>

        <v-snackbar
            v-model="filesLoadingSnackbar"
            :timeout="3000"
            class="project-title__snackbar"
            top>
            File upload completed
        </v-snackbar>
        <v-snackbar
            v-model="errorSubmitingToClient"
            :timeout="3000"
            color="danger"
            bottom>
            Something went wrong while submitting to client
        </v-snackbar>
        <v-snackbar
            v-model="submitedToClient"
            :timeout="3000"
            class="project-title__snackbar"
            bottom>
            Success! Model uploaded and submitted to client.
        </v-snackbar>
        <v-snackbar
            v-model="fileDeleteSnackbar"
            :timeout="3000"
            class="project-title__snackbar"
            top>
            File deleted
        </v-snackbar>
    </v-container>
</template>

<script>
    import Breadcrumbs from "@/components/Breadcrumbs";
    import ProjectStepDropdown from "@/components/ProjectStepDropdown"
    import UploadService from "@/api/uploadFilesService.api";
    import { projectApi, commentApi } from "@/api";
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { size } from "vee-validate/dist/rules";
    import { mapGetters } from "vuex";
    import DialogDefault from "../ui/dialog-default";
    import DialogCancelProject from "../ui/dialog-cancel-project";
    import DialogCancelProjectManager from "../ui/dialog-cancel-project-manager";
    import DialogDeleteProjectFile from "../ui/dialog-delete-project-file";
    import ProductSlider from "@/components/ProductSlider";
    import ProjectHeader from "@/components/project/ProjectHeader";
    import ProjectFooter from "@/components/project/ProjectFooter";
    import ProjectInProgressInfo from "@/components/project/ProjectInProgressInfo";
    import ProjectReferencesSlider from "@/views/project/start/review-project/ProjectReferencesSlider";
    import { FILETYPES } from "@/utils/constans";
    import { MbToKb } from "@/utils/index.utils";
    import config from "@/utils/config";
    import DialogConfirmRequestModification from "@/components/ui/DialogConfirmRequestModification";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";

    extend("size", {
        ...size,
        message: `The File field size must be less than ${config.fileSizeLimit}MB`
    });

    export default {
        name: "project-in-progress",
        components: {
            DialogConfirmRequestModification,
            ProjectInProgressInfo,
            ProjectFooter,
            DialogDefault,
            DialogCancelProjectManager,
            DialogCancelProject,
            DialogDeleteProjectFile,
            Breadcrumbs,
            ValidationObserver,
            ValidationProvider,
            ProjectStepDropdown,
            ProductSlider,
            ProjectHeader,
            ProjectReferencesSlider,
            SimpleCheckbox
        },

        data() {
            return {
                initialLoading: true,
                autoDeliverRendersLoading: false,
                managerSliderFiles: [],
                clientSliderFiles: [],
                allFiles:[],

                errorSubmitingToClient: false,
                submitedLoading: false,
                isSubmited: true,
                submitedToClient: false,
                showSubmitDialog: false,
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        href: "/dashboard"
                    },
                    {
                        text: ""
                    }
                ],
                projectStepsLinks: null,

                // File upload
                uploadedCount: 0,
                // File upload

                fileInfos: [],
                userUploadedFilesId: [],
                userUploadedFiles: [],
                filesLoading: false,
                filesLoadingSnackbar: false,
                uploadFileSize: MbToKb(config.fileSizeLimit), //500MB

                approveDialog: false,

                fileDeleteSnackbar: false,
                cancelDialog: false,
                approving: false,

                uploadingFileProgress: null,

                roundMenu: false,
                rounds: [],
                filesByRounds: {},
                selectedRound: 1,
                selectedFile: null,
                selectedFileVersions: [],
                lastRoundFiles: [],
                deleteModalData: {
                    showModal: false,
                    file: null,
                    slider: ""
                },
                isReplyLoading: false,
                FILETYPES: FILETYPES,
                specsPreviewModal: false,
                modalSliderControlsObj: {},
                showX2Slider: false,

                assignClientModal: false,
                requestConfirmDialog: false,
                clientEmail: ""
            };
        },
        provide() {
            const isReplyLoading = {}

            Object.defineProperty(isReplyLoading, "loading", {
                enumerable: true,
                get: () => this.isReplyLoading
            })

            return {
                isReplyLoading
            }
        },
        computed: {
            ...mapGetters([
                "isClient",
                "isManager",
                "isAdmin",
                "isManagerOrAdmin",
                "profile",
                "getProjectData",
                "getValueProjectStatus",
                "isAdminProject",
                "getAssetsSpecifications",
                "projectIsOnHold"
            ]),

            currentLink(){
                return this.projectStepsLinks ? this.projectStepsLinks.find(link => link.to.name === this.$route.name) || this.projectStepsLinks : null
            },

            rendersAcceptExtension() {
                return this.$config.allowedFileExtension.renders;
            },
            extendedModeForDesignerView() {
                return !this.projectIsOnHold && (!this.isManagerOrAdmin && this.isReadyForReview || this.isManagerOrAdmin && this.isInProgress && this.selectedRound === this.currentRound);
            },
            project() {
                return this.$store.getters.getProjectData;
            },

            isDraft() {
                return this.project.status_id === this.$config.project.status.draft
            },
            isCreated() {
                return this.project.status_id === this.$config.project.status.created
            },
            isDraftOrCreated() {
                return this.isDraft || this.isCreated
            },
            isFinalAssets() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.final_assets_prep);
            },
            isCompleted() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.completed);
            },
            isInProgress() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.in_progress_rendering);
            },
            isReadyForReview() {
                return this.project.status === this.getValueProjectStatus(this.$config.project.status.ready_for_review_renders);
            },

            currentRound() {
                return this.getProjectData.current_render_round.round;
            },
            isRendersUploaded() {
                let files = this.lastRoundFiles.filter(file => file.comments.every(comment => !comment.round));
                return files.length;
            },
            enableRequestModification() {
                return this.managerSliderFiles.some(file => file.comments.some(comment => comment.round === this.currentRound + 1));
            },
            disabledDeleteToolbar() {
                const attachedToManager = this.selectedFile.props && this.selectedFile.props[0] && this.selectedFile.props[0].attachToManager;

                const fileWithCommentHide = this.selectedFile.comments.some(comment =>  attachedToManager && this.selectedFile.round.includes(comment.round))
                return fileWithCommentHide
            }
        },
        async created() {
            this.$store.commit("setIsInProjectView", true);

            await this.$store.dispatch("getAssets", this.project.id);
            this.clientSliderFiles = JSON.parse(JSON.stringify(this.getAssetsSpecifications)).reduce((acc, asset) => {
                acc.push(...asset.children);
                return acc;
            }, []);

            this.allFiles = await projectApi.getFiles(this.$route.params.id);
            this.setFiles();
            this.setProjectStepsLinks();

            this.initialLoading = false;
        },
        mounted() {
            this.breadcrumbs[1].text = this.project.title;
        },
        destroyed() {
            this.$store.commit("setIsInProjectView", false);
        },
        methods: {
            getSpecModuleByType(type) {
                return this.getAssetsSpecifications
                    .filter(model => model.children.find(child => child.type === type))
                    .map((model, idx) => {
                        return { ...model, type, idx: idx+1, children: model.children.filter(child => child.type === type) }
                    });
            },
            openSpecsPreviewModal(file) {
                this.specsPreviewModal = true;

                this.$nextTick(() => {
                    this.showX2Slider = true;

                    this.$nextTick(() => {
                        this.modalSliderControlsObj.selectIndexFromId(file.id);
                    })
                })
            },
            setFiles() {
                let managerSliderFiles = [];
                const rounds = new Set(this.allFiles.reduce((acc, file) => {
                    if (file.round !== null && file.props && file.props[0] && file.props[0].attachToManager) {
                        acc.push(...file.round);
                    }
                    return acc;
                }, []));

                this.rounds = rounds.size !== 0 ? [...rounds].sort((a, b) => a - b) : [this.currentRound];
                this.selectedRound = this.currentRound;

                this.allFiles.forEach((file) => {
                    const fileComments = this.getProjectData.comments[file.id];
                    let formatedComments = [];
                    for (const key in fileComments) {
                        const element = fileComments[key];
                        formatedComments.push(...element);
                    }
                    file.comments = formatedComments;

                    file.rounds.forEach(roundFile => {
                        const roundFileComments = this.getProjectData.comments[roundFile.id];
                        let commentsArr = [];
                        for (const key in roundFileComments) {
                            const element = roundFileComments[key];
                            commentsArr.push(...element);
                        }
                        roundFile.comments = commentsArr;
                    })

                    const attachedToManager = file.props && file.props[0] && file.props[0].attachToManager;

                    const fileWithCommentHide = file.comments.some(comment =>  attachedToManager && file.round.includes(comment.round))
                    if (this.isManagerOrAdmin && attachedToManager && file.round.includes(this.selectedRound) ||
                        this.isClient && attachedToManager && !this.isInProgress && !fileWithCommentHide ||
                        this.isClient && attachedToManager && this.isInProgress && file.round.includes(this.selectedRound)) {
                        managerSliderFiles.push(file);
                    }
                });

                this.setFilesByRounds(this.allFiles.filter(file => file.round !== null && file.props && file.props[0] && file.props[0].attachToManager));
                if (this.filesByRounds[this.selectedRound]) {
                    const firstFileInRound = this.filesByRounds[this.selectedRound][0];
                    if (firstFileInRound?.rounds?.length) {
                        this.selectedFile = firstFileInRound.rounds[firstFileInRound.rounds.length-1];
                    } else {
                        this.selectedFile = this.filesByRounds[this.selectedRound][0];
                    }
                }

                this.setSelectedFileVersions();

                this.managerSliderFiles = managerSliderFiles.map(slide => {
                    slide.version = slide.version[this.currentRound];
                    if (slide.rounds.length) {
                        return slide.rounds[slide.rounds.length-1];
                    }
                    return slide;
                });
            },
            setFilesByRounds(files) {
                files.forEach(file => {
                    file.round_base_file_id = file.id;
                    file.round.forEach(round => {
                        const version = typeof file.version === "object" ? file.version[round] : file.version;

                        if (this.filesByRounds[round]) {
                            this.filesByRounds[round] = [...this.filesByRounds[round], { ...file, version }];
                        } else {
                            this.filesByRounds[round] = [{ ...file, version }];
                        }
                    })
                });
                this.lastRoundFiles = this.filesByRounds[this.currentRound] || [];
            },
            setSelectedFileVersions() {
                if (this.filesByRounds[this.selectedRound]) {
                    this.selectedFileVersions = this.filesByRounds[this.selectedRound].filter(file => file.round_base_file_id === this.selectedFile.round_base_file_id);
                }
                this.lastRoundFiles = this.filesByRounds[this.currentRound] || [];
            },
            changeRound(round) {
                if (round === this.selectedRound) return;

                this.selectedRound = round;
                this.managerSliderFiles = this.filesByRounds[this.selectedRound];

                const firstFileInRound = this.managerSliderFiles[0];
                if (firstFileInRound?.rounds?.length) {
                    this.selectedFile = firstFileInRound.rounds[firstFileInRound.rounds.length-1];
                    this.managerSliderFiles = this.managerSliderFiles.map(slide => {
                        if (slide?.rounds?.length) {
                            return slide.rounds[slide.rounds.length-1];
                        }
                        return slide;
                    });
                } else {
                    this.selectedFile = firstFileInRound;
                }

                this.setSelectedFileVersions();
            },
            changeFileVersion(file) {
                this.selectedFile = file;
                this.managerSliderFiles = this.managerSliderFiles.map(sliderFile => sliderFile.round_base_file_id === file.round_base_file_id ? file : sliderFile);
            },
            setModelPreview(data) {
                const { slider, file } = data;
                this.managerSliderFiles = this.managerSliderFiles.map(slide => slide.id === file.id ? file : slide);
            },
            async addReply(reply, slider) {
                this.isReplyLoading = true
                try {
                    const response = await commentApi.reply(reply.content.parent_id, {
                        canvas_data: [],
                        comment: reply.content.comment,
                        comment_id: +new Date,
                        file_id: reply.fileId,
                        parent_id: reply.content.parent_id,
                        project_id: this.$route.params.id,
                        type: 1,
                        username: this.$store.getters.profile.firstName + " " +this.$store.getters.profile.lastName,
                        round: slider === "designers" && this.isReadyForReview && this.isClient ? this.currentRound + 1 : null
                    })

                    this.managerSliderFiles.forEach((file, index) => {
                        if (file.id === response.file_id) {
                            if (file.comments && Array.isArray(file.comments)) {
                                const commentsList = file.comments;
                                commentsList.push(response);
                                this.$set(this.managerSliderFiles[index], "comments", commentsList);
                            } else {
                                this.$set(this.managerSliderFiles[index], "comments", [response]);
                            }
                        }
                    });
                } catch (e) {
                    console.log(e)
                } finally {
                    this.isReplyLoading = false
                }

            },
            updateFile({ fileIndex, canvas_data }, slider) {
                if (this.managerSliderFiles[fileIndex]) {
                    this.$set(this.managerSliderFiles[fileIndex], "canvas_data", canvas_data);
                }
            },
            async addComment(comment, slider) {
                const createdComment = await commentApi.create({
                    comment_id: +new Date(),
                    canvas_data: [
                        {
                            brush_width: comment.brush_width,
                            canvas_width: comment.canvas_width,
                            canvas_height: comment.canvas_height,
                            points: comment.points
                        }
                    ],
                    type: this.$config.commentType.comment,
                    username:
                        this.$store.getters.profile.firstName +
                        " " +
                        this.$store.getters.profile.lastName,
                    comment: comment.text,
                    parent_id: null,
                    project_id: parseInt(this.$route.params.id),
                    file_id: comment.fileId,
                    x: Math.round(comment.position.x),
                    y: Math.round(comment.position.y),
                    round: slider === "designers" && this.isReadyForReview && this.isClient ? this.currentRound + 1 : null
                });

                this.managerSliderFiles.forEach((file, index) => {
                    if (file.id === createdComment.file_id) {
                        if (file.comments && Array.isArray(file.comments)) {
                            const commentsList = file.comments;
                            commentsList.push(createdComment);
                            this.$set(
                                this.managerSliderFiles[index],
                                "comments",
                                commentsList
                            );
                        } else {
                            this.$set(this.managerSliderFiles[index], "comments", [
                                createdComment
                            ]);
                        }
                    }
                });
            },

            async requestModification() {
                this.requestConfirmDialog = false
                this.submitedLoading = true;
                const clientReferences = this.managerSliderFiles.filter(file => file.comments.some(comment => comment.round === this.currentRound + 1))

                const res = await projectApi.requestRendersModification(this.project.id, {
                    files: clientReferences.map(file => file.id)
                });

                this.managerSliderFiles = clientReferences;
                await this.$store.dispatch("updateProjectData", {
                    current_render_round: res.data.data.current_render_round,
                    status: this.getValueProjectStatus(this.$config.project.status.in_progress_rendering),
                    status_id: this.$config.project.status.in_progress_rendering
                });
                this.submitedLoading = false;
            },
            async assignClient() {
                try {
                    this.submitedLoading = true;

                    await projectApi.submitToClient(this.project.id, { email: this.clientEmail });
                    await this.$store.dispatch("updateProjectData", {
                        status: this.getValueProjectStatus(this.$config.project.status.in_cqa),
                        status_id: this.$config.project.status.in_cqa
                    });

                    this.submitedToClient = true;
                    this.isSubmited = false;
                    this.assignClientModal = false;
                } catch (error) {
                    this.$refs.client_email_form.setErrors(error.response.data.errors);
                    console.error(error);
                } finally {
                    this.submitedLoading = false;
                }
            },
            async submitToClient() {
                const lastUpdatedFiles = this.lastRoundFiles.map(file => {
                    if (file.rounds.length) {
                        return file.rounds[file.rounds.length - 1]
                    } else {
                        return file;
                    }
                });

                this.managerSliderFiles = lastUpdatedFiles;
                this.filesByRounds[this.currentRound] = lastUpdatedFiles;
                this.selectedFile = lastUpdatedFiles.find(file => file.round_base_file_id === this.selectedFile.round_base_file_id);
                this.setSelectedFileVersions();

                await this.$store.dispatch("updateProjectData", {
                    status: this.getValueProjectStatus(this.$config.project.status.in_cqa),
                    status_id: this.$config.project.status.in_cqa
                });
                this.submitedLoading = true;

                try {
                    await projectApi.submitToClient(this.project.id);
                    this.submitedToClient = true;
                    this.showSubmitDialog = false;
                    this.submitedLoading = false;
                    this.isSubmited = false;
                } catch (e) {
                    this.showSubmitDialog = false;
                    this.submitedLoading = false;
                    this.errorSubmitingToClient = true;
                }
            },
            async approveDesign() {
                const data = {
                    id: 1,
                    user_id: this.project.user_id,
                    designer_id: this.project.designer_id,
                    manager_id: this.project.manager_id,
                    title: this.project.title,
                    description: this.project.description,
                    type: this.project.type,
                    status: this.project.status,
                    designer_amount: this.project.designer_amount,
                    links: this.project.links,
                    estimated_date: this.project.estimated_date,
                    created_at: this.project.created_at,
                    updated_at: this.project.updated_at
                };

                this.approving = true;
                try {
                    await projectApi.approveRenders(this.project.id, data)
                    await this.$store.dispatch("updateProjectData", {
                        status: this.getValueProjectStatus(this.$config.project.status.final_assets_prep),
                        status_id: this.$config.project.status.final_assets_prep
                    });
                    await this.$router.push({ name: "project-assets", params: { id: this.$route.params.id } })
                } catch (err) {
                    console.error(err);
                } finally {
                    this.approving = false;
                    this.closeApproveDialog();
                    this.assignClientModal = false
                }
            },

            openApproveDialog() {
                this.approveDialog = true;
            },
            closeApproveDialog() {
                this.approveDialog = false;
            },
            toggleDeleteModal(file = null, slider = "") {
                this.deleteModalData = {
                    showModal: !this.deleteModalData.showModal,
                    file, slider
                };
            },

            async replaceFile(fileData, slider) {
                const removedFile = await UploadService.removeFile(fileData.target);
                if(removedFile) {
                    await UploadService.updateProps(fileData.newFile.id, [{ attachToManager: slider==="designers" }]);
                    await projectApi.bindFile(this.$route.params.id, { files:[fileData.newFile.id] });

                    const fileIndex = this.managerSliderFiles.findIndex(file=>file.id === fileData.target)
                    this.managerSliderFiles.splice(fileIndex, 1 , fileData.newFile)
                }
            },
            async deleteFile(fileData, slider, allVersions = false) {
                const removedFile = await UploadService.removeFile(fileData.id, allVersions);

                if (removedFile) {
                    if (allVersions) {
                        const newFileList = this.filesByRounds[this.selectedRound].filter(file => file.id !== fileData.round_base_file_id);
                        this.filesByRounds[this.selectedRound] = [...newFileList];
                        this.managerSliderFiles = [...newFileList];
                        this.selectedFile = newFileList[0];
                        this.setSelectedFileVersions();
                    } else {
                        const newFileList = this.filesByRounds[this.selectedRound].map(file => {
                            if (file.id === fileData.round_base_file_id) {
                                return {
                                    ...file,
                                    rounds: file.rounds.filter((fileVersion, idx, arr) => {
                                        if (fileVersion.id === fileData.id) {
                                            this.selectedFile = arr.length > 1 ? arr[idx-1] || arr[idx+1] : file;
                                        } else {
                                            return fileVersion;
                                        }
                                    })
                                }
                            } else {
                                return file;
                            }
                        })

                        this.managerSliderFiles = [...newFileList];
                        this.filesByRounds[this.selectedRound] = [...newFileList];
                        this.setSelectedFileVersions();
                    }
                }

                if (this.deleteModalData.file) {
                    this.toggleDeleteModal();
                }
            },

            async selectFile(event, forceAttachToManager = true) {
                if (!event.target.files || !event.target.files.length) {
                    return;
                }
                const validateModel = await this.$refs.provider.validate(event);
                const fileInfosCount = this.fileInfos.length;
                if (!validateModel.valid) {
                    return;
                }
                const invalidArr = [];

                event.target.files.forEach((item) => {
                    item.percentage = 0;
                    item.reloadCount = 1;

                    const fileExtension = "." + item.name.split(".").slice(-1).join("").toLowerCase();

                    if (this.rendersAcceptExtension.includes(fileExtension)) {
                        item.typeImage = this.$config.fileTypes.reference;
                    }

                    if (this.rendersAcceptExtension.includes(fileExtension)) {
                        this.fileInfos.push(item);
                    } else {
                        invalidArr.push(item.name);
                    }
                });

                if (invalidArr.length > 0) {
                    const type = "alertInvalidFileType";
                    this.$store.state.dialogs.content[type].text = `Sorry, ${invalidArr.length === 1 ? "file" : "files"}
                        "${invalidArr.join(",")}" is invalid, allowed extensions are: ${this.rendersAcceptExtension.join(", ")}`;
                    this.$store.commit(this.$store.state.dialogs.content[type].set, true);
                }
                event.target.value = "";
                this.uploadedCount = event.target.files.length;
                if (this.fileInfos.length !== fileInfosCount) {
                    this.uploadFiles(forceAttachToManager);
                }
            },
            async upload(idx, file, forceAttachToManager) {
                try {
                    let data = await UploadService.upload(file, (event) => {
                        const uploadProgeressPercentage = Math.round(
                            (100 * event.loaded) / event.total
                        );
                        file.percentage = uploadProgeressPercentage;

                        if (uploadProgeressPercentage < 100) {
                            this.uploadingFileProgress = uploadProgeressPercentage;
                        }
                    });

                    if (this.isManagerOrAdmin || forceAttachToManager) {
                        data = await UploadService.updateProps(data.id, [
                            { attachToManager: true }
                        ]);
                        data.comments = [];
                        this.managerSliderFiles.push(data)
                    }

                    this.fileInfos.splice(idx, 1, data);
                    if (this.$store.getters.getProjectID > 0) {
                        if (data?.id) {
                            this.userUploadedFilesId.push(data.id);
                            this.userUploadedFiles.push(data);
                        }
                    }
                } catch {
                    file.percentage = 0;
                    this.uploadedCount++;
                    file.message = "Could not upload the file:" + file.name;
                    if (file.reloadCount > 100) {
                        //auto delete
                        this.fileInfos.splice(idx, 1);
                    } else if (!file.deleted) {
                        file.reloadCount++;
                        file.reload = setTimeout(
                            () => this.upload(idx, file),
                            2000 * file.reloadCount
                        );
                    }
                }
                this.uploadedCount--;
                this.uploadingFileProgress = null;
            },
            uploadFiles(forceAttachToManager) {
                const arr = [];
                this.fileInfos.forEach((file, idx) => {
                    if (file.typeImage !== undefined) {
                        arr.push(this.upload(idx, file, forceAttachToManager));
                    }
                })

                Promise.allSettled(arr).then(async () => {
                    await this.addProjectFiles();
                });
            },
            async addProjectFiles() {
                let res = false;
                this.filesLoading = true;
                let data = {
                    files: this.userUploadedFilesId
                };

                if (this.isManagerOrAdmin) {
                    let uploadedFile = null;
                    res = await projectApi.bindFileToRound(this.project.id, {
                        files: this.userUploadedFilesId,
                        round: this.selectedRound,
                        version: this.generateFileVersion()
                    });
                    this.managerSliderFiles = this.managerSliderFiles.map(file => {
                        const userUploadedFile = this.userUploadedFiles.find(uploadedFile => file.id === uploadedFile.id)
                        if (userUploadedFile) {
                            uploadedFile = res.data.data.find(item => item.id === userUploadedFile.id);
                            uploadedFile.round_base_file_id = uploadedFile.id;
                            uploadedFile.comments = [];
                            uploadedFile.version = uploadedFile.version[this.currentRound];
                            return uploadedFile;
                        }
                        return file;
                    });
                    this.setFilesByRounds([...this.managerSliderFiles]);
                    this.selectedFile = uploadedFile;
                    this.setSelectedFileVersions();
                } else {
                    res = await projectApi.bindFile(this.$route.params.id, data);
                }

                if (res) {
                    this.userUploadedFilesId = [];
                    this.userUploadedFiles = [];
                    this.filesLoading = false;
                    this.filesLoadingSnackbar = true;
                }
            },
            async bindFileToProject(file, slider) {
                let sliderComponent = null;
                const originalModel = this.filesByRounds[this.selectedRound].find(item => item.id === file.round_base_file_id);
                file.version = this.generateFileVersion(originalModel?.rounds?.length ? originalModel.rounds[originalModel.rounds.length - 1] : originalModel);
                file.comments = [];

                const response = await projectApi.bindFileToRound(this.project.id, {
                    files: [file.id],
                    round: this.selectedRound,
                    version: file.version
                });

                await UploadService.updateProps(file.id, [{ attachToManager: true }]);
                if (!response) return;

                if (file.round_base_file_id) {
                    originalModel.rounds.push(file);
                    this.filesByRounds[this.selectedRound] = this.filesByRounds[this.selectedRound].map(item => {
                        return item.id === file.round_base_file_id ? originalModel : item;
                    });
                    file.round = [file.round || this.selectedRound];
                    this.selectedFile = originalModel.rounds[originalModel.rounds.length - 1];
                    this.setSelectedFileVersions();
                    this.changeFileVersion(file);
                } else {
                    file.round_base_file_id = file.id;
                    file.round = [file.round || this.selectedRound];
                    sliderComponent = this.$refs["designers-slider"];
                    this.filesByRounds[this.selectedRound] = [...this.filesByRounds[this.selectedRound], file];
                    this.selectedFile = file;
                    this.managerSliderFiles.push(file);
                    this.setSelectedFileVersions();

                    setTimeout(() => {
                        sliderComponent.slideTo(this.managerSliderFiles.length - 1);
                    }, 50);
                }
            },

            async deleteComment({ file, comment }, slider){
                console.log("File and comment: ",  file, comment);

                const deleted = await commentApi.remove(comment)

                const fileIndex = this.managerSliderFiles.findIndex(slide=>slide.id == file);
                const commentIndex = this.managerSliderFiles[fileIndex].comments.findIndex(c=>c.id == comment);
                if(deleted){
                    const updatedComments = this.managerSliderFiles[fileIndex].comments;
                    updatedComments.splice(commentIndex,1)
                    this.$set(this.managerSliderFiles[fileIndex], "comments", updatedComments)
                }
            },

            async deleteComments(commentsData, slider){
                const commentsToStay = commentsData.commentsId
                let fileComments = this.managerSliderFiles[this.managerSliderFiles.findIndex(slide=>slide.id === commentsData.file)].comments;
                if(fileComments){
                    const commentsToDelete = fileComments.filter(comment=>{
                        return !commentsToStay.includes(comment.id);
                    })
                    if(commentsToDelete && commentsToDelete.length>0) {
                        commentsToDelete.forEach(comment=>{
                            this.deleteComment({ file: commentsData.file, comment: comment.id })
                        })
                    }
                }

            },
            async updateComment(commentData, slider){
                const updatedComment = await commentApi.update(commentData.comment.id, {
                    comment: commentData.comment.text
                });

                const fileIndex = this.managerSliderFiles.findIndex(slide=>slide.id == commentData.file);
                const commentIndex = this.managerSliderFiles[fileIndex].comments.findIndex(comment=>comment.id == commentData.comment.id);

                const updatedComments = this.managerSliderFiles[fileIndex].comments;
                updatedComments.splice(commentIndex,1,updatedComment)
                this.$set(this.managerSliderFiles[fileIndex], "comments", updatedComments)
            },

            async toggleAutoDeliverRenders(data = { field: "", value: false }) {
                if (this.autoDeliverRendersLoading) return;
                this.autoDeliverRendersLoading = true;

                await projectApi.editSpecificField(this.project.id, data);
                this.autoDeliverRendersLoading = false;
            },


            generateFileVersion(file = null) {
                if (file) {
                    const currentVersion = parseInt(file.version.replace(/^[^.]*./, ""), 10);
                    return `${this.selectedRound}.${currentVersion+1}`
                }
                return `${this.selectedRound}.0`;
            },
            setProjectStepsLinks() {
                const { in_progress_rendering, ready_for_review_renders } = this.$config.project.status;

                this.projectStepsLinks = [
                    {
                        to: { name: "project", params: { id: this.$route.params.id } },
                        title: "References & Comments",
                        disabled: false,
                        visible: !this.isClient
                    },
                    {
                        to: { name:"project-assets", params: { id: this.$route.params.id } },
                        title: "Final Assets",
                        disabled: [in_progress_rendering, ready_for_review_renders].includes(this.getProjectData.status_id),
                        visible: true
                    },
                    {
                        to: { name: "render-review", params: { id: this.$route.params.id } },
                        title: "Render Review",
                        disabled: false,
                        visible: true
                    }
                ].filter(link => link.visible);


            }
        }
    };
</script>

<style lang="scss" scoped>
.nav-wrapper {
    display: flex;
    align-items: center;
}

.project-step-dd {
    margin-left: 22px;
}

.bs-back-link {
  font-size: 14px;
  line-height: 18px;
  color: #3f7359;

  .circle {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 1px solid #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;

    img {
      display: block;
      width: 5px;
      height: 10px;
    }
  }
}

.support-link {
  font-size: 14px;
  line-height: 18px;
  color: #3F7359;
  text-decoration: underline;
  margin-right: 30px;
}

.files-group-placeholder {
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-bottom: 1px solid #bcbcbc;
      padding-bottom: 10px;

    .title {
      font-size: 14px !important;
      line-height: 18px;
      color: #464646;
      font-family: inherit !important;
    }

    .upload-btn {
      position: relative;
      background-color: transparent;
      color: #3f7359;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      display: block;
      cursor: pointer !important;
      z-index: 1;

      input {
        position: relative;
        opacity: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .files-group-notification {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #464646;
    margin-top: 64px;
  }
}

.project-scroll-section {

  .def-page-cont {
    max-width: 1200px;
  }
}

.slider-column {
    position: relative;
  width: calc(50% - 32px);

  &:first-child {
    margin-right: 32px;
  }

  &:last-child {
    margin-left: 32px;
  }

  .slider-title {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #464646;
    padding: 7px 0;
    border-bottom: 1px solid #bcbcbc;
    margin-bottom: 12px;
  }

    div {
        font-size: 14px;
        img {
            transition: all .3s ease;
        }
        &.is-active img {
            transform: rotate(-180deg);
        }
    }
}

.rounds-selector-menu {
    border-radius: 5px!important;
    padding: 6px 0;
    border: 1px solid #BCBCBC;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15);
    background-color: #fff;
    ul {
        list-style-type: none;
    }
    .rounds-selector {
        max-height: 300px;
        overflow-y: auto;
        & > li {
            margin-bottom: 4px;
            & > span {
                margin-bottom: 4px;
                font-weight: 700;
            }
        }
        font-size: 14px;
        line-height: 18px;
        color: #464646;
        span {
            padding: 4px 16px;
            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }
}

.project-header {
  font-size: 24px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #464646;
}

.link-btn-bold {
  .v-btn__content,
  button .v-btn__content {
    font-weight: bold !important;
  }
}
.apd-btn {
  border: 1px solid #464646 !important;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 14px!important;
  line-height: 18px;
  letter-spacing: 0px;
  color: #464646;

    &:not(.request-changes-btn) {
        background-color: #9ff4a9 !important;
    }

  &.v-btn.v-btn--disabled {
    background-color: #e8e8e8 !important;
    color: #bcbcbc !important;
    border: 1px solid #bcbcbc !important;
  }

    .tooltip-wrapper {
        display: none;
        position: absolute;
        left: -30px;
        bottom: 22px;
        height: 72px;
        width: 180px;
        border-top: 1px solid #c4c4c4;
        background: transparent url('./../../assets/img/configurator-tooltip.png') no-repeat -2px -130px;
        background-size: 184px;
        pointer-events: none;

        .tooltip {
            position: static !important;
            transform: none;
            padding: 12px;
            color: #000;
        }
    }

    &.request-changes-btn .tooltip-wrapper {
        width: 194px;
        background-size: 198px;
        background-position: -2px -144px;
    }
}
.apd-btn-wrap:hover {
    .apd-btn.v-btn.v-btn--disabled .tooltip-wrapper {
        display: block;
    }
}
.pfi-designer {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #545454;
}
.pms-label {
  font-size: 20px;
  line-height: 32px;
  color: #0b3c49;
}
.pms-col {
  &:nth-child(2) {
  }

  @media screen and (max-width: 959px) {
  }
}
.pms-col-sep {
  @media screen and (min-width: 960px) {
    &:before {
      content: "";
      display: block;
      height: 398px;
      border-left: 1px solid #d2d2d2;
      margin-top: 80px;
    }
  }
  @media screen and (max-width: 959px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px 12px;
    &:before {
      content: "";
      display: block;
      border-top: 1px solid #d2d2d2;
    }
  }
}
.pms-img-wrap {
    position:  relative;

    &.absolute-preview {
        position: absolute;
        top: 47px;
        left: 0;
        height: calc(100% - 228px);
        padding: inherit;
        z-index: 2;

        img {
            height: 100%;
        }
    }

    .placeholder-text {
        position: absolute;
        z-index: 2;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        top: 28px;
        left: 30px
    }

  img {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      height: 454px;
      object-fit: cover;
      border-radius: 4px;
      display: block;
  }
}

.hs-edit-ttl {
  position: absolute;
  top: 5px;
  right: -20px;
  cursor: pointer;
  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.7;
  }
}

::v-deep .assets-slider {
    .drawer {
        margin-bottom: 0!important;
        padding: 8px;
        background: #f4f4f4;
        border-radius: 5px 5px 0 0;
        border: 1px solid #bcbcbc;
        border-bottom: none;
    }

    .gallery-top {
        border: 1px solid #bcbcbc;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        .swiper-slide {
            border-radius: 0;
            border: none;
        }
    }

    .gallery-thumbs-wrap {
        padding: 1px 0 2px;
    }
}



::v-deep .client-email-input {
    .v-input__slot {
        min-height: 34px!important;

        input {
            font-size: 12px;
            color: #464646;
            &::placeholder {
                color: #464646;
            }
        }
    }
}

.deliver-renders-checkbox.v-input {
    ::v-deep label {
        font-size: 16px;
        line-height: 16px;
    }
}
</style>

<style lang="scss">
.delete-all-versions-modal {
    border-radius: 0!important;
    background-color: #fff;
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.15)!important;
    color: #464646;
    h4 {
        font-size: 24px;
        line-height: 27px;
        font-weight: 600;
        color: #464646;
    }
    p {
        font-size: 18px;
        line-height: 23px;
    }
    .v-btn {
        font-size: 14px;
        letter-spacing: 0px;
        &.delete-btn {
            background-color: #9ff4a9;
        }
    }
}
.project-in-progress-page {
    .apd-btn .v-btn__content {
        font-size: 14px;
    }
}
</style>
