<template>
    <div
        v-if="!$store.getters.getProjectLoading"
        class="grid ml-auto mr-auto">
        <project-header
            :edit-sku="$route.name === 'project-start-edit'"
            :show-type="false"
            :show-status="false"
            class="mb-4"/>

        <v-row>
            <v-col
                cols="12"
                md="7" class="pos-rel z-2">
                <product-slider
                    ref="slider"
                    id="left-slider"
                    :items="slides"
                    :atachments="jewelryAttachments"
                    :enable-delete="slides.length > 1"
                    :extended-mode="true"
                    @addReply="addReply"
                    @updateFile="updateFile"
                    @addComment="addComment"
                    @addMetall="addMetall"
                    @addStone="addStone"
                    @redoComment="redoComment"
                    @addSnapshot="addSnapshot"
                    @bindFileToProject="bindFileToProject"
                    @deleteAllFileVersions="toggleDeleteModal($event, 'clients')"
                    @deleteComment="deleteComment"
                    @updateComment="updateComment"
                    @deleteComments="deleteComments"/>
            </v-col>
            <v-col
                cols="12"
                md="5"
                style="position: relative">
                <!--<disabled v-if="this.$store.getters['drawer/isDraw']"/>-->
                <project-editor
                    :selected="jewelryAttachments"
                    @selectAtachment="selectJewelryAttachment"
                    @onCollapse="onCollapse"
                    @saveEditable="(saveFunction) => this.$emit('saveEditable', saveFunction)"/>
            </v-col>
        </v-row>

        <dialog-delete-project-file
            :delete-modal-data="deleteModalData"
            @cancel="toggleDeleteModal"
            @delete="deleteFile"/>
    </div>
</template>

<script>
    import ProjectHeader from "@/components/project/ProjectHeader";

    //import ProjectReferencesSlider from "@/views/project/start/review-project/ProjectReferencesSlider";

    import ProductSlider from "@/components/ProductSlider";
    import DialogDeleteProjectFile from "../../../../components/ui/dialog-delete-project-file";
    import ProjectEditor from "@/views/project/start/review-project/ProjectEditor";
    import { instanceComment } from "@/store/index";
    const store = instanceComment();

    import { projectApi, commentApi } from "@/api";
    import { mapGetters } from "vuex";
    import UploadFilesServiceApi from "@/api/uploadFilesService.api"
    import UploadService from "@/api/uploadFilesService.api";

    export default {
        name: "review-project-page",
        async beforeMount() {
            if(!this.$route.params.id) return
            const files = await projectApi.getFiles(this.$route.params.id);
            files.forEach((file) => {
                const fileComments = this.getProjectData.comments[file.id];
                console.log("File comments: ", fileComments);
                let formatedComments = [];
                for (const key in fileComments) {
                    const element = fileComments[key];
                    formatedComments.push(...element);
                }
                file.comments = formatedComments;
                file.modelPreview = "";
            });

            this.slides = files;
        },
        data: () => ({
            commentText: "",
            recalculateSlider: () => {},
            store: store,

            jewelryAttachments: null,
            slides: [],

            uploadModalOpened: false,
            acceptableFileTypes: null,
            multipleFilesUpload: false,
            uploadedFilePreview: null,
            deleteModalData: {
                showModal: false,
                file: null,
                slider: ""
            },
            isReplyLoading: false
        }),
        provide() {
            const isReplyLoading = {}

            Object.defineProperty(isReplyLoading, "loading", {
                enumerable: true,
                get: () => this.isReplyLoading
            })

            return {
                isReplyLoading
            }
        },
        components: {
            ProjectHeader,
            //ProjectReferencesSlider,
            ProjectEditor,
            ProductSlider,
            DialogDeleteProjectFile
        },
        methods: {
            onCollapse() {
                setTimeout(() => this.recalculateSlider && this.recalculateSlider(), 200);
            },
            selectJewelryAttachment(attachment) {
                this.jewelryAttachments = attachment;
            },
            setFocus(msg) {
                console.log(msg);
            },
            updateFile({ fileIndex, canvas_data }) {
                if (this.slides[fileIndex]) {
                    this.$set(this.slides[fileIndex], "canvas_data", canvas_data);
                }
                this.$store.commit("setItemData", null);
                this.jewelryAttachments = null;
            },
            async addComment(comment) {
                const createdComment = await commentApi.create({
                    comment_id: +new Date(),
                    canvas_data: [
                        {
                            brush_width: comment.brush_width,
                            canvas_width: comment.canvas_width,
                            canvas_height: comment.canvas_height,
                            points: comment.points
                        }
                    ],
                    type: this.$config.commentType.comment,
                    username:
                        this.$store.getters.profile.firstName +
                        " " +
                        this.$store.getters.profile.lastName,
                    comment: comment.text,
                    parent_id: null,
                    project_id: parseInt(this.$route.params.id),
                    file_id: comment.fileId,
                    x: Math.round(comment.position.x),
                    y: Math.round(comment.position.y)
                });

                this.slides.forEach((file, index) => {
                    if (file.id === createdComment.file_id) {
                        if (file.comments && Array.isArray(file.comments)) {
                            const commentsList = file.comments;
                            commentsList.push(createdComment);
                            this.$set(this.slides[index], "comments", commentsList);
                        } else {
                            this.$set(this.slides[index], "comments", [createdComment]);
                        }
                    }
                });
            },
            redoComment(comment) {
                switch (comment.type) {
                    case this.$config.commentType.comment:
                        this.addComment(comment)
                        break;
                    case this.$config.commentType.stone:
                        this.addStone(comment)
                        break;
                    case this.$config.commentType.metal:
                        this.addMetall(comment)
                        break;
                }
            },
            async addReply(reply) {
                this.isReplyLoading = true
                try {
                    const response = await commentApi.reply(reply.content.parent_id, {
                        canvas_data: [],
                        comment: reply.content.comment,
                        comment_id: +new Date,
                        file_id: reply.fileId,
                        parent_id: reply.content.parent_id,
                        project_id: this.$route.params.id,
                        type: 1,
                        username: this.$store.getters.profile.firstName + " " +this.$store.getters.profile.lastName
                    })

                    this.slides.forEach((file, index) => {
                        if (file.id === response.file_id) {
                            if (file.comments && Array.isArray(file.comments)) {
                                const commentsList = file.comments;
                                commentsList.push(response);
                                this.$set(this.slides[index], "comments", commentsList);
                            } else {
                                this.$set(this.slides[index], "comments", [response]);
                            }
                        }
                    });
                } catch (e) {
                    console.log(e)
                } finally {
                    this.isReplyLoading = false
                }

            },
            async addMetall(metalData) {
                const metallComment = await commentApi.create({
                    comment_id: +new Date(),
                    canvas_data: [
                        {
                            brush_width: metalData.brush_width,
                            canvas_width: metalData.canvas_width,
                            canvas_height: metalData.canvas_height,
                            points: metalData.points
                        }
                    ],
                    type: this.$config.commentType.metal,
                    username:
                        this.$store.getters.profile.firstName +
                        " " +
                        this.$store.getters.profile.lastName,
                    comment: metalData.comment,
                    parent_id: null,
                    project_id: parseInt(this.$route.params.id),
                    file_id: metalData.file_id,
                    metal_type: metalData.metal_type,
                    x: Math.round(metalData.x),
                    y: Math.round(metalData.y)
                });
                console.log("add metal commnet: ", metallComment);
                this.addCommentToFile(metallComment.file_id, metallComment);
                // this.jewelryAttachments = null;
            },
            async addStone(stoneData) {
                const stoneComment = await commentApi.create({
                    comment_id: +new Date(),
                    canvas_data: [
                        {
                            brush_width: stoneData.brush_width,
                            canvas_width: stoneData.canvas_width,
                            canvas_height: stoneData.canvas_height,
                            points: stoneData.points
                        }
                    ],
                    type: this.$config.commentType.stone,
                    username:
                        this.$store.getters.profile.firstName +
                        " " +
                        this.$store.getters.profile.lastName,
                    comment: stoneData.comment,
                    stone_shape: [this.getCutStones[0].id],
                    parent_id: null,
                    project_id: parseInt(this.$route.params.id),
                    file_id: stoneData.file_id,
                    stone_type: stoneData.stone_type,
                    x: Math.round(stoneData.x),
                    y: Math.round(stoneData.y)
                });
                console.log("add stone commnet: ", stoneComment);
                this.addCommentToFile(stoneComment.file_id, stoneComment);
                // this.jewelryAttachments = null;
            },
            addCommentToFile(fileId, comment) {
                const fileIndex = this.slides.findIndex((slide) => slide.id === fileId);
                if (fileIndex >= 0) {
                    let comments
                    if(this.slides[fileIndex].comments && this.slides[fileIndex].comments.length>0){
                        comments = [...this.slides[fileIndex].comments, comment];
                    } else {
                        comments = [comment]
                    }

                    this.$set(this.slides[fileIndex], "comments", comments);
                }
            },
            async addSnapshot(snapshot) {
                const file = snapshot[0];
                file.typeImage = 2
                this.$refs.slider.setPreloader(true)
                const response = await UploadFilesServiceApi.upload(file);
                const responseFile = await UploadService.updateProps(response.id, [{ isSnapshot: true }]);
                const bindedToProject = await projectApi.bindFile(this.$route.params.id, { files:[responseFile.id] });

                this.$refs.slider.setPreloader(false)

                if(bindedToProject){
                    responseFile.modelPreview = "";
                    this.slides.push(responseFile)
                    setTimeout(()=>{
                        this.$refs.slider.slideTo(this.slides.length-1)
                    }, 200)
                }
            },
            async bindFileToProject(file){
                const response = await projectApi.bindFile(this.$route.params.id, { files:[file.id] });
                if(response) {
                    file.modelPreview = "";
                    this.slides.push(file)
                    setTimeout(()=>{
                        this.$refs.slider.slideTo(this.slides.length-1)
                    }, 200)
                }
            },

            async deleteComment({ file, comment }){
                const fileIndex = this.slides.findIndex(slide=> +slide.id === +file);
                const commentIndex = this.slides[fileIndex].comments.findIndex(cmt => +cmt.id === +comment);

                const deleted = await commentApi.remove(comment)
                if(deleted){
                    const updatedComments = this.slides[fileIndex].comments;
                    updatedComments.splice(commentIndex,1)
                    this.$set(this.slides[fileIndex], "comments", updatedComments)
                }
            },
            async deleteComments(commentsData){
                const commentsToStay = commentsData.commentsId
                const fileComments = this.slides[this.slides.findIndex(slide=>slide.id === commentsData.file)].comments;

                const commentsToDelete = fileComments.filter(comment=>{
                    return !commentsToStay.includes(comment.id);
                })

                console.log("commentsToDelete", commentsToDelete);
                if(commentsToDelete && commentsToDelete.length>0) {
                    commentsToDelete.forEach(comment=>{
                        this.deleteComment({ file: commentsData.file, comment: comment.id })
                    })
                }
            },
            async updateComment(commentData){
                const fileIndex = this.slides.findIndex(slide=>slide.id === commentData.file);
                const commentIndex = this.slides[fileIndex].comments.findIndex(comment=>comment.id === commentData.comment.id);
                const updateData = Object.assign({ comment: commentData.comment.text }, commentData.comment)

                delete updateData.text
                delete updateData.id

                const updatedComment = await commentApi.update(commentData.comment.id, updateData);

                const updatedComments = this.slides[fileIndex].comments;
                updatedComments.splice(commentIndex,1,updatedComment)
                this.$set(this.slides[fileIndex], "comments", updatedComments)
            },
            toggleDeleteModal(file = null, slider = "") {
                this.deleteModalData = {
                    showModal: !this.deleteModalData.showModal,
                    file, slider
                };
            },
            async deleteFile() {
                const removedFile = await UploadFilesServiceApi.removeFile(this.deleteModalData.file.id);

                if (removedFile) {
                    this.slides = this.slides.filter(slide => slide.id !== this.deleteModalData.file.id);
                }

                this.toggleDeleteModal();
            }
        },
        computed: {
            ...mapGetters(["getProjectData", "profile", "getCutStones", "getValueProjectStatus"])
        }
    };
</script>

<style lang="scss" scoped>
.grid {
  max-width: 1074px;
  width: 100%;
}
</style>
