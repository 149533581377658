<template>
    <div class="deatils-container">
        <div class="conteiner-title">
            MODELING TECHNICAL SPECIFICATIONS
        </div>
        <div class="content">
            <ul class="py-3 ">
                <li><b class="font-weight-bold">Geometry:</b> Geometry should be subdivision-ready without normals artifacts</li>
                <li><b class="font-weight-bold">Subdivisions:</b> Any subdivision modifiers should not be collapsed</li>
                <li><b class="font-weight-bold">Pivot Point:</b> Pivot Point should be at the world origin</li>
                <li><b class="font-weight-bold">Rotation:</b> Rotation should be set to 0,0,0</li>
                <li><b class="font-weight-bold">Scale:</b> Scale should be reset</li>
                <li><b class="font-weight-bold">Orientation:</b> Place a jewelry piece that the front is facing Y- (3dsmax)</li>
                <li><b class="font-weight-bold">Display units:</b> Please use inch as your system and display units</li>
                <li><b class="font-weight-bold">Required Formats:</b> Final submission should be exported as OBJ or FBX file formats</li>
                <li><b class="font-weight-bold">Naming:</b> Name your models according to SKU</li>
                <li>No UV unwrap, materials or renders are needed.</li>
                <li>If a jewelry piece is made out of 2 or more different metals, those parts should be separated topology islands</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "modeling-tech-specs"
    }
</script>

