<template>
    <v-tooltip top nudge-bottom="10px" nudge-right="50%" min-width="160"
               content-class="tooltip-information"
               v-if="statusId === $config.extraChargeStatuses.paid || statusId === $config.extraChargeStatuses.skipped">
        <template #activator="{on, attrs}">
            <span class="d-flex" :class="activatorClass" v-on="on" v-bind="[attrs, $attrs]">
                <svg :width="size" :height="size"
                     viewBox="0 0 20 20"
                     :fill="color"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9.99984" cy="10.0003" r="7.83333" stroke="#464646"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.56095 6.92552C9.62925 7.04076 9.77344 7.09838 9.99352 7.09838C10.1149 7.09838 10.2136 7.07918 10.2895 7.04076C10.373 7.00235 10.4299 6.94856 10.4602 6.87942C10.4982 6.80259 10.5209 6.73344 10.5285 6.67197C10.5437 6.60282 10.5513 6.52215 10.5513 6.42995C10.5513 6.22251 10.5172 6.05732 10.4489 5.9344C10.3806 5.81147 10.2288 5.75 9.99352 5.75C9.83415 5.75 9.71273 5.78842 9.62925 5.86525C9.54577 5.94208 9.49644 6.02275 9.48126 6.10726C9.46608 6.1841 9.4585 6.29166 9.4585 6.42995C9.4585 6.6374 9.49265 6.80259 9.56095 6.92552ZM10.4445 14.25H9.56091V11.3457V8.41663H10.4445L10.4445 11.3457L10.4445 14.25Z" fill="#464646"/>
                </svg>
            </span>
        </template>

        <span class="font-weight-regular">
            <template v-if="statusId === $config.extraChargeStatuses.paid">
                Client <b class="font-weight-regular green-60--text">paid</b> extra charges.
                <br>
                Proceed with changes
            </template>
            <template v-if="statusId === $config.extraChargeStatuses.skipped">
                Client did <b class="font-weight-regular red-30--text">not paid</b>.
                <br>
                Proceed without changes
            </template>
        </span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "extra-charge-status-tooltip",
        props: {
            activatorClass: {
                type: [String, Array, Object],
                default: ""
            },
            size: {
                type: Number,
                default: 20
            },
            color: {
                type: String,
                default: "none"
            },
            statusId: {
                type: Number,
                default: null
            }
        }
    }
</script>
