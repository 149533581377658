<template>
    <v-dialog
        v-model="deleteModalData.showModal"
        width="490"
        @keydown.esc="$emit('cancel')"
        @click:outside="$emit('cancel')"
        content-class="delete-all-versions-modal pa-8">
        <template v-if="deleteModalData.file">
            <h4 v-if="deleteModalData.file.type === $config.fileTypes.models" class="mb-8">Delete 3D model</h4>
            <h4 v-else class="mb-8">Are you sure you want to delete this file?</h4>
            <p v-if="deleteModalData.file.type === $config.fileTypes.models" class="mb-8">Deleting 3D model will delete all of it's versions. Continue?</p>
            <p v-else class="mb-8">If you delete this file, all comments will be deleted as well.</p>
        </template>
        <div class="d-flex justify-end">
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                @click="$emit('cancel')"
                class="mr-3">
                <template>Cancel</template>
            </v-btn>
            <v-btn
                width="110"
                height="40"
                outlined
                rounded
                @click="$emit('delete')"
                class="delete-btn">
                <template>Delete</template>
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "dialog-delete-project-file",
        props: {
            deleteModalData: {
                type: Object,
                default: () => ({
                    showModal: false,
                    file: null
                })
            }
        }
    }
</script>
