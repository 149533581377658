import { render, staticRenderFns } from "./StartProjectTab.vue?vue&type=template&id=663d8fca&scoped=true"
import script from "./StartProjectTab.vue?vue&type=script&lang=js"
export * from "./StartProjectTab.vue?vue&type=script&lang=js"
import style0 from "./StartProjectTab.vue?vue&type=style&index=0&id=663d8fca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663d8fca",
  null
  
)

export default component.exports